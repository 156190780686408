import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { OpenDayReportView } from './open-day-report.view';
import { Router } from '@angular/router';
import { MessageService, DialogService } from 'primeng/api';
import { UiUtils } from 'src/app/core/ui-utils';
import { DayRegisterDetailModel } from 'src/app/models/cash-register.model';
import { ReportOpenDayModel } from 'src/app/models/report.model';
import { OpenDayReportPresenter } from './presenter/open-day-report.presenter';
import { DomainAction } from 'src/app/models/rol.model';
import { OpenDayAdvanceComponent } from '../../open-day-advance/open-day-advance.component';

@Component({
  selector: 'app-open-day-report',
  templateUrl: './open-day-report.component.html',
  styleUrls: ['./open-day-report.component.scss']
})
export class OpenDayReportComponent extends AbstractView implements OnInit, OpenDayReportView {

  reportOpenDays: ReportOpenDayModel[];
  searchValue: '';
  initDate = null;
  endDate = null;
  page = 0;
  size = 20;
  totalElements: number;
  public ref;
  selectedReport: ReportOpenDayModel;
  id = '';
  closed: true;
  daySalesDate: Date;
  constructor(
    private messageService: MessageService,
    public router: Router,
    private openDayReportPresenter: OpenDayReportPresenter,
    public uiUtils: UiUtils,
    public dialogService: DialogService) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_REPORT_CASH_OPENING)) {
      this.redirectTo('/panel/pos');
    }
    this.daySalesDate = new Date();
    openDayReportPresenter.view = this;
    this.blockUi();
    openDayReportPresenter.onSearchOpenDayReport();

  }

  ngOnInit() {
  }

  paginate($event) {
    this.page = $event['page'];
    this.openDayReportPresenter.onSearchOpenDayReport();
  }

  searchOpenDayReports() {
    this.page = 0;
    this.openDayReportPresenter.onSearchOpenDayReport();
  }

  showOpenDayAdvanceComponent(report: ReportOpenDayModel) {
    this.ref = this.dialogService.open(OpenDayAdvanceComponent, {
      header: 'AVANCES ',
      closeOnEscape: true,
      data: report
    });
  }

  printReport(report: any) {
    this.id = report.cashRegisterId;
    this.openDayReportPresenter.printOpenCashRegister();
  }

  clearFilters() {
    this.initDate = null;
    this.endDate = null;
    this.searchValue = null;
    this.searchOpenDayReports();
  }

  clearUser() {
    this.searchValue = null;
    this.searchOpenDayReports();
  }

  printDayReport() {
    this.openDayReportPresenter.getDaySales();
  }

}
