import { Action } from '@ngrx/store';

import { User } from 'src/app/models/user.model';

export enum ActionTypes {
  LOGIN = 'LOGIN'
}

export class LogIn implements Action {
  constructor(public user: User) { }
  readonly type = ActionTypes.LOGIN;
}

export type ActionsUnion = LogIn;
