import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordService } from 'src/app/services/change-password.service';
import { ResetPasswordView } from '../reset-password.view';

@Injectable()
export class ResetPasswordPresenter {
    view: ResetPasswordView;
    constructor(
        private changePasswordService: ChangePasswordService
    ) { }

    confirmReset() {
        this.changePasswordService.confirmReset(this.view.token).subscribe(res => {
            this.view.verifying = false;
        }, (error: HttpErrorResponse) => {
            this.view.showError('Advertencia', error.error.message);
            this.view.goTohome();
        });
    }
    resetPasword() {
        this.changePasswordService.resetPassword(this.view.user, this.view.token).subscribe(res => {
            this.view.showSuccess('', 'Contraseña actualizada con éxito');
            setTimeout(() => {
                this.view.goTohome();
            }, 1000);
        }, (error: HttpErrorResponse) => {
            this.view.showError('Error al actualizar contraseña', error.error.message);

        });
    }

}
