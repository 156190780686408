import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientModel } from 'src/app/models/client.model';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { Product } from 'src/app/models/product.model';
import { ListSalesPrices, ReportSalesPriceModel, ResumeSalesPrices } from 'src/app/models/reportsalesprice.modal';
import { DataProvider } from 'src/app/services/data.provider';
import { ReportSalesPricesPresenter } from './presenter/report-prices-sales.presenter';
import { ReportSalesPricesView } from './report-sales-prices.view';

@Component({
  selector: 'app-report-prices-sales',
  templateUrl: './report-prices-sales.component.html',
  styleUrls: ['./report-prices-sales.component.scss']
})
export class ReportPricesSalesComponent extends AbstractView implements OnInit, ReportSalesPricesView {

  initDate = new Date();
  endDate = new Date();
  visible = false;
  data: any;
  listSalesPrice: ListSalesPrices[];
  resumeSalesPrice: ResumeSalesPrices[];
  users: UserPresenter[] = [];
  searchValue: '';
  totalElements: number;
  selectedUser: UserPresenter;
  showSelectedUser = false;
  showSelectedProduct: boolean;
  selectedProduct: Product;
  productId = '';
  products: Product[];
  searchProduct: string;
  documentNo = '';
  pageInvoice = 0;
  sizeInvoice = 20;
  invoices: ListSalesPrices[];
  totalElementsInvoice: number;
  categorySelected = '';
  reportSalesPrice: ReportSalesPriceModel[];

  constructor(
    private messageService: MessageService,
    public router: Router,
    private reportPricesSalesPresenter: ReportSalesPricesPresenter,
    private dataProvider: DataProvider,
  ) {
    super(messageService, router);
    reportPricesSalesPresenter.view = this;
  }

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.reportPricesSalesPresenter.getInvoicesForUserReport();
  }

  addUser() {
    this.reportPricesSalesPresenter.findInvoicesByUser();
  }

  clearUser() {
    this.reportPricesSalesPresenter.clearUser();
  }

  onSearchUser(event) {
    this.searchValue = event.query;
    this.reportPricesSalesPresenter.onSearchUser();
  }

  clearProduct() {
    this.reportPricesSalesPresenter.clearProduct();
  }

  addProduct() {
    this.reportPricesSalesPresenter.findReportsByProduct();
  }

  onSearchProduct(event) {
    this.searchProduct = event.query;
    this.reportPricesSalesPresenter.onSearchProduct();
  }

  hardwareDetail(type) {
    this.reportPricesSalesPresenter.getInvoicesHeavyOrHardware(type);
  }

  paginateInvoice($event) {
    this.pageInvoice = $event['page'];
    this.reportPricesSalesPresenter.getInvoicesHeavyOrHardware(this.categorySelected);
  }

}
