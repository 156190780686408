import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientModel, ProductsByClient, ReportProductsByClient } from 'src/app/models/client.model';
import { Product } from 'src/app/models/product.model';
import { ReportClientsByProductsPresenter } from './presenter/report-clients-by-products.presenter';
import { ReportClientsByProductsView } from './report-clients-by-products.view';

@Component({
  selector: 'app-report-clients-by-products',
  templateUrl: './report-clients-by-products.component.html',
  styleUrls: ['./report-clients-by-products.component.scss']
})
export class ReportClientsByProductsComponent extends AbstractView implements OnInit, ReportClientsByProductsView {

  showSelectedProduct: boolean;
  selectedProduct: Product;
  searchProduct: string;
  products: Product[];

  showSelectedClient: boolean;
  selectedClient: ClientModel;
  searchClient: string;
  clients: ClientModel[];

  initDate = new Date();
  endDate = new Date();
  reports: ReportProductsByClient[];
  reportsCSV: ProductsByClient[];
  selectedResult: ReportProductsByClient[];
  page = 0;
  size = 20;
  totalElements = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20];
  pageSize = 20;


  constructor(
    public service: MessageService,
    public router: Router,
    public reportPresenter: ReportClientsByProductsPresenter,
  ) {
    super(service, router);
    reportPresenter.view = this;
  }

  ngOnInit() {
    this.reportPresenter.getReport();
  }

  getReport() {
    this.reportPresenter.getReport();

  }
  clearProduct() {
    this.reportPresenter.clearProduct();
  }
  clearClient() {
    this.reportPresenter.clearClient();
  }
  addProduct() {
    this.reportPresenter.findReportsByProduct();
  }
  addClient() {
    this.reportPresenter.findReportsByClient();
  }
  onSearchProduct(event) {
    this.searchProduct = event.query;
    this.reportPresenter.onSearchProduct();
  }
  onSearchClient(event) {
    this.searchClient = event.query;
    this.reportPresenter.onSearchClient();
  }
  paginate($event) {
    this.page = $event['page'];
    this.reportPresenter.getReport();
  }
  getData(event?: PageEvent) {
    this.selectedResult = this.reports.slice(event.pageIndex * event.pageSize,
      event.pageIndex * event.pageSize + event.pageSize);
    console.log(this.selectedResult);
    return event;
  }
  getReportClient() {
    this.reportPresenter.getReportClient();
  }
  getReportClientPdf() {
    this.reportPresenter.getReportClient();
  }
  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
