import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductQuestComponent } from './product-quest/product-quest.component';
import { InvoiceHeaderComponent } from './invoice-header/invoice-header.component';
import { InvoiceBalanceComponent } from './invoice-balance/invoice-balance.component';
import { PosComponent } from './pos.component';
import { TableModule } from 'primeng/table';
import {
    DropdownModule,
    PaginatorModule,
    ButtonModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    CalendarModule,
    TooltipModule,
    ConfirmationService,
    KeyFilterModule,
    InputTextareaModule,
    SplitButtonModule,
    InputMaskModule,
    ChartModule,
    InputSwitchModule,
    RadioButtonModule,
    CheckboxModule,
    ProgressBarModule
} from 'primeng/primeng';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SlideshowModule } from 'ng-simple-slideshow';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { InvoiceBalancePresenter } from './invoice-balance/presenter/invoice-balance.presenter';
import { SearchOrderComponent } from './search-order/search-order.component';
import { PaymentsDetailPresenter } from './payments-detail/presenter/payments-detail.presenter';
import { InputTextModule } from 'primeng/inputtext';
import { EnterPasswordComponent } from './enter-password/enter-password.component';
import { SearchQuotationComponent } from './search-quotation/search-quotation.component';
import { PromotionSugestionsComponent } from './promotion-sugestions/promotion-sugestions.component';
import { LinePromotionsComponent } from './line-promotions/line-promotions.component';
import { AddLinesComponent } from '../dashboard/tranfers/components/add-lines/add-lines.component';
import { SharedModule } from 'src/app/main-components/shared/shared/shared.module';
@NgModule({
    declarations: [
        PosComponent,
        ProductQuestComponent,
        InvoiceHeaderComponent,
        InvoiceBalanceComponent,
        SearchOrderComponent,
        EnterPasswordComponent,
        SearchQuotationComponent,
        PromotionSugestionsComponent,
        LinePromotionsComponent,
        AddLinesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        TableModule,
        PaginatorModule,
        AutoCompleteModule,
        ButtonModule,
        ScrollPanelModule,
        ToastModule,
        TableModule,
        DropdownModule,
        AutoCompleteModule,
        SlideshowModule,
        ConfirmDialogModule,
        CalendarModule,
        TooltipModule,
        KeyFilterModule,
        InputTextareaModule,
        SplitButtonModule,
        InputMaskModule,
        ButtonModule,
        ChartModule,
        InputSwitchModule,
        RadioButtonModule,
        InputTextModule,
        CheckboxModule,
        ProgressBarModule,
        SharedModule
    ],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [InvoiceBalancePresenter, ConfirmationService, PaymentsDetailPresenter]


})
export class PosModule {
}
