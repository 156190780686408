import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientAdvanceReportView } from './client-advance-report.view';
import { Router } from '@angular/router';
import { MessageService, DialogService } from 'primeng/api';
import { UiUtils } from 'src/app/core/ui-utils';
import { ClientAdvanceReportModel } from 'src/app/models/client-advance-report.model';
import { ClientAdvanceReportPresenter } from './presenter/client-advance-report.presenter';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-client-advance-report',
  templateUrl: './client-advance-report.component.html',
  styleUrls: ['./client-advance-report.component.scss']
})

export class ClientAdvanceReportComponent extends AbstractView implements OnInit, ClientAdvanceReportView {

  reportClientAdvance: ClientAdvanceReportModel[];
  withData: boolean;
  initDate: Date;
  endDate: Date;
  constructor(
    private messageService: MessageService,
    public router: Router,
    private clientAdvanceReportPresenter: ClientAdvanceReportPresenter,
    public uiUtils: UiUtils,
    public dialogService: DialogService) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_REPORT_CLIENT_ADVANCE)) {
      this.redirectTo('/panel/pos');
    }
    clientAdvanceReportPresenter.view = this;
  }

  ngOnInit() {
  }

  getClientAdvanceReport() {
    this.clientAdvanceReportPresenter.getClientAdvanceReport();
  }

  downloadClientAdvanceReport() {
    this.clientAdvanceReportPresenter.downloadClientAdvanceReport();
  }

}
