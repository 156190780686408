
import * as $params from './order.action';
import { OrderLine } from '../models/orderline.model';
import { Order } from '../models/order.model';
import { InvoiceModel } from '../models/invoice.model';
import { Product } from '../models/product.model';
import { MeasureConversionComboModel } from '../models/measure-combo.model';
import { MeasureConversionModel } from '../models/measure-conversion.model';
import { ProcessModel } from '../models/process.model';
import { round } from '../core/utils';
import { Decimal } from 'decimal.js';
import { Settings, GeneralConfigurations } from '../services/defaultconfigurations.service';
import { PromotionModel, Item } from '../models/promotion-message.model';
import { DinamicTax, TaxModelList } from '../models/tax.model';

export interface IAppState {
    orderLines: OrderLine[];
    subTotal12: number;
    subTotal0: number;
    totalTax: number;
    updateOrder: Order;
    invoice: InvoiceModel;
    isInvoiceAllowed: boolean;
    observation: string;
    procesesSelected: ProcessModel;
    autoAddStatus: boolean;
    totalFEDiscount: number;
    totalAgreementDiscount: number;
    totalCardsIncrement?: number;
    pvpSubtotal?: number;
    creditCardPayment?: boolean;
    incrementPercentage?: number;
    billetonAmount?: { subC: number, subD: number };
    isBilleton?: boolean;
    isAdminPriceDiscount?: boolean;
    subTotalWithoutDiscounts?: number;
    discountPromotion?: number;
    discountRecycling?: number;
    deferred?: number;
    current?: number;
    creditred?: number;
    commissionCard?: number;
    processPayment?: string;
    taxModelList?: TaxModelList;
}

export const INITIAL_STATE: IAppState = {
    orderLines: [],
    subTotal12: 0,
    subTotal0: 0,
    totalTax: 0,
    updateOrder: null,
    invoice: null,
    isInvoiceAllowed: false,
    procesesSelected: ProcessModel.INVOICE,
    observation: null,
    autoAddStatus: false,
    totalFEDiscount: 0,
    totalAgreementDiscount: 0,
    totalCardsIncrement: 0,
    pvpSubtotal: 0,
    creditCardPayment: true,
    incrementPercentage: 0,
    billetonAmount: { subC: 0, subD: 0 },
    isBilleton: false,
    isAdminPriceDiscount: false,
    subTotalWithoutDiscounts: 0,
    discountPromotion: 0,
    discountRecycling: 0,
    deferred: 0,
    current: 0,
    creditred: 0,
    commissionCard: 0,
    processPayment: '',
    taxModelList: null
};


export function OrderReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {
    switch (action.type) {
        case $params.OrderActions.SET_OBSERVATION:
            return Object.assign({}, state, {
                observation: action.observation
            });
        case $params.OrderActions.ADD_ORDER_LINE:
            let itemInTable = false;
            let orderWithId: OrderLine = null;
            let orderWithOutId: OrderLine = null;
            state.commissionCard = action.commissionCard;
            state.processPayment = action.processPayment;
            const productIdFound = null;
            state.orderLines.forEach(line => {
                if (line.productId === action.orderLine.productId &&
                    line.warehousePresenter.referenceId === action.orderLine.warehousePresenter.referenceId) {
                    if (line.inventoryMovementDetailId) {
                        if (line.warehousePresenter.referenceId === action.orderLine.warehousePresenter.referenceId
                            && line.active) {
                            line.enteredQuantity++;
                            orderWithId = line;
                            itemInTable = true;
                        } else if (line.warehousePresenter.referenceId === action.orderLine.warehousePresenter.referenceId
                            && !line.active) {
                            line.enteredQuantity = 1;
                            line.active = true;
                            orderWithId = line;
                            itemInTable = true;
                        }
                    } else {
                        if (line.warehousePresenter.referenceId === action.orderLine.warehousePresenter.referenceId
                            && line.active) {
                            line.enteredQuantity++;
                            orderWithOutId = line;
                            itemInTable = true;
                        }
                    }
                    calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                }
            });

            if (productIdFound) {
                const orderLinesWithProductId: OrderLine[] = [];
                const orderLinesWithoutProductId: OrderLine[] = [];
                state.orderLines.forEach(line => {
                    if (line.product.productId === productIdFound) {
                        orderLinesWithProductId.push(line);
                    } else {
                        orderLinesWithoutProductId.push(line);
                    }
                });
                state.orderLines = orderLinesWithProductId.concat(orderLinesWithoutProductId);
            }


            if (orderWithOutId) {
                state.orderLines = removeLines(state.orderLines, orderWithOutId);
                state.orderLines.splice(0, 0, orderWithOutId);

            } else if (orderWithId) {
                state.orderLines = state.orderLines.filter(line => (line.inventoryMovementDetailId !==
                    orderWithId.inventoryMovementDetailId));
                state.orderLines.splice(0, 0, orderWithId);
            }

            if (itemInTable) {
                return Object.assign({}, state, {
                    orderLines: state.orderLines,
                    totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                    totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard, state.processPayment),
                    totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                    billetonAmount: getBilletonAmount(state.orderLines),

                    subTotal12: getSubtotal12(state.orderLines),
                    current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                    deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                        state.commissionCard, state.processPayment),
                    creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                        state.commissionCard, state.processPayment),
                    discountRecycling: getDiscountRecycling(state.orderLines),
                    subTotal0: getSubtotal0(state.orderLines),
                    tax: getTax(state.orderLines),
                    totalTax: getTotalTax(state.orderLines),
                    discountPromotion: getPromotionDiscount(state.orderLines),
                    getDiscountRecycling: getDiscountRecycling(state.orderLines),
                    pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                    procesesSelected: state.procesesSelected,
                    taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
                });
            } else {
                state.orderLines.splice(0, 0, action.orderLine);
                calcLineTotal(action.orderLine, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                return Object.assign({}, state, {
                    orderLines: state.orderLines,
                    subTotal12: getSubtotal12(state.orderLines),
                    current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                    deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                        state.commissionCard, state.processPayment),
                    creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                        state.commissionCard, state.processPayment),
                    discountRecycling: getDiscountRecycling(state.orderLines),
                    subTotal0: getSubtotal0(state.orderLines),
                    tax: getTax(state.orderLines),
                    totalTax: getTotalTax(state.orderLines),
                    discountPromotion: getPromotionDiscount(state.orderLines),
                    getDiscountRecycling: getDiscountRecycling(state.orderLines),
                    pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                    totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                    totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                        state.processPayment),
                    totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                    billetonAmount: getBilletonAmount(state.orderLines),
                    procesesSelected: state.procesesSelected,
                    taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
                });
            }


        case $params.OrderActions.CHANGE_LINE_QUIANTITY:
            state.orderLines.forEach(line => {
                if (line.productId === action.orderLine.productId &&
                    line.warehousePresenter.referenceId === action.orderLine.warehousePresenter.referenceId) {
                    if (action.orderLine.enteredQuantity < line.enteredQuantity) {
                        line.discount = 0;
                        line.discountAgremment = 0;
                        line.promotions = null;
                    }
                    line.enteredQuantity = action.orderLine.enteredQuantity;
                    line.selectedMeasure = action.orderLine.selectedMeasure;
                    line.discount = action.orderLine.discount;
                    line.discountPromotion = 0;
                    calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                }
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.CHANGE_LINE_QUIANTITY_RECYCLING:
            state.orderLines.forEach(line => {
                if (line.productId === action.orderLine.productId &&
                    line.warehousePresenter.referenceId === action.orderLine.warehousePresenter.referenceId) {
                    if (!action.orderLine.isRecycling) {
                        line.quantityRecycling = 0;
                    }
                    line.isRecycling = action.orderLine.isRecycling;
                    calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                }
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });

        case $params.OrderActions.CHANGE_LINE_DISCOUNT:
            state.orderLines.forEach(line => {
                if (line.productId === action.orderLine.productId) {
                    line.error = action.orderLine.error;
                    line.discount = action.orderLine.discount;
                    // line.product.priceWithoutTax = action.orderLine.product.priceWithoutTax;
                    calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                }
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),

                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.APPLY_PROMOTIONS:
            applyPromotions(action.promotions, state.orderLines, state.procesesSelected, state.commissionCard, state.processPayment);
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)

            });
        case $params.OrderActions.SET_DISCOUNT_TO_CERO:
            state.orderLines.forEach(line => {
                line.discount = 0;
                line.discountAgremment = 0;
                calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.ADD_INCREMENT:
            state.orderLines.forEach(line => {
                calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                incrementPercentage: action.value,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });

        case $params.OrderActions.SET_DEFAULT_PRICE:
            let ifServiceCredired = false;
            state.orderLines.forEach(line => {
                if (line.referenceCode === 'L20179') {
                    ifServiceCredired = true;
                }
            });
            state.orderLines.forEach(line => {
                if (!ifServiceCredired) {
                    line.product.normalPriceWithoutTax = line.selectedMeasure.normalPrice;
                    line.product.priceWithoutTax = line.selectedMeasure.price;
                    calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                }
                /* line.product.normalPriceWithoutTax = line.selectedMeasure.normalPrice;
                line.product.priceWithoutTax = line.selectedMeasure.price;
                calcLineTotal(line, state.isAdminPriceDiscount); */
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                incrementPercentage: 0,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.SET_DEFAULT_PRICE_CREDIRED:
            state.orderLines.forEach(line => {
                line.product.normalPriceWithoutTax = line.selectedMeasure.normalPrice;
                line.product.priceWithoutTax = line.selectedMeasure.normalPrice;
                calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard,
                    state.processPayment);
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                incrementPercentage: 0,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.SET_CLIENT_AGREEMENT_DISCOUNT:
            state.orderLines.forEach(line => {
                const maxDiscount = new Decimal(100)
                    .minus(new Decimal(line.product.priceLimitWithoutTax).mul(100).div(line.selectedMeasure.price)).toNumber();
                line.maxDiscount = maxDiscount;
                const discount = action.productsDiscount[line.product.productId] ? action.productsDiscount[line.product.productId] : 0;
                line.discount = (line.discount === 0 || discount > line.discount) ? discount : line.discount;
                line.discount = line.discount > line.maxDiscount ? line.maxDiscount : line.discount;
                line.discountAgremment = line.discount > line.maxDiscount ? line.maxDiscount : line.discount;
                line.agreementType = action.agreementType;
                calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),

                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.CHANGE_LINE_UNIT_MEASURE:
            state.orderLines.forEach(line => {
                if (line.productId === action.orderLine.productId) {
                    line.selectedMeasure = action.orderLine.selectedMeasure;
                    line.product.priceWithoutTax = line.selectedMeasure.price;
                    line.product.priceLimitWithoutTax = line.selectedMeasure.basePrice;
                    line.product.normalPriceWithoutTax = line.selectedMeasure.normalPrice;
                    line.product.specialPrice = line.selectedMeasure.specialPrice;
                    line.product.wholesalerPrice = line.selectedMeasure.wholesalerPrice;
                    line.product.administratorPrice = line.selectedMeasure.administratorPrice;
                    line.product.basePrice = line.selectedMeasure.basePrice;
                    line.discount = 0;
                    line.discountAgremment = 0;
                    line.maxQuantity = new Decimal(line.product.availableQuantity).mul(line.selectedMeasure.multiplyRate).toNumber();
                    line.discountPromotion = 0;
                    calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                }
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                    state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),

                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.CHANGE_MAX_QUANTITY:
            state.orderLines.forEach(line => {
                action.products.forEach(product => {
                    if (product.warehousePresenters[0].referenceId &&
                        line.warehousePresenter.referenceId === product.warehousePresenters[0].referenceId &&
                        line.product.productId === product.productId) {
                        if (product.availableQuantity !== null) {
                            line.product.availableQuantity = product.availableQuantity;
                            line.maxQuantity = product.availableQuantity * line.selectedMeasure.multiplyRate;
                        }
                    }
                });
            });
            return Object.assign({}, state, {
                orderLines: state.orderLines,
                procesesSelected: state.procesesSelected
            });


        case $params.OrderActions.UPDATE_ORDER:
            const orderLines: OrderLine[] = [];
            action.editOrder.orderDetailPresenters.forEach(line => {

                line.product.priceLimitWithoutTax = getDefault(line.product, line.measureUnitConversionPresenter).basePrice;
                line.product.normalPriceWithoutTax = getDefault(line.product, line.measureUnitConversionPresenter).normalPrice;
                line.product.priceWithoutTax = getDefault(line.product, line.measureUnitConversionPresenter).price;
                const maxDiscount = (100 - Number(((getDefault(line.product, line.measureUnitConversionPresenter).priceLimit * 100) /
                    getDefault(line.product, line.measureUnitConversionPresenter).price)));
                const orderLine: OrderLine = {
                    discount: line.discountRate,
                    maxDiscount: maxDiscount >= 0 ? maxDiscount : 0,
                    measureCombo: getCombo(line.product),
                    selectedMeasure: getDefault(line.product, line.measureUnitConversionPresenter),
                    orderDetailId: line.orderDetailId,
                    description: line.product.description,
                    name: line.product.name,
                    price: getDefault(line.product, line.measureUnitConversionPresenter).price * (1 + line.tax.value),
                    priceWithDiscount: line.unitPriceWithTax,
                    priceList: null,
                    priceWithoutTax: line.unitPrice,
                    product: line.product,
                    productId: line.product.productId,
                    enteredQuantity: line.enteredQuantity,
                    convertedQuantity: line.convertedQuantity,
                    referenceCode: line.product.referenceCode,
                    subTotal: null,
                    total: line.enteredQuantity * line.unitPriceWithTax,
                    taxToShow: line.enteredQuantity * line.tax.value,
                    tax: line.tax,
                    active: line.active,
                    maxQuantity: line.product.availableQuantity * line.measureUnitConversionPresenter.multiplyRate,
                    warehousePresenter: line.warehousePresenter,
                    inventoryMovementDetailId: line.inventoryMovementDetailId

                };
                calcLineTotal(orderLine, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
                orderLines.push(orderLine);

            });
            return Object.assign({}, state, {
                isInvoiceAllowed: false,
                updateOrder: action.editOrder,
                orderLines: orderLines,
                subTotal12: getSubtotal12(orderLines),
                current: getCurrent(orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                subTotal0: getSubtotal0(orderLines),
                tax: getTax(orderLines),
                totalTax: getTotalTax(orderLines),
                pvpSubtotal: getPvpSubtotal(orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(orderLines, state.commissionCard, state.processPayment),
                totalCardsIncrement: getCardsIncrement(orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(orderLines),

                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });



        case $params.OrderActions.DELETE_ORDER_LINE:

            if (action.orderLine.inventoryMovementDetailId) {
                state.orderLines.forEach(line => {
                    if (line.inventoryMovementDetailId === action.orderLine.inventoryMovementDetailId) {
                        line.active = false;
                    }
                });
                return Object.assign({}, state, {
                    orderLines: state.orderLines,
                    subTotal12: getSubtotal12(state.orderLines),
                    current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                    deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                        state.commissionCard, state.processPayment),
                    creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                        state.commissionCard, state.processPayment),
                    discountRecycling: getDiscountRecycling(state.orderLines),
                    subTotal0: getSubtotal0(state.orderLines),
                    tax: getTax(state.orderLines),
                    totalTax: getTotalTax(state.orderLines),
                    discountPromotion: getPromotionDiscount(state.orderLines),
                    getDiscountRecycling: getDiscountRecycling(state.orderLines),
                    pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),

                    totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                    totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard,
                        state.processPayment),
                    totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                    billetonAmount: getBilletonAmount(state.orderLines),

                    procesesSelected: state.procesesSelected,
                    taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
                });
            } else {

                return Object.assign({}, state, {
                    orderLines: removeLines(state.orderLines, action.orderLine),
                    subTotal12: getSubtotal12(removeLines(state.orderLines, action.orderLine)),
                    current: getCurrent(removeLines(state.orderLines, action.orderLine), state.billetonAmount.subC, state.isBilleton),
                    deferred: getDeferred(removeLines(state.orderLines, action.orderLine), state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                    creditred: getDeferred(removeLines(state.orderLines, action.orderLine), state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                    subTotal0: getSubtotal0(removeLines(state.orderLines, action.orderLine)),
                    tax: getTax(removeLines(state.orderLines, action.orderLine)),
                    totalTax: getTotalTax(removeLines(state.orderLines, action.orderLine)),
                    pvpSubtotal: getPvpSubtotal(removeLines(state.orderLines, action.orderLine),
                    state.commissionCard, state.processPayment),
                    totalFEDiscount: getFEDiscount(removeLines(state.orderLines, action.orderLine),
                    state.commissionCard, state.processPayment),
                    totalAgreementDiscount: getAgreementDiscount(removeLines(state.orderLines, action.orderLine),
                    state.commissionCard, state.processPayment),
                    totalCardsIncrement: getCardsIncrement(removeLines(state.orderLines, action.orderLine),
                    state.creditCardPayment),
                    billetonAmount: getBilletonAmount(removeLines(state.orderLines, action.orderLine)),
                    discountPromotion: getPromotionDiscount(removeLines(state.orderLines, action.orderLine)),
                    getDiscountRecycling: getDiscountRecycling(removeLines(state.orderLines, action.orderLine)),

                    procesesSelected: state.procesesSelected,
                    taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
                });

            }

        case $params.OrderActions.DELETE_ALL_ORDER_LINES:
            return Object.assign({}, state, {
                updateOrder: null,
                isInvoiceAllowed: false,
                orderLines: [],
                subTotal12: 0,
                subTotal0: 0,
                pvpSubtotal: 0,
                tax: 0,
                totalTax: 0,
                procesesSelected: state.procesesSelected,
                totalFEDiscount: 0,
                totalAgreementDiscount: 0,
                isBilleton: false,
                billetonAmount: 0,
                discountPromotion: 0,
                discountRecycling: 0,
                current: 0,
                deferred: 0,
                creditred: 0,
            });
        case $params.OrderActions.ALLOW_INVOICE_PROCESS:
            return Object.assign({}, state, {
                procesesSelected: ProcessModel.INVOICE
            });
        case $params.OrderActions.DENY_INVOICE_PROCESS:
            return Object.assign({}, state, {
                procesesSelected: ProcessModel.ORDER
            });
        case $params.OrderActions.CHANGE_PROCESS:
            return Object.assign({}, state, {
                procesesSelected: action.process
            });
        case $params.OrderActions.CLEAR_EDIT_ORDER:
            return Object.assign({}, state, {
                updateOrder: null,
                isInvoiceAllowed: false,
                procesesSelected: state.procesesSelected
            });
        case $params.OrderActions.AllOW_INVOICE:
            return Object.assign({}, state, {
                isInvoiceAllowed: true,
                procesesSelected: state.procesesSelected
            });
        case $params.OrderActions.DENY_INVOCE:
            return Object.assign({}, state, {
                isInvoiceAllowed: false,
                procesesSelected: state.procesesSelected
            });
        case $params.OrderActions.CHANGE_AUTO_ADD_STATUS:
            return Object.assign({}, state, {
                autoAddStatus: action.state
            });
        case $params.OrderActions.SET_CREDIT_CARD_PAYMENT:
            GeneralConfigurations.creditCardPayment = action.creditCardPayment;
            state.commissionCard = action.commissionCard;
            state.processPayment = action.processPayment;
            state.orderLines.forEach(line => {
                calcLineTotal(line, state.isAdminPriceDiscount, state.commissionCard, state.processPayment);
            });
            return Object.assign({}, state, {
                creditCardPayment: action.creditCardPayment,
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, state.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),

                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)

            });
        case $params.OrderActions.SET_BILLETON:

            return Object.assign({}, state, {
                isBilleton: action.isBilleton,
                orderLines: state.orderLines,
                subTotal12: getSubtotal12(state.orderLines),
                current: getCurrent(state.orderLines, state.billetonAmount.subC, action.isBilleton),
                deferred: getDeferred(state.orderLines, state.billetonAmount.subD, action.isBilleton,
                    state.commissionCard, state.processPayment),
                creditred: getDeferred(state.orderLines, state.billetonAmount.subD, state.isBilleton,
                    state.commissionCard, state.processPayment),
                discountRecycling: getDiscountRecycling(state.orderLines),
                subTotal0: getSubtotal0(state.orderLines),
                tax: getTax(state.orderLines),
                totalTax: getTotalTax(state.orderLines),
                discountPromotion: getPromotionDiscount(state.orderLines),
                getDiscountRecycling: getDiscountRecycling(state.orderLines),
                pvpSubtotal: getPvpSubtotal(state.orderLines, state.commissionCard, state.processPayment),
                totalFEDiscount: getFEDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalAgreementDiscount: getAgreementDiscount(state.orderLines, state.commissionCard, state.processPayment),
                totalCardsIncrement: getCardsIncrement(state.orderLines, state.creditCardPayment),
                billetonAmount: getBilletonAmount(state.orderLines),
                procesesSelected: state.procesesSelected,
                taxModelList: getSubtotalAndTaxDinamic(state.orderLines, state.processPayment)
            });
        case $params.OrderActions.SET_ADMIN_PRICE_DISCOUNT:
            return Object.assign({}, state, {
                isAdminPriceDiscount: action.isAdminPriceDiscount
            });
        default:
            return state;
    }
}

export function removeLines(lines: OrderLine[], lineToRemove: OrderLine): OrderLine[] {
    const linesToReturn: OrderLine[] = [];
    if (!lineToRemove.orderDetailId) {
        lines.forEach(line => {
            if (!(line.product.productId === lineToRemove.product.productId &&
                !line.orderDetailId &&
                line.warehousePresenter.referenceId === lineToRemove.warehousePresenter.referenceId)) {
                linesToReturn.push(line);
            }
        });
        return linesToReturn;
    } else {
        return lines;
    }
}

function getPvpSubtotal(orderLines: OrderLine[], commissionCard, processPayment): number {
    let subtotal = 0;
    orderLines.forEach(line => {
        if (line.referenceCode === 'L20179') {
            line.product.normalPriceWithoutTax = line.priceWithoutTax;
        }
        if (line.active) {
            if (processPayment !== undefined && processPayment === 'deferred') {
                subtotal = new Decimal(subtotal)
                    .add((new Decimal(line.product.normalPriceWithoutTax)
                    .add(new Decimal(line.product.normalPriceWithoutTax).mul(commissionCard)))
                        .mul(line.enteredQuantity)).toDecimalPlaces(2).toNumber();
            } else {
                subtotal = new Decimal(subtotal)
                    .add(new Decimal(line.product.normalPriceWithoutTax)
                        .mul(line.enteredQuantity)).toDecimalPlaces(2).toNumber();
            }
        }
    });
    return subtotal;
}
function getSubtotal12(orderLines: OrderLine[]): number {
    let subtotal = 0;
    orderLines.forEach(line => {
        if (line.active) {
            if (line.tax.value !== 0) {
                subtotal = new Decimal(subtotal).add(line.subTotal).toNumber();

            }
        }
    });
    return subtotal;
}

function getSubtotal0(orderLines: OrderLine[]): number {
    let subtotal = 0;
    orderLines.forEach(line => {
        if (line.active) {
            if (line.tax.value === 0) {
                subtotal = new Decimal(subtotal).add(line.subTotal).toNumber();
            }
        }
    });
    return subtotal;
}

function getSubtotalAndTaxDinamic(orderLines: OrderLine[], processPayment): TaxModelList {
    const dinamicTaxList: DinamicTax[] = [];
    orderLines.forEach(line => {
        console.log(line.product.referenceCode + ' ' + line.priceWithoutTax);
        if (line.active) {
            const lineSub = ((new Decimal(line.priceWithoutTax || 0).mul(line.enteredQuantity || 0))
                .minus(line.discountPromotion && processPayment === 'deferred'
                ? line.priceWithoutTax : line.discountPromotion || 0)).toDecimalPlaces(4).toNumber();
            if (!dinamicTaxList.length) {
                const dinamicTax: DinamicTax = {
                    taxModel: line.tax,
                    totalDinamicTax: round(line.taxToShow, 2),
                    subtotal: round(lineSub, 2)
                };

                dinamicTaxList.push(dinamicTax);

            } else {
                const dinamicTaxFind = dinamicTaxList.find(tax => tax.taxModel.taxId === line.tax.taxId);
                if (!dinamicTaxFind) {
                    const dinamicTax: DinamicTax = {
                        taxModel: line.tax,
                        totalDinamicTax: round(line.taxToShow, 2),
                        subtotal: round(lineSub, 2)
                    };
                    dinamicTaxList.push(dinamicTax);

                } else {
                    const totalDinamicTax = new Decimal(dinamicTaxFind.totalDinamicTax || 0)
                        .add(line.taxToShow || 0).toDecimalPlaces(4).toNumber();
                    const subtotal = new Decimal(dinamicTaxFind.subtotal || 0).add(lineSub || 0).toDecimalPlaces(2).toNumber();

                    dinamicTaxFind.totalDinamicTax = totalDinamicTax;
                    dinamicTaxFind.subtotal = subtotal;

                }
            }
        }
    });
    const totalTaxList = dinamicTaxList
        .map(dinamic => dinamic.totalDinamicTax)
        .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);

    const subtotalList = dinamicTaxList
        .map(dinamic => dinamic.subtotal)
        .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);


    const taxModelList: TaxModelList = {
        dinamicTax: dinamicTaxList,
        totalTaxList: totalTaxList,
        subtotal: subtotalList
    };

    return taxModelList;
}

function getDiscountRecycling(orderLines: OrderLine[]): number {
    let discountRecycling = 0;
    orderLines.forEach(line => {
        if (line.active) {
            discountRecycling = discountRecycling + (new Decimal(line.quantityRecycling || 0).mul(line.discountRecycling || 0)
                .toDecimalPlaces(2).toNumber());

        }
    });
    return discountRecycling;
}
function getTax(orderLines: OrderLine[]): number {
    return orderLines
        .filter(line => line.active && line.product.taxPresenter.value)
        .map(line => line.taxToShow)
        .reduce((a, b) => new Decimal(a).add(b).toNumber(), 0);
}
function getTotalTax(orderLines: OrderLine[]): number {
    let totalTax = 0;
    const subTotal = orderLines
        .filter(line => line.active && line.product.taxPresenter.value)
        .map(line => new Decimal(line.subTotal).toDecimalPlaces(2).toNumber())
        .reduce((a, b) => new Decimal(a).add(b).toDecimalPlaces(2).toNumber(), 0);
    totalTax = new Decimal(subTotal).mul(GeneralConfigurations.defaultTax || 0.12).toDecimalPlaces(2).toNumber();
    return totalTax;
}

export function getCombo(product: Product): MeasureConversionComboModel[] {
    const comboMeasure: MeasureConversionComboModel[] = [];
    if (product.measureUnitConversionPresenters) {
        product.measureUnitConversionPresenters.forEach(unit => {
            comboMeasure.push({ label: unit.measureUnitPresenterTo.name, value: unit });
        });
    }
    return comboMeasure;
}

export function getDefault(product: Product, measure: MeasureConversionModel): MeasureConversionModel {
    let returnValue: MeasureConversionModel = null;
    if (product.measureUnitConversionPresenters) {
        product.measureUnitConversionPresenters.forEach(unit => {
            if (unit.measureUnitPresenterTo.referenceId === measure.measureUnitPresenterTo.referenceId) {
                returnValue = unit;
                return returnValue;
            }
        });
    }
    return returnValue;

}

export function getFEDiscount(orderLines: OrderLine[], commissionCard, processPayment): number {
    let total = 0;
    orderLines.forEach(line => {
        if (processPayment !== undefined && processPayment === 'deferred') {
            if (line.active && line.product.priceCommissionCardWithoutTax < line.product.normalPriceCommissionCardWithoutTax) {
                total = total + new Decimal(new Decimal(line.product.normalPriceCommissionCardWithoutTax).minus(line.priceWithoutTax))
                    .mul(line.enteredQuantity).toNumber();
            }
        } else {
            if (line.active && line.product.priceWithoutTax < line.product.normalPriceWithoutTax) {
                total = total + new Decimal(new Decimal(line.product.normalPriceWithoutTax).minus(line.priceWithoutTax))
                    .mul(line.enteredQuantity).toNumber();
            }
        }
    });
    return total;
}

export function getAgreementDiscount(orderLines: OrderLine[], commissionCard, processPayment): number {
    let total = 0;
    orderLines.forEach(line => {
        if (processPayment !== undefined && processPayment === 'deferred') {
            if (line.active && line.priceCommissionCardWithoutTax <= (line.selectedMeasure.price +
                (line.selectedMeasure.price * commissionCard))) {
                total = total + new Decimal((new Decimal(line.selectedMeasure.price)
                    .add(line.discountPromotion ? 0 : (new Decimal(line.selectedMeasure.price)
                        .mul(commissionCard)))).minus(line.priceCommissionCardWithoutTax))
                    .mul(line.enteredQuantity).toNumber();
            }
        } else {
            if (line.active && line.priceWithoutTax <= line.selectedMeasure.price) {
                total = total + new Decimal(new Decimal(line.selectedMeasure.price).minus(line.priceWithoutTax))
                    .mul(line.enteredQuantity).toNumber();
            }
        }
    });
    return total;
}
export function getCardsIncrement(orderLines: OrderLine[], creditCard: boolean): number {
    let total = 0;
    if (creditCard) {
        orderLines.forEach(line => {
            if (line.active) {
                total += (line.priceWithoutTax - line.selectedMeasure.price) * line.enteredQuantity;
            }

        });
    }
    return total;
}
export function getBilletonAmount(orderLines: OrderLine[]): { subD: number, subC: number } {
    let subtotalD = 0;
    let subtotalLineD = 0;
    let subtotalC = 0;
    let subtotalLineC = 0;
    orderLines.forEach(line => {
        if (line.active && line.product.isBilleton) {
            subtotalLineD = new Decimal(((new Decimal(line.enteredQuantity).mul(line.product.normalPriceWithoutTax))
                .minus(line.subtotalDiscountRecycling || 0)).toDecimalPlaces(6).toNumber()).toNumber();

            subtotalD = new Decimal(subtotalD).add(subtotalLineD || 0).toNumber();
        }
    });
    orderLines.forEach(line => {
        if (line.active && line.product.isBilleton) {
            subtotalLineC = new Decimal(((new Decimal(line.enteredQuantity).mul(line.product.priceWithoutTax))
                .minus(line.allDiscount || 0)).toDecimalPlaces(6).toNumber()).toNumber();

            subtotalC = new Decimal(subtotalC).add(subtotalLineC || 0).toNumber();
        }
    });
    return {
        subD: subtotalD >= Settings.defaultMinBilletonAmount ? Settings.defaultBilletonDiscountAmount : 0,
        subC: subtotalC >= Settings.defaultMinBilletonAmount ? Settings.defaultBilletonDiscountAmount : 0,
    };
}
export function getPromotionDiscount(orderLines: OrderLine[]): number {
    let discount = 0;
    orderLines.forEach(line => {
        if (line.active) {
            discount = new Decimal(discount).add(line.discountPromotion || 0).toNumber();
        }
    });
    return discount;
}


export function calcLineTotal(line: OrderLine, isAdminPriceDiscount: boolean, commissionCard: number, processPayment: string) {
    const creditCardPayment = GeneralConfigurations.creditCardPayment;
    const applyPromoDay = GeneralConfigurations.applyPromoDay;
    // if (!line.product.modifyPrice) {
    //     line.product.priceWithoutTax = creditCardPayment ? line.product.priceWithoutTax :
    //         line.product.priceWithoutTax = isAdminPriceDiscount ? line.product.priceWithoutTax : line.selectedMeasure.price;
    // } else {
    if (processPayment !== undefined) {
        line.product.priceCommissionCardWithoutTax = (new Decimal(line.product.priceWithoutTax)
            .add(new Decimal(line.product.priceWithoutTax)
                .mul(commissionCard))).toDecimalPlaces(4).toNumber();
    } else {
        line.product.priceCommissionCardWithoutTax = (new Decimal(line.product.normalPriceWithoutTax)
            .add(new Decimal(line.product.normalPriceWithoutTax)
                .mul(commissionCard))).toDecimalPlaces(4).toNumber();
    }
    line.product.normalPriceCommissionCardWithoutTax = (new Decimal(line.product.normalPriceWithoutTax)
        .add(new Decimal(line.product.normalPriceWithoutTax)
            .mul(commissionCard))).toDecimalPlaces(4).toNumber();

    line.normalPriceCommissionCardWithoutTax = line.product.normalPriceCommissionCardWithoutTax;
    line.priceWithoutTax = line.product.priceWithoutTax;
    line.priceCommissionCardWithoutTax = line.product.priceCommissionCardWithoutTax;
    if (line.orderDetailId === null || line.orderDetailId === '') {
        if (line.priceWithoutTax > line.normalPrice || line.priceWithoutTax < line.selectedMeasure.price) {
            line.ifPriceElderly = true;
            line.discount = 0;
        } else {
            line.ifPriceElderly = false;
        }
    }
    // line.selectedMeasure.price = line.product.priceWithoutTax;
    // line.selectedMeasure.normalPrice = line.product.priceWithoutTax;
    // }
    line.discount = new Decimal(line.discount || 0).toDecimalPlaces(6).toNumber();
    line.discountPromotion = line.discountPromotion || 0;
    line.allDiscount = line.discountPromotion || 0;
    if (creditCardPayment && !line.product.modifyPrice) {
        if (processPayment !== undefined && processPayment === 'deferred') {
            line.priceWithoutTax = line.product.priceCommissionCardWithoutTax;
        } else {
            line.priceWithoutTax = line.selectedMeasure.normalPrice;
        }
        // line.discountPromotion = 0;
        // line.discount = 0;
        // implement discount for heavy
        line.discount = line.discount ? line.discount : line.discountAgremment || 0;
        line.discount = applyPromoDay ? 0 : line.discount;
        line.priceWithoutTax = line.discountPromotion ? (processPayment === 'deferred'
            ? line.priceWithoutTax : line.selectedMeasure.price) : new Decimal(
                new Decimal(line.priceWithoutTax).toDecimalPlaces(4).toNumber())
                .minus(new Decimal(line.priceWithoutTax).mul(line.discount).div(100)).toDecimalPlaces(4).toNumber();
        if (processPayment !== undefined && processPayment === 'deferred') {
            line.subTotal = ((new Decimal(line.enteredQuantity).mul(new Decimal(line.priceWithoutTax).toDecimalPlaces(4).toNumber()))
                .minus((new Decimal(line.discountPromotion).add(new Decimal(line.discountPromotion).mul(commissionCard))) || 0))
                .toDecimalPlaces(2).toNumber();
        } else {
            line.subTotal = ((new Decimal(line.enteredQuantity).mul(new Decimal(line.priceWithoutTax).toDecimalPlaces(4).toNumber()))
                .minus(line.discountPromotion || 0)).toDecimalPlaces(2).toNumber();
        }
    } else {
        line.discount = line.discount ? line.discount : line.discountAgremment || 0;
        line.discount = applyPromoDay ? 0 : line.discount;
        line.priceWithoutTax = line.discountPromotion ? line.selectedMeasure.price : new Decimal(
            new Decimal(line.product.priceWithoutTax).toDecimalPlaces(4).toNumber())
            .minus(new Decimal(line.product.priceWithoutTax).mul(line.discount).div(100)).toDecimalPlaces(4).toNumber();
        if (processPayment !== undefined && processPayment === 'deferred') {
            line.subTotal = ((new Decimal(line.enteredQuantity).mul(new Decimal(line.priceWithoutTax)
                .add(new Decimal(line.priceWithoutTax).mul(commissionCard))))
                .minus((new Decimal(line.discountPromotion).add(new Decimal(line.discountPromotion)
                .mul(commissionCard))) || 0)).toDecimalPlaces(2).toNumber();
        } else {
            line.subTotal = ((new Decimal(line.enteredQuantity).mul(line.priceWithoutTax))
                .minus(line.discountPromotion || 0)).toDecimalPlaces(2).toNumber();
        }
    }
    if (line.isRecycling) {
        line.subtotalDiscountRecycling = (new Decimal(line.quantityRecycling || 0).mul(line.discountRecycling || 0)
            .toDecimalPlaces(6).toNumber());
        if (processPayment !== undefined && processPayment === 'deferred') {
            line.subTotal = new Decimal(((new Decimal(line.enteredQuantity).mul(line.priceWithoutTax))
                .minus(line.discountPromotion || 0)).toDecimalPlaces(6).toNumber())
                .minus(line.subtotalDiscountRecycling).toDecimalPlaces(2).toNumber();
        } else {
            line.subTotal = new Decimal(((new Decimal(line.enteredQuantity).mul(line.priceWithoutTax))
                .minus(line.discountPromotion || 0)).toDecimalPlaces(6).toNumber())
                .minus(line.subtotalDiscountRecycling).toDecimalPlaces(2).toNumber();
        }
    }
    const maxDiscount = new Decimal(100)
        .minus(new Decimal(line.selectedMeasure.basePrice).mul(100).div(line.selectedMeasure.price)).toNumber();
    line.maxDiscount = maxDiscount;
    line.convertedQuantity = new Decimal(line.enteredQuantity).mul(line.selectedMeasure.divideRate).toNumber();
    if (processPayment !== undefined && processPayment === 'deferred') {
        line.priceWithDiscount = new Decimal(line.priceWithoutTax).toDecimalPlaces(2)
            .add(new Decimal(line.tax.value).toDecimalPlaces(2)
                .mul(line.priceWithoutTax).toDecimalPlaces(2)).toNumber();
    } else {
        line.priceWithDiscount = new Decimal(line.priceWithoutTax).toDecimalPlaces(2)
            .add(new Decimal(line.tax.value).toDecimalPlaces(2)
                .mul(line.priceWithoutTax).toDecimalPlaces(2)).toNumber();
    }
    line.price = new Decimal(line.priceWithoutTax).toDecimalPlaces(2)
        .add(new Decimal(line.priceWithoutTax).toDecimalPlaces(2)
            .mul(line.tax.value).toDecimalPlaces(2)).toNumber();

    const tax = new Decimal(line.subTotal).mul(line.tax.value).toDecimalPlaces(2).toNumber();
    line.total = new Decimal(line.subTotal).add(tax).toNumber();
    line.taxToShow = tax;

    if (processPayment !== undefined && processPayment === 'deferred') {
        const subtotalWithoutPromo = (new Decimal(line.enteredQuantity).mul(line.selectedMeasure.price)).toDecimalPlaces(6).toNumber();
        const taxWithoutPromo = new Decimal(subtotalWithoutPromo).mul(line.tax.value).toDecimalPlaces(2).toNumber();
        line.totalWithoutPromo = new Decimal(subtotalWithoutPromo).add(taxWithoutPromo).toNumber();

        line.allDiscount = new Decimal(line.allDiscount || 0).add(line.subtotalDiscountRecycling || 0).toDecimalPlaces(2).toNumber();
    } else {
        const subtotalWithoutPromo = (new Decimal(line.enteredQuantity).mul(line.selectedMeasure.price)).toDecimalPlaces(6).toNumber();
        const taxWithoutPromo = new Decimal(subtotalWithoutPromo).mul(line.tax.value).toDecimalPlaces(2).toNumber();
        line.totalWithoutPromo = new Decimal(subtotalWithoutPromo).add(taxWithoutPromo).toNumber();

        line.allDiscount = new Decimal(line.allDiscount || 0).add(line.subtotalDiscountRecycling || 0).toDecimalPlaces(2).toNumber();
    }
}

export function applyPromotions(
    promos: PromotionModel[], lines: OrderLine[], proceses: ProcessModel, commissionCard: number, processPayment: string) {
    if (proceses === ProcessModel.INVOICE) {
        const items: Item[] = [];
        promos.forEach(promo => {
            promo.benefit.forEach(benefit => {
                benefit.apply.item.forEach(item => {
                    item.promotionName = benefit.displayMessage;
                    item.promotionCode = promo.code;
                    items.push(item);
                });
            });
        });
        lines.filter(line => line.active).forEach((line, index) => {
            line.promotionError = '';
            const i = index + 1;
            line.promotions = items.filter(item => item.seq === i.toString());
            const discountPromotion = line.promotions.map(item => item.value).reduce((a, b) => new Decimal(a).plus(b).toNumber(), 0);
            line.discountPromotion = discountPromotion;
            if (line.discountPromotion) {
                line.product.priceCost =
                    line.product.priceCost ? line.product.priceCost : line.selectedMeasure.priceLimit;
                line.priceCost = new Decimal(line.product.priceCost).mul(line.selectedMeasure.divideRate).toNumber();
                line.promotionPrice = (new Decimal(line.selectedMeasure.normalPrice).mul(line.enteredQuantity || 1))
                    .minus(line.discountPromotion)
                    .div(line.enteredQuantity || 1)
                    .toNumber();
                line.product.priceWithoutTax = line.selectedMeasure.price;
                line.discount = 0;
                line.discountAgremment = 0;
            }
            calcLineTotal(line, false, commissionCard, processPayment);

        });
    }
}
function getCurrent(orderLines: OrderLine[], billetonAmount: number, isBilleton: boolean): number {
    let tax = 0;
    let current = 0;
    let subtotal0 = 0;
    let subtotal12 = 0;
    orderLines.forEach(line => {
        let price = 0;
        let subtotal = 0;
        if (line.active) {
            if (line.tax.value === 0) {
                price = new Decimal(line.selectedMeasure.price)
                    .minus(new Decimal(line.selectedMeasure.price).mul(line.discountAgremment || 0).div(100)).toDecimalPlaces(6).toNumber();
                subtotal = ((new Decimal(line.enteredQuantity).mul(price))
                    .minus(line.allDiscount || 0)).toDecimalPlaces(2).toNumber();
                subtotal0 = new Decimal(subtotal0).add(subtotal).toNumber();
            } else {
                price = new Decimal(line.selectedMeasure.price)
                    .minus(new Decimal(line.selectedMeasure.price).mul(line.discountAgremment || 0).div(100)).toDecimalPlaces(6).toNumber();
                subtotal = ((new Decimal(line.enteredQuantity).mul(price))
                    .minus(line.allDiscount || 0)).toDecimalPlaces(2).toNumber();
                subtotal12 = new Decimal(subtotal12).add(subtotal).toNumber();
                const taxLine = new Decimal(subtotal).mul(line.product.taxPresenter.value).toDecimalPlaces(4).toNumber();
                tax = tax + taxLine;
            }
        }
    });
    tax = new Decimal(tax).toDecimalPlaces(2).toNumber();
    if (isBilleton) {
        if (subtotal12 > billetonAmount) {
            subtotal12 = new Decimal(subtotal12).minus(billetonAmount).toDecimalPlaces(2).toNumber();
            const def = GeneralConfigurations.defaultTax;
            tax = new Decimal(tax)
                .minus(new Decimal(def * 100 || 12).mul(billetonAmount || 0)
                    .div(100)).toDecimalPlaces(2).toNumber();
        } else {
            subtotal0 = new Decimal(subtotal0).minus(billetonAmount).toDecimalPlaces(2).toNumber();
            tax = tax;
        }
    }
    return current = new Decimal(subtotal12).add(subtotal0).add(tax).toDecimalPlaces(2).toNumber();
}
function getDeferred(orderLines: OrderLine[], billetonAmount: number, isBilleton: boolean, commissionCard, processPayment): number {
    let tax = 0;
    let deferred = 0;
    let subtotal0 = 0;
    let subtotal12 = 0;
    orderLines.forEach(line => {
        let subtotal = 0;
        if (line.active) {
            if (line.tax.value === 0) {
                subtotal = ((new Decimal(line.enteredQuantity).mul(new Decimal(line.selectedMeasure.price)
                    .add(line.allDiscount ? 0 : (new Decimal(line.selectedMeasure.price).mul(commissionCard)))))
                    .minus(line.allDiscount || 0)
                    .minus(line.subtotalDiscountRecycling || 0)).toDecimalPlaces(2).toNumber();
                subtotal0 = new Decimal(subtotal0).add(subtotal).toDecimalPlaces(2).toNumber();
            } else {
                subtotal = ((new Decimal(line.enteredQuantity).mul(new Decimal(line.selectedMeasure.price)
                    .add(line.allDiscount ? 0 : (new Decimal(line.selectedMeasure.price).mul(commissionCard)))))
                    .minus(line.allDiscount || 0)
                    .minus(line.subtotalDiscountRecycling || 0)).toDecimalPlaces(4).toNumber();
                subtotal12 = new Decimal(subtotal12).add(subtotal).toDecimalPlaces(2).toNumber();
                const taxLine = new Decimal(subtotal).mul(line.product.taxPresenter.value).toDecimalPlaces(4).toNumber();
                tax = tax + taxLine;
            }
        }
    });
    tax = new Decimal(tax).toDecimalPlaces(2).toNumber();
    if (isBilleton) {
        if (subtotal12 > billetonAmount) {
            subtotal12 = new Decimal(subtotal12).minus(billetonAmount).toDecimalPlaces(2).toNumber();
            const def = GeneralConfigurations.defaultTax;
            tax = new Decimal(tax)
                .minus(new Decimal(def * 100 || 12).mul(billetonAmount || 0)
                    .div(100)).toDecimalPlaces(2).toNumber();
        } else {
            subtotal0 = new Decimal(subtotal0).minus(billetonAmount).toDecimalPlaces(2).toNumber();
            tax = tax;
        }
    }
    return deferred = new Decimal(subtotal12).add(subtotal0).add(tax).toDecimalPlaces(2).toNumber();
}

