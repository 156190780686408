import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MessageService, ConfirmationService, DynamicDialogRef } from 'primeng/api';
import { Router } from '@angular/router';
import { SearchOrderView } from './search-order.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { Order, CreditStatus } from 'src/app/models/order.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ClientModel } from 'src/app/models/client.model';
import { SearchOrderPresenter } from './presenter/search-order.presenter';
import Swal from 'sweetalert2';
import { AutoComplete } from 'primeng/primeng';

@Component({
  selector: 'app-search-order',
  templateUrl: './search-order.component.html',
  styleUrls: ['./search-order.component.scss'],
  providers: [ConfirmationService],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class SearchOrderComponent extends AbstractView implements OnInit, SearchOrderView, AfterViewChecked {


  @ViewChild('txtClient', { read: false, static: false }) txtClient: AutoComplete;
  orders: Order[] = [];
  selectedOrder: Order;
  searchClient: '';
  pageClient: 0;
  sizeClient: 5;
  clients: ClientModel[];
  selectedClient: ClientModel;
  initDate = null;
  endDate = null;
  documentNo: number;
  showSelectedClient = false;


  constructor(
    private messageService: MessageService,
    public router: Router,
    private searchOrderPresenter: SearchOrderPresenter,
    private ref: DynamicDialogRef

  ) {
    super(messageService, router);
    searchOrderPresenter.view = this;
    searchOrderPresenter.findOrders();
  }

  ngOnInit() {
  }


  ngAfterViewChecked(): void {
    this.txtClient.focusInput();
  }

  onSearchClient(event) {
    this.searchClient = event.query;
    this.pageClient = 0;
    this.sizeClient = 5;
    this.searchOrderPresenter.onSearchClient();
  }
  addClient() {
    this.searchOrderPresenter.findOrdersByClient();
  }

  clearClient() {
    this.searchOrderPresenter.clearClient();
  }

  findOrders() {
    this.searchOrderPresenter.findOrders();
  }



  editOrder(order: Order) {
    this.selectedOrder = order;
    this.searchOrderPresenter.getOrderAndEdit();
  }
  closeView() {
    this.ref.close();
  }

  delete(order: Order) {
    this.selectedOrder = order;
    if (window.confirm('Are sure you want to delete this item ?')) {
      this.searchOrderPresenter.cancelOrder();
    }
  }

  showCancelConfirmation(order: Order) {
    this.selectedOrder = order;
    Swal.fire({
      text: '¿Desea anular la orden?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular'
    }).then((result) => {
      if (result.value) {
        this.searchOrderPresenter.cancelOrder();
      }
    });
  }



  createInvoceFromOrder(order: Order) {
    this.selectedOrder = order;
    this.searchOrderPresenter.createInvoiceFromOrder();
  }

  blockEditButton(order: Order) {
    if (order.creditStatus === CreditStatus.NOT_REQUESTED) {
      return false;
    } else {
      return true;
    }
  }

  blockCancelButton(order: Order) {
    if (order.creditStatus === CreditStatus.PENDING) {
      return true;
    } else {
      return false;
    }
  }

  blockInvoiceButton(order: Order) {
    if (order.creditStatus === CreditStatus.NOT_REQUESTED || order.creditStatus === CreditStatus.APPROVED) {
      return false;
    } else {
      return true;
    }
  }
  getOrderStatus(order: Order) {
    switch (order.creditStatus) {
      case CreditStatus.APPROVED:
        return 'Aprobada';
      case CreditStatus.REJECTED:
        return 'Rechazada';
      case CreditStatus.PENDING:
        return 'Pendiente';
      case CreditStatus.NOT_REQUESTED:
        return 'Sin solicitud de crédito';
    }
  }





}

