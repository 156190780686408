import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { PanelMasterComponent } from '../../panelmaster.component';
import { AbstractView } from '../../../../core/abstract_view';
import { Router } from '@angular/router';
import { MessageService, DialogService, ConfirmationService } from 'primeng/api';
import { TopbarPresenter } from './presenter/topbar.presenter';
import { TopBarView } from './topbar.view';
import { interval, Subscription } from 'rxjs';
import { UserPresenter } from '../../../../models/fulluser.model';
import { formatDate } from '@angular/common';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { HelpComponent } from '../../pages/help/help.component';
import { RolComponent } from '../../pages/rol/rol.component';
import { OpenCashRegisterComponent } from '../../pages/cash-register/open-cash-register/open-cash-register.component';
import { GenerateAdvanceComponent } from '../../pages/cash-register/generate-advance/generate-advance.component';
import { SettingsComponent } from '../../pages/settings/settings.component';
import { AboutComponent } from '../../pages/about/about.component';
import { InfoModel } from 'src/app/models/infoversion.model';
import { InitDataComponent } from '../../pages/init-data/init-data.component';
import { DataProvider } from 'src/app/services/data.provider';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { CloseCashRegisterComponent } from '../../pages/cash-register/close-cash-register/close-cash-register.component';
import { GenerateCashRegisterComponent } from '../../pages/cash-register/generate-cash-register/generate-cash-register.component';
import Swal from 'sweetalert2';
import { SearchSalesDepositPresenter } from '../../pages/dashboard/cash-register/search-sales-deposit/presenter/search-sales-deposit.presenter';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends AbstractView implements OnInit, TopBarView {

    subscription: Subscription;
    status = ' ';
    iconColor = this.topBarPresenter.getAdvanceService.iconColor;
    iconStyle = {
        color: this.iconColor
    };

    fullUser: UserPresenter = {
        userId: '',
        userName: '',
        password: '',
        fullName: '',
        dni: '',
        referenceId: ''
    };
    infoVersion: InfoModel = {
        app: {
            name: 'versión de back-end: ',
            description: '',
            version: '0',
            revision: '',
        }

    };
    today = new Date();
    jstoday = '';

    public ref;
    constructor(
        public app: PanelMasterComponent,
        public topBarPresenter: TopbarPresenter,
        public searchSalesDepositPresenter: SearchSalesDepositPresenter,
        public router: Router,
        public messageService: MessageService,
        private hotKey: HotkeysService,
        public dialogService: DialogService,
        public dataProvider: DataProvider,
        public confirmationService: ConfirmationService
    ) {
        super(messageService, router);
        this.topBarPresenter.view = this;
        const source = interval(60000);
        this.subscription = source.subscribe(() => this.topBarPresenter.showReminder());
        this.jstoday = formatDate(this.today, 'dd-MM-yyyy', 'en-US', '-05');
        this.shortcuts();
    }

    lockedCloseCash: [];
    statusCloseCash: [];

    ngOnInit(): void {
        this.topBarPresenter.init();
        this.topBarPresenter.loadValueLockedbox();
        this.topBarPresenter.loadStatusDeposit();
    }

    logout() {
        window.location.reload();
        this.topBarPresenter.logout();
    }
    shortcuts() {
        this.hotKey.add(new Hotkey(['f1'], (event: KeyboardEvent): boolean => {
            this.showHelp();
            return false;
        }, ['input', 'textarea'], 'Mostrar atajos'));
        this.hotKey.add(new Hotkey(['f2'], (event: KeyboardEvent): boolean => {
            this.showRols();
            return false;
        }, ['input', 'textarea'], 'Mostrar roles'));

    }

    showHelp() {
        this.ref = this.dialogService.open(HelpComponent, {
            showHeader: true,
            header: 'Atajos',
            closeOnEscape: true,
            height: '515px'
        });
    }

    showDefaultSettings() {
        this.ref = this.dialogService.open(SettingsComponent, {
            showHeader: true,
            header: 'Configuraciones por defecto',
            closeOnEscape: true,
        });
    }
    showRols() {
        this.ref = this.dialogService.open(RolComponent, {
            showHeader: true,
            header: 'Permisos',
            closeOnEscape: true
        });
    }

    showGenerateCashRegisterComponent() {
        if (this.topBarPresenter.getAdvanceService.status === 'Debe abrir caja') {
            if (this.lockedCloseCash.length > 0) {
                Swal.fire({
                    title: 'Caja bloqueada',
                    text: 'Su caja se encuentra bloqueada por falta de depositos, por favor comuniquese con su gestor de operaciones.',
                    confirmButtonColor: '#00687d',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.value) {
                    }
                });
            } else {
                if (this.statusCloseCash.length > 0) {
                    this.ref = this.dialogService.open(GenerateCashRegisterComponent, {
                        showHeader: true,
                        closeOnEscape: true,
                    });
                    this.ref.onClose.subscribe((res) => {
                        this.searchSalesDepositPresenter.onSearchCloseDayReport();
                    });
                } else {
                    this.ref = this.dialogService.open(OpenCashRegisterComponent, {
                        showHeader: true,
                        closeOnEscape: true,
                        header: 'Abrir caja'
                    });

                    this.ref.onClose.subscribe((res) => {

                    });
                }

            }
        } else if (this.topBarPresenter.getAdvanceService.status === 'Avance pendiente') {
            this.showAdvanceCompponent();
        }
    }

    showOpenCashRegisterCompponent() {
        this.showGenerateCashRegisterComponent();
        this.ref = this.dialogService.open(OpenCashRegisterComponent, {
            showHeader: true,
            closeOnEscape: true,
            header: 'Abrir caja'
        });

        this.ref.onClose.subscribe((res) => {

        });
    }

    showAdvanceCompponent() {
        this.ref = this.dialogService.open(GenerateAdvanceComponent, {
            showHeader: true,
            closeOnEscape: true,
            header: 'Avances'
        });

        this.ref.onClose.subscribe((res) => {

        });
    }

    showInitCompponent() {
        this.ref = this.dialogService.open(InitDataComponent, {
            showHeader: true,
            closeOnEscape: true,
            header: 'Carga Inicial'
        });

        this.ref.onClose.subscribe((res) => {

        });
    }


    showAboutCompponent() {
        this.ref = this.dialogService.open(AboutComponent, {
            data: this.infoVersion,
            showHeader: true,
            closeOnEscape: true,
            header: 'Acerca de',
            width: '200px',
            height: '150px'
        });

        this.ref.onClose.subscribe((res) => {

        });
    }


    showComponent() {
        this.topBarPresenter.showComponent();
    }

    showOpenCpos() {
        console.log('Conectar cPOS');
        this.confirmationService.confirm({
            message: '¿Desea conectar el dispositivo cPOS?',
            header: 'Conectar cPOS',
            acceptLabel: 'Aceptar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.topBarPresenter.showOpenCpos();
            }
        });
    }


    getTitleName() {
        switch (this.router.url) {
            case '/panel/search-clients':
                return 'Clientes';
            case '/panel/search-invoices':
                return 'Facturas';
            case '/panel/search-credit-memos':
                return 'Notas de crédito';
            case '/panel/open-day-report':
                return 'Reporte caja';
            case '/panel/close-day-report':
                return 'Reporte cierre';
            case '/panel/graficas':
                return 'Reporte ventas';
            case '/panel/pos':
                return 'MEGAPOS';
            case '/panel/aprove-credit-memo':
                return 'Aprobar Nota de crédito';
            case '/panel/aprove-cash-return':
                return 'Devolución efectivo NC';
            default: return 'MEGAPOS';
        }
    }

    changePassword(espape: boolean, message: string) {
        this.ref = this.dialogService.open(ChangePasswordComponent, {
            header: 'CAMBIAR CONTRASEÑA ' + message,
            closeOnEscape: espape,
            width: '25%',
            height: '350px',
            closable: espape,

        });
        this.ref.onClose.subscribe((res) => {
            this.dataProvider.isDisabledScreen = false;
        });
    }
    showCloseCashRegisterCompponent(espape: boolean, message: string) {
        this.ref = this.dialogService.open(CloseCashRegisterComponent, {
            showHeader: true,
            header: 'CERRAR CAJA ' + message,
            closeOnEscape: espape,
            closable: espape,
            height: '550px',
            width: '700px',
            data: true

        });
        this.ref.onClose.subscribe((res) => {
            this.dataProvider.isDisabledScreen = false;
        });

    }

}



