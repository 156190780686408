import { AssingPricesView } from '../assing-prices.view';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { ClientService } from 'src/app/services/client.service';
import { Store } from '@ngrx/store';
import { ClientModel } from 'src/app/models/client.model';
import { DataProvider } from 'src/app/services/data.provider';
import { InvoiceService } from 'src/app/services/invoice.service';

@Injectable({
  providedIn: 'root',
})
export class AssingPricesPresenter implements Presenter {
    view: AssingPricesView;
    constructor(
        private userService: UserService,
        private clientService: ClientService,
        private store: Store<{ client: ClientModel, adviser: ClientModel }>,
    ) { }

    onSearchUser() {
      console.log(this.view.page);
      const page = this.view.page;
      const sizeSeller = this.view.sizeSeller;
      if (page < 0) {
          return;
      }
      if (!sizeSeller || sizeSeller < 1) {
          return;
      }
      this.clientService.searchSellerDependent(page, sizeSeller).then(data => {
          const users: ClientModel[] = data['data'];
          this.view.totalElements = data['totalElements'];
          this.view.users = users;
      }).catch(() => {
          this.view.showError('Atención', 'Algo salio mal buscando el cliente');
      });
  }

  sendUserPrice(newUserPrice) {
    this.clientService.saveUserPrice(newUserPrice).subscribe(
      (res: any) => {
        this.view.showSuccess('Precios asignados', '');
        this.onSearchUser();
      }, (error: HttpErrorResponse) => {
        this.view.showError('', error.error.message);
      }
    );

  }

  loadUserPriceId(userId) {
    this.clientService.getUserPrices(userId).subscribe(
      (res: any) => {
        if (res) {
          this.view.loadUserPrice = res;
          this.view.userPrice.userPriceId = res.userPriceId;
          this.view.roles.forEach(role => role.selected = false);
          if (res.price !== '') {
            this.view.selectedRoles = this.view.loadUserPrice.price.split(',');
          }
          this.view.roles.forEach(role => {
            if (this.view.selectedRoles.includes(role.value)) {
              role.selected = true;
            }
            if (res.price === '') {
              this.resetRole();
            }

          });
        } else {
          this.resetRoles();
        }
      },
      (error) => {
        console.error('Error al recuperar los precios de los usuarios', error);
        this.resetRoles();
      }
    );
  }

resetRoles() {
    this.view.roles.forEach(role => role.selected = false);
    this.view.selectedRoles = [];
    this.view.userPrice.userPriceId = '';
}
resetRole () {
  this.view.roles.forEach(role => role.selected = false);
  this.view.selectedRoles = [];
}
}
