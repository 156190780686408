import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import {
  ConfirmationService,
  DialogService,
  MessageService,
} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { apearAnimation, bottomToTop, rightToRight } from 'src/app/animations';
import { AbstractView } from 'src/app/core/abstract_view';
import { MeasureConversionModel } from 'src/app/models/measure-conversion.model';
import { Order } from 'src/app/models/order.model';
import { OrderLine } from 'src/app/models/orderline.model';
import { PriceList } from 'src/app/models/pricelist.model';
import { ProcessModel } from 'src/app/models/process.model';
import { Product, WareHouse } from 'src/app/models/product.model';
import { TypeStatusQuotation } from 'src/app/models/quotation.model';
import { TransferDetailModel } from 'src/app/models/transfer.mode';
import { SetDiscountToCero } from 'src/app/reducers/order.action';
import { DataProvider } from 'src/app/services/data.provider';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';
import Swal from 'sweetalert2';

import { leftToLeft } from '../../../../../animations';
import { TopbarPresenter } from '../../../shared/topbar/presenter/topbar.presenter';
import { SearchOrderComponent } from '../search-order/search-order.component';
import { SearchQuotationComponent } from '../search-quotation/search-quotation.component';
import { ProductQuestPresenter } from './presenter/product-quest.presenter';
import { ProductQuestView } from './product-quest.view';
import { AdvancesDetailComponent } from '../advances-detail/advances-detail.component';
import { ClearAdvances } from 'src/app/reducers/advance.action';
import { UserPriceModel } from 'src/app/models/invoice.model';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  animations: [apearAnimation, leftToLeft, bottomToTop, rightToRight],
  selector: 'app-product-quest',
  templateUrl: './product-quest.component.html',
  styleUrls: ['./product-quest.component.scss'],
  providers: [DialogService],
})
export class ProductQuestComponent
  extends AbstractView
  implements OnInit, ProductQuestView, OnDestroy {
  public noSpecial: RegExp = /^[0-9%.]/;
  @ViewChild('dt', { read: false, static: false }) dt: Table;
  @ViewChild('editQ', { read: false, static: false }) searchElement: ElementRef;
  @ViewChild('productQuestSearch', { read: false, static: false })
  searchItem: AutoComplete;
  orderLines: OrderLine[];
  orderLinesToShow: OrderLine[];
  selectedOrderLine: OrderLine;
  rowGroupMetadata: any;
  search = '';
  page = 0;
  size = 150;
  roles = '';
  highestRole: string;
  products: Product[];
  selectedProduct: Product;
  totalElements: number;
  priceListId: string;
  priceList: { label: string; value: PriceList }[];
  selectedPrice: PriceList = {
    priceListId: '',
    name: '',
  };
  adminPriceDiscount = false;
  isRecycling = false;
  selectedClient = null;
  updateOrder: Order = null;
  wareHousePresenter: WareHouse = { name: 'Pro', referenceId: 'pro' };
  urlArray: string[] = [
    '/assets/layout/images/promo/banner_2.jpg',
    '/assets/layout/images/promo/banner_1.jpg',
    '/assets/layout/images/promo/banner_3.jpg',
  ];

  addToProductQuest: false;
  public ref;
  creditCardPayment: false;
  selectedTransferLine: TransferDetailModel;
  procesesSelected: ProcessModel;
  loadUserPrice: UserPriceModel;
  messageSubscription: Subscription;
  approveAllSuggestion = false;
  isCredired = false;
  isCrediredEdit = false;
  showSelectedClient = false;
  adminPermison = false;
  constructor(
    private messageService: MessageService,
    private productQuestPresenter: ProductQuestPresenter,
    public router: Router,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    private hotKey: HotkeysService,
    public dataProvider: DataProvider,
    public configuration: DefaultconfigurationsService,
    public topBarPresenter: TopbarPresenter,
    private store: Store<{ orderLines: OrderLine }>
  ) {
    super(messageService, router);
    productQuestPresenter.view = this;
    productQuestPresenter.init();
    this.shortcuts();
    if (this.getAuth(DomainAction.ALLOW_POS_ADMIN_PRICES)) {
      this.adminPermison = true;
    }
    }

  ngOnInit() {
    this.productQuestPresenter.transferProvider.getTransferDetailsToInvoice();
    this.productQuestPresenter.loadUserPriceId();
  }

  ngOnDestroy(): void {
    this.hotKey.pause(
      this.hotKey.add(
        new Hotkey(
          ['alt+up', 'ctrl+up'],
          (event: KeyboardEvent): boolean => {
            this.showSearchOrder(null);
            return false;
          },
          ['input', 'textarea'],
          'Mostrar ordenes'
        )
      )
    );
    this.hotKey.pause(
      this.hotKey.add(
        new Hotkey(
          ['esc'],
          (event: KeyboardEvent): boolean => {
            this.setFocusOnSearch();
            return false;
          },
          ['input', 'textarea'],
          'Foco en búsqueda de productos'
        )
      )
    );
    this.hotKey.pause(
      this.hotKey.add(
        new Hotkey(
          [
            'alt+del',
            'alt+backspace',
            'ctrl+del',
            'ctrl+backspace',
            'shift+del',
            'shift+backspace',
          ],
          (event: KeyboardEvent): boolean => {
            this.showClearConfirmation();
            return false;
          },
          ['input', 'textarea']
        )
      )
    );
  }

  shortcuts() {
    this.hotKey.add(
      new Hotkey(
        ['alt+up', 'ctrl+up'],
        (event: KeyboardEvent): boolean => {
          this.showSearchOrder(null);
          return false;
        },
        ['input', 'textarea'],
        'Mostrar ordenes'
      )
    );

    this.hotKey.add(
      new Hotkey(
        ['esc'],
        (event: KeyboardEvent): boolean => {
          this.setFocusOnSearch();
          return false;
        },
        ['input', 'textarea'],
        'Foco en búsqueda de productos'
      )
    );

    this.hotKey.add(
      new Hotkey(
        [
          'alt+del',
          'alt+backspace',
          'ctrl+del',
          'ctrl+backspace',
          'shift+del',
          'shift+backspace',
        ],
        (event: KeyboardEvent): boolean => {
          this.showClearConfirmation();
          return false;
        },
        ['input', 'textarea']
      )
    );
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.orderLinesToShow.length > 0) {
      for (let i = 0; i < this.orderLinesToShow.length; i++) {
        const rowData = this.orderLinesToShow[i];
        const referenceCode = rowData.referenceCode;
        if (i === 0) {
          this.rowGroupMetadata[referenceCode] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.orderLinesToShow[i - 1];
          const previousRowGroup = previousRowData.referenceCode;
          if (referenceCode === previousRowGroup) {
            this.rowGroupMetadata[referenceCode].size++;
          } else {
            this.rowGroupMetadata[referenceCode] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  onSearchProduct(event) {
    this.search = event.query;
    this.productQuestPresenter.onSearchProductPos();
  }

  setFocusOnSearch() {
    this.delay(10).then(() => {
      this.searchItem.focusInput();
    });
  }
  setFocusInTable() {
    this.delay(10).then(() => {
      if (document.getElementById('0')) {
        document.getElementById('0').focus();
      }
    });
  }
  setSearchToNull() {
    this.delay(10).then(() => {
      this.selectedProduct = null;
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  onChangeListPrice() {
    this.productQuestPresenter.onSearchProductPos();
  }
  onEnter() {
    this.productQuestPresenter.addOrderLine();
  }
  onClickLine(product: Product, event?: Event) {
    if (!product.collectionCenterStock || product.collectionCenterStock <= 0) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.showError('', 'Stock de acopio no disponible');
      return;
    }
    if (!product.priceWithoutTax || product.priceWithoutTax === 0) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.showError(
        'Atención',
        'No se puede agregar un producto con precio $0'
      );
      return;
    }
    this.selectedProduct = product;
    this.selectedProduct.warehousePresenters.splice(0, 1);
    this.selectedProduct.availableQuantity =
      this.selectedProduct.warehousePresenters[0].availableQuantity;
    this.productQuestPresenter.addOrderLine();
  }
  stopPropag(event?: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  onClick(product: Product, event?: Event) {
    if (!product.productId) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    } else {
      if (!product.priceWithoutTax || product.priceWithoutTax === 0) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        this.showError(
          'Atención',
          'No se puede agregar un producto con precio $0'
        );
        return;
      }
      this.selectedProduct = product;
      this.productQuestPresenter.addOrderLine();
    }
  }

  showSearchQuotations(button: any) {
    if (button) {
      button.blur();
    }
    this.ref = this.dialogService.open(SearchQuotationComponent, {
      showHeader: true,
      header: 'Búsqueda de cotizaciones',
      closeOnEscape: true,
      // height: '475px',
      width: '80%',
    });
  }

  showSearchAdvances(button: any) {
    if (button) {
      button.blur();
    }
    if (this.showSelectedClient) {
      if (this.selectedClient.allowClientAdvance) {
        Swal.fire({
          title: 'Confirmar información',
          html: '<p><b>Correo registrado: </b>' + this.selectedClient.email
            + '<br><br> Si la dirección de correo electrónico es correcto dar clic en <b>Aceptar</b>, caso contrario'
            + 'dar clic en <b>Rechazar</b> y actualizar en la información del cliente.',
          text: '¿Desea limpiar toda la pantalla?',
          showCancelButton: true,
          cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
          cancelButtonText: 'Rechazar',
          confirmButtonColor: '#00687d',
          confirmButtonText: 'Aceptar',
        }).then((result) => {
          if (result.value) {
            this.ref = this.dialogService.open(AdvancesDetailComponent, {
              showHeader: true,
              closeOnEscape: false,
              header: 'Anticipos',
              width: '80%',
            });
            this.ref.onClose.subscribe((res: any) => {
              console.log(res);
              this.store.dispatch(new ClearAdvances());
            });
          }
        });
      } else {
        Swal.fire({
          title: 'Atención',
          text: 'Cliente no permitido para ANTICIPOS',
          showCancelButton: false,
          confirmButtonColor: '#00687d',
          confirmButtonText: 'Aceptar'
        });
      }
    } else {
      Swal.fire({
        title: 'Atención',
        text: 'Es necesario ingresar un cliente.',
        showCancelButton: false,
        confirmButtonColor: '#00687d',
        confirmButtonText: 'Aceptar'
      });
    }
  }
  showSearchOrder(button: any) {
    if (button) {
      button.blur();
    }
    this.ref = this.dialogService.open(SearchOrderComponent, {
      showHeader: true,
      header: 'Búsqueda de órdenes',
      closeOnEscape: true,
      height: '475px',
      width: '850px',
    });
  }

  showClearConfirmation() {
    Swal.fire({
      text: '¿Desea limpiar toda la pantalla?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Limpiar',
    }).then((result) => {
      if (result.value) {
        this.productQuestPresenter.clearAll();
      }
    });
  }

  deleteItem(line: OrderLine) {
    if (this.procesesSelected === ProcessModel.QUOTATION) {
      this.showConfirmation(line);
    } else {
      this.selectedOrderLine = line;
      this.productQuestPresenter.deleteOrderLine();
      this.store.dispatch(new SetDiscountToCero());
    }
  }

  changeItemDiscount(orderLine: OrderLine) {
    this.selectedOrderLine = orderLine;
    this.productQuestPresenter.changeLineDiscount();
    this.setFocusOnSearch();
  }
  // update price when change selected
  changePrice(price: number, line: OrderLine) {
    this.setFocusOnSearch();
    line.discount = 0;
    line.product.priceWithoutTax = price;
    line.product.priceCommissionCardWithoutTax = price + (price * this.dataProvider.commissionCard);
    this.selectedOrderLine = line;
    this.productQuestPresenter.changeLineDiscount();
  }
  changeItemDiscountFocus(orderLine: OrderLine) {
    this.selectedOrderLine = orderLine;
    this.productQuestPresenter.changeLineDiscount();
  }
  changeItemQuiantity(orderLine: OrderLine) {
    if (orderLine.enteredQuantity === orderLine.product.enteredQuantity) {
      orderLine.product.checkedApproveSuggestion = true;
    } else {
      if (this.dataProvider.quotationStatus !== null) {
        orderLine.product.checkedApproveSuggestion = false;
        this.showMessage(
          'Cantidad modificada, ya no puede aprobar la cotización'
        );
      }
    }

    this.selectedOrderLine = orderLine;
    this.productQuestPresenter.changeLine();
    this.setFocusOnSearch();
  }
  changeItemQuiantityFocus(orderLine: OrderLine) {
    this.selectedOrderLine = orderLine;
    this.productQuestPresenter.changeLine();
  }
  changeComboItem(orderLine: OrderLine, unit: MeasureConversionModel) {
    this.selectedOrderLine = orderLine;
    this.selectedOrderLine.selectedMeasure = unit;
    this.selectedOrderLine.product.suggestedPrice = 0;
    this.productQuestPresenter.changeLineUnitMeasure();
  }
  changeSuggestedPrice(suggestedPrice: number, orderLine: OrderLine) {
    if (suggestedPrice === orderLine.product.suggestedPriceBusinessManager) {
      orderLine.product.checkedApproveSuggestion = true;
    } else {
      if (this.dataProvider.quotationStatus !== null) {
        orderLine.product.checkedApproveSuggestion = false;
        this.showMessage(
          'Precio sugerido modificado, ya no puede aprobar la cotización'
        );
      }
    }

    if (
      orderLine.limit < suggestedPrice &&
      suggestedPrice < orderLine.priceWithoutTax &&
      !this.dataProvider.isAdminPriceDiscount
    ) {
      this.showEnablePriceSpecial();
    }

    this.selectedOrderLine = orderLine;
    this.selectedOrderLine.suggestedPrice = suggestedPrice ? suggestedPrice : 0;
    this.productQuestPresenter.changeLineSuggestedPrice();
  }
  showEnablePriceSpecial() {
    Swal.fire({
      title: 'Active la opción Precios Aprobador Especial',
      text: 'Para el rango de valores en el precio sugerido',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    });
  }
  clearSearch() {
    this.selectedProduct = null;
  }

  printName(name) {
    this.showInfo('', name);
  }

  changeAutoAddStatus() {
    this.productQuestPresenter.changeAutoAddStatus();
  }

  listenLineTRansfer(selectLine: TransferDetailModel) {
    this.selectedTransferLine = selectLine;
    this.productQuestPresenter.addTransferLineToOrderLine();
  }
  changeIsRecycling(line: OrderLine) {
    if (line.quantityRecycling > line.enteredQuantity) {
      line.quantityRecycling = line.enteredQuantity;
      return;
    }
    if (!line.isRecycling) {
      line.quantityRecycling = 0;
    }
    this.productQuestPresenter.changeIsRecycling(line);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    return false;
  }

  petitions() {
    this.configuration.getGeneralConfigurations();
    if (this.topBarPresenter.view.fullUser) {
      this.topBarPresenter.validateCloseCash(
        this.topBarPresenter.view.fullUser.userId
      );
    }
  }
  approveAllSuggestions() {
    if (this.approveAllSuggestion) {
      this.setStateAllSuggested(true);
    } else {
      this.setStateAllSuggested(false);
    }
  }
  setStateAllSuggested(check: boolean) {
    this.orderLinesToShow.forEach((line: OrderLine) => {
      line.product.checkedApproveSuggestion = check;
      if (check) {
        line.product.suggestedPrice =
          line.product.suggestedPriceBusinessManager;
      } else {
        line.product.suggestedPrice = line.suggestedPrice;
      }
    });
  }

  showConfirmation(line: OrderLine) {
    Swal.fire({
      text: `¿Desea eliminar ${line.product.name}? `,
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      if (result.value) {
        line.orderDetailId = null;
        this.selectedOrderLine = line;
        this.productQuestPresenter.deleteOrderLine();
        this.store.dispatch(new SetDiscountToCero());
      }
    });
  }

  validationQuotationDisabling(): boolean {
    let disabled = false;
    if (this.dataProvider.quotationStatus === TypeStatusQuotation.TO_BE_INVOICED
      || this.dataProvider.quotationStatus === TypeStatusQuotation.APPROVED) {
      disabled = true;
    }
    return disabled;
  }

  validationQuotationDisablingDropdownUnit(line: OrderLine) {
    if (line.orderDetailId === null) {
      return false;
    } else {
      return true;
    }
  }

  validateApproval() {
    let valid = false;
    if (
      this.dataProvider.quotationId &&
      this.dataProvider.quotationStatus === TypeStatusQuotation.TO_BE_APPROVED
    ) {
      valid = true;
    }
    return valid;
  }

  approvePriceSuggestedManager(line: OrderLine): void {
    this.selectedOrderLine = line;

    let findPrice: number;
    const orderline = this.orderLinesToShow.find(
      (element) => element.referenceCode === line.product.referenceCode
    );
    if (line.product.checkedApproveSuggestion) {
      findPrice = orderline.suggestedPriceBusinessManager;
    } else {
      findPrice = orderline.suggestedPrice;
    }
    this.selectedOrderLine.product.suggestedPrice = findPrice;
    this.selectedOrderLine.enteredQuantity = orderline.product.enteredQuantity;
  }
  validateProductCredired(orderLinesToShow) {
    return orderLinesToShow.some((element) => element.product.referenceCode === 'L20179');
  }
}
