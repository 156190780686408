import { Injectable } from '@angular/core';
import { GenerateCashRegisterView } from '../generate-cash-register.view';
import { BankService } from 'src/app/services/bank.service';
import { BankAccount } from 'src/app/models/bankaccount.model';
import { DataProvider } from 'src/app/services/data.provider';
import { Presenter } from 'src/app/core/presenter';
import { ClientModel } from 'src/app/models/client.model';
import { OrderLine } from 'src/app/models/orderline.model';
import { Store } from '@ngrx/store';
import { CashRegisterService } from 'src/app/services/cash-register.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
  })
  export class GenerateCashRegisterPresenter implements Presenter {
    view: GenerateCashRegisterView;
    constructor(
    private bankService: BankService,
    private dataProvider: DataProvider,
    private cashRegisterService: CashRegisterService,
    private store: Store<{
      client: ClientModel;
      adviser: ClientModel;
      orderLines: OrderLine;
    }>
    ) {}
loadBanksFromAtix() {
      this.bankService.ownBanks().subscribe(
        (res: BankAccount[]) => {
          this.view.ownBanks = [];
          res.forEach((bankAcount) => {
            const bankIsPresent = this.view.ownBanks.find(
              (bank) => bank.bankId === bankAcount.bankPresenter.bankId
            );
            if (!bankIsPresent) {
              this.view.ownBanks.sort((a, b) => a.name.localeCompare(b.name));
              this.view.ownBanks.push(bankAcount.bankPresenter);
            }
          });
          this.view.selectedBank = null;
          this.dataProvider.bankAcounts = res;
        },
        () => {
          this.view.showError('', 'No hay bancos ');
        }
      );
    }
  filterBankAccounts() {
    this.view.bankAccountSelected = null;
    this.view.filteredBankAccounts = this.dataProvider.bankAcounts.filter(
      (bankAcount) =>
        this.view.selectedBank.bankId === bankAcount.bankPresenter.bankId
    );
  }

  loadCloseCashAmount (dateCorrect: string) {
    this.cashRegisterService.getCloseCashAmount(dateCorrect).subscribe(
      (res: any) => {
        this.view.dayCloseCash = res;
      }

    );
  }

  sendDeposit(depositCloseCash) {
    this.cashRegisterService.depositeCloseCash(depositCloseCash).then((deposit: any) => {
      if (deposit.length > 0) {
        this.view.notFoundDeposit = deposit;
        let deposits = '';
        this.view.notFoundDeposit.forEach((resp: any) => {
          deposits += resp.referencePayment + ' - ';
        });
        this.view.showError('Deposito no encontrado: ', deposits);
      } else {
        this.view.showSuccess('Registro exitoso', '');
        this.view.closeView(deposit);
      }
    }).catch((error) => {
      if (error instanceof HttpErrorResponse) {
        this.view.showError('Error al Registrar', error.error.message);
      } else {
        this.view.showError('Atención', 'Algo salio mal');
      }
    });
  }

  loadValueLockedbox() {
    this.cashRegisterService.getValueLockedbox().subscribe(
      (locked: any) => {
        this.view.lockedCloseCash = locked;
      }
    );
  }

  loadAllDateValidate() {
    this.cashRegisterService.getAllDatesValidate().subscribe(
      (validate: any) => {
        this.view.dateValidate = validate;
      }
    );
  }

}
