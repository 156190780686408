import { Injectable } from '@angular/core';
import { PaymentTerm } from '../models/paymentterm';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentTermService {

  constructor(private http: HttpClient) { }
  public searchPaymentTerm(): Promise<PaymentTerm[]> {
    return new Promise((resolve, reject) => {
      this.http.get<PaymentTerm[]>(environment.apiUrl + '/listPaymentTerms')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
