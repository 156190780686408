import { Presenter } from 'src/app/core/presenter';
import { Injectable } from '@angular/core';
import { ChangePasswordView } from '../change-password.view';
import { ChangePasswordService } from 'src/app/services/change-password.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPresenter } from 'src/app/models/fulluser.model';


@Injectable({
    providedIn: 'root',
})
export class ChangePasswordPresenter implements Presenter {
    view: ChangePasswordView;

    constructor(
        private changePasswordService: ChangePasswordService
    ) {


    }
    validatePassword() {
        if (this.view.form.invalid) {
            return;
        } else {
            const user: UserPresenter = {
                userId: '',
                userName: '',
                fullName: '',
                dni: '',
                referenceId: '',
                password: this.view.form.value.password,
                oldPassword: this.view.form.value.oldPassword,
            };
            this.view.showConfirmation(user);
        }
    }
    changePassword(user: UserPresenter) {
        this.view.blockUi();
        this.changePasswordService.changePassword(user).subscribe(() => {
            this.view.showSuccess('Credenciales', 'Actualizadas con éxito');
            this.view.closeView();
        }, (error: HttpErrorResponse) => {
            this.view.showError('Error al actualizar credenciales', error.error.message);
        });
    }
}
