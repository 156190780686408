import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';
import { KardexReportView } from '../kardex-report.view';
import { OrderLine } from 'src/app/models/orderline.model';
import { ProcessModel } from 'src/app/models/process.model';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { Store } from '@ngrx/store';
import { ClientModel } from 'src/app/models/client.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentType, KardexDetailModel, Product } from 'src/app/models/product.model';
import { formatDate } from '@angular/common';
import { TriStateCheckbox } from 'primeng/primeng';




@Injectable({
    providedIn: 'root',
})
export class KardexReportPresenter implements Presenter {
    view: KardexReportView;
    lines: OrderLine[] = [];
    orderLinesToRestore: OrderLine[] = [];
    procesesSelected: ProcessModel;

    constructor(
        private productService: ProductService,
        private reportService: ReportService,
        private presenterSetting: SettingPresenter,
        private store: Store<{ client: ClientModel, orderLines: OrderLine }>
    ) {
    }

    init() {

    }

    getKarkexStock() {
        this.view.reports = [];
        this.view.visible = false;
        let initDate = '';
        let endDate = '';
        let product = null;
        let productReferenceId = null;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (!this.view.selectedProduct) {
            return;
        } else {
            product = this.view.selectedProduct.productId;
            productReferenceId = this.view.selectedProduct.referenceId;
        }

        this.view.blockUi();

        this.reportService.getProductKardex(product, initDate, endDate).subscribe((res: KardexDetailModel[]) => {

            res.reverse();
            if (res.length) {
                res.forEach(line => {

                    switch (line.documentType) {
                        case DocumentType.INVOICE: line.documentType = 'Factura'; break;
                        case DocumentType.CREDIT_NOTE: line.documentType = 'Nota de Credito'; break;
                        case DocumentType.DEBIT_NOTE: line.documentType = 'Nota de Debito'; break;
                        case DocumentType.REMISSION_GUIDE: line.documentType = 'Despacho'; break;
                        case DocumentType.WITHHOLDING: line.documentType = 'Retencion'; break;
                        case DocumentType.ORDER: line.documentType = 'Orden'; break;
                        case DocumentType.TRANSFER: line.documentType = 'Transferencia'; break;
                        case DocumentType.ADJUSTMENT: line.documentType = 'Ajuste'; break;
                        case DocumentType.INITIAL_STOCK: line.documentType = 'Stock Inicial'; break;
                        case DocumentType.PURCHASE: line.documentType = 'Compra'; break;
                        case DocumentType.QUOTATION: line.documentType = 'Cotización'; break;
                        case DocumentType.OTHER: line.documentType = 'Otro'; break;

                    }
                });
                this.view.visible = true;
                this.view.reports = res;
            } else {
                this.view.visible = false;
                this.view.showInfo('', 'Búsqueda sin registros');
            }

        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar movimientos');
        });
        this.reportService.getProductByReferenceId(productReferenceId, initDate, endDate).subscribe((res: Product) => {
            this.view.warehauses = res.warehousePresenters;

        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar registro');
        });
    }

    findReportsByProduct() {
        if (this.view.selectedProduct && typeof this.view.selectedProduct !== 'string') {
            this.view.showSelectedProduct = true;
            this.getKarkexStock();
        }
    }

    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.getKarkexStock();
    }

    onSearchProductPos() {
        const searchValue = this.view.search;
        const page = this.view.page;
        const size = this.view.size;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.productService.searchProduct(searchValue, page, size, prceListId, null).then(data => {

            this.view.products = data['data'];

            if (this.view.products.length <= 0) {
                this.view.showError('', 'Búsqueda sin registros');
                this.view.products = [];
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }


    printKardexReport() {
        let initDate = '';
        let endDate = '';
        let date = '';
        const format = 'dd-MM-yyyy';
        const locale = 'en-US';
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        date = initDate + ' / ' + endDate;
        const reports = this.view.reports.map(line => {
            return {
                Fecha: line.created,
                Tipo: line.documentType,
                Usuario: line.user,
                Numero: line.documentNo,
                Entrada: line.input,
                Salida: line.output,
                Fisico: line.physical,
                Reserva: line.assigned,
                Disponible: line.available
            };

        });
        const reportsToCSV = JSON.parse(JSON.stringify(reports,
            [
                'Fecha',
                'Tipo',
                'Usuario',
                'Numero',
                'Entrada',
                'Salida',
                'Fisico',
                'Reserva',
                'Disponible',
            ], 2));
        this.view.csvDownload('Kardex ' + ' ' + this.view.selectedProduct.referenceCode + ' ' + date + '.csv', reportsToCSV);
    }

}
