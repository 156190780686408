import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { PaymentCollectionsErpPresenter } from 'src/app/models/payment-collections.model';
import { ReceiptsPaymentsDailyPresenter } from './presenter/receipts-payments-daily.presenter';
import { ReceiptsPaymentsDaily } from './receipts-payments-daily.view';

@Component({
  selector: 'app-receipts-payments-daily',
  templateUrl: './receipts-payments-daily.component.html',
  styleUrls: ['./receipts-payments-daily.component.scss']
})
export class ReceiptsPaymentsDailyComponent extends AbstractView implements ReceiptsPaymentsDaily, OnInit {
  initDate = new Date();
  endDate = new Date();
  page = 0;
  size = 10;
  totalElements = 0;
  paymentCollections: PaymentCollectionsErpPresenter[] = [];
  selectedPaymentCollection: PaymentCollectionsErpPresenter;
  constructor(
    private messageService: MessageService,
    public router: Router,
    public receiptsPaymentsDailyPresenter: ReceiptsPaymentsDailyPresenter,
    public dialogService: DialogService
  ) {
    super(messageService, router);
    receiptsPaymentsDailyPresenter.view = this;
  }

  ngOnInit() {
    this.initDate.setDate(this.initDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate() - 1);

    this.getPaymentCollections();
  }

  getPaymentCollections() {
    this.receiptsPaymentsDailyPresenter.getPaymentCollections();
  }

  resynchronizeTransactions() {
    this.receiptsPaymentsDailyPresenter.resynchronizeTransactions();
  }

  resynchronizeTransaction(paymentCollection: PaymentCollectionsErpPresenter) {
    this.selectedPaymentCollection = paymentCollection;
    this.receiptsPaymentsDailyPresenter.resynchronizeTransaction();
  }

  paginate($event) {
    this.page = $event['page'];
    this.size = $event['rows'];
    this.getPaymentCollections();
  }

}
