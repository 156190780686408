import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { User } from 'src/app/models/user.model';
import { DevolutionFile, TransferMovementType, TransfersModel, TransferStatus } from 'src/app/models/transfer.mode';
import { WareHouse } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class TransferService {


  constructor(private http: HttpClient) { }

  getAvailableStock(productReferenceId: any, transferMovementType: TransferMovementType) {
    return this.http.get(environment.apiUrl + '/getWarehousesStock?productReferenceId=' + productReferenceId
      + '&transferMovementType=' + transferMovementType);
  }

  getTransfers(userId: string, initDate: string, endDate: string, documentNo: string, page: number, size: number,
    // tslint:disable-next-line: align
    transferMovementTypes: string[], transferStatus: TransferStatus, origin: string, destiny: string) {
    return this.http.

      get(environment.apiUrl + '/getTransfers?userId=' + userId + '&initDate=' + initDate + '&endDate=' + endDate
        + '&documentNo=' + documentNo + '&transferMovementTypes=' + transferMovementTypes + '&transferStatus=' + transferStatus
        + '&origin=' + origin + '&destiny=' + destiny + '&page=' + page + '&size=' + size);
  }


  setTransferStatus(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/changeTransferStatus', transfer);
  }

  getTransferById(transferId: string, toClaim: boolean) {
    return this.http.get(environment.apiUrl + '/getTransferById?transferId=' + transferId + '&toClaim=' + toClaim);
  }

  getTransferWarehouses() {
    return this.http.get(environment.apiUrl + '/getTransferWarehouses');
  }

  saveTransfer(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/saveTransfer', transfer);

  }
  updateTransfer(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/updateTransfer', transfer);

  }

  getTransferDetailsToInvoice() {
    return this.http.get(environment.apiUrl + '/getTransferDetailsToInvoice');
  }

  cancelTransfer(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/cancelTransfer?transferId=' + transfer.transferId, {});

  }
  supplyReception(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/supplyReception', transfer);

  }

  getPdf(transferId: string) {
    return this.http.get(environment.apiUrl + '/printTransferTicket?transferId=' + transferId);
  }

  getTransfersSearch(searchValue: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/getTransferDetailsToInvoice?searchValue=' + searchValue + 'page' + page + 'size' + size);
  }

  saveClaim(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/saveClaim', transfer);

  }
  syncroniceTransfer(transferId: string) {
    return this.http.get(environment.apiUrl + '/resynTransfer?transferId=' + transferId);
  }
  saveReturn(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/saveTransferDevolution', transfer);
  }
  loadReturnFile(file: DevolutionFile[]) {
    return this.http.post(environment.apiUrl + '/devolutionFile', file);
  }
  saveDraftDevolution(transfer: TransfersModel) {
    return this.http.post(environment.apiUrl + '/saveTransferDevolution', transfer);
  }
  deleteTransferDetail(transferId: string) {
    return this.http.get(environment.apiUrl + '/deleteTransferDetail?transferId=' + transferId);
  }
  cancelTransferDevolution(transferId: string) {
    return this.http.get(environment.apiUrl + '/cancelTransferDevolution?transferId=' + transferId);
  }
  getDevolutionPdf(transferId: string) {
    return this.http.get(environment.apiUrl + '/printDevolutionTicket?transferId=' + transferId);
  }

  getPendingTransfers() {
    return this.http.get(environment.apiUrl + '/isPendingTransfers');
  }

  getProductWithoutList(data: string[]) {
    return this.http.get(environment.apiUrl + '/getProductWithoutList?uidProduct=' + data);
  }
}
