import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PromotionMessageModel } from '../models/promotion-message.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private http: HttpClient) { }

  getPromotions(promotionMessage: PromotionMessageModel) {
    return this.http.post(environment.apiUrl + '/searchPromo', promotionMessage);
  }
}
