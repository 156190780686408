import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.scss']
})
export class RolComponent extends AbstractView implements OnInit {
  constructor(
    private messageService: MessageService, public router: Router
  ) {
    super(messageService, router);
  }

  ngOnInit() {
  }

}
