import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SearchInvoicePresenter } from './presenter/search-invoice.presenter';
import { AbstractView } from 'src/app/core/abstract_view';
import { SearchInvoiceView } from './search-invoice.view';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { ClientModel } from 'src/app/models/client.model';
import { MessageService, DialogService, MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { GenerateCreditNoteComponent } from '../../credit-memo/generate-credit-note/generate-credit-note.component';
import { GenerateDispatchComponent } from '../../dispatch/generate-dispatch/generate-dispatch.component';
import { DispatchModel } from 'src/app/models/dispatch.model';
import { DomainAction } from 'src/app/models/rol.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { InvoiceStatus } from 'src/app/models/invoice.status';
import { QuotationModel } from 'src/app/models/quotation.model';

@Component({
  selector: 'app-search-invoice',
  templateUrl: './search-invoice.component.html',
  styleUrls: ['./search-invoice.component.scss'],
  providers: [DialogService]
})
export class SearchInvoiceComponent extends AbstractView implements OnInit, SearchInvoiceView, OnDestroy {

  @ViewChild('docNo', { read: false, static: false }) docNo: ElementRef;

  dockNum$ = new Subject<string>();

  dockNumSubscription: Subscription;
  items: MenuItem[];

  showCreditNote = false;
  invoices: InvoiceModel[];
  selectedInvoice: InvoiceModel;

  orders: InvoiceModel[] = [];
  selectedOrder: InvoiceModel;
  searchClient: '';
  pageClient: 0;
  sizeClient: 5;
  clients: ClientModel[];
  selectedClient: ClientModel;
  initDate = null;
  endDate = null;
  documentNo = null;

  showSelectedClient = false;
  public ref;

  page = 0;
  size = 20;

  totalElements: number;
  invoiceNumbers = '';

  constructor(
    public searchInvoicePresenter: SearchInvoicePresenter,
    private messageService: MessageService,
    public router: Router,
    public dialogService: DialogService,

  ) {
    super(messageService, router);
    searchInvoicePresenter.view = this;
    if (!this.getAuth(DomainAction.ALLOW_POS_LIST_INVOICES)) {
      this.redirectTo('/panel/pos');
    }
    this.blockUi();
    searchInvoicePresenter.findInvoices();
  }
  ngOnDestroy(): void {
    this.dockNumSubscription.unsubscribe();
  }

  ngOnInit() {
    this.dockNumSubscription = this.dockNum$.pipe(debounceTime(1000)).subscribe(res => this.findInvoices());
  }


  findInvoices() {
    this.page = 0;
    this.searchInvoicePresenter.findInvoices();
  }

  onSearchClient(event) {
    this.searchClient = event.query;
    this.pageClient = 0;
    this.sizeClient = 5;
    this.searchInvoicePresenter.onSearchClient();
  }

  clearClient() {
    this.searchInvoicePresenter.clearClient();
  }

  addClient() {
    this.searchInvoicePresenter.findInvoicesByClient();
  }

  showCreditNoteComponent(invoice: InvoiceModel) {
    this.ref = this.dialogService.open(GenerateCreditNoteComponent, {
      header: 'NOTA DE CRÉDITO',
      closeOnEscape: true,
      width: '80%',
      height: '550px',
      data: invoice
    });
  }

  showDispatchComponent(dispatch: DispatchModel) {
    this.ref = this.dialogService.open(GenerateDispatchComponent, {
      header: 'DESPACHO',
      closeOnEscape: true,
      width: '60%',
      height: '460px',
      data: dispatch
    });
    this.ref.onClose.subscribe(() => {
      this.searchInvoicePresenter.findInvoices();
    });
  }
  getInvoice(invoice: InvoiceModel) {
    this.docNo.nativeElement.focus();
    this.docNo.nativeElement.blur();
    this.selectedInvoice = invoice;
    this.searchInvoicePresenter.getInvoiceAndGenerateCreditMemo();
  }
  getInvoiceAndDispatch(invoice: InvoiceModel) {
    this.docNo.nativeElement.focus();
    this.docNo.nativeElement.blur();
    this.selectedInvoice = invoice;
    this.searchInvoicePresenter.getInvoiceAndDispatch();
  }

  paginate($event) {
    this.page = $event['page'];
    this.searchInvoicePresenter.findInvoices();
  }

  getPdf(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    this.searchInvoicePresenter.getPdf();
  }
  postSyncroniceInvoiceAndDispatch(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    this.searchInvoicePresenter.postSyncroniceInvoiceAndDispatch();
  }
  getInvoices() {
    this.dockNum$.next(this.documentNo);
  }

  cancelInvoice(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    Swal.fire({
      text: '¿Desea anular esta factura ?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular factura'
    }).then((result) => {
      if (result.value) {
        this.searchInvoicePresenter.cancelInvoice();
      }
    });
  }
  syncroniceInvoices() {
    let invoices: number[] = [];
    // tslint:disable-next-line: only-arrow-functions
    invoices = this.invoiceNumbers.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    this.searchInvoicePresenter.rezyncInvoices(invoices);
  }
  translateInvoiceStatus(invoiceStatus: string) {
    switch (invoiceStatus) {
      case InvoiceStatus.WITHOUT_DISPATCH: return 'SIN DESPACHO';
      case InvoiceStatus.PARTIALLY_DISPATCHED: return 'PARCIALMENTE DESPACHADO';
      case InvoiceStatus.DISPATCHED: return 'DESPACHADO';
      default: return invoiceStatus;
    }
  }
  getVoucher(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    this.searchInvoicePresenter.getVoucher();
  }

}
