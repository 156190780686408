import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Presenter } from 'src/app/core/presenter';
import { ClientModel } from 'src/app/models/client.model';
import { CreditStatus } from 'src/app/models/order.model';
import { OrderLine, OrderLineToSave } from 'src/app/models/orderline.model';
import { CollectionCenter, Product } from 'src/app/models/product.model';
import { AddClient } from 'src/app/reducers/client.action';
import {
    AllowInvoice,
    ChangeMAxQuantity,
    DenyInvoiceProcess,
    SetBilleton,
    SetCreditCardPayment,
    SetObservationInRedux,
    UpdateOrder,
} from 'src/app/reducers/order.action';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { WarehouseService } from 'src/app/services/warehouse.service';

import { InvoiceModel } from '../../../../../../models/invoice.model';
import { SelectInvoice } from '../../../../../../reducers/invoice.action';
import { SearchOrderView } from '../search-order.view';
import { DataProvider } from '../../../../../../services/data.provider';
import { GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';



@Injectable({
    providedIn: 'root',
})
export class SearchOrderPresenter implements Presenter {
    view: SearchOrderView;
    constructor(
        private clientService: ClientService,
        private orderService: OrderService,
        private invoiceService: InvoiceService,
        private store: Store<{ client: ClientModel, orderLines: OrderLine, invoice: InvoiceModel }>,
        private productService?: ProductService,
        private warehouseService?: WarehouseService,
        private dataProvider?: DataProvider
    ) {
    }



    onSearchClient() {
        const searchValue = this.view.searchClient;
        const page = this.view.pageClient;
        const size = this.view.sizeClient;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }

        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.clients = clients;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearClient() {
        this.view.showSelectedClient = false;
        this.view.selectedClient = null;
        this.findOrders();
    }

    findOrdersByClient() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            this.view.showSelectedClient = true;
            this.findOrders();
        }
    }

    findOrders() {
        let initDate = '';
        let endDate = '';
        let client = null;
        let docNo = null;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        this.view.blockUi();
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (this.view.selectedClient) {
            client = this.view.selectedClient;
        }
        if (this.view.documentNo) {
            docNo = this.view.documentNo;
        }

        this.orderService.findOrders(client, initDate, endDate, docNo).then(data => {
            this.view.orders = data;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }


    cancelOrder() {
        this.view.blockUi();
        const order = this.view.selectedOrder;
        this.orderService.cancelOrder(order.orderId).then(data => {
            if (data) {
                this.view.showError('', 'orden anulada');
                this.findOrders();
            } else {
                this.view.showError('Atención', 'Ocurrió un problema anulando la orden');
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

    createInvoiceFromOrder() {
        this.view.blockUi();
        const invoice: InvoiceModel = {};
        this.orderService.getOrder(this.view.selectedOrder.orderId).then(order => {
            this.dataProvider.isOrder = true;
            invoice.orderPresenter = order;
            this.dataProvider.processPayment = 'current';
            GeneralConfigurations.creditCardPayment = false;
            for (const line of order.orderDetailPresenters) {
                line.warehousePresenter.local = line.warehousePresenter.referenceId === this.dataProvider.defaultWarehouse.referenceId;
            }
            invoice.orderPresenter.creditStatus = CreditStatus.APPROVED_OVERDRAFT;
            this.store.dispatch(new SelectInvoice(invoice));
            this.store.dispatch(new UpdateOrder(invoice.orderPresenter));
            this.store.dispatch(new SetObservationInRedux(order.observation));
            this.store.dispatch(new AddClient(invoice.orderPresenter.clientPresenter));
            this.store.dispatch(new AllowInvoice());
            this.store.dispatch(new DenyInvoiceProcess());
            this.getCollectionCenterStock(order.orderDetailPresenters);
            this.view.closeView();
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    getOrderAndEdit() {
        this.view.blockUi();
        const order = this.view.selectedOrder;
        this.orderService.getOrder(order.orderId).then(data => {
            if (data) {
                this.view.selectedOrder = data;
                this.store.dispatch(new DenyInvoiceProcess());
                this.editOrder();
            } else {
                this.view.showError('Atención', 'Ocurrió un problema editando la orden');
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

    editOrder() {
        const order = this.view.selectedOrder;
        this.store.dispatch(new UpdateOrder(order));
        this.store.dispatch(new AddClient(order.clientPresenter));
        this.store.dispatch(new SetObservationInRedux(order.observation));
        this.getCollectionCenterStock(order.orderDetailPresenters);
        this.store.dispatch(new SetCreditCardPayment(order.isCreditCardPayment
            , this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.view.closeView();
    }

    getCollectionCenterStock(lines: OrderLineToSave[]) {
        if (!this.warehouseService.deafaultWarehouse) {
            return;
        }
        const products: Product[] = [];
        lines.forEach(line => {
            if (line.warehousePresenter.referenceId !==
                this.warehouseService.deafaultWarehouse.referenceId) {
                const prod: Product = { productId: line.product.productId, referenceId: line.product.referenceId };
                products.push(prod);
            }
        });

        if (products.length <= 0) {
            return;
        }
        const collectionCenter: CollectionCenter[] = [];
        products.forEach(prod => {
            collectionCenter.push({ productPresenter: { productId: prod.productId, referenceId: prod.referenceId } });
        });
        /*
        this.productService.getCollectionCenterStock(collectionCenter).then((collections: CollectionCenter[]) => {
            collections.forEach(collection => {
                products.forEach(prod => {
                    if (collection.productPresenter.productId === prod.productId) {
                        collection.warehousePresenter.availableQuantity = Math.trunc(collection.warehousePresenter.availableQuantity);
                        prod.availableQuantity = collection.warehousePresenter.availableQuantity;
                        prod.warehousePresenters = [collection.warehousePresenter];
                    }
                });
            });
            this.store.dispatch(new ChangeMAxQuantity(products));

        }).catch(error => { });*/
    }





}
