import { Action } from '@ngrx/store';
import { ClientModel } from '../models/client.model';


export enum ClientActions {
    ADD_CLIENT = 'ADD_CLIENT',
    CLEAR_CLIENT = 'CLEAR_CLIENT',
}

export class AddClient implements Action {
    constructor(public client: ClientModel) { }
    readonly type = ClientActions.ADD_CLIENT;
}

export class ClearClient implements Action {
    constructor() { }
    readonly type = ClientActions.CLEAR_CLIENT;
}


export type ActionsUnion = AddClient | ClearClient;
