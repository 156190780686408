import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';


import { SearchProductView } from '../search-product..view';
import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/models/product.model';
import { Store, select } from '@ngrx/store';
import { ClientModel } from 'src/app/models/client.model';

@Injectable({
    providedIn: 'root',
})
export class SearchProductPresenter implements Presenter {
    view: SearchProductView;
    constructor(private productService: ProductService) {
    }

    onSearchProduct() {
        const searchValue = this.view.search;
        const page = this.view.page;
        const size = this.view.size;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.productService.searchProduct(searchValue, page, size, searchValue, null).then(data => {
            const products: Product[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.products = products;
        }).catch((error) => {
            this.view.showError('Atención', 'Ocurrió un problema al intentar buscar los productos');
        });
    }

}
