import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-reports',
  templateUrl: './sales-reports.component.html',
  styleUrls: ['./sales-reports.component.scss'],
})
export class SalesReportsComponent implements OnInit {
  navLinks = [];
  constructor() {}
  ngOnInit() {
    this.navLinks = [
      {
        label: 'Reporte por método de pago',
        link: './payment_method-report',
        index: 0,
        visible: true,
      },
      {
        label: 'Reporte por tipo de producto',
        link: './product-category-report',
        index: 1,
        visible: true,
      },
      {
        label: 'Reporte de productos por marca',
        link: './product-supplier-report',
        index: 1,
        visible: true,
      },
      {
        label: 'Reporte de productos por tipo de precio',
        link: './report-prices-sales',
        index: 1,
        visible: true,
      },
      {
        label: 'Reporte de productos vendidos',
        link: './product-history',
        index: 1,
        visible: true,
      },
      {
        label: 'Reporte de comisiones',
        link: './report-commission',
        index: 1,
        visible: false,
      },
    ];
  }
}
