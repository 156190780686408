import { Injectable } from '@angular/core';
import { OpenCashRegisterView } from '../open-cash-register.view';
import { CashRegisterModel } from 'src/app/models/cash-register.model';
import { CashRegisterService } from 'src/app/services/cash-register.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AdvanceService } from 'src/app/services/advance.service';
import { DataProvider } from 'src/app/services/data.provider';
import { CreditCardService } from 'src/app/services/credit-card.service';

@Injectable({
  providedIn: 'root',
})
export class OpenCashRegisterPresenter {
  view: OpenCashRegisterView;
  constructor(
    public cashRegisterService: CashRegisterService,
    public advanceService: AdvanceService,
    public creditCardService: CreditCardService,
    public dataProvider: DataProvider
  ) { }

  validate() {
    if (isNaN(this.view.amount)) {
      this.view.showError('', 'Ingrese un monto correcto');
      return;
    }
    let amount = 0;
    if (this.view.amount) {
      amount = this.view.amount;
    }
    const openDay: CashRegisterModel = {
      amount: amount,
      observation: this.view.observation
    };
    this.view.blockUi();

    this.cashRegisterService
      .getDefaultConfigurationCpos()
      .subscribe((res: any) => {
        this.dataProvider.isConfigurationCpos = res;
      });

    this.cashRegisterService
      .openCashRegister(openDay)
      .then((data: any) => {
        if (data) {
          this.dataProvider.isConnectionCpos = data.connectionCpos;
          this.view.showSuccess('', 'Caja abierta');
          this.view.closeModal();
          this.advanceService.advanceStatus();
          this.dataProvider.isOpenCash = true;
          if (this.dataProvider.isOpenCash && !this.dataProvider.isConnectionCpos) {
            this.dataProvider.needOpenCpos = true;
          } else {
            this.dataProvider.needOpenCpos = false;
          }
        }
      })
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          this.view.showError('Atención', error.error.message);
        } else {
          this.view.showError('Atención', 'Algo salio mal');
        }
      });
  }
  connectionCpos() {
    this.creditCardService.saveConnectionCpos().subscribe((res: any) => {
      this.dataProvider.isConnectionCpos = res;
      if (res) {
          if (this.dataProvider.isOpenCash &&
              !this.dataProvider.isConnectionCpos
          ) {
              this.dataProvider.needOpenCpos = true;
          } else {
              this.dataProvider.needOpenCpos = false;
          }
          this.view.showSuccess('Correcto', 'Dispositivo Conectado');
      } else {
          this.view.showError('Atención', 'Sin conección con CPOS');
      }
  }, (error) => {
      this.view.showError('Atención', 'Sin conección con CPOS');
  });
  }
}
