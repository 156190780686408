import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { AproveView } from '../aprove.view';
import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AprovePresenter implements Presenter {
    view: AproveView;

    constructor(private userService: UserService) {
    }




    acceptLoging() {
        const user = this.view.user;
        const password = this.view.password;
        if (!password || password.length === 0) {
            this.view.showError('', 'Debe ingresar una contraseña');
            return;
        }
        if (!user || user.length === 0) {
            this.view.showError('', 'Debe ingresar un usuario');
            return;
        }
        this.view.blockUi();
        this.userService.doLogin(user, password).then(data => {
            this.view.closeAproveComponent(true);

        }).catch((error) => {

            this.view.showError('Error', 'Credenciales incorrectas');

        });
    }


}
