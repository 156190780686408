import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteSpacePipe } from 'src/app/main-components/shared/pipes/white-space.pipe';



@NgModule({
  declarations: [
    WhiteSpacePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WhiteSpacePipe
  ]
})
export class SharedModule { }
