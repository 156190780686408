import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { ClientModel } from 'src/app/models/client.model';
import { ProductForm } from 'src/app/models/product-form';
import {
  PaymentCollectionsAvailableServicesPresenter,
  SendMessageResponsePresenter,
} from 'src/app/models/receipts-payments';
import { MessageImputsPresenter } from 'src/app/models/step';
import { ClientService } from 'src/app/services/client.service';
import { GetFinalCostumerService } from 'src/app/services/get-final-costumer.service';
import { ReceiptsPaymentsService } from 'src/app/services/receipts-payments.service';

import { ReceiptsPaymentsView } from '../receipts-payments.view';

// import * as servEjemplo from '../../receipts-payments-process/servicios.json';
@Injectable({
  providedIn: 'root',
})
export class ReceiptsPaymentsPresenter implements Presenter {
  view: ReceiptsPaymentsView;

  constructor(
    private finalConsumerService: GetFinalCostumerService,
    private receiptsPaymentsService: ReceiptsPaymentsService,
    private clientService: ClientService
  ) { }

  login() {
    this.view.blockUi();
    this.receiptsPaymentsService.login().subscribe((res: PaymentCollectionsAvailableServicesPresenter) => {
      if (res.success) {
        this.getAvailableServices();
        this.view.showSuccess('Bienvenido', 'A Cobros Pagos');
      } else {
        this.view.redirectToPos();
        this.view.showExeption(res);
      }
    }, (errors: HttpErrorResponse) => {
      const errorB = {
        message: { code: '', description: errors.error.message },
        result: { services: [] },
        success: false
      };

      this.view.showExeption(errorB);
      this.view.redirectToPos();
    });
  }

  getAvailableServices() {
    this.view.blockUi();
    this.receiptsPaymentsService.getAvailableServices().subscribe((res: PaymentCollectionsAvailableServicesPresenter) => {
      if (res.success) {
        this.view.respuesta = res;
        this.view.listCategoriesSelectItem();
        this.view.showSuccess('Consulta de servicios ', 'Ok');
        this.view.loadTotalProductsSelectItem();
      }
    }, (errors: HttpErrorResponse) => {
      const errorB = {
        message: { code: '', description: errors.error.message },
        result: { services: [] },
        success: false
      };
      this.view.redirectToPos();
      this.view.showExeption(errorB);
    });
  }

  onSearchClient() {
    const searchValue = this.view.queryClient;
    const page = this.view.page;
    const size = this.view.size;
    if (searchValue.length < 3) {
      return;
    }
    if (page < 0) {
      return;
    }
    if (!size || size < 1) {
      return;
    }
    this.view.blockUi();
    this.clientService.searchClient(searchValue, page, size).then((data) => {
      const clients: ClientModel[] = data['data'];
      this.view.totalElements = data['totalElements'];
      this.view.clients = clients;
    }).catch((error) => {
      this.view.showBackException(error);
    });
  }
  validateClient(): boolean {
    if (!this.view.selectedClient || typeof this.view.selectedClient === 'string') {
      return false;
    } else {
      return true;
    }
  }
  createInputs(productForm: ProductForm): MessageImputsPresenter[] {
    const inputs: MessageImputsPresenter[] = [];
    for (const [key, value] of Object.entries(productForm.form.value)) {
      const input: MessageImputsPresenter = {
        code: key,
        value: value,
      };
      inputs.push(input);
    }
    return inputs;
  }
  sendMessage(productForm: ProductForm, setLocalStorage: boolean) {
    if (!this.validateClient()) {
      this.view.showWarn('Cliente necesario', 'Ingrese un cliente válido');
      return;
    }
    const inputs: MessageImputsPresenter[] = this.createInputs(productForm);
    if (localStorage.getItem('codePayValueForm1') !== null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].code === localStorage.getItem('codePayValueForm1')) {
          this.view.payValue = inputs[i].value;
        }
      }
    }
    if (localStorage.getItem('numList')) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].code === localStorage.getItem('codeListForm' + i)) {
          inputs[i].value = localStorage.getItem('listValueForm' + i);
        }
      }
    }
    this.view.message213 = {
      trxIdOriginal: this.view.transactionId ? this.view.transactionId : '00000000-0000-0000-0000-000000000000',
      user: {
        code: '',
        name: ''
      },
      service: {
        code: this.view.valueProductSelected.service.code,
        name: this.view.valueProductSelected.service.name
      },
      product: {
        code: this.view.valueProductSelected.product.code,
        cash: this.view.valueProductSelected.product.cash,
        name: this.view.valueProductSelected.product.name
      },
      customer: {
        type: 'C',
        id: this.view.selectedClient.dni,
        name: `${this.view.selectedClient.firstName != null ? this.view.selectedClient.firstName : ''} ${
          this.view.selectedClient.middleName != null ? this.view.selectedClient.middleName : ''} ${
          this.view.selectedClient.lastName != null ? this.view.selectedClient.lastName : ''}`,
        phone: this.view.selectedClient.addressPresenters[0].phone ? this.view.selectedClient.addressPresenters[0].phone : '0000000000',
        email: this.view.selectedClient.email,
        address: this.view.selectedClient.addressPresenters[0].sectorPresenter.name
      },
      message: {
        action: {
          code: productForm.action.code,
        },
        inputs: inputs,
      },
      system: {
        date: '',
        time: '',
        ip: ''
      },
      dni: this.view.dni,
      amountRetire: this.view.payValue,
      isTwoSteps: this.view.isTwoSteps
    };
    this.view.blockUi();
    this.receiptsPaymentsService.sendMessage(this.view.message213).subscribe((res: SendMessageResponsePresenter) => {
      if (res.success !== null && res.success) {
        this.view.transactionId = res.result.transactionId;
        const lastForm = this.view.steps[this.view.steps.length - 1];
        // paso 2
        if (productForm.output.type === 'list' && res.result.items.length >= 1) {
          const order = productForm.order + 1;
          const step = this.view.steps.find((stepf) => stepf.order === order);
          step.items = res.result.items;
          step.items.forEach((item) => (item.commentsAmount = item.comments + ' - $' + item.amount.toString()));
          this.view.itemSelected = step.items[0];
          if (productForm.parameters) {
            step.parameters = productForm.parameters;
          }
          if (setLocalStorage) {
            this.view.steps[1].form.controls[localStorage.getItem('codeCounterpartForm2')].setValue(this.view.itemSelected.code);
            this.view.steps[1].form.controls[localStorage.getItem('codePayValueForm2')].setValue(this.view.itemSelected.totalAmount);
          }
          this.view.totalAmount = this.view.itemSelected.totalAmount;
          this.view.minimumAmount = this.view.itemSelected.minimumAmount;
          this.view.amount = this.view.itemSelected.amount;
          this.view.commissionAmount =
            this.view.itemSelected.commissionAmount;
          this.view.disableCounterpart = true;
          this.view.disableTotalAmount = true;
          this.view.disableAmount = true;
        } else if (productForm.output.type === 'void' && productForm.title === lastForm.title) {
          this.view.confirmTransaction();
          this.view.disableTotalAmount = false;
          this.view.disableCounterpart = false;
          this.view.disableAmount = false;
        }

        this.view.goNext();
      } else {
        if (!res.success && res.success !== null) {
          this.view.showExeption(res);
        } else {
          this.view.showError('Atención ', 'Algo salío mal ');
        }
      }
    }, (error) => {
      this.view.showBackException(error);
    });
  }

  confirmTransaction() {
    this.view.blockUi();
    this.receiptsPaymentsService.confirmTransaction(this.view.transactionId).subscribe((res: any) => {
      if (res.success !== null && res.success) {
        this.view.transactionId = '';
        this.view.selectedClient = null;
        this.view.valueProductSelected = null;
        this.view.showSelectedClient = false;
        this.view.showSelectedService = false;
        this.view.showSuccess('Éxito', 'Transacción generada');
        res.pdf = res.result.content;
        this.view.showPdf(res);
      } else {
        this.view.showExeption(res);
      }
      this.view.clearAll();
    }, (error) => {
      this.view.showExeption(error);
    });
  }

  getFinalConsumer() {
    this.view.blockUi();
    this.finalConsumerService.getFinalCostumer().then((data) => {
      this.view.selectedClient = data;
      this.view.showSelectedClient = true;
      this.view.dni = data.dni;
      this.view.selectedClient.dni = data.dni.substring(0, 10);
    }).catch((error) => {
      this.view.showBackException(error);
    });
  }
}
