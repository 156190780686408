
import { UserModel } from '../models/client.model';
import * as $params from './seller.action';

export interface IAppState {
    seller: UserModel;
    showSelectedSeller: boolean;
}

export const INITIAL_STATE: IAppState = {
    seller: null,
    showSelectedSeller: false
};

export function SellerReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {
    console.log(action);
    switch (action.type) {
        case $params.SellerActions.ADD_SELLER:
            return Object.assign({}, state, {
                seller: action.seller,
                showSelectedSeller: true
            });
        case $params.SellerActions.CLEAR_SELLER:
            return Object.assign({}, state, {
                seller: null,
                showSelectedSeller: false
            });

        default:
            return state;
    }
}
