import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractView } from 'src/app/core/abstract_view';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent extends AbstractView implements OnInit {

  navLinks = [];
  constructor(public router: Router, public service: MessageService) {
    super(service, router);
  }
  ngOnInit() {
    this.navLinks = [{
      label: 'Stock PDV',
      link: './stock-report',
      index: 0,
      visible: true
    }, {
      label: 'Kardex PDV',
      link: './kardex-report',
      index: 1,
      visible: true
    }, {
      label: 'Stock Valorizado',
      link: './valued-stock-report',
      index: 2,
      visible: this.getAuth('ALLOW_POS_VALUED_STOCK_REPORT')
    }, /*{
      label: 'Stock de Acopio',
      link: './stock-report-acopio',
      index: 1,
      visible: true
    },*/
    ];
  }

}
