import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';
import { StockReportAcopioView } from '../stock-report-acopio.view';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { HttpErrorResponse } from '@angular/common/http';
import { StockCollectionCenterPrint } from 'src/app/models/stockcollectioncenter';





@Injectable({
    providedIn: 'root',
})
export class StockReportAcopioPresenter implements Presenter {
    view: StockReportAcopioView;

    constructor(
        private productService: ProductService,
        private reportService: ReportService,
        private presenterSetting: SettingPresenter

    ) {
    }

    paginatedProductStock(printpdf: boolean) {
        this.view.blockUi();
        const print = printpdf;
        let product = null;
        const page = this.view.page + 1;
        const size = this.view.size;
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.referenceId;
        } else {
            product = '';
        }
        this.reportService.paginatedProductStockAcopio(product, page, size, print).subscribe((data: any) => {
            if (!printpdf) {
                this.view.reports = data['data'];
                this.view.totalElements = data.totalElements;
                if (this.view.reports.length <= 0) {
                    this.view.reports = [];
                    this.view.showError('', 'Producto sin stock en acopio');
                }
            } else {
                this.view.reportsCSV = data['data'];
                this.productsToCSV();
            }
        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar stock de acopio');
        });
    }

    findReportsByProduct() {
        if (this.view.selectedProduct && typeof this.view.selectedProduct !== 'string') {
            this.view.showSelectedProduct = true;
            this.paginatedProductStock(false);
        }
    }
    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.paginatedProductStock(false);
    }

    onSearchProductPos() {
        const searchValue = this.view.search;
        const pageProd = this.view.pageProd;
        const sizeProd = this.view.sizeProd;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        if (pageProd < 0) {
            return;
        }
        if (!sizeProd || sizeProd < 1) {
            return;
        }
        this.productService.searchProduct(searchValue, pageProd, sizeProd, prceListId, null).then(data => {

            this.view.products = data['data'];
            if (this.view.products.length <= 0) {
                this.view.showError('', 'Búsqueda sin registros');
                this.view.products = [];
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    productsToCSV() {
        let reportPdf: any = [];
        const repors: any = this.view.reportsCSV;
        repors.forEach(report => {
            const line: StockCollectionCenterPrint = {
                referenceCode: report.productPresenter.referenceCode,
                name: report.productPresenter.name,
                avaliableQuantity: report.warehousePresenter.availableQuantity
            };
            reportPdf.push(line);
        });
        reportPdf = reportPdf.map(line => {
            return {
                CODIGO: line.referenceCode,
                NOMBRE: line.name,
                EXISTENCIA: line.avaliableQuantity,
            };
        });

        const products = JSON.parse(JSON.stringify(reportPdf, ['CODIGO', 'NOMBRE', 'EXISTENCIA'], 2));
        this.view.csvDownload('Reporte-Stock-Acopio.csv', products);
    }

}
