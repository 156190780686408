import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { UserPresenter } from '../models/fulluser.model';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private http: HttpClient) { }

  changePassword(user: UserPresenter) {
    return this.http.post(environment.apiUrl + '/reset-password', user);
  }
  forgetPassword(username: string) {
    return this.http.post(environment.apiUrl + '/public/forgot-password?username=' + username, {});

  }

  confirmReset(token: string) {
    return this.http.post(environment.apiUrl + '/public/confirm-reset?token=' + token, {});
  }

  resetPassword(user: UserPresenter, token: string) {
    return this.http.post(environment.apiUrl + '/public/reset-password?token=' + token, user);
  }
}
