import { VaultManagmentView } from '../vault-managment.view';
import { Injectable } from '@angular/core';
import { VaultManagmentService } from 'src/app/services/vault-managment.service';
import { Presenter } from 'src/app/core/presenter';
import { MovementVaultType } from 'src/app/models/movement-vault-type.model';
import { MovementVault } from 'src/app/models/movement-vault.model';
@Injectable({
    providedIn: 'root',
})
export class VaultManagmentPresenter implements Presenter {
    view: VaultManagmentView;

    constructor(private vaultManagmentService: VaultManagmentService) {}

    openVault() {
        this.vaultManagmentService.openVault().then((res) => {
            this.view.showSuccess('Bóveda', 'Abierta correctamente');
        }).catch(err => {
            this.view.showError('Error', err.error.message);
        });
    }

    findOpenVault() {
        this.vaultManagmentService.getVault().then((res) => {
            this.view.vault = res;
        });
    }

    findVaultMovements() {
        this.vaultManagmentService.getMovementsVault().then((res) => {
            this.view.movementsVault = res;
        });
    }

    findMovementVaultTypes() {
        const values: { label: string, value: MovementVaultType }[] = [];
        this.vaultManagmentService.getMovementVaultTypes().then((res) => {
            res.forEach(v => {
                values.push({ label: v.name, value: v });
            });
        });
        this.view.movementVaultTypes = values;
    }

    saveMovementVault() {
        if (!this.view.movementVaultTypeSelected) {
            this.view.showWarn('Bóveda', 'Debe seleccionar un tipo de movimiento');
            return;
        }
        if (!this.view.newMovementAmount || this.view.newMovementAmount <= 0) {
            this.view.showWarn('Bóveda', 'Debe ingresar un monto válido para el movimiento');
            return;
        }
        const vaultMovement: MovementVault = {
            movementVaultTypePresenter: this.view.movementVaultTypeSelected,
            amount: this.view.newMovementAmount
        };
        this.vaultManagmentService.saveMovementVault(vaultMovement).then((res) => {
            this.view.showSuccess('Bóveda', 'Nuevo Movimiento registrado');
            this.findVaultMovements();
            this.findOpenVault();
            this.view.newMovementAmount = 0;
        }).catch(err => {
            this.view.showError('Error', err.error.message);
        });
    }
}
