import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/primeng';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientModel } from 'src/app/models/client.model';
import { CreditMemoModel } from 'src/app/models/creditmemo.model';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { DomainAction } from 'src/app/models/rol.model';
import Swal from 'sweetalert2';

import { SearchCreditMemoPresenter } from './presenter/search-credit-memo.presenter';
import { SearchCreditMemoView } from './search-credit-memo.view';
import { apearAnimation, leftToLeft } from 'src/app/animations';

@Component({
  animations: [apearAnimation, leftToLeft],
  selector: 'app-search-credit-memo',
  templateUrl: './search-credit-memo.component.html',
  styleUrls: ['./search-credit-memo.component.scss'],
  providers: [DialogService]
})
export class SearchCreditMemoComponent extends AbstractView implements OnInit, SearchCreditMemoView {

  @ViewChild('docNo', { read: false, static: false }) docNo: ElementRef;

  items: MenuItem[];

  showCreditNote = false;
  creditMemos: CreditMemoModel[];
  selectedCreditMemo: CreditMemoModel;

  orders: InvoiceModel[] = [];
  selectedOrder: InvoiceModel;
  searchClient: '';
  pageClient = 0;
  sizeClient = 20;
  clients: ClientModel[];
  selectedClient: ClientModel;
  initDate = null;
  endDate = null;
  documentNo = null;
  showSelectedClient = false;
  public ref;

  page = 0;
  size = 20;

  totalElements: number;
  creditMemoNumbers = '';

  constructor(
    private searchCreditMemoPresenter: SearchCreditMemoPresenter,
    private messageService: MessageService,
    public router: Router,
    public dialogService: DialogService,

  ) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_LIST_CREDIT_MEMOS)) {
      this.redirectTo('/panel/pos');
    }
    searchCreditMemoPresenter.view = this;
    this.blockUi();
    searchCreditMemoPresenter.findCreditMemos();
  }

  ngOnInit() {
  }


  findCreditMemos() {
    this.page = 0;
    this.searchCreditMemoPresenter.findCreditMemos();
  }

  onSearchClient(event) {
    this.searchClient = event.query;
    this.pageClient = 0;
    this.sizeClient = 20;
    this.searchCreditMemoPresenter.onSearchClient();
  }

  clearClient() {
    this.searchCreditMemoPresenter.clearClient();
  }

  addClient() {
    this.searchCreditMemoPresenter.findCreditMemosByClient();
  }


  paginate($event) {
    this.page = $event['page'];
    this.searchCreditMemoPresenter.findCreditMemos();
  }

  getPdf(creditMemo: CreditMemoModel) {
    this.selectedCreditMemo = creditMemo;
    this.searchCreditMemoPresenter.getPdf();
  }

  showConfirmation(creditMemo: CreditMemoModel) {
    this.selectedCreditMemo = creditMemo;
    Swal.fire({
      text: '¿Desea retornar todo el efectivo de esta Nota de crédito?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Retornar efectivo',
      confirmButtonColor: '#00687d',
    }).then((result) => {
      if (result.value) {
        this.searchCreditMemoPresenter.createMovementCashToCreditMemo();
      }
    });
  }
  getCreditMemoDetails(event, overlaypanel: OverlayPanel, creditMemo: CreditMemoModel) {
    if (!this.selectedCreditMemo || this.selectedCreditMemo.creditMemoId !== creditMemo.creditMemoId) {
      this.selectedCreditMemo = creditMemo;
      this.searchCreditMemoPresenter.getDetails();
    }
    overlaypanel.toggle(event);
  }

  sendResyncronizationCreditMemo(creditMemo: CreditMemoModel) {
    this.selectedCreditMemo = creditMemo;
    this.searchCreditMemoPresenter.sendResyncronizationCreditMemo();
  }
    syncroniceCreditMemos() {
    let creditMemos: number[] = [];
    // tslint:disable-next-line: only-arrow-functions
    creditMemos = this.creditMemoNumbers.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    this.searchCreditMemoPresenter.rezyncCreditMemos(creditMemos);
  }
}
