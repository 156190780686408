
import { Injectable } from '@angular/core';

import { Presenter } from 'src/app/core/presenter';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportService } from 'src/app/services/report.service';
import { formatDate } from '@angular/common';
import { OpenDayReportView } from '../open-day-report.view';
import { OpenCashRegisterPresenter } from '../../../../cash-register/open-cash-register/presenter/open-cash-register.presenter';


@Injectable({
    providedIn: 'root',
})
export class OpenDayReportPresenter implements Presenter {
    view: OpenDayReportView;

    constructor(
        private reportService: ReportService,
    ) { }

    onSearchOpenDayReport() {
        let searchValue = '';
        let initDate = '';
        let endDate = '';
        let page = 0;
        if (this.view.page) {
            page = this.view.page;
        }
        let size = 20;
        if (this.view.size) {
            size = this.view.size;
        }
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }

        if (this.view.searchValue) {
            searchValue = this.view.searchValue;
        }
        this.reportService.findOpenDayRegisters(searchValue, initDate, endDate, page, size).then(data => {
            this.view.reportOpenDays = data['data'];
            this.view.totalElements = data['totalElements'];
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

    printOpenCashRegister() {
        this.reportService.printOpenCashRegister(this.view.id).subscribe((res: OpenCashRegisterPresenter) => {
            this.view.showPdf(res);
        });
    }

    getDaySales() {
        if (!this.view.daySalesDate) {
            this.view.showError('', 'Ingrese una fecha');
            return;
        }
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        const daySalesDate = formatDate(this.view.daySalesDate, format, locale);
        this.view.blockUi();
        this.reportService.getDaySales(daySalesDate).subscribe(res => {
            this.view.showPdf(res);
        }, (error: HttpErrorResponse) => {
            this.view.showError('', error.error.message);
        });

    }

}
