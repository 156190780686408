import { Presenter } from 'src/app/core/presenter';
import { Injectable } from '@angular/core';
import { ChangePasswordService } from 'src/app/services/change-password.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ForgotPasswordView } from '../forget-password.view';


@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordPresenter implements Presenter {
    view: ForgotPasswordView;

    constructor(
        private changePasswordService: ChangePasswordService
    ) {
    }

    validatePassword() {
        if (this.view.form.invalid) {
            return;
        } else {
            const user: string = this.view.form.value.username;
            this.view.showConfirmation(user);
        }
    }

    changePassword(user: string) {
        this.view.blockUi();
        this.changePasswordService.forgetPassword(user).subscribe(() => {
            this.view.showSuccess('', 'Correo enviado con éxito');
            setTimeout(() => {
                this.view.closeView();
            }, 1000);
        }, (error: HttpErrorResponse) => {
            this.view.showError('Error al actualizar credenciales', error.error.message);
        });
    }
}
