import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { Product } from 'src/app/models/product.model';
import { CreateTransfersView } from './create-transfers.view';
import { TransfersPresenter } from './presenter/create-transfers.presenter';
import { TransferProvider } from '../../transfer.provider';
import { DomainAction } from 'src/app/models/rol.model';
import { TransferMovementType, TransferStatus } from 'src/app/models/transfer.mode';
import { DataProvider } from 'src/app/services/data.provider';
import { GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';

@Component({
  selector: 'app-create-tranfers',
  templateUrl: './create-tranfers.component.html',
  styleUrls: ['./create-tranfers.component.scss']
})
export class CreateTranfersComponent extends AbstractView implements OnInit, CreateTransfersView {
  searchProd = '';
  pageProd = 0;
  sizeProd = 30;
  productsProd: Product[];
  selectedProductProd: Product;
  totalElementsProd: number;
  addToProductQuestProd: false;
  public ref;
  constructor(
    private messageService: MessageService,
    public transfersPresenter: TransfersPresenter,
    public router: Router,
    public transferProvider: TransferProvider,
    public dataProvider: DataProvider
  ) {
    super(messageService, router);
    transfersPresenter.view = this;
  }

  ngOnInit() {
    this.transferProvider.transfersReport = [];
    this.transferProvider.selectedUser = null;
    this.transferProvider.initDate = null;
    this.transferProvider.endDate = null;
    this.transferProvider.documentNo = null;
    this.transferProvider.transferMovementTypeSelected = [];
    this.transferProvider.transferStatusSelected = null;
    this.transferProvider.originSelected = null;
    this.transferProvider.destinySelected = null;
    console.log('this.create transfer', this.dataProvider.showConfigDevolutions);
    console.log('this.create transfer', this.transferProvider.transferMovementTypes);

    if (this.dataProvider.showConfigDevolutions) {
      const exists = this.transferProvider.transferMovementTypes.some((obj: any) => obj.value === TransferMovementType.DEVOLUTION);
      if (!exists) {
        this.transferProvider.transferMovementTypes.push(
          { name: 'Devolución', value: TransferMovementType.DEVOLUTION }
        );
      }

    }

  }
  onSearchProduct(event) {
    this.transferProvider.searchProd = event.query;
    this.transferProvider.onSearchProductPos();
  }
  onSearchTransfer(event) {
    this.transferProvider.transferMovementTypeSelected = [TransferMovementType.SUPPLYING];
    this.transferProvider.transferStatusSelected = TransferStatus.RECEIVED;
    this.transferProvider.destinySelected = this.dataProvider.defaultWarehouse;

    if (!event) {
      this.transferProvider.transfersClaim = [];
      return;
    }
    this.transferProvider.documentNo = event;
    this.transferProvider.findTransfers('searchTransferClaim');
  }
  onSearchTransferDraft(event) {
    this.transferProvider.transferMovementTypeSelected = [TransferMovementType.DEVOLUTION];
    this.transferProvider.transferStatusSelected = TransferStatus.DRAFT;
    this.transferProvider.originSelected = this.dataProvider.defaultWarehouse;

    if (!event) {
      this.transferProvider.transfersDevolution = [];
      return;
    }
    this.transferProvider.documentNo = event;
    this.transferProvider.findTransfers('searchTransferDevolution');
  }


  getStock(product: Product) {
    if (this.transferProvider.movementTypeSelected.value === TransferMovementType.DEVOLUTION) {
      this.transferProvider.addProductToLineReturn(product, null);
    }
    if (this.transferProvider.movementTypeSelected.value === TransferMovementType.SUPPLYING_BETWEEN_PDV ||
      this.transferProvider.movementTypeSelected.value === TransferMovementType.BETWEEN_PDV) {
      if (product.productId) {
        this.transferProvider.getProductsInPdv(product);
      }
    }
    this.clearSearch();
  }


  clearSearch() {
    this.transferProvider.selectedProductProd = null;
  }

}


