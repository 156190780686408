import { Injectable } from '@angular/core';
import { TypeStatusQuotation } from 'src/app/models/quotation.model';

@Injectable({
  providedIn: 'root',
})
export class FunctionsQuotation {
  translateQuotationStatus(quotationStatus: string): string {
    switch (quotationStatus) {
      case TypeStatusQuotation.INIT:
        return 'INICIADA';
      case TypeStatusQuotation.PENDING: // HQ
        return 'PENDIENTE';
      case TypeStatusQuotation.RECEIVED: // HQ
        return 'RECIBIDA';
      case TypeStatusQuotation.TO_BE_APPROVED: // HQ
        return 'POR APROBAR';
      case TypeStatusQuotation.IN_REVIEW:
        return 'EN REVISIÓN AUTORIZADOR';
      case TypeStatusQuotation.APPROVED:
        return 'APROBADA';
      case TypeStatusQuotation.PURCHASING_PROCESS:
        return 'PROCESO DE COMPRA';
      case TypeStatusQuotation.PRODUCT_RECEPTION: // HQ
        return 'RECEPCIÓN DE PRODUCTO';
      case TypeStatusQuotation.TO_BE_INVOICED:
        return 'POR FACTURAR';
      case TypeStatusQuotation.INVOICED:
        return 'FACTURADA';
      case TypeStatusQuotation.REACHED:
        return 'RECHAZADA';
      case TypeStatusQuotation.VOIDED:
        return 'ANULADA';
      case TypeStatusQuotation.CANCELED:
        return 'CANCELADA';
      default:
        return quotationStatus;
    }
  }

  setTooltipQuotationStation(quotationStatus: string): string {
    switch (quotationStatus) {
        case TypeStatusQuotation.INIT:
          return 'Cotización iniciada';
        case TypeStatusQuotation.PENDING: // HQ
          return 'Autorizador aún no lee la cotización';
        case TypeStatusQuotation.RECEIVED: // HQ
          return 'Autorizador ha leído la cotización';
        case TypeStatusQuotation.TO_BE_APPROVED: // HQ
          return 'Esperando aprobación del PDV';
        case TypeStatusQuotation.IN_REVIEW:
          return 'Autorizador esta revisando la cotización';
        case TypeStatusQuotation.APPROVED:
          return 'Aprobada por el PDV';
        case TypeStatusQuotation.PURCHASING_PROCESS:
          return 'En proceso de compra';
        case TypeStatusQuotation.PRODUCT_RECEPTION: // HQ
          return 'En recepción del producto';
        case TypeStatusQuotation.TO_BE_INVOICED:
          return 'Lista para facturar';
        case TypeStatusQuotation.INVOICED:
          return 'Cotización facturada';
        case TypeStatusQuotation.REACHED:
          return 'Cotización rechazada';
        case TypeStatusQuotation.VOIDED:
          return 'Cotización anulada';
        case TypeStatusQuotation.CANCELED:
          return 'Cotización cancelada';
        default:
          return quotationStatus;
      }
  }


}
