import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { MessageService, DialogService } from 'primeng/api';
import { UiUtils } from 'src/app/core/ui-utils';
import { DomainAction } from 'src/app/models/rol.model';
import { CreditCardReportView } from './credit-card-report.view';
import { CreditCardReportPresenter } from './presenter/credit-card-report.presenter';
import { ClientModel } from '../../../../../models/client.model';
import { InvoiceModel } from '../../../../../models/invoice.model';
import { Payment } from '../../../../../models/payment.model';
import * as XLSX from 'xlsx';
import { CreditCardType } from 'src/app/models/creditcardtype.model';

@Component({
  selector: 'app-credit-card-report',
  templateUrl: './credit-card-report.component.html',
  styleUrls: ['./credit-card-report.component.scss']
})
  export class CreditCardReportComponent extends AbstractView implements OnInit, CreditCardReportView {

    @ViewChild('docNo', { read: false, static: false }) docNo: ElementRef;

  reports: Payment[];
  selectedInvoice: InvoiceModel;

  orders: InvoiceModel[] = [];
  searchClient: '';
  pageClient: 0;
  sizeClient: 5;
  clients: ClientModel[];
  selectedClient: ClientModel;
  initDate = null;
  endDate = null;
  documentNo = null;

  showSelectedClient = false;
  public ref;

  page = 0;
  size = 20;

  totalElements: number;
  fileName = 'CreditCardReport.xlsx';

  creditCardTypes: string;

  constructor(
      private searchCreditMemoPresenter: CreditCardReportPresenter,
      private messageService: MessageService,
      public router: Router,
      public dialogService: DialogService
    ) {
      super(messageService, router);
      if (!this.getAuth(DomainAction.ALLOW_POS_LIST_CREDIT_MEMOS)) {
        this.redirectTo('/panel/pos');
      }
      searchCreditMemoPresenter.view = this;
      this.blockUi();
      searchCreditMemoPresenter.findReports();
    }
    ngOnInit() {
    }

    findReports() {
      this.page = 0;
      this.searchCreditMemoPresenter.findReports();
    }

    onSearchClient(event) {
      this.searchClient = event.query;
      this.pageClient = 0;
      this.sizeClient = 5;
      this.searchCreditMemoPresenter.onSearchClient();
    }

    clearClient() {
      this.searchCreditMemoPresenter.clearClient();
    }

    addClient() {
      this.searchCreditMemoPresenter.findReportsByClient();
    }


    paginate($event) {
      this.page = $event['page'];
      this.searchCreditMemoPresenter.findReports();
    }

    exportexcel(): void {

      const element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Reporte de Tarjetas');

      XLSX.writeFile(wb, this.fileName);
    }
    getTranslate(creditCardType: CreditCardType) {
      switch (creditCardType) {
        case CreditCardType.CURRENT_PAYMENT: return 'CORRIENTE';
        case CreditCardType.CREDIT_WITH_INTEREST: return 'CRÉDITO CON INTERESES';
        case CreditCardType.CREDIT_WITHOUT_INTEREST: return 'CRÉDITO SIN INTERESES';
      }
    }
}
