import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ClientModel } from '../models/client.model';
import { BankAccount } from '../models/bankaccount.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  public searchClient(searchValue: string, page: number, size: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/clientsByCriterion?searchValue=' + searchValue + '&page=' + page + '&size=' + size)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  public searchSeller(page: number, sizeSeller: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/sellersByCriterion?warehouseReferenceId=' + '&page=' + page
        + '&size=' + sizeSeller)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public searchSellerDependent(page: number, sizeSeller: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/sellerDependentByCriterion?warehouseReferenceId=' + '&page=' + page
        + '&size=' + sizeSeller)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public saveClient(newClient: ClientModel): Promise<ClientModel> {
    return new Promise((resolve, reject) => {
      this.http.post<ClientModel>(environment.apiUrl + '/saveClient', newClient)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public findBankAccount(newClient: ClientModel): Promise<BankAccount[]> {
    return new Promise((resolve, reject) => {
      this.http.get<BankAccount[]>(environment.apiUrl + '/bankAccount?clientId=' + newClient.clientId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public saveBankAccount(bankAccount: BankAccount): Promise<BankAccount> {
    return new Promise((resolve, reject) => {
      this.http.put<BankAccount>(environment.apiUrl + '/bankAccount',
        JSON.stringify(bankAccount), { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  forbiddenCustomer() {
    return this.http.get(environment.apiUrl + '/forbiddenCustomer');
  }
  loadProfessions() {
    return this.http.get(environment.apiUrl + '/professions');
  }
  searchClients(searchValue: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/clientsByCriterion?searchValue=' + searchValue + '&page=' + page + '&size=' + size);
  }

  getProductByClient(clientId: string, productId: string, initDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/getClient?clientId=' + clientId
      + '&productId=' + productId
      + '&initDate=' + initDate
      + '&endDate=' + endDate
    );
  }

  getReportClient(clientId: string, productId: string, initDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/getReportClient?clientId=' + clientId
      + '&productId=' + productId
      + '&initDate=' + initDate
      + '&endDate=' + endDate
    );
  }

  getClients(searchValue: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/clientsByCriterion?searchValue=' + searchValue + '&page=' + page + '&size=' + size);
  }

  validateInvoicesOverdue(clientId: string) {
    return this.http.get(environment.apiUrl + '/getOverdueInvoiceByClient?clientId=' + clientId);
  }

  // Servicio post para Asignar precios a usuarios
  saveUserPrice(UserPricePresenter) {
    return this.http.post(environment.apiUrl + '/saveUserPrice' , UserPricePresenter);
  }

  getUserPrices (userId) {
    return this.http.get(environment.apiUrl + '/getUserPriceByUserId?userId=' + userId);
  }

}
