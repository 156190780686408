import { UserPresenter } from './fulluser.model';
import { MeasureConversionModel } from './measure-conversion.model';
import { Tax } from './tax.model';

export interface Product {
  enteredQuantity?: number;
  productId?: string;
  referenceId?: string;
  description?: string;
  name?: string;
  referenceCode?: string;
  taxPresenter?: Tax;
  priceWithoutTax?: any;
  subTotal?: any;
  priceWithTax?: any;
  assignedQuantity?: any;
  availableQuantity?: any;
  measureUnitConversionPresenters?: MeasureConversionModel[];
  priceLimitWithoutTax?: number;
  priceLimitWithTax?: number;
  collectionCenterStock?: number;
  warehousePresenters?: WareHouse[];
  normalPriceWithTax?: number;
  normalPriceWithoutTax?: number;
  agreementDiscount?: number;
  isCreditMemo?: boolean;
  isBilleton?: boolean;
  codigo?: string;
  specialPrice?: number;
  wholesalerPrice?: number;
  administratorPrice?: number;
  enableInput?: boolean;
  priceCost?: number;
  productCategoryPresenters?: ProductCategory[];
  transferStock?: number;
  discountRecycling?: number;
  isRecycling?: boolean;
  modifyPrice?: boolean;
  priceWithSurcharge?: number;
  minToDispatch?: number;
  acopioToDispatch?: number;
  isHomologate?: boolean;
  trademark?: string;
  suggestedPrice?: number;
  stockPDV?: number;
  suggestedPriceBusinessManager?: number;
  suggestedQuantityBusinessManager?: number;
  observationHq?: string;
  observation?: string;
  statusColor?: string;
  commissionPercentage?: number;
  checkedApproveSuggestion?: boolean;
  descriptionMeasure?: string;
  family?: string;
  mark?: string;
  isHeavy?: boolean;
  priceCommissionCardWithoutTax?: any;
  normalPriceCommissionCardWithoutTax?: number;
  basePrice?: number;
}

export interface CollectionCenter {
  productPresenter: Product;
  warehousePresenter?: WareHouse;
}

export interface WareHouse {
  warehouseId?: string;
  referenceId?: string;
  name?: string;
  availableQuantity?: number;
  assignedQuantity?: number;
  local?: boolean;
  ip?: string;
  warehousesLinked?: WareHouse[];
  collectionCenterReferenceId?: string;
  businessUnit?: string;
  dispatchedReserveQuantity?: number;
}

export interface ProductCategory {
  name?: string;
  referenceId?: string;
  productCategoryType?: ProudctCategoryType;
}

export enum ProudctCategoryType {
  FAMILY = 'FAMILY',
  MOTHER_CATEGORY = 'MOTHER_CATEGORY',
  SKU = 'SKU',
  SUBFAMILY = 'SUBFAMILY',
  TRADEMARK = 'TRADEMARK',
}
export interface KardexDetailModel {
  movementId: string;
  created: string;
  documentNo: string;
  documentType: DocumentType | any;
  user: UserPresenter;
  input: number;
  output: number;
  physical: number;
  assigned: number;
  available: number;
  voided?: number;
  dispatchedQuantity?: number;

}

export enum DocumentType {
  INVOICE = 'INVOICE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  DEBIT_NOTE = 'DEBIT_NOTE',
  REMISSION_GUIDE = 'REMISSION_GUIDE',
  WITHHOLDING = 'WITHHOLDING',
  ORDER = 'ORDER',
  TRANSFER = 'TRANSFER',
  ADJUSTMENT = 'ADJUSTMENT',
  INITIAL_STOCK = 'INITIAL_STOCK',
  PURCHASE = 'PURCHASE',
  QUOTATION = 'QUOTATION',
  OTHER = 'OTHER',
}

export interface InvoiceSalesModel {
  created: string;
  isHeavy: boolean;

  subtotalInvoice: number;
  subtotalCreditMemo: number;
  subtotalBilleton: number;
  subtotal: number;

  subtotalInvoiceOther: number;
  subtotalCreditMemoOther: number;
  subtotalBilletonOther: number;
  subtotalOther: number;

  total: number;
}
export interface SalesCategoryModel {
  date: string;
  heavyTotal: number;
  heavySubTotal: number;
  heavyTax: number;
  hdStoreTotal: number;
  hdStoreSubTotal: number;
  hdStoreTax: number;
  total?: number;
  subtotal?: number;
  tax?: number;
}
export interface ProductSupplierPresenter {
  mark: string;
  subtotal: number;
  taxed: number;
  total: number;
  createdDate: Date;
  productSupplierDetailPresenters: ProductSupplierDetailPresenter[];
}
export interface ProductSupplierDetailPresenter {
  name: string;
  description: string;
  referenceCode: string;
  quantity: number;
  price: number;
  subtotal: number;
  taxed: number;
  total: number;
}
