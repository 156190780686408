import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrentPriceService {
  size = 0;
  available = 0;
  constructor(public http: HttpClient) { }

  getAllPrices() {
    return this.http.get(environment.apiUrl + '/getAllLabels  ', { responseType: 'text' });
  }
  getPriceBycode(productCode: string) {
    return this.http.get(environment.apiUrl + '/getProductLabel?productCode=' + productCode, { responseType: 'text' });
  }

  getCurrentPrices() {
    return this.http.get(environment.apiUrl + '/getProductPriceLabels', { responseType: 'text' });
  }
  getRangePrices(from: number, to: number) {
    return this.http.get(environment.apiUrl + '/getProductPriceLabels?from=' + from + '&&to=' + to
      , { responseType: 'text' });
  }
  getNumberOfLabels() {
    return this.http.get(environment.apiUrl + '/getNumberOfLabels');
  }
  getCodeLabel(codes: string[]) {
    return this.http.post(environment.apiUrl + '/getLabelsByCodes', codes, { responseType: 'text' });
  }

  getAutoServiceFile() {
    return this.http.get(environment.apiUrl + '/getAutoServiceFile' );
  }
  getAutoServiceFilePDF() {
    return this.http.get(environment.apiUrl + '/getAutoServiceFilePDF' );
  }
}
