import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import Swal from 'sweetalert2';
import { ForgotPasswordView } from './forget-password.view';
import { ForgotPasswordPresenter } from './presenter/forget-password.presenter';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  providers: [MessageService]

})

export class ForgetPasswordComponent extends AbstractView implements OnInit, ForgotPasswordView {

  form = this.formBuilder.group({});
  submitted = false;


  constructor(
    public formBuilder: FormBuilder,
    private messageService: MessageService,
    private changePasswordPresenter: ForgotPasswordPresenter,
    public router: Router) {
    super(messageService, router);
    changePasswordPresenter.view = this;
  }

  ngOnInit() {
    document.body.style.backgroundImage = 'url(/assets/layout/images/login/login-bg.jpg)';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.height = '100%';
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(3),
      ]],
    });
  }
  get registerFormControl() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.changePasswordPresenter.validatePassword();
  }
  closeView() {
    this.router.navigate(['']);
  }
  showConfirmation(user: string) {

    Swal.fire({
      text: '¿Se le enviará un correo, desea continuar?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Continuar'
    }).then((result) => {
      if (result.value) {
        this.changePasswordPresenter.changePassword(user);
      }
    });
  }

}
