import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { DialogService, ConfirmationService, MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';
import { AbstractView } from '../../../../../../core/abstract_view';
import { Product } from '../../../../../../models/product.model';
import { ValuesStockReportPresenter } from './presenter/valued-stock-report.presenter';
import { ValuedStockReportView } from './valued-stock-report.view';


@Component({
  selector: 'app-stock-report',
  templateUrl: './valued-stock-report.component.html',
  styleUrls: ['./valued-stock-report.component.scss']
})


export class ValuedStockReportComponent extends AbstractView implements OnInit, ValuedStockReportView {

  fileName = 'ExcelSheet.xlsx';
value: '';
  search = '';
  page = 0;
  size = 20;
  products: Product[];
  selectedProduct: Product;
  creditCardPayment: false;
  showSelectedProduct: false;
  reports: Product[];

  pageProd: 0;
  sizeProd: 20;
  reportsCSV = [];
  totalElements: 0;
  totalStockValued = 0;
  constructor(
    private messageService: MessageService,
    public presenter: ValuesStockReportPresenter,
    public router: Router,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService) {
    super(messageService, router);
    presenter.view = this;
    this.presenter.paginatedProductStock();
  }


  ngOnInit() {
    this.presenter.getTotalStockValed();
  }
  clearProduct() {
    this.presenter.clearProduct();
  }

  onClick() {
    this.presenter.getReportStock();
  }
  addProduct() {
    this.presenter.findReportsByProduct();
  }
  onSearchProduct(event) {
    this.search = event.query;
    this.presenter.onSearchProductPos();
  }

  onChangeListPrice() {
    this.presenter.onSearchProductPos();
  }

  clearSearch() {
    this.selectedProduct = null;
  }
  exportexcel(): void {

    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte de Stock');

    XLSX.writeFile(wb, this.fileName);
  }
  getNumber(rowData: number) {
    if (!rowData) {
      return 0;
    } else {
      return rowData;
    }

  }

  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  paginate($event) {
    this.page = $event['page'];
    this.presenter.paginatedProductStock();
}
}

