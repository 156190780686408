import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Router } from '@angular/router';
import { DialogService, MessageService, SelectItem } from 'primeng/api';
import { Listbox } from 'primeng/primeng';
import { leftToLeft } from 'src/app/animations';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientModel } from 'src/app/models/client.model';
import { ProductForm } from 'src/app/models/product-form';
import {
  CategoryPresenter,
  ItemsPresenter,
  PaymentCollectionsAvailableServicesPresenter,
  Product,
  ServicePresenter,
  ServicesTuenlacePresenter,
} from 'src/app/models/receipts-payments';
import { SendMessageRequestPresenter, Steps } from 'src/app/models/step';
import Swal from 'sweetalert2';

import { ManageClientComponent } from '../../../client/manage-client/manage-client.component';
import { ReceiptsPaymentsPresenter } from './presenter/receipts-payments.presenter';
import { ReceiptsPaymentsView } from './receipts-payments.view';

@Component({
  animations: [leftToLeft],
  selector: 'app-receipts-payments',
  templateUrl: './receipts-payments.component.html',
  styleUrls: ['./receipts-payments.component.scss'],
})
export class ReceiptsPaymentsComponent
  extends AbstractView
  implements OnInit, ReceiptsPaymentsView {
  categoriesSelectItem: SelectItem[] = [];
  categorySelected: CategoryPresenter;
  serviceSelectedItem: SelectItem[] = [];
  serviceSelected: ServicePresenter;
  lstProductsSelectItem: SelectItem[] = [];
  productSelected: Product;
  productSelectedItem: any;
  products: Product[] = [];
  filteredValues: any[] = [];
  valueProductSelected: any = [];

  isLinear = false;
  steps: ProductForm[] = [];
  formProduct: ProductForm;
  clients: ClientModel[];
  selectedClient: ClientModel;
  showSelectedClient = false;
  showSelectedService = false;
  page = 0;
  size = 6;
  totalElements: number;
  queryProduct = '';

  queryClient = '';
  transactionId = '';
  itemSelected: ItemsPresenter = {};

  respuesta: PaymentCollectionsAvailableServicesPresenter;

  public ref;
  currentStepper: MatStepper;
  message213: SendMessageRequestPresenter;
  disableCounterpart = false;
  disableAmount = false;
  disableTotalAmount = false;
  isEditable = false;
  dni: string;
  payValue = 0;
  totalAmount = 0;
  minimumAmount = 0;
  amount: number;
  commissionAmount: number;
  @ViewChild('lboxProduct', { static: false }) listboxProduct: Listbox;
  @ViewChild('lboxService', { static: false }) listboxService: Listbox;
  @ViewChild('lboxCategory', { static: false }) listboxCategory: Listbox;
  lengthCategory = 0;
  startTime;
  totalProductsSelectItem: SelectItem[] = [];
  isAllProduct = false;
  isTwoSteps = true;
  rcValue = '';
  showConfirmationRecargas = false;
  constructor(
    public router: Router,
    protected renderer: Renderer2,
    private formBuilder: FormBuilder,
    public dialogService: DialogService,
    private messageService: MessageService,
    public receiptsPaymentsPresenter: ReceiptsPaymentsPresenter
  ) {
    super(messageService, router);
    receiptsPaymentsPresenter.view = this;
  }
  ngOnInit() {
    this.receiptsPaymentsPresenter.login();
    this.cleanLocalStorage();
  }
  loadTotalProductsSelectItem() {
    this.respuesta.result.services.forEach((element: any) => {
      const si: SelectItem = {
        label: element.product.name,
        value: element,
      };
      this.totalProductsSelectItem.push(si);
    });

    this.sortTotalProductsSI();

    // seteamos inicialmente todos los productos
    this.isAllProduct = true;
    this.lstProductsSelectItem = this.totalProductsSelectItem;
  }
  sortTotalProductsSI() {
    this.totalProductsSelectItem.sort((a, b) => {
      const nameA = a.label;
      const nameB = b.label;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  cleanLocalStorage() {
    localStorage.removeItem('codePayValueForm1');
    localStorage.removeItem('codeCounterpartForm1');
    localStorage.removeItem('codePayValueForm2');
    localStorage.removeItem('codeCounterpartForm2');

    /*     if (localStorage.getItem('numList')) {
            for (let i = 1 ; i <= +localStorage.getItem('numList'); i++) {
                localStorage.removeItem('codeListForm' + i);
                localStorage.removeItem('listValueForm' + i);
             }
        } */
    localStorage.removeItem('numList');
  }
  searchProductsSelectItem(event) {
    this.queryProduct = event.query.toLowerCase();
    this.searchProductSelectItem();
  }
  searchProductSelectItem() {
    if (!this.respuesta) {
      this.showWarn('Advertencia', 'No existen registros para buscar');
      return;
    }
    this.filteredValues = this.respuesta.result.services
      .filter(
        (service) =>
          service.product.keywords.includes(this.queryProduct) ||
          service.product.name.toLowerCase().includes(this.queryProduct)
      )
      .slice(0, 20);
  }
  searchServices(event) {
    this.queryProduct = event.query.toLowerCase();
    this.searchProduct();
  }
  searchProduct() {
    if (!this.respuesta) {
      this.showWarn('Advertencia', 'No existen registros para buscar');
      return;
    }
    this.filteredValues = this.respuesta.result.services
      .filter(
        (service) =>
          service.product.keywords.includes(this.queryProduct) ||
          service.product.name.toLowerCase().includes(this.queryProduct)
      )
      .slice(0, 20);
  }
  addClient() {
    if (this.selectedClient && this.selectedClient.clientId) {
      this.showSelectedClient = true;
      this.dni = this.selectedClient.dni;
    }
  }
  onSearchClient(event) {
    this.queryClient = event.query;
    this.page = 0;
    this.receiptsPaymentsPresenter.onSearchClient();
  }
  clearClient() {
    if (this.selectedClient && this.selectedClient.clientId) {
      this.showSelectedClient = false;
      this.selectedClient = null;
    }
  }
  clearProduct() {
    this.cleanProductSelected();
    this.clearMessage123();
    this.cleanFilters();
    this.cleanLocalStorage();
    if (!this.isAllProduct) {
      this.setFirstCategory();
    }
  }
  cleanProductSelected() {
    if (typeof this.valueProductSelected !== 'undefined') {
      this.showSelectedService = false;
      this.valueProductSelected = null;
      this.disableAmount = false;
      this.disableTotalAmount = false;
      this.disableCounterpart = false;
    }
  }
  clearProductFromAllProductsWhenIsFalse() {
    this.cleanProductSelected();
    this.clearMessage123();
    this.cleanFilters();
    this.cleanLocalStorage();
    this.setFirstCategory();
  }
  clearProductFromAllProductsWhenIsTrue() {
    this.cleanProductSelected();
    this.clearMessage123();
    this.cleanFilters();
    this.cleanLocalStorage();
  }
  clearMessage123() {
    this.message213 = null;
    this.transactionId = null;
    this.isTwoSteps = true;
  }
  showNewClientModal() {
    console.log(this.selectedClient, this.showSelectedClient);

    this.ref = this.dialogService.open(ManageClientComponent, {
      showHeader: true,
      header: this.showSelectedClient ? 'Actualizar cliente' : 'Nuevo cliente',
      height: '400px',
      width: '70%',
      data: {
        isClient: this.showSelectedClient,
        client: this.showSelectedClient
          ? this.selectedClient
          : this.queryClient,
      },
    });

    this.ref.onClose.subscribe((newClient: ClientModel) => {
      if (newClient) {
        this.receiptsPaymentsPresenter.view.selectedClient = newClient;
        const cli = this.setName(
          this.receiptsPaymentsPresenter.view.selectedClient
        );
        const action = this.showSelectedClient
          ? ' actualizado con éxito! '
          : ' ingresado con éxito! ';
        this.showSuccess('', cli.toUpperCase() + action);
        this.addClient();
      }
      this.clearClient();
    });
  }
  setProduct(event: ServicesTuenlacePresenter) {
    this.showSelectedService = false;
    this.rcValue = '';
    this.valueProductSelected = this.respuesta.result.services.find(
      (service) =>
        service.category.code === event.category.code &&
        service.service.code === event.service.code &&
        service.product.code === event.product.code
    );

    this.addProduct();
  }

  addProduct() {
    if (
      this.valueProductSelected &&
      typeof this.valueProductSelected !== 'string'
    ) {
      this.disableAmount = false;
      this.disableTotalAmount = false;
      this.valueProductSelected.service.code === '000005' ? this.showConfirmationRecargas = true : this.showConfirmationRecargas = false;
      this.createForms();
    }
  }
  createForms() {
    this.sortSteps();

    const productSelected: Steps[] = this.valueProductSelected.steps;
    const lengthProductSelected: number = productSelected.length;
    this.isTwoSteps = lengthProductSelected === 1 ? false : true;
    let form = this.formBuilder.group({});
    this.steps = [];

    for (let i = 0; i < lengthProductSelected; i++) {
      form = this.createForm(productSelected[i]);
      const productForm: ProductForm = {
        form: form,
        title:
          'Paso ' + productSelected[i].order + ' de ' + lengthProductSelected,
        order: productSelected[i].order,
        inputs: productSelected[i].input,
        action: productSelected[i].action,
        output: productSelected[i].output,
        parameters: this.valueProductSelected.parameters,
      };
      this.steps.push(productForm);
    }
    setTimeout(() => {
      this.showSelectedService = true;
    }, 200);
    this.setCodesToLocalStorage();
  }
  sortSteps() {
    this.valueProductSelected.steps.sort(function (a, b) {
      const nameA = a.order;
      const nameB = b.order;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    const lengthSteps = this.valueProductSelected.steps.length;

    for (let i = 0; i < lengthSteps; i++) {
      this.valueProductSelected.steps[i].input.sort((a, b) => {
        const idA = a.id;
        const idB = b.id;
        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
        return 0;
      });
    }
  }
  setCodesToLocalStorage() {
    let numList = 0;
    for (let i = 0; i < this.steps[0].inputs.length; i++) {
      // this.steps[0].inputs.forEach((input) => {
      if (this.steps[0].inputs[i].name === 'PayValue') {
        localStorage.setItem('codePayValueForm1', this.steps[0].inputs[i].code);
      }
      if (this.steps[0].inputs[i].name === 'Counterpart') {
        localStorage.setItem(
          'codeCounterpartForm1',
          this.steps[0].inputs[i].code
        );
      }
      if (this.steps[0].inputs[i].type === 'list') {
        localStorage.setItem('codeListForm' + i, this.steps[0].inputs[i].code);
        numList++;
        localStorage.setItem('numList', String(numList));
      }
      // });
    }
    if (typeof this.steps[1] !== 'undefined') {
      this.steps[1].inputs.forEach((input) => {
        if (input.name === 'PayValue') {
          localStorage.setItem('codePayValueForm2', input.code);
        }
        if (input.name === 'Counterpart') {
          localStorage.setItem('codeCounterpartForm2', input.code);
        }
      });
    }
  }
  createForm(element: Steps): FormGroup {
    const group = {};
    element.input.forEach((input) => {
      const validators = [];
      input.validators.forEach((validator) => {
        if (validator.type.code === '001') {
          validators.push(Validators.minLength(Number(validator.value)));
        }
        if (validator.type.code === '002') {
          validators.push(Validators.maxLength(Number(validator.value)));
        }
      });
      validators.push(Validators.required);
      group[input.code] = ['', validators];
    });
    const form: FormGroup = this.formBuilder.group(group);
    this.disableCounterpart = false;
    return form;
  }
  submit(stepper: MatStepper, productForm: ProductForm) {
    if (localStorage.getItem('codePayValueForm1') !== null) {
      productForm.form.controls[
        localStorage.getItem('codePayValueForm1')
      ].setValue(
        this.rcValue.length > 0
          ? this.rcValue
          : productForm.form.value[localStorage.getItem('codePayValueForm1')]
      );
      productForm.form.value[localStorage.getItem('codePayValueForm1')] =
        this.rcValue.length > 0
          ? this.rcValue
          : productForm.form.value[localStorage.getItem('codePayValueForm1')];
    }
    this.formProduct = productForm;
    if (this.formProduct.form.invalid) {
      this.showWarn('Advertencia', 'Completar información necesaria');
      return;
    }
    this.sortInputs();

    this.currentStepper = stepper;
    console.log(this.steps);
    this.showConfirmationRecargas && this.formProduct.action.code === '002' ? this.showConfirmationDialog() :
      this.receiptsPaymentsPresenter.sendMessage(this.formProduct, true);
  }

  showConfirmationDialog(): void {
    const messageCounterpart = `Confirma realizar la recarga para el número
    : <b>${this.formProduct.form.value[localStorage.getItem('codeCounterpartForm1')]}</b>,
    por el valor de: <b>$${this.formProduct.form.value[localStorage.getItem('codePayValueForm1')]}</b>`;
    const messageCounterpart2 = `Confirma realizar la recarga para el número
    : <b>${this.formProduct.form.value[localStorage.getItem('codeCounterpartForm2')]}</b>,
    por el valor de: <b>$${this.formProduct.form.value[localStorage.getItem('codePayValueForm2')]}</b>`;
    Swal.fire({
      title: 'Atención ',
      html: this.steps.length > 1 ? messageCounterpart2
        : messageCounterpart,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.value) {
        this.receiptsPaymentsPresenter.sendMessage(this.formProduct, true);
      }
    });
  }

  sortInputs() {
    const lengthInputs = this.formProduct.inputs.length;

    for (let i = 0; i < lengthInputs; i++) {
      this.formProduct.inputs[i].options.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      this.formProduct.inputs.sort((a, b) => {
        const idA = a.id;
        const idB = b.id;
        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
        return 0;
      });
    }
  }
  goBack(stepper: MatStepper, productForm: ProductForm) {
    if (productForm.order === 2) {
      this.currentStepper = stepper;
      this.currentStepper.previous();

      this.enableAmountCounterpart();

      this.clearMessage123();

      // al regresar, borramos todos los inputs
      this.steps[0].inputs.forEach((input) => {
        this.formProduct.form.controls[input.code].setValue('');
      });
      this.clearAll();
    }
  }
  enableAmountCounterpart() {
    this.disableCounterpart = false;
    this.disableTotalAmount = false;
  }
  goNext() {
    this.currentStepper.next();
  }
  showExeption(res: PaymentCollectionsAvailableServicesPresenter) {
    Swal.fire({
      title: 'Atención: ' + res.message.code,
      text: res.message.description,
      showCancelButton: false,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.value) {
        this.clearAll();
      }
    });
  }
  confirmTransaction() {

    this.receiptsPaymentsPresenter.confirmTransaction();

    // clean filter of Products
    this.listboxProduct._filterValue = '';

  }
  redirectToPos() {
    this.router.navigate(['/panel/pos']);
  }
  getFinalCostumer() {
    this.receiptsPaymentsPresenter.getFinalConsumer();
  }
  listCategoriesSelectItem() {
    this.respuesta.result.services.forEach((element) => {
      if (
        !this.categoriesSelectItem.find(
          (cat) => element.category.name === cat.label
        )
      ) {
        this.categoriesSelectItem.push({
          label: element.category.name,
          value: element.category,
        });
      }
    });

    this.lengthCategory = this.categoriesSelectItem.length;
    this.sortCategoriesSelectedItem();
    this.setFirstCategory();
  }
  setFirstCategory() {
    this.categorySelected = this.categoriesSelectItem[0].value;
    this.getServices(this.categorySelected);
    this.serviceSelected = null;
  }
  sortCategoriesSelectedItem() {
    this.categoriesSelectItem.sort((a, b) => {
      const nameA = a.label;
      const nameB = b.label;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  getServices(categorySelected: CategoryPresenter) {
    this.products = [];
    this.lstProductsSelectItem = [];
    this.serviceSelectedItem = [];

    this.respuesta.result.services
      .filter((cat) => cat.category.code === categorySelected.code)
      .map((cat1) => cat1.service)
      .forEach((service) => {
        if (
          !this.serviceSelectedItem.find(
            (serv) => service.code === serv.value.code
          )
        ) {
          this.serviceSelectedItem.push({
            label: service.name,
            value: service,
          });
        }
      });

    this.sortServicesSI();
    if (!this.isAllProduct) {
      // clean filter of services
      this.listboxService._filterValue = '';
    }
    this.isAllProduct = false;
  }
  sortServicesSI() {
    this.serviceSelectedItem.sort((a, b) => {
      const nameA = a.label;
      const nameB = b.label;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  getProducts(serviceSelected: ServicePresenter) {
    this.products = [];
    this.lstProductsSelectItem = [];

    this.respuesta.result.services
      .filter((ser) => ser.service.code === serviceSelected.code)
      // .map((ser1) => ser1.product)
      .forEach((service) => {
        this.lstProductsSelectItem.push({
          label: service.product.name,
          value: service,
        });
      });

    this.sortProductsSI();
    // clean filter of Products
    this.listboxProduct._filterValue = '';
    this.productSelected = null;
    this.isAllProduct = false;
  }
  sortProductsSI() {
    this.lstProductsSelectItem.sort((a, b) => {
      const nameA = a.label;
      const nameB = b.label;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  enableAmount() {
    this.disableAmount = this.disableAmount ? false : true;
    if (!this.disableAmount) {
      const event = {
        target: {
          value: 0,
        },
      };
      setTimeout(() => {
        this.itemSelected.amount = 0;
        this.changeTotalAmount(event);
        this.renderer.selectRootElement('#amount').focus();
      });
    } else {
      setTimeout(() => {
        this.itemSelected.amount = this.amount;
        this.steps[1].form.controls[
          localStorage.getItem('codePayValueForm2')
        ].setValue(this.totalAmount);
      });
    }
  }
  changeTotalAmount(event) {
    const totalAmount = +event.target.value + this.commissionAmount;
    this.steps[1].form.controls[
      localStorage.getItem('codePayValueForm2')
    ].setValue(totalAmount.toFixed(2));
  }
  goNextStepper(stepper: MatStepper, productForm: ProductForm) {
    if (!this.receiptsPaymentsPresenter.validateClient()) {
      return;
    }
    if (productForm.order === 2) {
      if (this.receiptsPaymentsPresenter.validateClient()) {
        // vemos el formulario 1
        if (this.steps[0].form.valid) {
          this.isEditable = true;
          this.submit(stepper, this.steps[0]);
        } else {
          return;
        }
      } else {
        this.currentStepper = stepper;
        this.currentStepper.previous();
      }
    } else if (productForm.order === 1) {
      this.enableAmountCounterpart();
      this.isEditable = true;
    }
  }

  cleanFilters() {
    // trigger me somewhere in your template
    this.listboxProduct._filterValue = '';
    this.listboxService._filterValue = '';
    if (this.lengthCategory > 1) {
      this.listboxCategory._filterValue = '';
    }
    this.serviceSelected = null;
    this.categorySelected = null;
  }
  clearAll() {
    this.clearClient();
    this.clearProduct();
  }
  showMinimunAmount(): void {
    Swal.fire({
      title: 'Atención ',
      text: 'El valor ingresado es menor al mínimo permitido',
      showCancelButton: false,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.value) {
        this.renderer.selectRootElement('#amount').focus();
      }
    });
  }
  setValueOfList(event, field) {
    this.rcValue = '';
    this.rcValue = event.value.name.substring(
      1,
      event.value.name.indexOf('.') + 3
    );
    // event.value.code.substring(0, 2) === 'RC' ? field.code = this.rcValue : this.rcValue = '';
    // this.rcValue.length > 0 ? this.disableTotalAmount = true : this.disableTotalAmount = false;
    for (let i = 0; i < this.steps[0].inputs.length; i++) {
      if (field.code === localStorage.getItem('codeListForm' + i)) {
        localStorage.setItem('listValueForm' + i, event.value.code);
      }
    }
  }
  allProducts() {
    if (this.isAllProduct) {
      this.lstProductsSelectItem = this.totalProductsSelectItem;
      this.clearProductFromAllProductsWhenIsTrue();
    } else {
      this.lstProductsSelectItem = [];
      this.clearProductFromAllProductsWhenIsFalse();
    }
  }
  changePriceItemSelected(itemSelected: any) {
    this.itemSelected = itemSelected;
    const totalAmount = this.itemSelected.amount + this.commissionAmount;
    this.steps[1].form.controls[
      localStorage.getItem('codePayValueForm2')
    ].setValue(totalAmount.toFixed(2));
    this.totalAmount = totalAmount;
    this.steps[1].form.controls[
      localStorage.getItem('codeCounterpartForm2')
    ].setValue(itemSelected.code);
  }
}
