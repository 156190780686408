import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';
import { Presenter } from 'src/app/core/presenter';
import { View } from 'src/app/core/view';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { InvoiceSalesModel, SalesCategoryModel } from 'src/app/models/product.model';
import { ListProductHistory } from 'src/app/models/reportProductHistory.model';
import { ReportSalesPriceModel } from 'src/app/models/reportsalesprice.modal';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';
import { ProductHistoryView } from '../product-history.view';

@Injectable({
    providedIn: 'root',
})
export class ProductHistoryPresenter implements Presenter {
    view: ProductHistoryView;

    constructor(
        private reportService: ReportService,
        private productService: ProductService,
        private presenterSetting: SettingPresenter) { }

    getReportProductHistory() {
        let initDate = '';
        let endDate = '';
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        const numbreLines = 0;
        const product = 'null';
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        this.view.blockUi();
        this.reportService.getInvoiceProduct(initDate, endDate, this.view.productId).subscribe((data: ListProductHistory[]) => {
            this.view.listProductHistory = data;
            this.view.reportsCSV = this.view.listProductHistory;
        }, (error: HttpErrorResponse) => {
            // this.view.productCategories = [];
            this.view.showError('Atención', error.error.message);
        });

        this.getproductHistory();
    }

    dowloadReprot() {
        let initDate = '';
        let endDate = '';
        let date = '';
        const format = 'dd-MM-yyyy';
        const locale = 'en-US';
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        date = initDate + ' / ' + endDate;
        const reports = this.view.reportsCSV.map((line) => {
            return {
                PDV: line.pdv,
                FechaInicial: initDate,
                FechaFinal: endDate,
                Codigo: line.codeProduct,
                Detalle: line.descProduct,
                CantidadVendida: line.salesCant,
                Unidad: line.salesUnit,
            };
        });
        const jsonHead = JSON.parse(JSON.stringify(['Fecha dia', 'Fecha hora']));
        const reportsToCSV = JSON.parse(
            JSON.stringify(
                reports,
                [
                    'PDV',
                    'FechaInicial',
                    'FechaFinal',
                    'Codigo',
                    'Detalle',
                    'CantidadVendida',
                    'Unidad',
                ],
                2
            )
        );
        this.view.csvDownload(
            'Reporte productos ' + ' ' + date + '.csv',
            reportsToCSV
        );
    }

    getproductHistory() {
        let initDate = '';
        let endDate = '';
        const pageInvoice = this.view.page;
        const sizeInvoice = this.view.size;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        this.reportService.getPaginateProductHistory(initDate, endDate, this.view.productId,
            pageInvoice, sizeInvoice).subscribe((data: ListProductHistory[]) => {
                this.view.totalElements = data['totalElements'];
                this.view.historyProducts = data['data'];
            }, (error: HttpErrorResponse) => {
                // this.view.productCategories = [];
                this.view.showError('Atención', error.error.message);
            });
    }

    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.view.productId = '';
        this.getReportProductHistory();
    }

    onSearchProduct() {
        const searchValue = this.view.searchProduct;
        const page = 0;
        const size = 20;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        this.productService
            .searchProduct(searchValue, page, size, prceListId, null)
            .then((data) => {
                this.view.products = data['data'];

                if (this.view.products.length <= 0) {
                    this.view.showError('', 'Búsqueda sin registros');
                    this.view.products = [];
                }
            })
            .catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salio mal');
                }
            });
    }

    findReportsByProduct() {
        if (
            this.view.selectedProduct &&
            typeof this.view.selectedProduct !== 'string'
        ) {
            this.view.showSelectedProduct = true;
            this.view.productId = this.view.selectedProduct.productId;
            this.getReportProductHistory();
        }
    }

}
