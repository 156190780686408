import { Injectable } from '@angular/core';
import { CurrentPriceService } from 'src/app/services/current-price.service';
import { MenuView } from '../menu.view';
import { MenuItemModel } from 'src/app/models/menu-item.model';
import { DataProvider } from 'src/app/services/data.provider';
import { timeout } from 'rxjs/operators';
import { TransferService } from '../../../pages/dashboard/tranfers/services/transfer.service';
import { WareHouse } from 'src/app/models/product.model';
import { TransfersModel, TransferStatus } from 'src/app/models/transfer.mode';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DefaultConfigurationPresenter, DefaultConfigurationType } from 'src/app/models/defaultconfigurations.model';
import { CashRegisterService } from 'src/app/services/cash-register.service';

@Injectable({
  providedIn: 'root'
})
export class MenuPresenter {
  view: MenuView;
  transferStatusSelected: TransferStatus.SENT;
  searchSupplying: TransfersModel[] = [];
  constructor(
    public currentPriceService: CurrentPriceService,
    private transferService?: TransferService,
    public cashRegisterService?: CashRegisterService,
    public dataProvider?: DataProvider,
    public warehouse?: WarehouseService,
    private http?: HttpClient,
  ) { }

  getNumberOfLabels() {
    this.currentPriceService.getNumberOfLabels().subscribe((res: { pendingLabels: number, printingLabels: number }) => {
      this.currentPriceService.size = res.printingLabels;
      if (res.pendingLabels > 0) {
        this.view.showConfirmation();
        this.currentPriceService.available = res.pendingLabels;
      }
    });
  }

  getTimeTransfer() {
    this.http.get(environment.apiUrl + '/getGeneralConfigurations')
      .subscribe((def: DefaultConfigurationPresenter[]) => {
        def.forEach(defConf => {
          switch (defConf.generalConfigurationType) {
            case DefaultConfigurationType.DEFAULT_TIME_TRANSFER_AUTOMATIC:
              this.getMovementSupplyingPending(Number(defConf.value));
              break;
          }
        });
      });
    }

    getMovementSupplyingPending(days) {
      this.http.get(environment.apiUrl + '/warehouse/local ').subscribe((defWare: WareHouse) => {
        this.dataProvider.defaultWarehouse = defWare;
        this.transferService.getTransfers(null, '', '', '', 0, 20, ['SUPPLYING'],
        TransferStatus.SENT, null, this.dataProvider.defaultWarehouse.warehouseId
        ).subscribe((data: any) => {
          let ifexists = false;
          const uidTransfer = [];
          data['data'].forEach(element => {
            const auxdate = new Date(element['createdDate']);
            auxdate.setDate(auxdate.getDate() + days);
            const auxdateNow = new Date();
            auxdateNow.setHours(23); auxdateNow.setMinutes(59); auxdateNow.setSeconds(59);
            if (auxdate <= auxdateNow) {
              ifexists = true;
              uidTransfer.push(element.transferId);
            }
          });
          if (ifexists) {
            this.getProductWithoutPriceList(uidTransfer);
            setTimeout(() => {
              this.view.showConfirmationPendingMovements(this.view.mensajeCodes);
            }, 300);
          }
        });
      });
  }

  getProductWithoutPriceList(data) {
    this.transferService.getProductWithoutList(data).subscribe((dataTransfer) => {
      this.view.codesProducts = dataTransfer;
      let mensaje = 'Las transferencias tienen productos sin lista de precios: ';
      this.view.codesProducts.forEach(element => {
        mensaje = mensaje + element + '; ';
      });
      this.view.mensajeCodes = mensaje;
    });
  }


}
