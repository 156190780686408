import {Injectable} from '@angular/core';
import {EmissionPointPresenter} from '../models/pointemission.model';
import {PriceList} from '../models/pricelist.model';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable( {
    providedIn : 'root'
} )
export class PointEmissionService {

    constructor( private http: HttpClient ) {
    }

    public getPointEmissionList(): Promise<EmissionPointPresenter[]> {
        return new Promise( ( resolve , reject ) => {
            this.http.get<EmissionPointPresenter[]>( environment.apiUrl + '/emissionPoints' )
                .subscribe( response => {
                    resolve( response );
                } , error => {
                    reject( error );
                } );
        } );
    }


}
