import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.scss']
})
export class ClientMenuComponent implements OnInit {

  navLinks = [];
  constructor() { }
  ngOnInit() {
    this.navLinks = [{
      label: 'Búsqueda de clientes',
      link: './search-clients',
      index: 0,
      visible: true
    }, {
      label: 'Reporte de productos por cliente',
      link: './report-clients',
      index: 1,
      visible: true
    }];
  }

}
