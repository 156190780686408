import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { SearchSalesDepositView } from './search-sales-deposit.view';
import { SearchSalesDepositPresenter } from './presenter/search-sales-deposit.presenter';
import { GenerateCashRegisterComponent } from '../../../cash-register/generate-cash-register/generate-cash-register.component';
import { DayCloseCash } from 'src/app/models/deposit-close-cash.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-search-sales-deposit',
  templateUrl: './search-sales-deposit.component.html',
  styleUrls: ['./search-sales-deposit.component.scss']
})
export class SearchSalesDepositComponent extends AbstractView implements OnInit, SearchSalesDepositView {

  value = '';
  display = false;
  displayReport = false;
  rowDepositCloseID = '';
  reportCloseDays: DayCloseCash[];
  searchValue: '';
  initDate = null;
  endDate = null;
  page = 0;
  size = 20;
  totalElements: number;

  SearchSalesDepositPresenter: any;
  public ref;
  GenerateCashRegisterComponent: any;
  constructor(
    public service: MessageService,
    public searchSalesDepositPresenter: SearchSalesDepositPresenter,
    public router: Router,
    public dialogService: DialogService,
  ) {
    super(service, router);
    searchSalesDepositPresenter.view = this;
    searchSalesDepositPresenter.onSearchCloseDayReport();
  }

  allDepositCloseCash: DayCloseCash[];
  depositDaydetailId: DayCloseCash[];
  justifiedDayCloseCash: DayCloseCash = {};
  lockedCloseCash: [];
  observation = '';

  ngOnInit() {
    this.searchSalesDepositPresenter.loadValueLockedbox();
  }

  paginate($event) {
    this.page = $event['page'];
    this.searchSalesDepositPresenter.onSearchCloseDayReport();
  }

  searchClosedayReports() {
    this.page = 0;
    this.searchSalesDepositPresenter.onSearchCloseDayReport();
  }

  showGenerateCashRegisterComponent(rowData) {
    this.ref = this.dialogService.open(GenerateCashRegisterComponent, {
      showHeader: true,
      closeOnEscape: true,
      data: {
        dataKey: true,
        date: rowData.dateCloseCash
      }
    });

    this.ref.onClose.subscribe((res) => {
      this.searchSalesDepositPresenter.onSearchCloseDayReport();
    });

  }

  showDialogJustified(rowData) {
    if (rowData.statusJustified === 'TO_BE_APPROVED') {

      Swal.fire({
        title: 'Procesando Justificación',
        html: rowData.mpJustified,
        confirmButtonColor: '#00687d',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {
        }
      });
    } else {
      if (rowData.statusJustified === 'INIT' || rowData.statusJustified === 'REJECTED'
        && rowData.dayAuthorization < 2) {
        this.observation = null;
        this.display = true;
        this.justifiedDayCloseCash = rowData;
      } else {
        Swal.fire({
          title: 'Intentos agotados',
          text: 'Su justificación no ha sido procesada, el valor debe ser depositado: $' + rowData.pendingAmount,
          confirmButtonColor: '#00687d',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.value) {
          }
        });
      }
    }
  }

  searchDepositDaydetailId(rowID) {
    this.displayReport = true;
    this.rowDepositCloseID = rowID;
    this.searchSalesDepositPresenter.loadDepositDayDetailById(this.rowDepositCloseID);
  }

  sendJustification() {
    this.display = false;
    this.justifiedDayCloseCash.mpJustified = this.observation;
    this.searchSalesDepositPresenter.sendJustification(this.justifiedDayCloseCash);
  }

}
