import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Vault } from '../models/vault.model';
import { MovementVault } from '../models/movement-vault.model';
import { MovementVaultType } from '../models/movement-vault-type.model';

@Injectable( {
    providedIn : 'root'
} )
export class VaultManagmentService {

    constructor(private http: HttpClient) { }

    public openVault() {
        return new Promise((resolve, reject) => {
            this.http.put(environment.apiUrl + '/vault/open', {})
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    public getVault(): Promise<Vault> {
        return new Promise((resolve, reject) => {
            this.http.get<Vault>(environment.apiUrl + '/vault', {})
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    public getMovementsVault(): Promise<MovementVault[]> {
        return new Promise((resolve, reject) => {
            this.http.get<MovementVault[]>(environment.apiUrl + '/vault/movements')
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    public getMovementVaultTypes(): Promise<MovementVaultType[]> {
        return new Promise((resolve, reject) => {
            this.http.get<MovementVaultType[]>(environment.apiUrl + '/vault/movements/types')
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    public saveMovementVault(movementVault: MovementVault) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + '/vault/movements', movementVault)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

}
