import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import Swal from 'sweetalert2';
import { AdvancesDetailView } from './advances-detail.view';
import { DialogService, DynamicDialogRef, MessageService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { AdvancesDetailPresenter } from './presenter/advances-detail.presenter';
import { DataProvider } from 'src/app/services/data.provider';
import { Payment } from 'src/app/models/payment.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { ClientModel } from 'src/app/models/client.model';
import { Bank, Card, GraceMonthsBank } from 'src/app/models/bank.model';
import { CreditCardType, CreditDeferredType } from 'src/app/models/creditcardtype.model';
import { BankAccount } from 'src/app/models/bankaccount.model';
import { CreditCardEntity } from 'src/app/models/bankentity';
import Decimal from 'decimal.js';
import { ClientAdvanceModel } from 'src/app/models/client-advance.model';

@Component({
  selector: 'app-advances-detail',
  templateUrl: './advances-detail.component.html',
  styleUrls: ['./advances-detail.component.scss']
})
export class AdvancesDetailComponent extends AbstractView implements OnInit, AdvancesDetailView, AfterViewInit {
  value = '';

  paymentsAdvance: Payment[];
  paymentsMethodsAdvances: { label: string, value: PaymentMethod }[] = [
    { label: 'Efectivo', value: PaymentMethod.CASH },
    { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER },
    { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD },
    { label: 'Tarjeta de Débito', value: PaymentMethod.DEBIT }
  ];
  paymentMethodSelectedAdvances: { label: string, value: PaymentMethod } = this.paymentsMethodsAdvances[0];
  fee: number;
  amountFee: number;

  banksEntities: Card[] = [];
  creditCardSelected: Card;
  debitCardSelected: Card;
  creditEntitySelected: CreditCardEntity;
  creditBankSelected: Bank;
  debitBankSelected: Bank;
  debitEntitySelected: CreditCardEntity;


  creditCardTypes: { label: string, value: CreditCardType }[] = [];
  creditCardTypeSelected: CreditCardType;
  creditDeferredTypes: { label: string, value: CreditDeferredType }[] = [];
  creditDeferredTypeSelected: CreditDeferredType;

  paymentFees: { label: string, value: number }[];
  paymentFeeSelected: any;

  butDisabled: false;
  requestOverdraft: false;

  clientBankAccounts: { label: string, value: BankAccount }[];
  subTotal12: number;
  defaultTax: any;
  subTotal0: number;
  subTotal: number;
  total: number;
  tax: number;
  checkNumber: string;
  checkAmount: number;
  effectiveDate = new Date();
  minDate = new Date();
  clientBankAccountSelected: { label: string, value: BankAccount };
  client: ClientModel;
  banks: { label: string, value: Bank }[];
  bankSelected: { label: string, value: Bank };
  accountNumber: string;
  checkConfirmationCode: string;
  totalPayments: number;
  totalAdvance: number;
  cashAmount: number;
  subventionAmount: number;
  voucherNumber: string;
  creditCardAmount: number;
  transferAmount: number;
  transferNumber: string;
  withholdAmount: number;
  withholdNumber: string;
  creditMemoAmount: number;
  creditMemoNumber: string;
  debitCardAmount: number;
  voucherNumberDebit: string;
  isChange = 'Cambio';
  selectedRowIndex = -1;
  isInvoiceProcess = false;
  credit = false;
  blockDetails = false;
  blockCard = false;
  creditLote: string;
  creditReference: string;
  creditAuth: string;
  pending = 0;

  debitLote: string;
  debitReference: string;
  debitAuth: string;

  cardBanks: Bank[] = [];
  selectedCreditCardBank: Bank;
  selectedDebitCardBank: Bank;
  selectedBank: Bank;
  searchBankValue: string;
  adviser: ClientModel;
  ownBanks: Bank[] = [];
  filteredBankAccounts: BankAccount[] = [];
  bankAccountSelected: BankAccount;

  paymentsDiferenOfCash = 0;
  paymentSubvention = 0;
  paymentCash = 0;

  isGrace = false;
  graces: { label: string, value: number }[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },

  ];

  feesCredired: { label: string, value: number }[] = [
    { label: '3', value: 3 },
    { label: '6', value: 6 },
    { label: '9', value: 9 },
    { label: '12', value: 12 },
    { label: '18', value: 18 },
    { label: '24', value: 24 },
    { label: '36', value: 36 },
  ];

  selectedGrace = null;
  isCPOS = false;
  debitCards: Card[] = [];
  creditCards: Card[] = [];
  public ref;
  isPromoDay = false;
  isisLowerPrice = false;
  isQuotation = false;
  isEntryByCredired = false;
  crediRedCode = '';
  selectedQuotesCredired = { label: '3', value: 3 };
  clientAdvance: ClientAdvanceModel = {};
  paymentsMethods: { label: string, value: PaymentMethod }[] = [
    { label: 'Efectivo', value: PaymentMethod.CASH },
    { label: 'Cheque', value: PaymentMethod.CHECK },
    { label: 'Nota de crédito', value: PaymentMethod.CREDIT_MEMO },
    { label: 'Retención', value: PaymentMethod.WITHHOLD },
    { label: 'Transferencia', value: PaymentMethod.BANK_TRANSFER },
    { label: 'Tarjeta de Crédito', value: PaymentMethod.CREDIT_CARD },
    { label: 'Tarjeta de Débito', value: PaymentMethod.DEBIT },
    { label: 'Total sin despacho', value: PaymentMethod.TOTAL_WITHOUT_DISPATCH },
    { label: 'Credired', value: PaymentMethod.CREDIRED}
];
  paymentMethodSelected: { label: string, value: PaymentMethod } = this.paymentsMethods[0];
  constructor(
    private messageService: MessageService,
    public router: Router,
    public dialogService: DialogService,
    public advancesDetailPresenter: AdvancesDetailPresenter,
    private dynamicDialogRef: DynamicDialogRef,
    public dataProvider: DataProvider
  ) {
    super(messageService, router);
    advancesDetailPresenter.view = this;

  }

  ngOnInit() {
    this.advancesDetailPresenter.init();
    this.advancesDetailPresenter.loadBanksFromAtix();
    this.advancesDetailPresenter.getBanksByCriteria();
  }
  ngAfterViewInit(): void {

  }

  evaluateCredit() {
    this.clearAllInputs();
  }

  clearAllInputs() {
    this.cashAmount = null;
    this.checkAmount = null;
    this.checkNumber = null;
    this.checkConfirmationCode = null;
    this.clientBankAccountSelected = null;
    this.selectedBank = null;
    this.accountNumber = null;
    this.transferAmount = null;
    this.transferNumber = null;
    this.selectedBank = null;
    this.bankAccountSelected = null;
    this.creditMemoAmount = null;
    this.creditMemoNumber = null;
    this.withholdAmount = null;
    this.withholdNumber = null;
    this.creditCardAmount = null;
    this.creditBankSelected = null;
    this.creditCardSelected = null;
    this.creditCardTypeSelected = null;
    this.paymentFeeSelected = null;
    this.selectedGrace = null;
    this.creditLote = null;
    this.creditReference = null;
    this.creditAuth = null;
    this.debitCardAmount = null;
    this.debitBankSelected = null;
    this.debitCardSelected = null;
    this.debitLote = null;
    this.debitReference = null;
    this.debitAuth = null;
    this.subventionAmount = null;
  }

  addPayment() {
    this.advancesDetailPresenter.addPaymentCpos();
  }

  showConfirmCreditCardPayment() {
    let creditCard = '';
    if (this.creditCardTypeSelected === CreditCardType.CREDIT_WITHOUT_INTEREST) {
      creditCard = 'Crédito sin intereses';
    } else if (this.creditCardTypeSelected === CreditCardType.CREDIT_WITH_INTEREST) {
      creditCard = 'Crédito con intereses';
    } else if (this.creditCardTypeSelected === CreditCardType.CURRENT_PAYMENT) {
      creditCard = 'Contado';
    }

    const message =
      '<p>Pago de: $' + this.creditCardAmount +
      '<p>Tarjeta: ' + this.creditCardSelected.name +
      '<p>Banco: ' + this.creditBankSelected.name +
      '<p>Tipo de crédito: ' + creditCard + ' Cuotas: ' + this.paymentFeeSelected.name;

    Swal.fire({
      title: 'Confirmar Pago',
      html: message,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'success',
    }).then((result) => {
      if (result.value) {
        this.advancesDetailPresenter.addCreditCardPayment();
      }
      if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  showConfirmDebitCardPayment() {
    const message =
      '<p>Pago de: $' + this.debitCardAmount +
      '<p>Tarjeta: ' + this.debitCardSelected.name +
      '<p>Banco: ' + this.debitBankSelected.name;

    Swal.fire({
      title: 'Confirmar Pago',
      html: message,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'success',
    }).then((result) => {
      if (result.value) {
        this.advancesDetailPresenter.addDebitCardPayment();
      }
      if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  validatecashAmount() {
    this.cashAmount = this.cashAmount ? new Decimal(this.cashAmount)
      .toDecimalPlaces(2).toNumber() : this.cashAmount;
  }
  validatecheckAmount() {
    this.checkAmount = this.checkAmount ? new Decimal(this.checkAmount)
      .toDecimalPlaces(2).toNumber() : this.checkAmount;
  }
  validatetransferAmount() {
    this.transferAmount = this.transferAmount ? new Decimal(this.transferAmount)
      .toDecimalPlaces(2).toNumber() : this.transferAmount;
  }

  validatecreditCardAmount() {
    this.creditCardAmount = this.creditCardAmount ? new Decimal(this.creditCardAmount)
      .toDecimalPlaces(2).toNumber() : this.creditCardAmount;
  }
  validatedebitCardAmount() {
    this.debitCardAmount = this.debitCardAmount ? new Decimal(this.debitCardAmount)
      .toDecimalPlaces(2).toNumber() : this.debitCardAmount;
  }

  filterAccounts() {
    this.advancesDetailPresenter.filterBankAccounts();
  }

  generateClientAdvance() {
    this.advancesDetailPresenter.validateCpos();
  }

  showConfirmation() {
    if (this.blockDetails) {
      return;
    }
    if (this.paymentsAdvance.length > 0) {
      Swal.fire({
        text: '¿Desea guardar el anticipo?',
        showCancelButton: true,
        cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#00687d',
        confirmButtonText: 'Guardar'
      }).then((result) => {
        if (result.value) {
          this.advancesDetailPresenter.generateClientAdvance();
        }
      });
    } else {
      this.showError('Atención', 'No existe formas de Anticipos agregadas.');
      return;
    }
  }

  dispose(viewMessage: boolean) {
    if (!viewMessage) {
      const paymentsCards = this.paymentsAdvance.filter(
        (payment) =>
          payment.paymentMethod === PaymentMethod.CREDIT_CARD ||
          payment.paymentMethod === PaymentMethod.DEBIT
      ).length;
      if (paymentsCards > 0) {
        Swal.fire({
          title: 'Alerta!',
          text: 'Tiene pagos con tarjetas, debe anularlos para salir',
        });
        return;
      }
    }
    this.dynamicDialogRef.close(true);
  }

  deleteRow(payment: Payment, evt) {
    this.selectedRowIndex = evt;
    if (payment.paymentMethod === PaymentMethod.CREDIT_CARD || payment.paymentMethod === PaymentMethod.DEBIT) {
        this.showConfirmationDelete(payment);
    } else {
        this.advancesDetailPresenter.deletePayment();
    }
  }

  showConfirmationDelete(payment: Payment) {
    const paymentName = payment.paymentMethod === PaymentMethod.CREDIT_CARD ? 'Crédito' : 'Débito';
    Swal.fire({
        text: '¿Eliminar el pago de Tarjeta de ' + paymentName + '?',
        showCancelButton: true,
        cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#00687d',
        confirmButtonText: 'Aceptar'
    }).then((result) => {
        if (result.value) {
            this.dataProvider.isConfigurationCpos
              ? this.advancesDetailPresenter.deleteCardPayment(payment)
              : this.advancesDetailPresenter.deletePayment();
        }
    });
}
onCardChange() {
  this.selectedGrace = null;
  this.paymentFeeSelected = null;
  this.advancesDetailPresenter.filterCards();
  this.advancesDetailPresenter.validatePromoBank();
}

onTypeCreditChange() {
  this.creditDeferredTypeSelected = null;
  this.paymentFeeSelected = null;
  if (this.creditCardTypeSelected.valueOf() !== CreditCardType.CREDIT_WITHOUT_INTEREST) {
      this.selectedGrace = null;
  }
  if (this.creditCardTypeSelected.valueOf() === CreditCardType.CURRENT_PAYMENT) {
      this.creditDeferredTypes = [
          { label: 'Normal o corriente', value: CreditDeferredType.NORMAL_OR_CURRENT },
      ];
      if (this.creditCardSelected) {
          this.creditCardSelected.paymentFeesBank = null;
          this.creditCardSelected.paymentFeesBank = [{ number: 1, name: '1' }];
          this.paymentFeeSelected = { number: 1, name: '1' };
          this.creditDeferredTypeSelected = CreditDeferredType.NORMAL_OR_CURRENT;
      }
  } else {
      this.advancesDetailPresenter.filterCards();
  }
  this.advancesDetailPresenter.onTypeCreditChange();
}

onBankChange() {
  this.creditCardSelected = null;
  this.debitCardSelected = null;
  this.paymentFeeSelected = null;
  this.creditCardTypeSelected = null;
  this.creditDeferredTypeSelected = null;
  this.advancesDetailPresenter.filterCardsPresenter();
}

}
