import { Component, OnInit } from '@angular/core';
import { SearchProductView } from './search-product..view';
import { AbstractView } from 'src/app/core/abstract_view';
import { MessageService } from 'primeng/api';
import { SearchProductPresenter } from './presenter/search-product.presenter';
import { Product } from 'src/app/models/product.model';
import { Router } from '@angular/router';
import { UiUtils } from 'src/app/core/ui-utils';
import { Client } from 'src/app/models/client.model';

@Component({
    selector: 'app-search-product',
    templateUrl: './search-product.component.html'
})
export class SearchProductComponent extends AbstractView implements OnInit, SearchProductView {

    search = '';
    page = 0;
    size = 20;
    products: Product[];
    totalElements: number;
    selectedClient: Client;
    constructor(
        private messageService: MessageService,
        public router: Router,
        private searchProductPresenter: SearchProductPresenter,
        public uiUtils: UiUtils) {
        super(messageService, router);
        searchProductPresenter.view = this;
    }

    ngOnInit() {
    }

    onKeyUp() {
        this.page = 0;
        this.searchProductPresenter.onSearchProduct();
    }

    paginate($event) {
        this.page = $event['page'];
        this.searchProductPresenter.onSearchProduct();
    }

    clearInput(nameModel) {
        nameModel.search = '';
    }


}
