import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransactionPresenter } from '../models/creditcardtype.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreditCardService {
  constructor(public http: HttpClient) { }

  addPayment(transactionPresenter: TransactionPresenter) {
    return this.http.post(environment.apiUrl + '/generateTransaction', transactionPresenter);
  }

  deletePayment(authorizationNumber: string) {
    return this.http.get(environment.apiUrl + '/cancelTransaction?authorizationNumber=' + authorizationNumber);
  }

  getConnectionCpos(userId: string) {
    return this.http.get(environment.apiUrl + '/getConnectionCpos?userId=' + userId);
  }

  getDefaultConfigurationCpos() {
    return this.http.get(environment.apiUrl + '/findDefaultConfigurationCpos');
  }

  saveConnectionCpos() {
    return this.http.get(environment.apiUrl + '/saveCposOnOpenedCashRegister');
  }

  getCommissionCard() {
    return this.http.get(environment.apiUrl + '/getCommissionCard');
  }
}
