import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuItem, MessageService, DialogService } from 'primeng/api';
import { AproveCashReturnPresenter } from './presenter/aprove-cash-return.presenter';
import { Router } from '@angular/router';
import { AproveCashReturnView } from './aprove-cash-return.view';
import Swal from 'sweetalert2';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';
import { MovementCashPresenter, StatusEnum } from 'src/app/models/movement-cash.model';

@Component({
  selector: 'app-aprove-cash-return',
  templateUrl: './aprove-cash-return.component.html',
  styleUrls: ['./aprove-cash-return.scss']
})
export class AproveCashReturnComponent extends AbstractView implements OnInit, AproveCashReturnView {

  @ViewChild('docNo', { read: false, static: false }) docNo: ElementRef;

  movements: MovementCashPresenter[] = [];
  selectedMovement: MovementCashPresenter;

  totalElements: number;

  constructor(
    private aproveCashReturnPresenter: AproveCashReturnPresenter,
    private messageService: MessageService,
    public router: Router,
    public dialogService: DialogService,

  ) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_APPROVE_MOVEMENT_CASH)) {
      this.redirectTo('/panel/pos');
    }
    aproveCashReturnPresenter.view = this;
    this.blockUi();
    this.aproveCashReturnPresenter.getPendingReturnCashMovements();
  }

  ngOnInit() {
  }

  showApproveMovement(movement: MovementCashPresenter) {
    this.selectedMovement = movement;
    this.selectedMovement.status = StatusEnum.APPROVED;
    Swal.fire({
      text: '¿Desea devolver el efectivo de esta nota de crédito?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Devolver efectivo'
    }).then((result) => {
      if (result.value) {
        this.aproveCashReturnPresenter.setCashMovementStatus();
      }
    });
  }

  showARejectMovement(movement: MovementCashPresenter) {
    this.selectedMovement = movement;
    this.selectedMovement.status = StatusEnum.CANCELED;
    Swal.fire({
      text: '¿Desea rechazar definitiva e irrevocablemente la devolución de efectivo de esta Nota de crédito?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Rechazar devolución de efectivo'
    }).then((result) => {
      if (result.value) {
        this.aproveCashReturnPresenter.setCashMovementStatus();
      }
    });
  }
}
