import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientModel } from 'src/app/models/client.model';
import { DispatchModel } from 'src/app/models/dispatch.model';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { InvoiceStatus } from 'src/app/models/invoice.status';
import { DomainAction } from 'src/app/models/rol.model';
import Swal from 'sweetalert2';
import { GenerateCreditNoteComponent } from '../../credit-memo/generate-credit-note/generate-credit-note.component';
import { GenerateDispatchComponent } from '../../dispatch/generate-dispatch/generate-dispatch.component';
import { InvoicesPendingView } from './invoices-pending.view';
import { InvoicesPendingPresenter } from './presenter/invoices-pending.presenter';

@Component({
  selector: 'app-invoices-pending',
  templateUrl: './invoices-pending.component.html',
  styleUrls: ['./invoices-pending.component.scss']
})
export class InvoicesPendingComponent extends AbstractView implements OnInit, InvoicesPendingView {

  @ViewChild('docNo', { read: false, static: false }) docNo: ElementRef;

  initDate = null;
  endDate = null;
  documentNo = null;

  invoices: InvoiceModel[];
  selectedInvoice: InvoiceModel;
  page = 0;
  size = 20;
  totalElements: number;
  invoiceStatusSelected: InvoiceStatus;

  public ref;
  invoiceStatus: { label: string, value: InvoiceStatus }[] = [
    { label: '', value: InvoiceStatus.EMPTY },
    { label: 'Sin despacho', value: InvoiceStatus.WITHOUT_DISPATCH },
    { label: 'Despacho Parcial', value: InvoiceStatus.PARTIALLY_DISPATCHED }
  ];

  visible = false;

  constructor(
    public invoicesPendingPresenter: InvoicesPendingPresenter,
    private messageService: MessageService,
    public router: Router,
    public dialogService: DialogService
  ) {
    super(messageService, router);
    invoicesPendingPresenter.view = this;
    if (!this.getAuth(DomainAction.ALLOW_POS_LIST_INVOICES)) {
      this.redirectTo('/panel/pos');
    }
  }
  ngOnInit() {
    this.getInvoices();
  }
  getInvoices() {
    this.invoicesPendingPresenter.findInvoices();
  }

  getPdf(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    this.invoicesPendingPresenter.getPdf();
  }
  getInvoiceAndDispatch(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    this.invoicesPendingPresenter.getInvoiceAndDispatch();
  }
  cancelInvoice(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    Swal.fire({
      text: '¿Desea anular esta factura ?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular factura'
    }).then((result) => {
      if (result.value) {
        this.invoicesPendingPresenter.cancelInvoice();
      }
    });
  }

  showCreditNoteComponent(invoice: InvoiceModel) {
    this.ref = this.dialogService.open(GenerateCreditNoteComponent, {
      header: 'NOTA DE CRÉDITO',
      closeOnEscape: true,
      width: '80%',
      height: '550px',
      data: invoice
    });
  }

  showDispatchComponent(dispatch: DispatchModel) {
    this.ref = this.dialogService.open(GenerateDispatchComponent, {
      header: 'DESPACHO',
      closeOnEscape: true,
      width: '60%',
      height: '460px',
      data: dispatch
    });
    this.ref.onClose.subscribe(() => {
      this.getInvoices();
    });
  }
  getInvoice(invoice: InvoiceModel) {
    this.selectedInvoice = invoice;
    this.invoicesPendingPresenter.getInvoiceAndGenerateCreditMemo();
  }
  paginate($event) {
    this.page = $event['page'];
    this.invoicesPendingPresenter.findInvoices();
  }
  translateInvoiceStatus(invoiceStatus: string) {
    switch (invoiceStatus) {
      case InvoiceStatus.WITHOUT_DISPATCH: return 'SIN DESPACHO';
      case InvoiceStatus.PARTIALLY_DISPATCHED: return 'PARCIALMENTE DESPACHADO';
      case InvoiceStatus.DISPATCHED: return 'DESPACHADO';
      default: return invoiceStatus;
    }
  }
  print() {
    this.invoicesPendingPresenter.printInvoiceReport();
  }
}
