import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent  extends AbstractView implements OnInit {

  navLinks = [];
  constructor(
    public router: Router,
    public service: MessageService

  ) {
    super(service, router);
  }
  ngOnInit() {
    this.navLinks = [
      {
        label: 'Impresión de etiquetas',
        link: './print-actual-prices',
        index: 1,
        visible: true
      },
      {
        label: 'Bóveda ',
        link: './vault-managment',
        index: 2,
        visible: true
      }];
  }

}
