import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { bottomToTop, leftToLeft, leftToRigthAnimation, rightToRight } from 'src/app/animations';
import { AbstractView } from 'src/app/core/abstract_view';
import { FunctionsQuotation } from 'src/app/main-components/shared/functions/traslate-quotation-status';
import { CreditStatus, Order } from 'src/app/models/order.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { PaymentTerm } from 'src/app/models/paymentterm';
import { ProcessModel } from 'src/app/models/process.model';
import { TypeStatusQuotation, TypeStatusQuotationInterface } from 'src/app/models/quotation.model';
import { SetCreditCardPayment } from 'src/app/reducers/order.action';
import { AdvanceService } from 'src/app/services/advance.service';
import { DataProvider } from 'src/app/services/data.provider';
import { DefaultconfigurationsService, GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';
import Swal from 'sweetalert2';

import { AproveComponent } from '../../aprove/aprove.component';
import { TransferProvider } from '../../dashboard/tranfers/transfer.provider';
import { EnterPasswordComponent } from '../enter-password/enter-password.component';
import { PaymentsDetailComponent } from '../payments-detail/payments-detail.component';
import { ProductQuestPresenter } from '../product-quest/presenter/product-quest.presenter';
import { InvoiceBalanceView } from './invoice-balance.view';
import { InvoiceBalancePresenter } from './presenter/invoice-balance.presenter';
import { TaxModelList } from 'src/app/models/tax.model';

@Component({
    animations: [
        trigger('botomUpAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(30%)' }),
                animate('250ms', style({ transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0)' }),
                animate('250ms', style({ transform: 'translateY(30%)' })),
            ]),
        ]),
        leftToLeft,
        rightToRight,
        bottomToTop,
        leftToRigthAnimation
    ],
    selector: 'app-invoice-balance',
    templateUrl: './invoice-balance.component.html',
    styleUrls: ['./invoice-balance.component.scss'],
    providers: [ConfirmationService, DialogService],
})
export class InvoiceBalanceComponent extends AbstractView implements OnInit, InvoiceBalanceView, AfterViewInit {

    @ViewChild('txtObservation', { read: false, static: false }) txtObservation: ElementRef;
    paymentTerm: { label: string, value: PaymentTerm }[];
    paymentTermSelected: PaymentTerm = {
        paymentTermId: '',
        description: ''
    };

    paymentMethod: { label: string, value: PaymentMethod }[];
    paymentMethodSelected;
    paymentMethodCash: PaymentMethod = PaymentMethod.CASH;

    client = null;
    adviser = null;
    lines: null;
    subTotal = 0;
    total = 0;
    totalTax = 0;
    // subTotal12 = 0;
    // subTotal0 = 0;
    feDiscount = 0;
    pvpSubtotal = 0;
    agreementDiscount = 0;
    cardsIncrement = 0;
    totalDiscount = 0;
    updateOrder = null;
    buttonTitle = 'GUARDAR ORDEN';
    isInvoiceAllowed: boolean;
    observation = '';
    isInvoiceProcess: false;
    refInvoice: any;
    refPassword: any;
    refRange: any;
    defaultTax = 'Na';
    creditStatus = 'Sin crédito';
    creditCardPayment = false;
    billetonAmount = 0;
    isBilleton = false;
    error = '';
    isAdminPriceDiscount = false;
    subTotalWithoutDiscounts = 0;
    discountPromotion = 0;
    discountRecycling = 0;
    current = 0;
    deferred = 0;
    credired = 0;
    ifExistProductCreditRed = false;
    paymentsCards = 0;
    paymentsMessage = '';
    isCredired = false;
    selectedState: TypeStatusQuotationInterface;
    action: string;
    statesQuotationToBeApproved: TypeStatusQuotationInterface[] = [
        {name: 'Aprobar', code: TypeStatusQuotation.APPROVED},
        // {name: 'Anular', code: TypeStatusQuotation.VOIDED},
        {name: 'Cancelar', code: TypeStatusQuotation.CANCELED},
        // {name: 'Rechazar', code: TypeStatusQuotation.REACHED},
        {name: 'Reenviar', code: TypeStatusQuotation.IN_REVIEW},
    ];
    statesQuotationApproved: TypeStatusQuotationInterface[] = [
        // {name: 'Anular', code: TypeStatusQuotation.VOIDED},
        {name: 'Aprobar', code: TypeStatusQuotation.APPROVED},
        {name: 'Cancelar', code: TypeStatusQuotation.CANCELED},
    ];
    statesQuotationToBeInvoiced: TypeStatusQuotationInterface[] = [
        // {name: 'Anular', code: TypeStatusQuotation.VOIDED},
        {name: 'Cancelar', code: TypeStatusQuotation.CANCELED},
        // {name: 'Rechazar', code: TypeStatusQuotation.REACHED},
    ];
    statesQuotationInReview: TypeStatusQuotationInterface[] = [
        // {name: 'Anular', code: TypeStatusQuotation.VOIDED},
        {name: 'Cancelar', code: TypeStatusQuotation.CANCELED},
        // {name: 'Rechazar', code: TypeStatusQuotation.REACHED},
    ];
    taxAndSuntotalDinamicList: TaxModelList = {
        dinamicTax: [],
        totalTaxList: 0,
        subtotal: 0
    };
    constructor(
        private messageService: MessageService,
        public invoiceBalancePresenter: InvoiceBalancePresenter,
        public functionsQuotation: FunctionsQuotation,
        public router: Router,
        public confirmationService: ConfirmationService,
        public dialogService: DialogService,
        private hotKey: HotkeysService,
        public defaultConfigurationService: DefaultconfigurationsService,
        public productQuestPresenter: ProductQuestPresenter,
        public advanceService?: AdvanceService,
        private transferProvider?: TransferProvider,
        public dataProvider?: DataProvider,
    ) {
        super(messageService, router);
        this.shortcuts();
    }

    showEnterPassword(order: Order) {
        this.refPassword = this.dialogService.open(EnterPasswordComponent, {
            showHeader: true,
            header: 'Descuento por rango',
            width: '20%',
            data: order
        });

        this.refPassword.onClose.subscribe(() => { });
    }


    shortcuts() {
        this.hotKey.add(new Hotkey(
            ['alt+g', 'ctrl+s', 'alt+s', 'ctrl+shift+g', 'alt+shift+g', 'ctrl+shift+s', 'alt+shift+s'],
            (event: KeyboardEvent): boolean => {
                this.onSaveOrder();
                return false;
            }, ['input', 'textarea'], 'Guardar orden | factura'));
        this.hotKey.add(new Hotkey(['ctrl+o', 'alt+o'], (event: KeyboardEvent): boolean => {
            this.txtObservation.nativeElement.focus();
            return false;
        }, ['input', 'textarea'], 'Foco en observación'));
    }

    ngOnInit() {}
    ngAfterViewInit() {
        setTimeout(() => {
            this.invoiceBalancePresenter.view = this;
            this.invoiceBalancePresenter.init();
        }, 200);
    }


    onPaymentMethodChange() {
        this.paymentTermSelected = null;
    }

    onSaveOrder() {
        this.advanceService.advanceStatus();
        this.invoiceBalancePresenter.validateData();
    }

    showConfirmation() {

        Swal.fire({
            text: (!this.updateOrder ? '¿Desea guardar la orden?' : '¿Desea actualizar la orden?'),
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: !this.updateOrder ? 'Guardar ' : ' Actualizar '
        }).then((result) => {
            if (result.value) {
                this.invoiceBalancePresenter.saveOrder();
            }
        });
    }
    showTransportConfirmation() {
        Swal.fire({
            text: 'Recuerde ingresar producto transporte',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: ' Aceptar '
        }).then((result) => {
            if (result.value) {
                this.invoiceBalancePresenter.save();
            }
        });
    }
    showQuotationConfirmation(message: string) {
        Swal.fire({
            text: message,
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: ' Aceptar '
        }).then((result) => {
            if (result.value) {
                this.invoiceBalancePresenter.saveNewQuotation();
            }
        });
    }

    onCreateInvoice() {
        if (this.refInvoice) {
            return;
        }
        GeneralConfigurations.applyPromoDay = false;
        this.hotKey.pause(
            this.hotKey.add(new Hotkey(
                ['alt+g', 'ctrl+s', 'alt+s', 'ctrl+shift+g', 'alt+shift+g', 'ctrl+shift+s', 'alt+shift+s'],
                (event: KeyboardEvent): boolean => {
                    this.onSaveOrder();
                    return false;
                }, ['input', 'textarea'], 'Guardar orden | factura')));

        this.refInvoice = this.dialogService.open(PaymentsDetailComponent, {
            showHeader: false,
            header: 'Pagos',
            closeOnEscape: false,
            height: '520px',
            width: '850px'
        });
        this.refInvoice.onClose.subscribe((res: any) => {
            this.refInvoice = null;
            if (GeneralConfigurations.applyPromoDay) {
                if (this.dataProvider.processPayment === 'current') {
                    this.invoiceBalancePresenter.store.dispatch(new SetCreditCardPayment(false
                        , this.dataProvider.commissionCard, this.dataProvider.processPayment));
                } else if (this.dataProvider.processPayment === 'deferred') {
                    this.invoiceBalancePresenter.store.dispatch(new SetCreditCardPayment(true
                        , this.dataProvider.commissionCard, this.dataProvider.processPayment));
                }
                GeneralConfigurations.applyPromoDay = false;
            }
            this.invoiceBalancePresenter.onChangePaymentType();
            if (res) {
                this.totalDiscount = 0;
                this.discountPromotion = 0;
                this.transferProvider.getTransferDetailsToInvoice();
            }
            this.hotKey.unpause(
                this.hotKey.add(
                    new Hotkey(
                        ['alt+g', 'ctrl+s', 'alt+s', 'ctrl+shift+g', 'alt+shift+g', 'ctrl+shift+s', 'alt+shift+s'],
                        (event: KeyboardEvent): boolean => {
                            this.onSaveOrder();
                            return false;
                        }, ['input', 'textarea'], 'Guardar orden | factura')));
        });
    }

    showGetAproveComponent() {
        this.hotKey.pause(
            this.hotKey.add(new Hotkey(
                ['alt+g', 'ctrl+s', 'alt+s', 'ctrl+shift+g', 'alt+shift+g', 'ctrl+shift+s', 'alt+shift+s'],
                (event: KeyboardEvent): boolean => {
                    this.onSaveOrder();
                    return false;
                }, ['input', 'textarea'], 'Guardar orden | factura')));
        this.refRange = this.dialogService.open(AproveComponent, {
            showHeader: true,
            header: 'Aprobar',
            closeOnEscape: true,
        });
        this.refRange.onClose.subscribe((value) => {


            this.hotKey.unpause(
                this.hotKey.add(
                    new Hotkey(
                        ['alt+g', 'ctrl+s', 'alt+s', 'ctrl+shift+g', 'alt+shift+g', 'ctrl+shift+s', 'alt+shift+s'],
                        (event: KeyboardEvent): boolean => {
                            this.invoiceBalancePresenter.onChangePaymentType();
                            this.onSaveOrder();
                            return false;
                        }, ['input', 'textarea'], 'Guardar orden | factura')));

            if (value) {
                this.invoiceBalancePresenter.save();
            }
        });
    }

    updateOrderAndGenerateInvoice() {
        this.advanceService.advanceStatus();
        this.invoiceBalancePresenter.validateData();
    }
    setObservation() {
        this.invoiceBalancePresenter.setObservation();
    }


    blockUiWhenOrderAproved() {
        if (this.updateOrder && this.updateOrder.creditStatus === CreditStatus.APPROVED) {
            return true;
        } else {
            return false;
        }
    }
    blockCreditRadio() {
        if (this.client && this.client.dni === '9999999999999' || (this.client && this.client.paymentTermPresenter.netDays <= 0)
            || (this.client && this.client.totalAmountAvailable <= 0) || this.isBilleton || this.creditCardPayment) {
            this.creditStatus = 'Sin crédito';
            return true;
        } else {
            return false;
        }
    }
    checkDiscount() {
        // this.invoiceBalancePresenter.checkDiscount();
    }
    onChangePaymentType() {
        if (this.paymentsCards) {
            this.showConfirmationPayments();
        } else {
            this.invoiceBalancePresenter.onChangePaymentType();
        }
    }

    showConfirmationPayments() {
        Swal.fire({
            title: 'Tiene pagos con tarjeta agregados',
            text: '¿Desea eliminarlos, para continuar?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: ' Aceptar '
        }).then((result) => {
            if (result.value) {
                this.invoiceBalancePresenter.deletePayments();
            }
            if (result.dismiss === Swal.DismissReason.cancel) {
                if (this.dataProvider.processPayment === 'deferred') {
                    this.dataProvider.processPayment = 'current';
                }
                if (this.dataProvider.processPayment === 'current') {
                    this.dataProvider.processPayment = 'deferred';
                }
            }
        });
    }

    validateQuotationUpdate(action: string) {
        if (this.dataProvider.quotationStatus === TypeStatusQuotation.APPROVED) {
            this.showMessageApproved();
        } else {
            this.action = action;
            this.invoiceBalancePresenter.validateData();
        }
    }
    showMessageApproved() {
        Swal.fire({
            text: 'La cotización ya se encuentra aprobada',
            showCancelButton: true,
            confirmButtonColor: '#00687d',
            confirmButtonText: ' Aceptar '
        });
    }

    showUpdateQuotation(action: string) {
        Swal.fire({
            text: `¿Desea ${action} la cotización ?`,
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: ' Aceptar '
        }).then((result) => {
            if (result.value) {
                this.invoiceBalancePresenter.updateQuotation();
            }
        });
    }

    translateQuotationStatus(quotationStatus: string): string {
        return this.functionsQuotation.translateQuotationStatus(quotationStatus);
    }
    setTooltipQuotationStation(quotationStatus: string ) {
        return this.functionsQuotation.setTooltipQuotationStation(quotationStatus);
    }

    loadStatesQuotation() {
        switch (this.dataProvider.quotationStatus) {
            case TypeStatusQuotation.APPROVED:
                return this.statesQuotationApproved;
            case TypeStatusQuotation.TO_BE_INVOICED:
                return this.statesQuotationToBeInvoiced;
            case TypeStatusQuotation.TO_BE_APPROVED:
                return this.statesQuotationToBeApproved;
            case TypeStatusQuotation.IN_REVIEW:
                return this.statesQuotationInReview;
        }
    }

    validateChangeStatusQuotation(): boolean {
        let valid = true;
        if (this.invoiceBalancePresenter.procesesSelected === ProcessModel.QUOTATION) {
            switch (this.dataProvider.quotationStatus) {
                case null:
                case TypeStatusQuotation.PENDING:
                case TypeStatusQuotation.CANCELED:
                case TypeStatusQuotation.VOIDED:
                case TypeStatusQuotation.REACHED:
                    valid = false;
                    break;
            }
        } else {
            valid = false;
        }
        return valid;
    }
    validateProductCredired() {
       this.isCredired = this.productQuestPresenter.validateProductCredired();
    }
}









