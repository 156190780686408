import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/models/order.model';
import { DynamicDialogRef, DynamicDialogConfig, MessageService } from 'primeng/api';
import { EnterPasswordView } from './enter-password.view';
import { EnterPasswordPresenter } from './presenter/enter-password.presenter';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { RankDiscountModel } from 'src/app/models/rank-discount.model';

@Component({
  selector: 'app-enter-password',
  templateUrl: './enter-password.component.html',
  styleUrls: ['./enter-password.component.scss']
})
export class EnterPasswordComponent extends AbstractView implements OnInit, EnterPasswordView {

  order: Order;
  key: string;
  rankDiscount: RankDiscountModel;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private enterPasswordPresenter: EnterPasswordPresenter,
    private messageService: MessageService,
    public router: Router,
  ) {
    super(messageService, router);
    this.order = config.data;
    enterPasswordPresenter.view = this;
  }

  ngOnInit() {
  this.getOrderDiscount();
  }

  getOrderDiscount() {
    this.enterPasswordPresenter.getDiscount();
  }

  closeModal() {
    this.ref.close();
  }

  aplyOrderDiscount() {
    this.enterPasswordPresenter.aplyOrderDiscount();
  }

}
