import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { ResetPasswordPresenter } from './presenter/reset-password.presenter';
import { AuthService } from 'src/app/core/auth.service';
import { AbstractView } from 'src/app/core/abstract_view';
import { ResetPasswordView } from './reset-password.view';
import { MessageService } from 'primeng/api';
import { CustomValidationService } from 'src/app/services/custom-validation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends AbstractView implements OnInit, ResetPasswordView {
  form = this.formBuilder.group({});
  token = '';
  user: UserPresenter = {};
  submited = false;
  verifying = true;

  visiblePassword = true;
  visibleConfirmPassword = true;
  errorMessage: string;
  submitted = false;
  constructor(
    public formBuilder: FormBuilder,
    public customValidator: CustomValidationService,
    private resetPasswordPresenter: ResetPasswordPresenter,
    public router: Router,
    private authService: AuthService,
    private readonly route: ActivatedRoute,
    private messageService: MessageService) {
    super(messageService, router);
    this.resetPasswordPresenter.view = this;
    this.token = this.route.snapshot.paramMap.get('token');
    if (!this.authService.isValidToken(this.token)) {
      this.goTohome();
      return;
    }
    this.resetPasswordPresenter.confirmReset();
    this.form = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      confirmPassword: ['', [Validators.required]],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      });
  }
  get registerFormControl() {
    return this.form.controls;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  ngOnInit() {

  }

  goTohome() {
    this.router.navigate(['']);
  }

  resetPassword() {
    if (this.form.invalid || this.submited) {
      return;
    }
    this.submited = true;
    this.user.password = this.form.value.password;
    this.resetPasswordPresenter.resetPasword();

  }

  enableForm() {
    this.form.enable();
  }

}
