import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/primeng';
import { AbstractView } from 'src/app/core/abstract_view';
import { GenerationType, ReasonClaim, TransferMovementType, TransfersModel, TransferStatus } from 'src/app/models/transfer.mode';
import { DataProvider } from 'src/app/services/data.provider';
import Swal from 'sweetalert2';
import { TransferService } from '../../services/transfer.service';
import { TransferProvider } from '../../transfer.provider';
import { WareHouse } from '../../../../../../../models/product.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-transfers-report',
  templateUrl: './transfers-report.component.html',
  styleUrls: ['./transfers-report.component.scss']
})

export class TransfersReportComponent extends AbstractView implements OnInit, OnDestroy {
  transferMovementTypes: { label: string, value: TransferMovementType }[] = [
    { label: 'Transferencia entre PDV', value: TransferMovementType.SUPPLYING_BETWEEN_PDV },
    { label: 'Abastecimiento matriz', value: TransferMovementType.SUPPLYING },
    { label: 'Vental puntual', value: TransferMovementType.BETWEEN_PDV },
    { label: 'Reclamo de Material', value: TransferMovementType.CLAIM },
    { label: 'Devolución', value: TransferMovementType.DEVOLUTION },
  ];
  transferStatus: { label: string, value: TransferStatus }[] = [
    { label: 'Aceptado', value: TransferStatus.ACCEPTED },
    { label: 'Aceptado parcial', value: TransferStatus.PARTIAL_ACCEPTED },
    { label: 'Anulado', value: TransferStatus.VOIDED },
    { label: 'Enviado', value: TransferStatus.SENT },
    { label: 'Rechazado', value: TransferStatus.REJECTED },
    { label: 'Solicitado', value: TransferStatus.REQUESTED },

  ];

  selectedTransfer: TransfersModel;

  docNum = new Subject<string>();
  docSubscription: Subscription;
  constructor(
    private messageService: MessageService,
    public transferProvider: TransferProvider,
    public transferService: TransferService,
    public dataProvider: DataProvider,
  ) {
    super(messageService);
  }
  ngOnDestroy(): void {
    this.docSubscription.unsubscribe();
  }

  ngOnInit() {
    this.transferProvider.transfersReport = [];
    this.transferProvider.selectedUser = null;
    this.transferProvider.initDate = null;
    this.transferProvider.endDate = null;
    this.transferProvider.documentNo = null;
    this.transferProvider.transferMovementTypeSelected = [];
    this.transferProvider.transferStatusSelected = null;
    this.transferProvider.originSelected = null;
    this.transferProvider.destinySelected = null;
    this.transferProvider.getTransferWarehouses();
    this.findTransfers();
    this.docSubscription = this.docNum.pipe(debounceTime(1000)).subscribe(() => {
      this.findTransfers();
    });
  }
  evaluateRole() {
    return this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS);
  }
  paginate($event) {
    this.transferProvider.page = $event['page'];
    this.findTransfers();
  }
  findTransfers() {
    this.transferProvider.findTransfers('transfersReport');
  }
  getDetails() {
    this.blockUi();
    this.transferService.getTransferById(this.selectedTransfer.transferId, false).subscribe((res: TransfersModel) => {
      res.transferDetailPresenters.forEach(transfer => {
        transfer.measureUnitPresenter.name = transfer.productPresenter.descriptionMeasure
          ? transfer.productPresenter.descriptionMeasure : transfer.measureUnitPresenter.name;
      });
      this.transferProvider.transfer = res;
    }, (error: HttpErrorResponse) => {
      this.showError('Error al  burcar transferencia', error.error.message);
    });
  }
  getTransferDetails(event, overlaypanel: OverlayPanel, transfer: TransfersModel) {
    if (!this.selectedTransfer || this.selectedTransfer.transferId !== transfer.transferId) {
      this.selectedTransfer = transfer;

    }
    this.getDetails();
    overlaypanel.toggle(event);
  }
  getDevolutionDraft(transfer: TransfersModel) {
    if (!this.selectedTransfer || this.selectedTransfer.transferId !== transfer.transferId) {
      this.selectedTransfer = transfer;
    }
    this.transferProvider.getTransferToGenerateDevolution(transfer, true);
  }
  getTranslateTMT(transferMovementType: TransferMovementType) {
    switch (transferMovementType) {
      case TransferMovementType.BETWEEN_PDV: return 'VENTA PUNTUAL';
      case TransferMovementType.CLAIM: return 'RECLAMO DE MATERIALES';
      case TransferMovementType.SPECIAL_ORDER: return 'ORDEN ESPECIAL';
      case TransferMovementType.SUPPLYING: return 'ABASTECIMIENTO MATRIZ';
      case TransferMovementType.SUPPLYING_BETWEEN_PDV: return 'TRANSFERENCIA ENTRE PDV';
      case TransferMovementType.DEVOLUTION: return 'DEVOLUCION PDV A MATRIZ';
    }
  }
  translateReason(reason: ReasonClaim) {
    switch (reason) {
      case ReasonClaim.MISSING: return 'Faltantes';
      case ReasonClaim.DISREPAIR: return 'Mal estado';
      case ReasonClaim.INCOMPLETE: return 'Incompleto';
      case ReasonClaim.CROSSING: return 'Cruce de material';
      case ReasonClaim.WARRANTY: return 'Garantía';
      case ReasonClaim.SURPLUS: return 'Sobrantes';
    }
  }
  getTranslateTS(line: TransfersModel) {
    switch (line.transferStatus) {
      case TransferStatus.ACCEPTED: return 'ACEPTADA';
      case TransferStatus.PARTIAL_ACCEPTED: return 'PARCIALMENTE ACEPTADA';
      case TransferStatus.REJECTED: return 'RECHAZADA';
      case TransferStatus.REQUESTED: return 'SOLICITADA';
      case TransferStatus.SENT: return line.origin.warehouseId === this.dataProvider.defaultWarehouse.warehouseId
        && line.transferMovementType !== TransferMovementType.CLAIM && line.transferMovementType !== TransferMovementType.DEVOLUTION
        ? 'POR REVISAR' : 'ENVIADA';
      case TransferStatus.VOIDED: return 'ANULADA';
      case TransferStatus.RECEIVED: return 'RECIBIDA';
      case TransferStatus.CLOSED: return 'CERRADA';
      case TransferStatus.DRAFT: return 'BORRADOR';
    }
  }
  getTranslateGT(generationType: GenerationType) {
    if (generationType === GenerationType.AUTOMATIC) {
      return 'AUTOMÁTICA';
    } else {
      return 'MANUAL';
    }
  }
  getQuantityNull(acceptedEnteredQuantity: number) {
    if (!acceptedEnteredQuantity && acceptedEnteredQuantity !== 0) {
      return '-';
    } else {
      return acceptedEnteredQuantity;
    }
  }
  onSearchUser(event) {
    this.transferProvider.searchUser = event.query;
    this.transferProvider.pageUser = 0;
    this.transferProvider.sizeUser = 5;
    this.transferProvider.onSearchUser();
  }

  showARejectTransfer(transfer: TransfersModel) {
    Swal.fire({
      text: '¿Desea anular la transferencia?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular'
    }).then((result) => {
      if (result.value) {
        this.cancelTransfer(transfer);
      }
    });
  }
  showARejectTransferDevolution(transfer: TransfersModel) {
    Swal.fire({
      text: '¿Desea anular la devolución?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular'
    }).then((result) => {
      if (result.value) {
        this.cancelTransferDevolution(transfer);
      }
    });
  }
  cancelTransfer(transfer: TransfersModel) {
    this.blockUi();
    this.transferService.cancelTransfer(transfer).subscribe((res: TransfersModel) => {
      this.showSuccess('', 'Transferencia anulada');
      this.findTransfers();
    }, (error: HttpErrorResponse) => {
      this.showError('Error al  anular transferencia', error.error.message);
    });
  }
  cancelTransferDevolution(transfer: TransfersModel) {
    if (!transfer.transferId) {
      this.showWarn('Advertencia', 'No se puede anular');
    }
    this.blockUi();
    this.transferService.cancelTransferDevolution(transfer.transferId).subscribe((res: TransfersModel) => {
      this.showSuccess('', 'Devolución anulada');
      this.findTransfers();
    }, (error: HttpErrorResponse) => {
      this.showError('Error al  anular devolución', error.error.message);
    });
  }
  findTransfersByUser() {
    if (this.transferProvider.selectedUser && typeof this.transferProvider.selectedUser !== 'string') {
      this.transferProvider.showSelectedUser = true;
      this.findTransfers();
    }
  }
  clearUser() {
    this.transferProvider.showSelectedUser = false;
    this.transferProvider.selectedUser = null;
    this.transferProvider.observation = '';
    this.findTransfers();
  }

  getPdf(transfersModel: TransfersModel) {
    if (transfersModel.transferMovementType === TransferMovementType.SUPPLYING_BETWEEN_PDV) {
      this.transferProvider.getTransferPdf(transfersModel.transferId);
    }
    if (transfersModel.transferMovementType === TransferMovementType.DEVOLUTION) {
      this.transferProvider.getDevolutionPdf(transfersModel.transferId);
    }
  }

  syncroniceTransfer(transfer: TransfersModel) {
    this.transferProvider.syncroniceTransfer(transfer);
  }
}
