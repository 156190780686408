
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';
import { Presenter } from 'src/app/core/presenter';
import { InvoiceSalesModel, SalesCategoryModel } from 'src/app/models/product.model';
import { ReportService } from 'src/app/services/report.service';
import { ProductCategorySalesReportView } from '../product-category-sales-report.view';

@Injectable({
    providedIn: 'root',
})
export class ProductCategorySalesReportPresenter implements Presenter {
    view: ProductCategorySalesReportView;

    constructor(
        private reportService: ReportService) { }

    getInvoicesForUserReport() {
        let initDate = '';
        let endDate = '';
        const pageInvoice = this.view.pageInvoice;
        const sizeInvoice = this.view.sizeInvoice;
        const format = 'yyyy-MM-dd';
        const locale = 'en-US';
        let numbreLines = 0;
        this.view.visible = false;
        if (!this.view.initDate || !this.view.endDate) {
            return;

        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);

        this.view.blockUi();

        this.reportService.getInvoiceSales(initDate, endDate).subscribe((data: InvoiceSalesModel[]) => {
            this.view.productCategories = data;
            this.view.totalIstCreditMemo = 0;
            this.view.totalIsCreditMemo = 0;
            this.view.total = 0;
            this.view.productCategories.forEach(line => {

                line.subtotal = new Decimal(line.subtotal).toDecimalPlaces(2).toNumber();
                line.subtotalInvoice = new Decimal(line.subtotalInvoice).toDecimalPlaces(2).toNumber();
                line.subtotalCreditMemo = new Decimal(line.subtotalCreditMemo).toDecimalPlaces(2).toNumber();
                line.subtotalBilleton = new Decimal(line.subtotalBilleton).toDecimalPlaces(2).toNumber();
                line.subtotal = new Decimal(line.subtotal).toDecimalPlaces(2).toNumber();

                line.subtotalOther = new Decimal(line.subtotalOther).toDecimalPlaces(2).toNumber();
                line.subtotalInvoiceOther = new Decimal(line.subtotalInvoiceOther).toDecimalPlaces(2).toNumber();
                line.subtotalCreditMemoOther = new Decimal(line.subtotalCreditMemoOther).toDecimalPlaces(2).toNumber();
                line.subtotalOther = new Decimal(line.subtotalOther).toDecimalPlaces(2).toNumber();

                line.total = new Decimal(line.subtotal).add(line.subtotalOther).toNumber();

                this.view.totalIstCreditMemo = new Decimal(this.view.totalIstCreditMemo).add(line.subtotal).toNumber();
                this.view.totalIsCreditMemo = new Decimal(this.view.totalIsCreditMemo).add(line.subtotalOther).toNumber();

                if (line.total > 0 || line.subtotal > 0) {
                    numbreLines = numbreLines + 1;
                }
            });
            if (numbreLines > 0) {
                this.view.visible = true;
                this.view.data = {
                    labels: this.view.productCategories.map(line => line.created),
                    datasets: [
                        {
                            label:  this.view.labelFE,
                            data: this.view.productCategories.map(line => line.subtotalOther),
                            fill: false,
                            borderColor: 'tomato',

                        },
                        {
                            label:  this.view.labelPE,
                            data: this.view.productCategories.map(line => line.subtotal),
                            fill: false,
                            borderColor: '#565656',

                        }
                    ]
                };
            } else {
                this.view.visible = false;
                this.view.showInfo('', 'Busqueda sin registros');

            }
        }, (error: HttpErrorResponse) => {
            this.view.productCategories = [];
            this.view.showError('Atención', error.error.message);
        });


    }


    printSalesReport() {
        const productCategories = this.view.productCategories.map(line => {
            return {

                Fecha: line.created,

                'Ventas M. Ferreteria': line.subtotalInvoice,
                'Devoluciones M. Ferreteria': line.subtotalCreditMemo,
                'Descuentos M. Ferreteria': line.subtotalBilleton,
                'Subtotal M. Ferreteria': line.subtotal,

                'Ventas M. Pesado': line.subtotalInvoiceOther,
                'Devoluciones M. Pesado': line.subtotalCreditMemoOther,
                'Subtotal M. Pesado': line.subtotalOther,

                Total: line.total,


            };
        });
        const products = JSON.parse(JSON.stringify(productCategories,
            [
                'Fecha',
                'Ventas M. Ferreteria',
                'Devoluciones M. Ferreteria',
                'Descuentos M. Ferreteria',
                'Subtotal M. Ferreteria',
                'Ventas M. Pesado',
                'Devoluciones M. Pesado',
                'Subtotal M. Pesado',
                'Total',
            ], 2));
        this.view.csvDownload('Reporte pesados.csv', products);
    }



}
