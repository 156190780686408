import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { ProductHistoryView } from './product-history.view';
import { ProductHistoryPresenter } from './presenter/product-history.presenter';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ListProductHistory } from 'src/app/models/reportProductHistory.model';
import { Product, ProductSupplierDetailPresenter, ProductSupplierPresenter } from 'src/app/models/product.model';

@Component({
  selector: 'app-product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.scss']
})
export class ProductHistoryComponent extends AbstractView implements OnInit, ProductHistoryView {

  initDate = new Date();
  endDate = new Date();
  listProductHistory: ListProductHistory[];
  reportsCSV: ListProductHistory[];
  page = 0;
  size = 20;
  totalElements: number;
  historyProducts: ListProductHistory[];
  showSelectedProduct: boolean;
  selectedProduct: Product;
  productId = '';
  productSupplierPresenter: ProductSupplierPresenter[] = [];
  searchProduct: string;
  products: Product[];

  constructor(
    private messageService: MessageService,
    public router: Router,
    public productHistoryPresenter: ProductHistoryPresenter
  ) {
    super(messageService, router);
    productHistoryPresenter.view = this;
   }

  ngOnInit() {
    this.getProductSalesReport();
    this.getPaginateProductHisyoty();
  }

  getProductSalesReport() {
    this.productHistoryPresenter.getReportProductHistory();
  }

  getPaginateProductHisyoty() {
    this.productHistoryPresenter.getproductHistory();
  }

  clearProduct() {
    this.productHistoryPresenter.clearProduct();
  }

  onSearchProduct(event) {
    this.searchProduct = event.query;
    this.productHistoryPresenter.onSearchProduct();
  }

  addProduct() {
    this.productHistoryPresenter.findReportsByProduct();
  }

  paginate($event) {
    this.page = $event['page'];
    this.productHistoryPresenter.getproductHistory();
  }

}
