import { Injectable } from '@angular/core';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';
import { InitDataView } from '../init-data.view';
import { SettingService } from 'src/app/services/setting.service';

@Injectable({
  providedIn: 'root',
})
export class InitDataPresenter {
  view: InitDataView;
  ip: string;
  constructor(
    public settingService: SettingService,
    public defConf?: DefaultconfigurationsService) { }

  validate() {
    if (this.view.ip.length === 0) {
      this.view.showError('', 'Debe ingresar una ip');
      return;
    }
    const ip = this.view.ip;
    this.view.blockUi();
  }

  loadInitialData(BD_ORIGEN: string) {
    this.settingService.getInitialData( BD_ORIGEN ).subscribe( result => {
        this.view.showSuccess('', result.mensaje);
    },
    error => {
      this.view.showError('', error.error.message);
    }
    );
  }
}
