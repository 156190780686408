
import * as $params from './client.action';
import { ClientModel } from '../models/client.model';

export interface IAppState {
    client: ClientModel;
    showSelectedClient: boolean;
}

export const INITIAL_STATE: IAppState = {
    client: null,
    showSelectedClient: false
};

export function ClientReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {

    switch (action.type) {
        case $params.ClientActions.ADD_CLIENT:
            return Object.assign({}, state, {
                client: action.client,
                showSelectedClient: true
            });
        case $params.ClientActions.CLEAR_CLIENT:
            return Object.assign({}, state, {
                client: null,
                showSelectedClient: false
            });

        default:
            return state;
    }
}
