import { Presenter } from 'src/app/core/presenter';
import { SettingsView } from '../settings.view';
import { Injectable } from '@angular/core';
import { DefaultConfigurationPresenter, DefaultConfigurationType } from 'src/app/models/defaultconfigurations.model';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { ClearAdviser } from 'src/app/reducers/adviser.action';
import { PromotionProvider } from '../../../../../services/promotion.provider';
import { SetAdminPriceDiscount, SetDefaultPrice, SetDiscountToCero } from 'src/app/reducers/order.action';
import { DataProvider } from 'src/app/services/data.provider';
import { CposService } from 'src/app/services/cpos.service';
@Injectable({
    providedIn: 'root',
})
export class SettingsPresenter implements Presenter {

    view: SettingsView;



    constructor(
        public defaultconfigurationsService: DefaultconfigurationsService,
        private store: Store<{}>,
        private promotionProvider?: PromotionProvider,
        public dataProvider?: DataProvider,
        public cposService?: CposService
    ) {

    }
    init() {

    }

    saveDefaultConfigurations() {
        if (!this.getVisibility(this.view.defaultConfigurationPresenterSelected)) {
            this.view.showError('', 'Sin cambios');

            return;
        }
        if (!(this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_SALES_EXPRESS
            || this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_ADMIN_PRICE
            || this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_SYNCHRONIZED_DATA
            || this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_PROMOTIONS_ACTIVE
            || this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_CREDENTIALS_ADMIN_PRICE
            || this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_SHOW_DEVOLUTIONS
     )) {
            if ((!this.view.defaultConfigurationPresenterSelected.value ||
                isNaN(Number(this.view.defaultConfigurationPresenterSelected.value)))) {
                this.view.showError('', 'Parametro incorrecto');
                return;
            }
        }

        if (this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_TIME_AUTO_CANCEL_ORDERS &&
            Number(this.view.defaultConfigurationPresenterSelected.value) <= 0) {
            this.view.showError('', 'Parametro incorrecto');
            return;
        }
        if (this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
            DefaultConfigurationType.DEFAULT_AMOUNT_CREDIT_DEBIT_CARD_SALES &&
            (Number(this.view.defaultConfigurationPresenterSelected.value) < 0 ||
                Number(this.view.defaultConfigurationPresenterSelected.value) > 100)) {
            this.view.showError('', 'Parametro incorrecto');
            return;
        }

        this.view.blockUi();
        this.defaultconfigurationsService.saveDefaultConfiguration(this.view.defaultConfigurationPresenterSelected).
            then(defaultconfigurationResult => {
                defaultconfigurationResult = defaultconfigurationResult.filter(conf => {
                    return conf.defaultConfigurationType !== DefaultConfigurationType.DEFAULT_MIN_BILLETON_AMOUNT;
                });
                this.defaultconfigurationsService.setDefaultConf(defaultconfigurationResult);
                this.view.defaultConfigurationPresenter = JSON.parse(JSON.stringify(defaultconfigurationResult));
                this.view.defaultConfigurationPresenterClone = JSON.parse(JSON.stringify(defaultconfigurationResult));
                this.view.showSuccess('', 'Configuración actualizada');
                if (this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
                    DefaultConfigurationType.DEFAULT_ADMIN_PRICE &&
                    !this.view.defaultConfigurationPresenterSelected.value) {
                    this.defaultconfigurationsService.showAdminPriceDiscount = false;
                    this.store.dispatch(new SetAdminPriceDiscount(false));
                    this.store.dispatch(new SetDiscountToCero());
                    this.store.dispatch(new SetDefaultPrice());
                }

                if (this.view.defaultConfigurationPresenterSelected.defaultConfigurationType ===
                    DefaultConfigurationType.DEFAULT_PROMOTIONS_ACTIVE) {
                    this.promotionProvider.enabled = this.view.defaultConfigurationPresenterSelected.value;
                }

            }).catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salió mal');

                }
            });

    }


    getVisibility(defaultConfigurationPresenter: DefaultConfigurationPresenter): boolean {
        let ret = true;
        this.view.defaultConfigurationPresenterClone.forEach(element => {
            if (defaultConfigurationPresenter.defaultConfigurationId === element.defaultConfigurationId &&
                defaultConfigurationPresenter.value === element.value) {
                ret = false;
            }
        });
        return ret;
    }
    validateConexionCpos() {
        this.view.blockUi();
        this.cposService.testConnection().subscribe((res: any) => {
            if (res) {
                this.view.showSuccess('Éxito', 'Conexión establecida con exito');
            } else {
                this.view.showWarn('Alerta', 'Algo salio mal');
            }
        }, (error) => {
            this.view.showBackException(error);
        });
    }

}
