import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { ReportSalesPriceModel } from 'src/app/models/reportsalesprice.modal';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';
import { ReportCommissionView } from '../report-commission.view';

@Injectable({
    providedIn: 'root',
})
export class ReportCommissionPresenter implements Presenter {
    view: ReportCommissionView;

    constructor(
        private reportService: ReportService,
        private productService: ProductService,
        private presenterSetting: SettingPresenter) { }

    findInvoicesByUser() {
        if (this.view.selectedUser && typeof this.view.selectedUser !== 'string') {
            this.view.showSelectedUser = true;
            this.getInvoicesForUserCommission();
        }
    }

    clearUser() {
        this.view.showSelectedUser = false;
        this.view.selectedUser = null;
        this.getInvoicesForUserCommission();
    }

    onSearchUser() {
        const searchValue = this.view.searchValue;
        if (searchValue.length < 3) {
            return;
        }
        this.reportService.usersByCriterion(searchValue, 0, 5).then(data => {
            const user: UserPresenter[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.users = user;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.view.productId = '';
        this.getInvoicesForUserCommission();
    }

    findReportsByProduct() {
        if (
            this.view.selectedProduct &&
            typeof this.view.selectedProduct !== 'string'
        ) {
            this.view.showSelectedProduct = true;
            this.view.productId = this.view.selectedProduct.referenceCode;
            this.getInvoicesForUserCommission();
        }
    }

    onSearchProduct() {
        const searchValue = this.view.searchProduct;
        const page = 0;
        const size = 20;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        this.productService
            .searchProduct(searchValue, page, size, prceListId, null)
            .then((data) => {
                this.view.products = data['data'];
                if (this.view.products.length <= 0) {
                    this.view.showError('', 'Búsqueda sin registros');
                    this.view.products = [];
                }
            })
            .catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salio mal');
                }
            });
    }

    getReportSalesCommission(type) {
        let initDate = '';
        let endDate = '';
        const pageInvoice = this.view.pageInvoice;
        const sizeInvoice = this.view.sizeInvoice;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        const numbreLines = 0;
        this.view.visible = false;
        if (!this.view.initDate || !this.view.endDate) {
            return;

        }
        let user = 'null';
        if (this.view.selectedUser) {
            user = this.view.selectedUser.dni;
        }
        let product = 'null';
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.referenceCode;
        }
        let invoice = 0;
        if (this.view.documentNo !== '') {
            invoice = Number(this.view.documentNo);
        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        this.view.categorySelected = type;
        this.reportService.getReportSalesCommission(initDate, endDate, invoice, product, user, type,
            pageInvoice, sizeInvoice).subscribe((data: ReportSalesPriceModel[]) => {
                this.view.totalElementsInvoice = data['totalElements'];
                this.view.invoices = data['data'];
                this.view.visible = true;
            }, (error: HttpErrorResponse) => {
                // this.view.productCategories = [];
                this.view.showError('Atención', error.error.message);
            });

    }

    getInvoicesForUserCommission() {
        let initDate = '';
        let endDate = '';
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        this.view.visible = false;
        if (!this.view.initDate || !this.view.endDate) {
            return;
        }

        let user = 'null';
        if (this.view.selectedUser) {
            user = this.view.selectedUser.dni;
        }
        let product = 'null';
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.referenceCode;
        }
        let invoice = 0;
        if (this.view.documentNo !== '') {
            invoice = Number(this.view.documentNo);
        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        this.view.blockUi();

        this.reportService.getReportSalesCommissionSumary(initDate, endDate, invoice, product, user).subscribe((data: ReportSalesPriceModel[]) => {
            this.view.resumeSalesPrice = data['resumeSalesCommissions'];
            this.view.listSalesPrice = data['listSalesCommissions'];
            this.view.invoices = [];
            this.view.pageInvoice = 0;

            initDate = formatDate(this.view.initDate, 'yyyy-MM-dd', 'en-US');
            endDate = formatDate(this.view.endDate, 'yyyy-MM-dd', 'en-US');

            const subtotalByDateFerreteria = {};
            const subtotalByDatePesado = {};

            this.view.listSalesPrice.forEach(line => {
                const dateKey = formatDate(line.date, 'yyyy-MM-dd', 'en-US');
                if (line.division === 'ferreteria') {
                    subtotalByDateFerreteria[dateKey] = (subtotalByDateFerreteria[dateKey] || 0) + parseFloat(line.salesSubtotal.toFixed(2));
                } else if (line.division === 'pesado') {
                    subtotalByDatePesado[dateKey] = (subtotalByDatePesado[dateKey] || 0) + parseFloat(line.salesSubtotal.toFixed(2));
                }
            });

            const allDates = this.getAllDatesInRange(new Date(initDate), new Date(endDate));
            const dataFerreteria = allDates.map(date => subtotalByDateFerreteria[date] || 0);
            const dataPesado = allDates.map(date => subtotalByDatePesado[date] || 0);

            if (dataFerreteria.some(total => total > 0) || dataPesado.some(total => total > 0)) {
                this.view.data = {
                    labels: allDates,
                    datasets: [
                        {
                            label: 'Ferreteria',
                            data: dataFerreteria.map(value => parseFloat(value.toFixed(2))),
                            fill: false,
                            borderColor: 'tomato', // Tomate
                        },
                        {
                            label: 'Pesado',
                            data: dataPesado.map(value => parseFloat(value.toFixed(2))),
                            fill: false,
                            borderColor: '#565656', // Plomo
                        }
                    ]
                };
                this.view.visible = true;
            } else {
                this.view.visible = false;
                this.view.showInfo('', 'Busqueda sin registros');
            }
        }, error => {
            this.view.showError('Atención', error.error.message);
        });
    }

    getAllDatesInRange(startDate, endDate) {
        const dateArray = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dateArray.push(formatDate(currentDate, 'yyyy-MM-dd', 'en-US'));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    }


}
