import { Action } from '@ngrx/store';
import { InvoiceModel } from '../models/invoice.model';
import { Payment } from '../models/payment.model';
export enum InvoiceActions {
    SELECT_INVOICE = 'SELECT_INVOICE',
    ADD_PAYMENT_TO_INVOICE = 'ADD_PAYMENT_TO_INVOICE',
    CLEAR_INVOICE = 'CLEAR_INVOICE',
    CLEAR_PAYMENTS = 'CLEAR_PAYMENTS',
    DELETE_PAYMENT = 'DELETE_PAYMENT',
    ADD_PAYMENT_TO_SALES = 'ADD_PAYMENT_TO_SALES',
}
export class SelectInvoice implements Action {
    constructor(public invoice: InvoiceModel) { }
    readonly type = InvoiceActions.SELECT_INVOICE;
}
export class AddPaymentToInvoice implements Action {
    constructor(public payment: Payment) { }
    readonly type = InvoiceActions.ADD_PAYMENT_TO_INVOICE;
}
export class ClearInvoice implements Action {
    constructor() { }
    readonly type = InvoiceActions.CLEAR_INVOICE;
}
export class ClearPayments implements Action {
    constructor() { }
    readonly type = InvoiceActions.CLEAR_PAYMENTS;
}
export class DeletePayment implements Action {
    constructor(public index: number) { }
    readonly type = InvoiceActions.DELETE_PAYMENT;
}
export type ActionsUnion =
    SelectInvoice |
    AddPaymentToInvoice |
    ClearPayments |
    DeletePayment |
    ClearInvoice;
