import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, DynamicDialogRef, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { FunctionsQuotation } from 'src/app/main-components/shared/functions/traslate-quotation-status';
import { ClientModel } from 'src/app/models/client.model';
import { OrderLine } from 'src/app/models/orderline.model';
import { ProcessModel } from 'src/app/models/process.model';
import { QuotationModel, TypeStatusQuotation } from 'src/app/models/quotation.model';
import { DataProvider } from 'src/app/services/data.provider';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';

import { SearchQuotationPresenter } from './presenter/search-quotation.presenter';
import { SearchQuotationView } from './search-quotation.view';

@Component({
  selector: 'app-search-quotation',
  templateUrl: './search-quotation.component.html',
  styleUrls: ['./search-quotation.component.scss'],
  providers: [ConfirmationService],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0,
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class SearchQuotationComponent extends AbstractView implements OnInit, SearchQuotationView {
  quotations: QuotationModel [];
  selectedQuotation: QuotationModel;
  searchClient = '';
  page = 0;
  pageClient = 0;
  sizeClient = 5;
  clients: ClientModel[];
  selectedClient: ClientModel;
  initDate = null;
  endDate = null;
  documentNo = null;
  showSelectedClient = false;

  linesNew: OrderLine[] = [];
  linesOld: OrderLine[] = [];
  procesesSelected: ProcessModel;
  constructor(
    private messageService: MessageService,
    public router: Router,
    private quotationPresenter: SearchQuotationPresenter,
    private ref: DynamicDialogRef,
    public functionsQuotation: FunctionsQuotation,
    public defaultConfigurationService: DefaultconfigurationsService,
    private dataProvider?: DataProvider,

  ) {
    super(messageService, router);
    quotationPresenter.view = this;
    quotationPresenter.findQuotations();
  }

  ngOnInit() {}

  onSearchClient(event) {
    this.searchClient = event.query;
    this.pageClient = 0;
    this.sizeClient = 5;
    this.quotationPresenter.onSearchClient();
  }
  addClient() {
    this.page = 0;
    this.documentNo = null;
    this.quotationPresenter.findQuotationsByClient();
  }
  findByNumber() {
    this.page = 0;
    this.clearClient();
  }

  clearClient() {
    this.quotationPresenter.clearClient();
  }
  closeView() {
    this.ref.close();
  }

  processQuotationOrInvoice(quotation: QuotationModel) {
    this.selectedQuotation = quotation;
    this.dataProvider.quotationId = quotation.quotationId;
    this.dataProvider.numberReferenceQuotation = quotation.number;
    this.dataProvider.isNewQuotation = false;
    this.dataProvider.isAdminPriceDiscount = false;
    this.dataProvider.quotationStatus = this.selectedQuotation.quotationStatus;
    this.dataProvider.sendBusinessManagerQuotation  = quotation.sendBusinessManager;
    this.defaultConfigurationService.showAdminPriceDiscount = false;

    this.quotationPresenter.findQuotationAndCreateInvoice();
  }
  findQuotationAndCreateInvoice(quotation: QuotationModel) {
    this.procesesSelected = ProcessModel.INVOICE;

    this.processQuotationOrInvoice(quotation);
  }
  findQuotationAndUpdateQuotation(quotation: QuotationModel) {
    this.procesesSelected = ProcessModel.QUOTATION;

    this.processQuotationOrInvoice(quotation);
  }
  findQuotationAndPrint(quotation: QuotationModel) {
    this.selectedQuotation = quotation;
    this.quotationPresenter.getPdf();
  }
  showNewQuotationConfirmation() {
/*     Swal.fire({
      title: 'Productos no disponibles',
      text: '¿Generar nueva cotización ?',
      confirmButtonColor: '#00687d',
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      confirmButtonText: 'Continuar y generar nueva cotización',
      cancelButtonText: 'Continuar sin generar nueva cotización',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.quotationPresenter.newQuotation();
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
        this.dataProvider.isNewQuotation = true;
        this.quotationPresenter.setSelectedQuotationToProcess('QUOTATION');
      }
    });*/
  }
  translateQuotationStatus(quotationStatus: string): string {
     return this.functionsQuotation.translateQuotationStatus(quotationStatus);
  }

  setTooltipQuotationStation(quotationStatus: string ) {
      return this.functionsQuotation.setTooltipQuotationStation(quotationStatus);
  }
  validateInvoicing(quotation: QuotationModel): boolean {
      let valid = true;
      switch (quotation.quotationStatus) {
        case TypeStatusQuotation.TO_BE_INVOICED:
        case TypeStatusQuotation.APPROVED:
            valid = false;
            break;
      }
      return valid;
  }

  syncroniceQuotation(quotation: QuotationModel) {
     this.quotationPresenter.resynchronizeQuotation(quotation.quotationId);
  }
}
