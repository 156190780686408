import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';

import { Product } from 'src/app/models/product.model';

import { GenerateCreditNoteView } from '../generate-credit-note.view';
import { MeasureConversionComboModel } from 'src/app/models/measure-combo.model';
import { MeasureConversionModel } from 'src/app/models/measure-conversion.model';
import { InvoiceLine, InvoiceLineToSave } from 'src/app/models/orderline.model';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { CreditMemoService } from 'src/app/services/credit-memo.service';
import { CreditMemoDetailModel, CreditMemoModel, ReasonCreditMemoPresenter } from 'src/app/models/creditmemo.model';
import { HttpErrorResponse } from '@angular/common/http';
import Decimal from 'decimal.js';
import { GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';
import { TaxService } from 'src/app/services/tax.service';
import { Tax } from 'src/app/models/tax.model';


@Injectable({
    providedIn: 'root',
})
export class GenerateCreditNotePresenter implements Presenter {
    view: GenerateCreditNoteView;

    constructor(
        private settingPresenter: SettingPresenter,
        private creditMemoService: CreditMemoService,
        private taxService: TaxService

    ) {
    }
    getCreditMemoReasons() {
        this.creditMemoService.getCreditMemoReasons().subscribe((reasons: ReasonCreditMemoPresenter[]) => {
            this.view.reasons = reasons;
            this.view.reasonsCopy = reasons;
            this.validateReasons();
        }
        );
    }

    fillLines() {
        this.view.invoice.invoiceDetailPresenters.forEach(line => {
            const lineToPush: InvoiceLine = {
                invoiceDetailId: line.invoiceDetailId,
                product: line.product,
                convertedQuantity: (line.discountPromotion || line.discountRecycling) && !line.product.acopioToDispatch ?
                    line.convertedQuantity : 0,
                enteredQuantity: (line.discountPromotion || line.discountRecycling) && !line.product.acopioToDispatch ?
                    line.enteredQuantity : 0,
                unitPrice: (line.discountPromotion || line.discountRecycling) && !line.product.acopioToDispatch ?
                    new Decimal(line.subtotal).div(line.enteredQuantity).toDecimalPlaces(6).toNumber() : line.unitPrice,
                tax: line.tax,
                lineNumber: line.lineNumber,
                unitPriceWithTax: line.unitPriceWithTax,
                subtotal: (line.discountPromotion || line.discountRecycling) && !line.product.acopioToDispatch ? line.subtotal : 0,
                total: (line.discountPromotion || line.discountRecycling) && !line.product.acopioToDispatch ? line.total : 0,
                measureUnitConversionPresenter: this.getDefault(line.product, line.measureUnitConversionPresenter),
                invoicedMeasureUnit: this.getDefault(line.product, line.measureUnitConversionPresenter),
                measureCombo: this.getCombo(line.product),
                discountPromotion: line.discountPromotion,
                discountRecycling: line.discountRecycling,
                maxQuantity: new Decimal(line.product.availableQuantity).
                    mul(line.measureUnitConversionPresenter.multiplyRate).toDecimalPlaces(6).toNumber(),
                minToDispatch: new Decimal(line.product.minToDispatch).
                    mul(line.measureUnitConversionPresenter.multiplyRate).toDecimalPlaces(6).toNumber(),
                acopioToDispatch: new Decimal(line.product.acopioToDispatch).
                    mul(line.measureUnitConversionPresenter.multiplyRate).toDecimalPlaces(6).toNumber()
            };
            lineToPush.product.priceWithoutTax = lineToPush.unitPrice;
            this.view.lines.push(lineToPush);
        });
        this.calculateTotals();
    }



    private getCombo(product: Product): MeasureConversionComboModel[] {
        const comboMeasure: MeasureConversionComboModel[] = [];
        if (product.measureUnitConversionPresenters) {
            product.measureUnitConversionPresenters.forEach(unit => {
                comboMeasure.push({ label: unit.measureUnitPresenterTo.name, value: unit });
            });
        }
        return comboMeasure;
    }

    private getDefault(product: Product, measure: MeasureConversionModel): MeasureConversionModel {
        let returnValue: MeasureConversionModel = null;
        if (product.measureUnitConversionPresenters) {
            product.measureUnitConversionPresenters.forEach(unit => {
                if (unit.measureUnitPresenterTo.referenceId === measure.measureUnitPresenterTo.referenceId) {
                    returnValue = unit;
                    return returnValue;
                }
            });
        }
        return returnValue;

    }

    deleteLine() {
        this.view.lines = this.view.lines.filter(line =>
            line.product.productId !== this.view.selectedLine.product.productId
        );
        this.calculateTotals();
        this.validateReasons();
    }

    changeLine() {
        const line = this.view.selectedLine;
        line.enteredQuantity = Number(this.view.selectedLine.enteredQuantity);
        if (isNaN(this.view.selectedLine.enteredQuantity) ||
            this.view.selectedLine.enteredQuantity > line.maxQuantity) {
            this.view.selectedLine.enteredQuantity = line.maxQuantity;
            line.enteredQuantity = line.maxQuantity;
            this.view.showError('cantidad incorrecta', '');
        }
        line.convertedQuantity = new Decimal(line.enteredQuantity)
            .mul(line.measureUnitConversionPresenter.divideRate)
            .toNumber();

        this.calculateTotals();
        this.validateReasons();
    }

    calculateTotals() {

        let subtotal0 = 0;
        let subtotal12 = 0;
        let totalTax = 0;
        let subtotal5 = 0;
        let totalTax5 = 0;
        let subtotal15 = 0;
        let totalTax15 = 0;

        this.view.lines.forEach(line => {
            if (line.enteredQuantity < line.minToDispatch) {
                line.enteredQuantity = line.minToDispatch;
                line.convertedQuantity = new Decimal(line.enteredQuantity)
                    .mul(line.measureUnitConversionPresenter.divideRate)
                    .toNumber();
            }
            let tax = new Decimal(line.subtotal).mul(line.tax.value).toDecimalPlaces(2).toNumber();
            if (!line.discountPromotion && !line.discountRecycling) {
                line.subtotal = new Decimal(line.enteredQuantity).mul(line.unitPrice).toDecimalPlaces(2).toNumber();
                tax = new Decimal(line.subtotal).mul(line.tax.value).toDecimalPlaces(2).toNumber();
                line.total = new Decimal(line.subtotal).add(tax).toNumber();
            }

            if (line.tax.value !== 0 && line.tax.value === 0.12) {
                subtotal12 = new Decimal(subtotal12)
                    .add(line.subtotal)
                    .toNumber();
            } else if (line.tax.value !== 0 && line.tax.value === 0.15) {
                subtotal15 = new Decimal(subtotal15)
                    .add(line.subtotal)
                    .toNumber();
            } else if (line.tax.value !== 0 && line.tax.value === 0.05) {
                subtotal5 = new Decimal(subtotal5)
                    .add(line.subtotal)
                    .toNumber();
            } else {
                subtotal0 = new Decimal(subtotal0)
                    .add(line.subtotal)
                    .toNumber();
            }
        });
        totalTax = new Decimal(subtotal12)
            .mul(0.12)
            .toDecimalPlaces(2)
            .toNumber();
        totalTax15 = new Decimal(subtotal15)
            .mul(0.15)
            .toDecimalPlaces(2)
            .toNumber();
        totalTax5 = new Decimal(subtotal5)
            .mul(0.05)
            .toDecimalPlaces(2)
            .toNumber();

        this.view.subtotal0 = subtotal0;
        this.view.subtotal12 = subtotal12;
        this.view.subtotal15 = subtotal15;
        this.view.subtotal5 = subtotal5;
        this.view.tax = totalTax;
        this.view.tax15 = totalTax15;
        this.view.tax5 = totalTax5;
        this.view.total = new Decimal(this.view.subtotal0).add(this.view.subtotal12)
            .add(this.view.subtotal5).add(this.view.subtotal15).add(this.view.tax)
            .add(this.view.tax5).add(this.view.tax15).toNumber();
    }
    validateReasons() {
        this.view.reasons = null;
        this.view.selectedReason = null;
        const dispatch = this.view.lines.filter(line => line.enteredQuantity !== line.minToDispatch).length;
        const reason = this.view.reasonsCopy.filter(re => re.referenceId === '1000067');

        if (!dispatch) {
            this.view.reasons = reason;
            this.view.selectedReason = reason[0];
        } else {
            this.view.reasons = this.view.reasonsCopy;
        }

    }

    validateBilleton() {

        const billetonAmount = new Decimal(this.view.invoice.orderPresenter.billetonAmount || 0).toDecimalPlaces(2).toNumber();
        const apportion = new Decimal(billetonAmount)
            .div(new Decimal(this.view.invoice.subtotal || 0)
                .add(billetonAmount))
            .toDecimalPlaces(10)
            .toNumber();

        if (billetonAmount > 0) {
            this.view.lines.forEach(line => {

                line.unitPrice = new Decimal(line.unitPrice)
                    .minus(new Decimal(apportion)
                        .mul(line.unitPrice)).toDecimalPlaces(6).toNumber();
                line.unitPrice = new Decimal(line.unitPrice).toDecimalPlaces(6).toNumber();

            });
        }
    }



    saveCredirMemo() {
        const linesToSave: CreditMemoDetailModel[] = [];
        this.view.lines.forEach(line => {
            const invoiceDetail: InvoiceLineToSave = {
                invoiceDetailId: line.invoiceDetailId,
                convertedQuantity: line.convertedQuantity,
                enteredQuantity: line.enteredQuantity,
                lineNumber: line.lineNumber,
                measureUnitConversionPresenter: line.measureUnitConversionPresenter,
                product: line.product,
                tax: line.tax,
                total: line.subtotal,
                unitPrice: line.unitPrice,
                unitPriceWithTax: line.unitPriceWithTax
            };
            const linetoPush: CreditMemoDetailModel = {
                convertedQuantity: line.convertedQuantity,
                enteredQuantity: line.enteredQuantity,
                creditMemoDetailId: null,
                invoiceDetailPresenter: invoiceDetail,
                lineNumber: line.lineNumber,
                measureUnitConversionPresenter: line.measureUnitConversionPresenter,
                product: line.product,
                tax: line.tax,
                total: line.total,
                subtotal: line.subtotal,
                unitPrice: line.unitPrice,
                unitPriceWithTax: line.unitPriceWithTax
            };

            if (linetoPush.enteredQuantity > 0) {
                linesToSave.push(linetoPush);
            }

        });

        const creditMemo: CreditMemoModel = {
            accessKey: null,
            clientPresenter: this.view.invoice.clientPresenter,
            creditMemoDetailPresenters: linesToSave,
            creditMemoId: null,
            emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
            invoicePresenter: this.view.invoice,
            observation: this.view.invoice.observation,
            paymentMethod: null,
            paymentTermPresenter: null,
            pdf: null,
            priceListPresenter: this.view.invoice.priceListPresenter,
            sequential: null,
            subtotal: this.view.subtotal0 + this.view.subtotal12 + this.view.subtotal5
                + this.view.subtotal15,
            subtotalTaxed: this.view.subtotal12 + this.view.subtotal15 + this.view.subtotal5,
            subtotalZeroTax: this.view.subtotal0,
            tax: this.view.tax + this.view.tax5 + this.view.tax15,
            total: this.view.total,
            transactionDate: null,
            reasonCreditMemoPresenter: this.view.selectedReason

        };
        this.view.blockUi();

        this.creditMemoService.saveCreditMemo(creditMemo).then(data => {
            this.view.showSuccess('', 'Nota de crédito generada con éxito. Pendiente aprobación');
            this.view.closeView();
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

    validateCreditMemo() {
        let quantity = 0;
        this.view.lines.forEach(line => {
            if (line.enteredQuantity > 0) {
                quantity += line.enteredQuantity;
            }

        });
        if (quantity <= 0) {
            this.view.showError('', 'Debe ingresar una cantidad para generar una nota de crédito');
            return;
        }

        if (!this.view.invoice.observation || this.view.invoice.observation.length < 4) {
            this.view.showError('', 'Debe ingresar una observación');
            return;
        }
        if (!this.view.selectedReason) {
            this.view.showError('', 'Debe seleccionar una razón');
            return;
        }
        this.view.showConfirmation();

    }

    getDefaultTax() {
        // cambiar fecha nuevo iva
        const startDate: Date = new Date('2024-04-01');
        const invoiceDate: Date = new Date(this.view.invoice.transactionDate);
        this.taxService.getDefaultTax().subscribe((tax: Tax) => {
            if (invoiceDate < startDate) {
                this.view.invoiceIsBefore = true;
                this.view.defaultTax = 0.12;
            } else {
                this.view.invoiceIsBefore = false;
                this.view.defaultTax = tax.value;
            }
        }, (error: HttpErrorResponse) => {
            this.view.showError('', error.error.message);
        });
    }


}
