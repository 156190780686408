import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { OpenCashRegisterView } from './open-cash-register.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { MessageService, DynamicDialogRef } from 'primeng/api';
import { UiUtils } from 'src/app/core/ui-utils';
import { OpenCashRegisterPresenter } from './presenter/open-cash-register.presenter';
import { Subscription, interval } from 'rxjs';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-open-cash-register',
  templateUrl: './open-cash-register.component.html',
  styleUrls: ['./open-cash-register.component.scss']
})
export class OpenCashRegisterComponent extends AbstractView implements OnInit, OpenCashRegisterView, AfterViewInit {

  @ViewChild('txtAmount', { read: false, static: false }) txtAmount: ElementRef;

  amount = null;
  observation: string;
  hour = new Date();
  constructor(
    private ref: DynamicDialogRef,
    public service: MessageService,
    public router: Router,
    public util: UiUtils,
    private openCashRegisterPresenter: OpenCashRegisterPresenter
  ) {
    super(service, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_CASH_OPEN)) {
      this.showError('', 'Sin autorización');
      this.ref.close();
    }
    this.openCashRegisterPresenter.view = this;
    const source = interval(1000);
    source.subscribe(() => this.hour = new Date());
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.txtAmount.nativeElement.focus();
  }
  validate() {
    this.openCashRegisterPresenter.validate();
    // this.openCashRegisterPresenter.connectionCpos(); //Apertura automática al dispositivo
  }
  closeModal() {
    this.ref.close();
  }
  focusObservation(element: any) {
    element.focus();
  }
}
