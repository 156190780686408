import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { OpenCashRegisterView } from '../open-cash-register/open-cash-register.view';
import { DynamicDialogRef, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UiUtils } from 'src/app/core/ui-utils';
import { OpenCashRegisterPresenter } from '../open-cash-register/presenter/open-cash-register.presenter';
import { interval } from 'rxjs';
import { GenerateAdvancePresenter } from './presenter/generate-advance.presenter';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-generate-advance',
  templateUrl: './generate-advance.component.html',
  styleUrls: ['./generate-advance.component.scss']
})
export class GenerateAdvanceComponent extends AbstractView implements OnInit, OpenCashRegisterView, AfterViewInit {

  @ViewChild('txtAmount', { read: false, static: false }) txtAmount: ElementRef;

  amount = null;
  observation: string;
  hour = new Date();
  constructor(
    private ref: DynamicDialogRef,
    public service: MessageService,
    public router: Router,
    public util: UiUtils,
    private generateAdvancePresenter: GenerateAdvancePresenter
  ) {
    super(service, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_REALIZE_ADVANCES)) {
      this.showError('', 'Sin autorización');
      this.ref.close();
    }
    this.generateAdvancePresenter.view = this;
    const source = interval(1000);
    source.subscribe(() => this.hour = new Date());
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.txtAmount.nativeElement.focus();
  }
  validate() {
    this.generateAdvancePresenter.validate();
  }
  closeModal() {
    this.ref.close();
  }
}
