import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AdvanceService {
  status = 'Debe abrir caja';
  iconColor = 'orange';

  constructor(private http: HttpClient) { }
  public getAdvanceStatusHttp(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get<boolean>(environment.apiUrl + '/getAdvanceStatus')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  advanceStatus() {
    this.getAdvanceStatusHttp().then(data => {
      if (data) {
        this.iconColor = 'red';
        this.status = 'Avance pendiente';
      } else {
        this.iconColor = 'green';
        this.status = '';
      }
    }).catch((error) => {
      /* istanbul ignore else */
      if (error instanceof HttpErrorResponse) {
        /* istanbul ignore if */
        if (error.error.message === 'No hay una caja abierta') {
          this.iconColor = 'orange';
          this.status = 'Debe abrir caja';
        }
      }

    });
  }
}

