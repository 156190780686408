import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { LoginComponent } from './main-components/single/login/login.component';
import { PanelMasterComponent } from './main-components/panel/panelmaster.component';
import { AuthGuard } from './core/auth.guard';
import { PosComponent } from './main-components/panel/pages/pos/pos.component';
import { SearchInvoiceComponent } from './main-components/panel/pages/invoice/search-invoice/search-invoice.component';
import { SearchCreditMemoComponent } from './main-components/panel/pages/credit-memo/search-credit-memo/search-credit-memo.component';
import { AproveCreditMemoComponent } from './main-components/panel/pages/credit-memo/aprove-credit-memo/aprove-credit-memo.component';
import { CreditMemoVoidComponent } from './main-components/panel/pages/credit-memo/credit-memo-void/credit-memo-void.component';
import { AproveCashReturnComponent } from './main-components/panel/pages/credit-memo/aprove-cash-return/aprove-cash-return.component';
import { CreditCardReportComponent } from './main-components/panel/pages/dashboard/credit-card-report/credit-card-report.component';
import { StockReportComponent } from './main-components/panel/pages/dashboard/stock/stock-report/stock-report.component';
import { KardexReportComponent } from './main-components/panel/pages/dashboard/stock/kardex-report/kardex-report.component';
import { StockComponent } from './main-components/panel/pages/dashboard/stock/stock/stock.component';
import { SalesReportsComponent } from './main-components/panel/pages/dashboard/sales-report/sales-reports/sales-reports.component';
import { ChartsComponent } from './main-components/panel/pages/dashboard/sales-report/charts/charts.component';
import {
    ProductCategorySalesReportComponent
} from './main-components/panel/pages/dashboard/sales-report/product-category-sales-report/product-category-sales-report.component';
import {
    CreateTranfersComponent
} from './main-components/panel/pages/dashboard/tranfers/components/create-transfers/create-tranfers.component';
import { TransfersComponent } from './main-components/panel/pages/dashboard/tranfers/components/transfers/transfers.component';
import {
    SearchTransfersComponent
} from './main-components/panel/pages/dashboard/tranfers/components/search-transfers/search-transfers.component';
import {
    TransfersReportComponent
} from './main-components/panel/pages/dashboard/tranfers/components/transfers-report/transfers-report.component';
import {
    SearchSupplyingComponent
} from './main-components/panel/pages/dashboard/tranfers/components/search-supplying/search-supplying.component';
import {
    StockReportAcopioComponent
} from './main-components/panel/pages/dashboard/stock/stock-report-acopio/stock-report-acopio.component';
import { ForgetPasswordComponent } from './main-components/single/forget-password/forget-password.component';
import { LoginGuardService } from './core/login-guard.service';
import { ResetPasswordComponent } from './main-components/single/reset-password/reset-password.component';
import { InvoicesPendingComponent } from './main-components/panel/pages/invoice/invoices-pending/invoices-pending.component';
import { InvoicesComponent } from './main-components/panel/pages/invoice/invoices/invoices.component';
import { ClientMenuComponent } from './main-components/panel/pages/client/client-menu/client-menu.component';
import {
    ReportClientsByProductsComponent
} from './main-components/panel/pages/client/report-clients-by-products/report-clients-by-products.component';
import {
    ReceiptsPaymentsComponent
} from './main-components/panel/pages/dashboard/receipts-payments/receipts-payments-process/receipts-payments.component';
import {
    ReceiptsPaymentsMenuComponent
} from './main-components/panel/pages/dashboard/receipts-payments/receipts-payments-menu/receipts-payments-menu.component';
import {
    ReceiptsPaymentsHistoricComponent
} from './main-components/panel/pages/dashboard/receipts-payments/receipts-payments-historic/receipts-payments-historic.component';
import { CreditMemoComponent } from './main-components/panel/pages/credit-memo/credit-memo/credit-memo.component';
import { SearchReportComponent } from './main-components/panel/pages/dashboard/cash-register/search-report/search-report.component';
import { CashRegisterComponent } from './main-components/panel/pages/dashboard/cash-register/cash-register/cash-register.component';
import { PrintPriceComponent } from './main-components/panel/pages/general/print-price/print-price.component';
import { VaultManagmentComponent } from './main-components/panel/pages/general/vault/vault-managment.component';
import { GeneralComponent } from './main-components/panel/pages/general/general/general.component';
import {
    ReceiptsPaymentsReportComponent
} from './main-components/panel/pages/dashboard/receipts-payments/receipts-payments-report/receipts-payments-report.component';
import {
    ReceiptsPaymentsDailyComponent
} from './main-components/panel/pages/dashboard/receipts-payments/receipts-payments-daily/receipts-payments-daily.component';
// tslint:disable-next-line: max-line-length
import { ProductSupplierReportComponent } from './main-components/panel/pages/dashboard/sales-report/product-supplier-report/product-supplier-report.component';
import { OpenDayReportComponent } from './main-components/panel/pages/dashboard/cash-register/open-day-report/open-day-report.component';
import {
    ClientAdvanceReportComponent
} from './main-components/panel/pages/dashboard/cash-register/client-advance-report/client-advance-report.component';
import {
    ValuedStockReportComponent
} from './main-components/panel/pages/dashboard/stock/valued-stock-report/valued-stock-report.component';
import {
    ReportPricesSalesComponent
} from './main-components/panel/pages/dashboard/sales-report/report-prices-sales/report-prices-sales.component';
import { ProductHistoryComponent } from './main-components/panel/pages/dashboard/sales-report/product-history/product-history.component';
import {
    SearchSalesDepositComponent
} from './main-components/panel/pages/dashboard/cash-register/search-sales-deposit/search-sales-deposit.component';
import { UsersAdminComponent } from './main-components/panel/pages/dashboard/users-admin/users-admin/users-admin.component';
import { AssingPricesComponent } from './main-components/panel/pages/dashboard/users-admin/assing-prices/assing-prices.component';
import { ReportCommissionComponent } from './main-components/panel/pages/dashboard/sales-report/report-commission/report-commission.component';
export const routes: Routes =
    [
        { path: '', component: LoginComponent },
        { path: 'forgot-password', component: ForgetPasswordComponent, canActivate: [LoginGuardService] },
        { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [LoginGuardService] },
        {
            path: 'panel', component: PanelMasterComponent, canActivate: [AuthGuard],
            children: [
                { path: 'pos', component: PosComponent, canActivate: [AuthGuard] },
                { path: 'search-credit-memos', component: SearchCreditMemoComponent, canActivate: [AuthGuard] },
                { path: 'close-day-report', component: SearchReportComponent, canActivate: [AuthGuard] },
                { path: 'open-day-report', component: OpenDayReportComponent, canActivate: [AuthGuard] },
                { path: 'client-advance-report', component: ClientAdvanceReportComponent, canActivate: [AuthGuard] },
                { path: 'search-sales-deposit', component: SearchSalesDepositComponent, canActivate: [AuthGuard] },
                { path: 'assing-prices', component: AssingPricesComponent, canActivate: [AuthGuard] },
                {
                    path: 'cash-register', component: CashRegisterComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'close-day-report', component: SearchReportComponent, canActivate: [AuthGuard] },
                        { path: 'open-day-report', component: OpenDayReportComponent, canActivate: [AuthGuard] },
                        { path: 'search-sales-deposit', component: SearchSalesDepositComponent, canActivate: [AuthGuard] },
                        { path: 'client-advance-report', component: ClientAdvanceReportComponent, canActivate: [AuthGuard] }
                    ]
                },
                {
                    path: 'users-admin', component: UsersAdminComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'assing-prices', component: AssingPricesComponent, canActivate: [AuthGuard] }]
                },

                {
                    path: 'graficas', component: SalesReportsComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'payment_method-report', component: ChartsComponent, canActivate: [AuthGuard] },
                        { path: 'product-category-report', component: ProductCategorySalesReportComponent, canActivate: [AuthGuard] },
                        { path: 'product-supplier-report', component: ProductSupplierReportComponent, canActivate: [AuthGuard] },
                        { path: 'credit-card-report', component: CreditCardReportComponent, canActivate: [AuthGuard] },
                        { path: 'report-prices-sales', component: ReportPricesSalesComponent, canActivate: [AuthGuard] },
                        { path: 'product-history', component: ProductHistoryComponent, canActivate: [AuthGuard] },
                        { path: 'report-commission', component: ReportCommissionComponent, canActivate: [AuthGuard] },
                    ]
                },
                { path: 'aprove-credit-memo', component: AproveCreditMemoComponent, canActivate: [AuthGuard] },
                { path: 'credit-memo-void', component: CreditMemoVoidComponent, canActivate: [AuthGuard] },
                { path: 'aprove-cash-return', component: AproveCashReturnComponent, canActivate: [AuthGuard] },
                { path: 'print-actual-prices', component: PrintPriceComponent, canActivate: [AuthGuard] },
                { path: 'vault-managment', component: VaultManagmentComponent, canActivate: [AuthGuard] },
                { path: 'credit-card-report', component: CreditCardReportComponent, canActivate: [AuthGuard] },
                {
                    path: 'credit-memos', component: CreditMemoComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'search-credit-memos', component: SearchCreditMemoComponent, canActivate: [AuthGuard] },
                        { path: 'aprove-credit-memo', component: AproveCreditMemoComponent, canActivate: [AuthGuard] },
                        { path: 'credit-memo-void', component: CreditMemoVoidComponent, canActivate: [AuthGuard] },
                        { path: 'aprove-cash-return', component: AproveCashReturnComponent, canActivate: [AuthGuard] },
                    ]
                },
                {
                    path: 'transfers', component: TransfersComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'create-transfer', component: CreateTranfersComponent, canActivate: [AuthGuard] },
                        { path: 'my-transfers', component: SearchTransfersComponent, canActivate: [AuthGuard] },
                        { path: 'for-me', component: TransfersReportComponent, canActivate: [AuthGuard] },
                        { path: 'supplying', component: SearchSupplyingComponent, canActivate: [AuthGuard] },

                    ]
                },
                {
                    path: 'manage', component: GeneralComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'print-actual-prices', component: PrintPriceComponent, canActivate: [AuthGuard] },
                        { path: 'vault-managment', component: VaultManagmentComponent, canActivate: [AuthGuard] },
                    ]
                },
                {
                    path: 'stock', component: StockComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'stock-report', component: StockReportComponent, canActivate: [AuthGuard] },
                        { path: 'kardex-report', component: KardexReportComponent, canActivate: [AuthGuard] },
                        { path: 'stock-report-acopio', component: StockReportAcopioComponent, canActivate: [AuthGuard] },
                        { path: 'valued-stock-report', component: ValuedStockReportComponent, canActivate: [AuthGuard] }
                    ]
                },
                {
                    path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'search-invoices', component: SearchInvoiceComponent, canActivate: [AuthGuard] },
                        { path: 'invoices-pending', component: InvoicesPendingComponent, canActivate: [AuthGuard] },
                    ]
                },
                {
                    path: 'clients', component: ClientMenuComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'report-clients', component: ReportClientsByProductsComponent, canActivate: [AuthGuard] },
                    ]
                },
                {
                    path: 'transactions', component: ReceiptsPaymentsMenuComponent, canActivate: [AuthGuard],
                    children: [
                        { path: 'search', component: ReceiptsPaymentsComponent, canActivate: [AuthGuard] },
                        { path: 'report', component: ReceiptsPaymentsReportComponent, canActivate: [AuthGuard] },
                        { path: 'historic', component: ReceiptsPaymentsHistoricComponent, canActivate: [AuthGuard] },
                        { path: 'daily-report', component: ReceiptsPaymentsDailyComponent, canActivate: [AuthGuard] },
                    ]
                },

            ]
        },
        { path: '**', pathMatch: 'full', redirectTo: '/panel/pos' },
    ]
    ;

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
