import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { Presenter } from 'src/app/core/presenter';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { InvoiceStatus } from 'src/app/models/invoice.status';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';

import { InvoicesPendingView } from '../invoices-pending.view';

@Injectable({
    providedIn: 'root',
})
export class InvoicesPendingPresenter implements Presenter {
    view: InvoicesPendingView;
    constructor(
        public clientService: ClientService,
        public invoiceService: InvoiceService
    ) {}
    findInvoices() {
        this.view.visible = false;
        let initDate = '';
        let endDate = '';
        let status = '';
        let docNo = '';
        const page = this.view.page;
        const size = this.view.size;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (this.view.documentNo) {
            docNo = this.view.documentNo;
        }
        if (
            this.view.invoiceStatusSelected &&
            this.view.invoiceStatusSelected !== InvoiceStatus.EMPTY
        ) {
            status = this.view.invoiceStatusSelected;
        }

        this.view.blockUi();
        this.invoiceService
            .findInvoicesNotDispatched(page, size, initDate, endDate, status)
            .subscribe(
                (data: any) => {
                    this.view.invoices = [];
                    this.view.totalElements = 0;
                    if (data.data) {
                        this.view.visible = true;
                        this.view.invoices = data.data;
                        this.view.totalElements = data.totalElements;
                    } else {
                        this.view.visible = false;
                        this.view.showInfo('', 'Búsqueda sin registros');
                    }
                },
                (error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.view.showError('Atención', error.error.message);
                    } else {
                        this.view.showError('Atención', 'Algo salio mal');
                    }
                }
            );
    }

    getPdf() {
        this.view.blockUi();
        this.invoiceService
            .getPdf(this.view.selectedInvoice.invoiceId)
            .pipe(timeout(300000))
            .subscribe(
                (data) => {
                    this.view.showPdf(data);
                },
                () => {
                    this.view.allowUi();
                    this.view.showError('', 'Error imprimiendo factura');
                }
            );
    }
    getInvoiceAndDispatch() {
        if (this.view.selectedInvoice.isExpired) {
            this.view.showError('Error', 'Factura expirada');
            return;
        }
        this.view.blockUi();
        this.invoiceService
            .getInvoiceAndDIspatch(this.view.selectedInvoice.invoiceId)
            .then((dispatch) => {
                if (dispatch.dispatchDetailPresenters.length) {
                    dispatch.totalWithoutDispatch =
                        dispatch.invoicePresenter.paymentLinePresenters.filter(
                            (line) =>
                                line.paymentMethod ===
                                PaymentMethod.TOTAL_WITHOUT_DISPATCH
                        ).length > 0;
                    dispatch.disableDispatch =
                        !dispatch.invoicePresenter.isPaid &&
                        dispatch.totalWithoutDispatch;
                    this.view.showDispatchComponent(dispatch);
                } else {
                    this.view.showError(
                        'Factura despachada en su totalidad',
                        ''
                    );
                }
            })
            .catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salio mal');
                }
            });
    }

    cancelInvoice() {
        this.view.blockUi();
        this.invoiceService.cancelInvoice(this.view.selectedInvoice).subscribe(
            (res) => {
                this.view.allowUi();
                this.view.showSuccess('', 'Factura cancelada');
                this.view.selectedInvoice.isExpired = true;
            },
            () => {
                this.view.allowUi();
                this.view.showError('', 'Ocurrió un anulando la factura');
            }
        );
    }
    getInvoiceAndGenerateCreditMemo() {
        if (this.view.selectedInvoice.isExpired) {
            this.view.showError('Error', 'Factura expirada');
            return;
        }
        this.view.blockUi();
        this.invoiceService
            .verifyAcopio(this.view.selectedInvoice.invoiceId)
            .subscribe(
                (data: boolean) => {
                    if (data) {
                        this.getInvoice();
                    }
                },
                (error: HttpErrorResponse) => {
                    if (error) {
                        this.view.showError('Atención', error.error.message);
                    } else {
                        this.view.showError('Atención', 'Algo salio mal');
                    }
                }
            );
    }
    getInvoice() {
        this.view.blockUi();
        this.invoiceService
            .getInvoice(this.view.selectedInvoice.invoiceId)
            .subscribe(
                (invoice: InvoiceModel) => {
                    let ac = 0;
                    invoice.invoiceDetailPresenters.forEach((line) => {
                        if (line.product.availableQuantity > 0) {
                            ac = line.product.availableQuantity;
                        }
                    });
                    if (ac > 0) {
                        invoice.observation = null;
                        this.view.showCreditNoteComponent(invoice);
                    } else {
                        this.view.showError(
                            'Factura devuelta en su totalidad',
                            ''
                        );
                    }
                },
                (error: HttpErrorResponse) => {
                    if (error) {
                        this.view.showError('Atención', error.error.message);
                    } else {
                        this.view.showError('Atención', 'Algo salio mal');
                    }
                }
            );
    }
    printInvoiceReport() {
        let initDate = '';
        let endDate = '';
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        this.view.blockUi();
        this.invoiceService.printInvoicesPending(initDate, endDate).subscribe(
            (res: any) => {
                this.view.showPdf(res);
            },
            (error: HttpErrorResponse) => {
                this.view.invoices = [];
                this.view.showError('Error', error.error.message);
            }
        );
    }
}
