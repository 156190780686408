import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';
import { ManageClientView } from '../manage-client.view';
import { CountryService } from 'src/app/services/country.service';
import { Country } from 'src/app/models/country.model';
import { StateService } from 'src/app/services/state.service';
import { CityService } from 'src/app/services/city.service';
import { SectorService } from 'src/app/services/sector.service';
import { IdentificationService } from 'src/app/services/identification.service';
import { TaxPayerService } from 'src/app/services/taxpayer.service';
import { IdentificationType, IdentificationPattern } from 'src/app/models/identificationtype.model';
import { TaxPayerType } from 'src/app/models/taxpayertype.model';
import { State } from 'src/app/models/state.model';
import { City } from 'src/app/models/city.model';
import { Sector } from 'src/app/models/sector.model';
import { ClientModel } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { PaymentTerm } from 'src/app/models/paymentterm';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { PaymentTermService } from 'src/app/services/paymentterm.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Profession } from 'src/app/models/profession.model';


@Injectable({
    providedIn: 'root',
})
export class ManageClientPresenter implements Presenter {
    view: ManageClientView;

    constructor(
        private identificationService: IdentificationService,
        private taxPayerService: TaxPayerService,
        private countryService: CountryService,
        private stateService: StateService,
        private cityService: CityService,
        private sectorService: SectorService,
        private clientService: ClientService,
        private paymentTermService: PaymentTermService
    ) {
    }

    loadInitData() {
        this.loadIdentificationTypes();
        this.loadTaxPayerTypes();
        this.loadCountries();
        this.loadPaymentTerm();
        this.loadPaymentMethod();
        this.loadProfessions();
    }


    public loadIdentificationTypes() {

        this.identificationService.searchIdentificationType().then(identifications => {
            const identificationsToView: { label: string, value: IdentificationType }[] = [];
            identifications.forEach(identification => {
                identificationsToView.push({ label: identification.description, value: identification });
            });
            this.view.identificationType = identificationsToView;
            if (!isNaN(Number(this.view.dni)) && (this.view.dni.length === 10 || this.view.dni.length === 13)) {
                this.view.identificationType.forEach(idtype => {
                    if (this.view.dni.length === 10 && idtype.value.identificationPattern === IdentificationPattern.IDENTIFICATION_CARD) {
                        this.view.identificationTypeSelected = idtype.value;
                    } else if (this.view.dni.length === 13 &&
                        idtype.value.identificationPattern === IdentificationPattern.RUC) {
                        this.view.identificationTypeSelected = idtype.value;
                    }
                });
            } else {
                this.view.identificationTypeSelected = identificationsToView[0].value;
            }
            if (this.view.isClientUpdate) {
                this.view.setIdentificationClient();
            }
        }).catch((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    public loadTaxPayerTypes() {
        this.taxPayerService.searchTaxPayerType().then(taxPayers => {
            const taxPayerToView: { label: string, value: TaxPayerType }[] = [];
            taxPayers.forEach(taxpayer => {
                taxPayerToView.push({ label: taxpayer.description, value: taxpayer });
                if (taxpayer.default) {
                    this.view.taxPayerTypeSelected = taxpayer;
                }
            });
            this.view.taxPayerType = taxPayerToView;
            if (this.view.isClientUpdate) {
                this.view.setTaxPayerClient();
            }
        }).catch(error => {
            this.view.showError('Atención', 'Ocurrió un error en la comunicación!');
        });
    }

    public loadPaymentTerm() {

        this.paymentTermService.searchPaymentTerm().then(paymentTerms => {
            const paymentTermsToView: { label: string, value: PaymentTerm }[] = [];
            paymentTerms.forEach(paymentTerm => {
                paymentTermsToView.push({ label: paymentTerm.description, value: paymentTerm });
            });

            this.view.paymentTerm = paymentTermsToView;
        }).catch(error => {
            this.view.showError('Atención', 'Ocurrió un error en la comunicación!');
        });
    }

    public loadCountries() {

        this.countryService.searchCountry().then(countries => {
            const countriesToView: { label: string, value: Country }[] = [];
            countries.forEach(country => {
                countriesToView.push({ label: country.name, value: country });
                if (country.isDefault) {
                    this.view.countrySelected = country;
                }
            });
            this.view.countries = countriesToView;
            this.loadStates();
        }).catch(error => {
            this.view.showError('Atención', 'Ocurrió un error en la comunicación!');
        });
    }

    public loadStates() {

        this.stateService.searchState(this.view.countrySelected.countryId).then(states => {
            const statesToView: { label: string, value: State }[] = [];
            states.forEach(state => {
                statesToView.push({ label: state.name, value: state });
                if (state.isDefault) {
                    this.view.stateSelected = state;
                }
            });
            this.view.states = statesToView;
            if (this.view.isClientUpdate) {
                this.view.setStateClient();
            }
            this.loadCities();
        }).catch(error => {
            this.view.showError('Atención', 'Ocurrió un error en la comunicación!');
        });
    }

    public loadCities() {
        this.cityService.searchCity(this.view.stateSelected.stateId).then(cities => {
            const citiesToView: { label: string, value: City }[] = [];
            cities.forEach(city => {
                citiesToView.push({ label: city.name, value: city });
                if (city.isDefault) {
                    this.view.citySelected = city;
                }
            });
            this.view.cities = citiesToView;
            if (this.view.isClientUpdate) { this.view.setCityClient(); }
            this.loadSectors();
        }).catch(error => {
            this.view.showError('Atención', 'Ocurrió un error en la comunicación!');
        });
    }

    public loadSectors() {
        this.sectorService.searchSector(this.view.citySelected.cityId).then(sectors => {
            const sectorsToView: { label: string, value: Sector }[] = [];
            sectors.forEach(sector => {
                sectorsToView.push({ label: sector.name, value: sector });
            });
            this.view.sectors = sectorsToView;
            if (this.view.isClientUpdate) { this.view.setSectorClient(); }
        }).catch(error => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');

            }
        });
    }


    public loadPaymentMethod() {

        const paymentMethod: { label: string, value: PaymentMethod }[] = [];
        paymentMethod.push({ label: 'EFECTIVO', value: PaymentMethod.CASH });
        paymentMethod.push({ label: 'CHEQUE', value: PaymentMethod.CHECK });
        paymentMethod.push({ label: 'CRÉDITO DISTRIBUIDORES', value: PaymentMethod.CREDIT });
        paymentMethod.push({ label: 'TARJETA DE CRÉDITO', value: PaymentMethod.CREDIT_CARD });
        paymentMethod.push({ label: 'TARJETA DE DÉBITO', value: PaymentMethod.DEBIT });
        paymentMethod.push({ label: 'NOTA DE CRÉDITO', value: PaymentMethod.CREDIT });
        this.view.paymentMethod = paymentMethod;
        this.view.paymentMethodSelected = PaymentMethod.CASH;

    }
    public loadProfessions() {

        this.clientService.loadProfessions().subscribe((res: Profession[]) => {
            this.view.professions = res;
            if (this.view.isClientUpdate) { this.view.setProfessionClient(); }
        }, (error: HttpErrorResponse) => {
            this.view.showError('Error al  buscar profesiones', error.error.message);
        });

    }

    evaluateFilter() {
        this.view.dni = '';
        if (this.view.identificationTypeSelected.identificationPattern === IdentificationPattern.RUC.valueOf()) {
            this.view.maxLenght = 13;
            this.view.filter = 'pint';
            return;
        } else {
            if (this.view.identificationTypeSelected.identificationPattern
                === IdentificationPattern.IDENTIFICATION_CARD.valueOf()) {
                this.view.maxLenght = 10;
                this.view.filter = 'pint';
                return;
            } else {
                this.view.maxLenght = 30;
                this.view.filter = '';
            }
        }

    }


    onSave() {

        const newClient: ClientModel = {
            clientId: this.view.isClientUpdate ? this.view.isClientUpdate['clientId'] : null,
            firstName: this.view.firstName,
            middleName: this.view.middleName,
            lastName: this.view.lastName,
            dni: this.view.dni,
            tradeName: this.view.tradeName,
            taxPayerTypePresenter: this.view.taxPayerTypeSelected,
            identificationTypePresenter: this.view.identificationTypeSelected,
            addressPresenter: this.view.address,
            email: this.view.mail,
            paymentTermPresenter: this.view.paymentTermSelected,
            paymentMethod: PaymentMethod.CASH,
            allowClientAdvance: this.view.allowClientAdvance,
            professionPresenter: this.view.professionSelected
        };

        if (!newClient.identificationTypePresenter) {
            this.view.showError('', 'Debe seleccionar un tipo de identificación');
            return;
        }

        if (!newClient.dni || newClient.dni.length <= 1) {
            this.view.showError('', 'Debe ingresar una identificación válida');
            return;
        }

        if (!newClient.firstName || newClient.firstName.length <= 1) {
            this.view.showError('', 'Debe ingresar un nombre válido');
            return;
        }
        if (
            this.view.identificationTypeSelected.identificationPattern !==
            IdentificationPattern.RUC.valueOf() &&
            (!newClient.lastName || newClient.lastName.length <= 1)
        ) {
            this.view.showError('', 'Debe ingresar un apellido válido');
            return;
        }
        if (!newClient.taxPayerTypePresenter) {
            this.view.showError('', 'Debe seleccionar un tipo de contribuyente');
            return;
        }

        if ((!newClient.addressPresenter.phone || newClient.addressPresenter.phone.length <= 5) &&
            (!newClient.addressPresenter.cellPhone || newClient.addressPresenter.cellPhone.length <= 5)) {
            this.view.showError('', 'Debe ingresar por lo menos un teléfono válido');
            return;
        }

        if (!newClient.addressPresenter.sectorPresenter) {
            this.view.showError('', 'Debe seleccionar una parroquia o sector');
            return;
        }



        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (!newClient.email || newClient.email.length <= 5 || !EMAIL_REGEXP.test(newClient.email)) {
            this.view.showError('', 'Debe ingresar un correo válido');
            return;
        }

        if (!newClient.addressPresenter.description || newClient.addressPresenter.description.length < 5) {
            this.view.showError('', 'Debe ingresar una dirección valida');
            return;
        }

        if (!newClient.paymentTermPresenter) {
            this.view.showError('', 'Debe seleccionar un término de pago');
            return;
        }

        if (
            this.view.identificationTypeSelected.identificationPattern ===
            IdentificationPattern.RUC.valueOf() &&
            (!newClient.lastName || newClient.lastName.length <= 1)
        ) {
            newClient.lastName = '  ';
        }

        newClient.firstName = newClient.firstName.toUpperCase();
        newClient.middleName = newClient.middleName.toUpperCase();
        newClient.lastName = newClient.lastName.toUpperCase();

        this.view.blockUi();
        this.clientService.saveClient(newClient).then(client => {
            this.view.closeView(client);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');

            }
        });

    }

    onSearchClient() {
        this.view.isClientSearch = false;
        let searchValue = this.view.search;
        let type = '';
        if (searchValue.length < 3) {
            return;
        }
        if (searchValue.length > 10) {
            searchValue = this.view.search.substring(0, 10);
        }
        this.clientService.searchClient(searchValue, 0, 25).then(data => {
            this.view.totalElements = data['totalElements'];
            type = data.data[0]['identificationTypePresenter']['description'];
            if (this.view.totalElements === 1 || this.view.totalElements === 2) {
                this.view.showError('Atención', `El usuario ya se encuentra registrado con ${type}`);
                this.view.isClientSearch = true;
            }
        });
    }

}
