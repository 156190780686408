import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { ReceiptsPaymentsService } from 'src/app/services/receipts-payments.service';
import { ReceiptsPaymentsDaily } from '../receipts-payments-daily.view';


@Injectable({
  providedIn: 'root',
})
export class ReceiptsPaymentsDailyPresenter implements Presenter {
  view: ReceiptsPaymentsDaily;

  constructor(private receiptsPaymentsService: ReceiptsPaymentsService) { }

  getPaymentCollections() {
    // return;
    const yesterday = new Date().getDate() - 1;
    if (this.view.initDate.getDate() > yesterday) {
      this.view.showWarn('Advertencia', 'Fecha inicio no puede ser mayor a fecha actual');
      this.view.initDate.setDate(this.view.initDate.getDate() - 1);
      return;
    }
    if (this.view.endDate.getDate() > yesterday) {
      this.view.showWarn('Advertencia', 'Fecha fin no puede ser mayor a fecha actual');
      this.view.endDate.setDate(this.view.endDate.getDate() - 1);
      return;
    }
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    let initDate = '';
    let endDate = '';
    initDate = formatDate(this.view.initDate, format, locale);
    endDate = formatDate(this.view.endDate, format, locale);
    this.view.blockUi();
    this.receiptsPaymentsService.getPaymentCollections(initDate, endDate, this.view.page, this.view.size).subscribe((res: any) => {
      this.view.paymentCollections = res.data;
      this.view.totalElements = res.totalElements;
    }, error => {
      this.view.showBackException(error);
    });
  }
  resynchronizeTransaction() {
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    this.view.selectedPaymentCollection.sendDate = formatDate(this.view.selectedPaymentCollection.sendDate, format, locale);
    this.view.blockUi();
    this.receiptsPaymentsService.resynchronizeTransaction(this.view.selectedPaymentCollection).subscribe((res: any) => {
      this.view.showSuccess('Éxito', 'Transacción reenviada');
    }, error => {
      this.view.showBackException(error);
    });
  }
  resynchronizeTransactions() {
    if (this.view.initDate.getDate() === this.view.endDate.getDate()) {
      this.view.showWarn('Advertencia', 'Fecha fin no puede ser igual a fecha de inicio');
      return;
    }
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    let initDate = '';
    let endDate = '';
    initDate = formatDate(this.view.initDate, format, locale);
    endDate = formatDate(this.view.endDate, format, locale);
    this.view.blockUi();
    this.receiptsPaymentsService.resynchronizeTransactions(initDate, endDate).subscribe((res: any) => {
      this.view.showSuccess('Éxito', res + ' transacciones reenviadas');
      this.getPaymentCollections();
    }, error => {
      this.view.showBackException(error);
    });
  }
}
