import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { State } from '../models/state.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient) { }

  public searchState(countryId: string): Promise<State[]> {
    return new Promise((resolve, reject) => {
      this.http.get<State[]>(environment.apiUrl + '/state?countryId=' + countryId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
