import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent extends AbstractView implements OnInit {
  navLinks = [];
  constructor(
    public router: Router,
    public service: MessageService

  ) {
    super(service, router);
  }
  ngOnInit() {
    this.navLinks = [
      {
        label: 'Reporte de movimientos',
        link: './for-me',
        index: 2,
        visible: true
      },
      {
        label: 'Movimientos pendientes ',
        link: './my-transfers',
        index: 1,
        visible: true
      },
      {
        label: 'Crear movimiento',
        link: './create-transfer',
        index: 0,
        visible: true
      },
      {
        label: 'Abastecimiento matriz',
        link: './supplying',
        index: 2,
        visible: true
      }];
  }

}
