import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/api';
import { Product } from 'src/app/models/product.model';
import { TransferDetailModel, TransferStatus } from 'src/app/models/transfer.mode';
import { DataProvider } from 'src/app/services/data.provider';
import Swal from 'sweetalert2';

import {
  bottomToTop,
  leftToLeft,
  righToLeft,
  rightToRight,
} from '../../../../../../../animations';
import { TransferProvider } from '../../transfer.provider';
import { AproveDevolutionComponent } from '../aprove-devolution/aprove-devolution.component';

@Component({
  animations: [leftToLeft, righToLeft, bottomToTop, rightToRight],
  selector: 'app-transfer-document',
  templateUrl: './transfer-document.component.html',
  styleUrls: ['./transfer-document.component.scss'],
})
export class TransferDocumentComponent implements OnInit {
  public ref;

  constructor(
    public transferProvider: TransferProvider,
    public dialogService: DialogService,
    public dataProvider: DataProvider,
  ) { }

  ngOnInit() {
    // this.saveAutomaticDevolution();
  }
  showAConfirmTransfer() {
    Swal.fire({
      text: '¿Desea confirmar la transferencia?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.value) {
        this.transferProvider.saveTransfer();
      }
    });
  }

  showGenerateCliam() {
    Swal.fire({
      text: '¿Desea generar el reclamo?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.value) {
        this.transferProvider.generateClaim();
      }
    });
  }
  showDevolutionComponent() {
    this.ref = this.dialogService.open(AproveDevolutionComponent, {
      header: 'COMFIRMAR DATOS DE DEVOLUCIÓN ',
      closeOnEscape: true,
      width: '400px',
      height: '350px',
      showHeader: true,
      closable: true
    });
    this.ref.onClose.subscribe((res: boolean) => {
      if (res) {
        this.showGenerateReturn();
      } else {
        this.transferProvider.showWarn('Advertencia', 'No se creó la devolución');
      }

    });

  }
  showGenerateReturn() {
    Swal.fire({
      text: '¿Desea generar la devolución?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.value) {
        this.transferProvider.deleteTransferDetail(TransferStatus.SENT);
      }
    });
  }

  saveDraft() {
    Swal.fire({
      text: '¿Desea guardar el borrador?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.value) {
        this.transferProvider.deleteTransferDetail(TransferStatus.DRAFT);
      }
    });
  }

  saveAutomaticDevolution() {
    this.transferProvider.saveAutomaticDevolution();
  }
  onSearchProduct(event) {
    this.transferProvider.searchProd = event.query;
    this.transferProvider.onSearchProductPos();
  }
  clearSearch() {
    this.transferProvider.selectedProductProd = null;
  }
  addProductToLine(product: Product, line: TransferDetailModel) {
    if (!product.productId) {
      return;
    }
    line.crossProductPresenter  = product;
    line.showSelectedCrossProduct = true;
    line.showcrossProduct = false;
    this.transferProvider.validateLineClaim(line);
  }
  clearCrossProduct(line: TransferDetailModel) {
    line.showSelectedCrossProduct = false;
    line.crossProductPresenter  = null;
    this.transferProvider.validateLineClaim(line);
  }
}
