import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { PaymentCollectionsErpPresenter } from '../models/payment-collections.model';

@Injectable({
  providedIn: 'root',
})
export class ReceiptsPaymentsService {
  public services: any = {};
  constructor(private http: HttpClient) { }

  login() {
    return this.http.get(
      environment.apiUrl + '/paymentCollectionsLoginTuenlace'
    );
  }
  getAvailableServices() {
    return this.http.get(environment.apiUrl + '/getAvailableServices');
  }
  sendMessage(sendMessageRequestPresenter: any) {
    return this.http.post(
      environment.apiUrl + '/sendMessage',
      sendMessageRequestPresenter
    );
  }
  searchTransactions(search: string) {
    return this.http.get(
      environment.apiUrl + '/searchTransactions?search=' + search
    );
  }
  cancelTransaction(transactionId: string) {
    return this.http.get(
      environment.apiUrl + '/cancelTransactionCP?transactionId=' + transactionId
    );
  }
  confirmTransaction(transactionId: string) {
    return this.http.get(
      environment.apiUrl + '/confirmTransaction?transactionId=' + transactionId
    );
  }

  getPaymentCollectionsTransaction(
    userId: string,
    initDate: string,
    endDate: string,
    documentNo: string,
    transactionStatus: any,
    codeProduct: string,
    counterpart: string,
    dni: string,
    page: number,
    size: number
  ) {
    return this.http.get(
      environment.apiUrl +
      '/getPaymentCollectionsTransaction?userId=' +
      userId +
      '&initDate=' +
      initDate +
      '&endDate=' +
      endDate +
      '&documentNo=' +
      documentNo +
      '&transactionStatus=' +
      transactionStatus +
      '&codeProduct=' +
      codeProduct +
      '&counterpart=' +
      counterpart +
      '&dni=' +
      dni +
      '&page=' +
      page +
      '&size=' +
      size
    );
  }

  printPaymentCollectionsCSV(
    userId: string,
    initDate: string,
    endDate: string,
    transactionStatus: any,
    codeProduct: string,
    counterpart: string,
    dni: string
  ) {
    return this.http.get(
      environment.apiUrl +
      '/printPaymentCollectionsCSV?userId=' +
      userId +
      '&initDate=' +
      initDate +
      '&endDate=' +
      endDate +
      '&transactionStatus=' +
      transactionStatus +
      '&codeProduct=' +
      codeProduct +
      '&counterpart=' +
      counterpart +
      '&dni=' +
      dni
    );
  }

  printPaymentCollections(userId: string, initDate: string, endDate: string, documentNo: string,
    // tslint:disable-next-line: align
    transactionStatus: any, codeProduct: string, counterpart: string, dni: string
  ) {
    return this.http.get(environment.apiUrl + '/printPaymentCollections?userId=' + userId + '&initDate=' + initDate +
      '&endDate=' + endDate + '&documentNo=' +
      documentNo +
      '&transactionStatus=' +
      transactionStatus +
      '&codeProduct=' +
      codeProduct +
      '&counterpart=' +
      counterpart +
      '&dni=' +
      dni
    );
  }
  printConfirmTransaction(transactionId: string) {
    return this.http.get(environment.apiUrl + '/printVoucher?transactionId=' + transactionId);
  }

  printCancelTransaction(transactionId: string) {
    return this.http.get(environment.apiUrl + '/reprintCancelTransaction?transactionId=' + transactionId);
  }
  getPaymentCollections(initDate: string, endDate: string, page: number, size: number) {
    return this.http.get(environment.apiUrl + '/getPaymentCollectionErp?initDate=' + initDate + '&endDate=' + endDate +
      '&page=' + page + '&size=' + size);
  }
  resynchronizeTransaction(paymentCollection: PaymentCollectionsErpPresenter) {
    return this.http.post(environment.apiUrl + '/sendPaymentCollectionErp', paymentCollection);
  }
  resynchronizeTransactions(initDate: string, endDate: string) {
    return this.http.get(environment.apiUrl + '/sendPaymentCollectionsErp?initDate=' + initDate + '&endDate=' + endDate);
  }
}
