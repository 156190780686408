import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

import { ClientModel } from '../models/client.model';
import { DispatchModel } from '../models/dispatch.model';
import { InvoiceModel } from '../models/invoice.model';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    constructor(private http: HttpClient) {}
    public saveInvoiceFromOrder(invoice: InvoiceModel): Promise<InvoiceModel> {
        return new Promise((resolve, reject) => {
            this.http
                .post<InvoiceModel>(
                    environment.apiUrl + '/saveInvoiceFromOrder',
                    invoice
                )
                .pipe(timeout(9000000))
                .subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    public saveOrderInvoiceAndDispatch(
        orderInvoiceAndDispatch: InvoiceModel
    ): Promise<InvoiceModel> {
        return new Promise((resolve, reject) => {
            this.http
                .post<InvoiceModel>(
                    environment.apiUrl + '/saveOrderInvoiceAndDispatch',
                    orderInvoiceAndDispatch
                )
                .pipe(timeout(9000000))
                .subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    public findInvoices(
        clientPresenter: ClientModel,
        initDate: string,
        endDate: string,
        documentNo: string,
        page: number,
        size: number
    ): Promise<InvoiceModel[]> {
        return new Promise((resolve, reject) => {
            this.http
                .post<InvoiceModel[]>(
                    environment.apiUrl +
                        '/findInvoices?initDate=' +
                        initDate +
                        '&endDate=' +
                        endDate +
                        '&documentNo=' +
                        documentNo +
                        '&page=' +
                        page +
                        '&size=' +
                        size,
                    clientPresenter,
                    { headers: { 'Content-Type': 'application/json' } }
                )
                .subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
    public getInvoice(invoiceId: string) {
        return this.http.get(
            environment.apiUrl + '/findInvoice?invoiceId=' + invoiceId
        );
    }

    public getInvoiceAndDIspatch(invoiceId: string): Promise<DispatchModel> {
        return new Promise((resolve, reject) => {
            this.http
                .get<DispatchModel>(
                    environment.apiUrl + '/dispatches?invoiceId=' + invoiceId
                )
                .subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    public getPdf(invoiceId: string) {
        return this.http.get(
            environment.apiUrl + '/printInvoice?invoiceId=' + invoiceId
        );
    }
    cancelInvoice(invoice: InvoiceModel) {
        return this.http.post(environment.apiUrl + '/cancelInvoice', invoice);
    }

    public postSyncroniceInvoiceAndDispatch(invoiceId: string) {
        return this.http.get(
            environment.apiUrl +
                '/resyncronizationInvoiceAndDispatch/' +
                invoiceId
        );
    }
    public verifyAcopio(invoiceId: string) {
        return this.http.get(
            environment.apiUrl +
                '/dispatches/verifyAcopio?invoiceId=' +
                invoiceId
        );
    }

    rezyncInvoices(invNumbers: number[]) {
        return this.http.get(
            environment.apiUrl + '/rezyncInvoices?invNumbers=' + invNumbers
        );
    }
    getPendingInvoicesWhitOutDispatch() {
        return this.http.get(
            environment.apiUrl + '/isPendingInvoicesWhitOutDispatch'
        );
    }
    findInvoicesNotDispatched(
        page: number,
        size: number,
        initDate: string,
        endDate: string,
        invoiceStatus: string
    ) {
        return this.http.get(
            environment.apiUrl +
                '/getInvoiceStatusByFilters?page=' +
                page +
                '&size=' +
                size +
                '&initDate=' +
                initDate +
                '&endDate=' +
                endDate
        );
    }
    printInvoicesPending(initDate: string, endDate: string) {
        return this.http.get(
            environment.apiUrl +
                '/dispatches/getReportIsPendingInvoicesWhitOutDispatch?initDate=' +
                initDate +
                '&endDate=' +
                endDate
        );
    }
    public getVoucher(invoiceId: string) {
        return this.http.get(
            environment.apiUrl + '/reprintVoucher?invoiceId=' + invoiceId
        );
    }
}
