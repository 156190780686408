import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { GenerateCashRegisterView } from './generate-cash-register.view';
import { DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Bank } from 'src/app/models/bank.model';
import { BankAccount } from 'src/app/models/bankaccount.model';
import { OpenCashRegisterComponent } from '../open-cash-register/open-cash-register.component';
import { GenerateCashRegisterPresenter } from './presenter/generate-cash-register.presenter';
import { Payment } from 'src/app/models/payment.model';
import { DataProvider } from 'src/app/services/data.provider';
import Swal from 'sweetalert2';
import { DayCloseCash, DepositCloseCashModel } from 'src/app/models/deposit-close-cash.model';
import Decimal from 'decimal.js';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-generate-cash-register',
  templateUrl: './generate-cash-register.component.html',
  styleUrls: ['./generate-cash-register.component.scss']
})
export class GenerateCashRegisterComponent extends AbstractView implements OnInit, GenerateCashRegisterView {
  value = '';

  rowDate: Date;
  dateHolder = 'Fecha de Venta';
  viewState = false;
  dateCorrect = '';
  valueLockedBox = true;
  dateValidate: [];
  availableDates: Date[] = [];

  GenerateCashRegisterPresenter: any;
  constructor(
    private ref: DynamicDialogRef,
    public service: MessageService,
    public router: Router,
    public dialogService: DialogService,
    public generateCashRegisterPresenter: GenerateCashRegisterPresenter,
    public dataProvider: DataProvider,
    public config: DynamicDialogConfig
  ) {
    super(service, router);
    generateCashRegisterPresenter.view = this;
  }

  payments: Payment[] = [];
  transferAmount: number;
  transferNumber: string;
  effectiveDate: any;
  ownBanks: Bank[];
  initDate: string;
  filteredBankAccounts: BankAccount[];
  accountNumber: string;
  selectedBank: Bank;
  bankAccountSelected: BankAccount;
  depositCloseCash: DepositCloseCashModel[] = [];
  dayCloseCash: DayCloseCash = {};
  notFoundDeposit: DepositCloseCashModel[] = [];
  lockedCloseCash: [];
  filteredDates: Date[];
  minDate: Date;
  maxDate: Date;

  ngOnInit(): void {
    this.generateCashRegisterPresenter.loadBanksFromAtix();
    this.generateCashRegisterPresenter.loadValueLockedbox();
    this.generateCashRegisterPresenter.loadAllDateValidate();
    if (this.config.data && this.config.data.date) {
      this.rowDate = new Date(this.config.data.date);
      const format = 'yyyy-MM-dd hh:mm:ss';
      const locale = 'en-US';
      this.viewState = true;
      this.dateCorrect = formatDate(this.rowDate, format, locale);
      this.generateCashRegisterPresenter.loadCloseCashAmount(this.dateCorrect);
      this.initDate = this.dateCorrect;
      const format1 = 'yyyy/MM/dd';
      const locale1 = 'en-US';
      this.dateHolder = formatDate(this.dateCorrect, format1, locale1);
    }
  }

  extractAndPushDates(data: any[]): void {
    if (!data || !Array.isArray(data)) {
      console.error('Invalid data:', data);
      return;
    }

    data.forEach(item => {
      const date = new Date(item.dateCloseCash);
      this.availableDates.push(date);
    });
  }

  getDatesOfCurrentAndLastMonth(): Date[] {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const dates: Date[] = [];
    const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    for (let day = 1; day <= daysInCurrentMonth; day++) {
      dates.push(new Date(currentYear, currentMonth, day));
    }

    const lastMonthDate = new Date(currentYear, currentMonth - 1);
    const lastMonthYear = lastMonthDate.getFullYear();
    const lastMonth = lastMonthDate.getMonth();
    const daysInLastMonth = new Date(lastMonthYear, lastMonth + 1, 0).getDate();

    for (let day = 1; day <= daysInLastMonth; day++) {
      dates.push(new Date(lastMonthYear, lastMonth, day));
    }

    return dates;
  }

  getFilteredDates(): Date[] {
    const datesOfCurrentAndLastMonth = this.getDatesOfCurrentAndLastMonth();

    const filteredDates = datesOfCurrentAndLastMonth.filter(date =>
      !this.availableDates.some(availableDate =>
        date.getFullYear() === availableDate.getFullYear() &&
        date.getMonth() === availableDate.getMonth() &&
        date.getDate() === availableDate.getDate()
      )
    );

    filteredDates.sort((a, b) => a.getTime() - b.getTime());

    return filteredDates;
  }

  showOpenCashRegisterCompponent() {
    this.ref = this.dialogService.open(OpenCashRegisterComponent, {
        showHeader: true,
        closeOnEscape: true,
        header: 'Abrir caja'
    });

    this.ref.onClose.subscribe((res) => {

    });
}

  clearAllInputs() {
    if (this.rowDate === undefined) {
      this.bankAccountSelected = null;
      this.selectedBank = null;
      this.initDate = null;
      this.transferAmount = null;
      this.transferNumber = null;
      this.viewState = false;
    } else {
      this.bankAccountSelected = null;
      this.selectedBank = null;
      this.transferAmount = null;
      this.transferNumber = null;
      this.viewState = false;
    }
  }

filterAccounts() {
  this.generateCashRegisterPresenter.filterBankAccounts();

}
  addPaymentSales() {
    if (!this.transferAmount || !this.transferNumber) {
      console.log(this.initDate);
      Swal.fire({
        title: 'Validación',
        text: 'Todos los campos son requeridos.'
      });
      return;
    } else {
      const format = 'yyyy-MM-dd hh:mm:ss';
      const locale = 'en-US';
      const dateCloseCash = formatDate(this.initDate, format, locale);
      this.depositCloseCash.push({
        bankPresenter: this.selectedBank,
        bankAccountPresenter: this.bankAccountSelected,
        dateCloseCash: dateCloseCash,
        amount: this.transferAmount,
        referencePayment: this.transferNumber,
        dayCloseCashPresenter: this.dayCloseCash
      });
      this.clearAllInputs();
    }
  }

deleteRow(depositCloseCash, ind) {
this.depositCloseCash.splice(ind, 1);
}

closeView(newDeposit: DepositCloseCashModel) {
  this.ref.close(newDeposit);
  }

registerDepositCloseCash () {
  this.generateCashRegisterPresenter.sendDeposit(this.depositCloseCash);
}

validatetransferAmount() {
  this.transferAmount = this.transferAmount ? new Decimal(this.transferAmount)
      .toDecimalPlaces(2).toNumber() : this.transferAmount;
}

  loadDate () {
    this.extractAndPushDates(this.dateValidate);
    this.filteredDates = this.getFilteredDates();
    this.minDate = this.filteredDates[0];
    this.maxDate = this.filteredDates[this.filteredDates.length - 1];
  }

  validateDate() {
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    this.viewState = true;
    this.dateCorrect = formatDate(this.initDate, format, locale);
    this.generateCashRegisterPresenter.loadCloseCashAmount(this.dateCorrect);
  }

  showCancelConfirm() {
    if (this.lockedCloseCash.length > 0) {
      Swal.fire({
        text: 'Por favor confirma que deseas cancelar el registro',
        showCancelButton: true,
        cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#00687d',
        confirmButtonText: 'Confirmar'
      }).then((result) => {
        if (result.value) {
          this.ref.close();
        }
      });
    } else {
      Swal.fire({
        text: 'Por favor confirma que deseas cancelar el registro',
        showCancelButton: true,
        cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#00687d',
        confirmButtonText: 'Confirmar'
      }).then((result) => {
        if (result.value) {
          this.ref.close();
          if (!this.dataProvider.isOpenCash) {
            this.showOpenCashRegisterCompponent();
          }
        }
      });
    }
  }
}
