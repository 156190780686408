import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Order } from '../models/order.model';
import { environment } from 'src/environments/environment';
import { ClientModel } from '../models/client.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }
  public saveOrder(newOrder: Order): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.http.post<Order>(environment.apiUrl + '/saveOrder', newOrder)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  public updateOrder(orderToUpdate: Order): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.http.post<Order>(environment.apiUrl + '/updateOrder', orderToUpdate)
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }
  public findOrders(clientPresenter: ClientModel, initDate: string, endDate: string, documentNo: number): Promise<Order[]> {
    return new Promise((resolve, reject) => {
      this.http.post<Order[]>(environment.apiUrl + '/findOrders?initDate=' + initDate + '&endDate=' + endDate,
        clientPresenter, { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }
  public getOrder(orderId: string): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.http.get<Order>(environment.apiUrl + '/findOrder?orderId=' + orderId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public cancelOrder(documentId: string): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.http.put<boolean>(environment.apiUrl + '/cancelDocument?documentId=' + documentId, null)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }


  public checkDiscount(order: Order) {
    return this.http.post(environment.apiUrl + '/checkRankDiscount', order);
  }
  public getDiscount(order: Order, key: string) {
    return this.http.post(environment.apiUrl + '/getRankDiscount?key=' + key, order);
  }
  public aplyDiscount(order: Order) {
    return this.http.post(environment.apiUrl + '/applyRankDiscount', order);
  }

  getOrdersPending() {
    return this.http.get(environment.apiUrl + '/pendingOrderToBeInvoiced');
  }
  getPendingMovementCashes() {
    return this.http.get(environment.apiUrl + '/isPendingMovementCashes');
  }
}
