import { Presenter } from 'src/app/core/presenter';
import { Injectable } from '@angular/core';
import { CloseDayReportView } from '../close-day-report.view';
import { ReportService } from 'src/app/services/report.service';
import { PaymentMethod } from 'src/app/models/paymentmethod';
@Injectable({
    providedIn: 'root',
})
export class ClosDayReportPresenter implements Presenter {
    view: CloseDayReportView;
    constructor(private reportService: ReportService) {
    }

    getCashRegisterResume() {
        this.reportService.getCashRegisterResumeById(this.view.selectedReport.dayRegisterId).subscribe(resume => {
            this.view.resume = resume;
        });
    }

    getSystemReport() {
        this.reportService.getSystemReport(this.view.selectedReport.dayRegisterId).then(report => {
            this.view.systemReport = report;

            this.view.barData = {
                labels: [
                    'Efectivo',
                    'Débito',
                    'Nota de crédito',
                    'Transferencia',
                    'Cheques',
                    'Crédito',
                    'Tarjeta',
                    'Retencion',
                    'Contado sin despacho',
                    'Subvention'],
                datasets: [
                    {
                        label: 'Sistema ',
                        backgroundColor: 'tomato',
                        borderColor: 'tomato',
                        data: [
                            this.view.systemReport.CASH,
                            this.view.systemReport.DEBIT,
                            this.view.systemReport.CREDIT_MEMO,
                            this.view.systemReport.BANK_TRANSFER,
                            this.view.systemReport.CHECK,
                            this.view.systemReport.CREDIT,
                            this.view.systemReport.CREDIT_CARD,
                            this.view.systemReport.WITHHOLD,
                            this.view.systemReport.TOTAL_WITHOUT_DISPATCH,
                            this.view.systemReport.SUBVENTION,
                        ]
                    },
                    {
                        label: 'Usuario',
                        backgroundColor: '#663399',
                        borderColor: '#663399',
                        data: [
                            this.view.userReport.CASH,
                            this.view.userReport.DEBIT,
                            this.view.userReport.CREDIT_MEMO,
                            this.view.userReport.BANK_TRANSFER,
                            this.view.userReport.CHECK,
                            this.view.userReport.CREDIT,
                            this.view.userReport.CREDIT_CARD,
                            this.view.userReport.WITHHOLD,
                            this.view.systemReport.TOTAL_WITHOUT_DISPATCH,
                            this.view.systemReport.SUBVENTION,
                        ]
                    }

                ]
            };
            this.setAdvanceReport();
        });
    }
    getDailyReport() {
        this.reportService.getDailyReport(this.view.selectedReport.dayRegisterId).then(report => {
            this.view.dailyReport = report;
            this.view.dailyReport.dayRegisterDetailPresenters.forEach(detail => {
                if (detail.paymentMethod === PaymentMethod.CASH) {
                    this.view.userReport.CASH = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.BANK_TRANSFER) {
                    this.view.userReport.BANK_TRANSFER = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.CHECK) {
                    this.view.userReport.CHECK = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.CREDIT) {
                    this.view.userReport.CREDIT = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.CREDIT_CARD) {
                    this.view.userReport.CREDIT_CARD = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.CREDIT_MEMO) {
                    this.view.userReport.CREDIT_MEMO = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.DEBIT) {
                    this.view.userReport.DEBIT = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.WITHHOLD) {
                    this.view.userReport.WITHHOLD = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.TOTAL_WITHOUT_DISPATCH) {
                    this.view.userReport.TOTAL_WITHOUT_DISPATCH = detail.amount;
                } else if (detail.paymentMethod === PaymentMethod.SUBVENTION) {
                    this.view.userReport.SUBVENTION = detail.amount;
                }
            });

            let userAdvance = 0;
            this.view.dailyReport.cashRegisterPresenter.movementAdvancePresenters.forEach(advance => {
                userAdvance += advance.amount;
            });
            this.view.userReport.CASH += userAdvance;

            this.view.barData = {
                labels: [
                    'Efectivo',
                    'Débito',
                    'Nota de crédito',
                    'Transferencia',
                    'Cheques',
                    'Crédito distribuidores',
                    'Tarjeta',
                    'Retencion',
                    'Contado sin despacho',
                    'Subvention'
                ],
                datasets: [
                    {
                        label: 'Sistema ',
                        backgroundColor: 'tomato',
                        borderColor: 'tomato',
                        data: [
                            this.view.systemReport.CASH,
                            this.view.systemReport.DEBIT,
                            this.view.systemReport.CREDIT_MEMO,
                            this.view.systemReport.BANK_TRANSFER,
                            this.view.systemReport.CHECK,
                            this.view.systemReport.CREDIT,
                            this.view.systemReport.CREDIT_CARD,
                            this.view.systemReport.WITHHOLD,
                            this.view.systemReport.TOTAL_WITHOUT_DISPATCH,
                            this.view.systemReport.SUBVENTION,
                        ]
                    },
                    {
                        label: 'Usuario',
                        backgroundColor: '#663399',
                        borderColor: '#663399',
                        data: [
                            this.view.userReport.CASH,
                            this.view.userReport.DEBIT,
                            this.view.userReport.CREDIT_MEMO,
                            this.view.userReport.BANK_TRANSFER,
                            this.view.userReport.CHECK,
                            this.view.userReport.CREDIT,
                            this.view.userReport.CREDIT_CARD,
                            this.view.userReport.WITHHOLD,
                            this.view.userReport.TOTAL_WITHOUT_DISPATCH,
                            this.view.userReport.SUBVENTION,
                        ]
                    }

                ]
            };
            this.setAdvanceReport();
        });
    }
    setAdvanceReport() {
        this.view.userReportTotal =
            this.view.userReport.CASH +
            this.view.userReport.BANK_TRANSFER +
            this.view.userReport.CHECK +
            this.view.userReport.CREDIT +
            this.view.userReport.CREDIT_CARD +
            this.view.userReport.CREDIT_MEMO +
            this.view.userReport.DEBIT +
            this.view.userReport.TOTAL_WITHOUT_DISPATCH +
            this.view.userReport.WITHHOLD +
            this.view.userReport.SUBVENTION;
        this.view.systemReportTotal =
            this.view.systemReport.CASH +
            this.view.systemReport.BANK_TRANSFER +
            this.view.systemReport.CHECK +
            this.view.systemReport.CREDIT +
            this.view.systemReport.CREDIT_CARD +
            this.view.systemReport.CREDIT_MEMO +
            this.view.systemReport.DEBIT +
            this.view.systemReport.TOTAL_WITHOUT_DISPATCH +
            this.view.systemReport.WITHHOLD +
            this.view.systemReport.SUBVENTION;
        this.view.advances = this.view.dailyReport.cashRegisterPresenter.movementAdvancePresenters;
    }

}
