import { ClientModel } from './client.model';
import { MeasureUnitPresenter } from './measure-conversion.model';
import { EmissionPointPresenter } from './pointemission.model';
import { PriceList } from './pricelist.model';
import { Product } from './product.model';
import { Tax } from './tax.model';

export interface QuotationModel {
  observation?: string;

  emissionPointPresenter?: EmissionPointPresenter;

  quotationId?: string;

  clientPresenter?: ClientModel;

  priceListPresenter?: PriceList;

  total?: number;

  subtotal?: number;

  tax?: number;

  discount?: number;

  subtotalZeroTax?: number;

  subtotalTaxed?: number;

  defaultTax?: Tax;

  quotationDetailPresenters?: Array<QuotationDetailModel>;

  pdf?: any;

  number?: string;

  isCreditCardPayment?: boolean;

  hasQuotation?: boolean;

  created?: string;

  sendBusinessManager?: boolean;
  quotationStatus?: string;
  statusColor?: string;

}

export interface QuotationDetailModel {
  commissionPercentage?: number;
  quotationDetailId?: string;
  product?: Product;
  unitPrice?: number;
  convertedQuantity?: number;
  enteredQuantity?: number;
  measureUnitFrom?: MeasureUnitPresenter;
  measureUnitTo?: MeasureUnitPresenter;
  multiplyRate?: number;
  divideRate?: number;
  discount?: number;
  discountRate?: number;
  taxPresenter?: Tax;
  lineNumber?: number;
  price?: number;
  specialPrice?: number;
  specialDiscount?: number;
  wholesalerDiscount?: number;
  administratorDiscount?: number;
  discountPromotion?: number;
  discountRecycling?: number;
  subtotal?: number;
  total?: number;
  chainPrice?: number;
  quantityRecycling?: number;
  wholesalerPrice?: number;
  suggestedPrice?: number;
  administratorPrice?: number;
  stockPDV?: number;
  limitPrice?: number;
  quotationStatus?: string;
  suggestedQuantityBusinessManager?: number;
  suggestedPriceBusinessManager?: number;
  observationHq?: string;
  observation: string;
  limitDiscount?: number;
  baseDiscount?: number;
  basePrice?: number;
}

export enum TypeStatusQuotation {
  INIT = 'INIT',
  PENDING = 'PENDING',
  RECEIVED = 'RECEIVED',
  TO_BE_APPROVED = 'TO_BE_APPROVED',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  PURCHASING_PROCESS = 'PURCHASING_PROCESS',
  PRODUCT_RECEPTION = 'PRODUCT_RECEPTION',
  TO_BE_INVOICED = 'TO_BE_INVOICED',
  INVOICED = 'INVOICED',
  REACHED = 'REACHED',
  VOIDED = 'VOIDED',
  CANCELED = 'CANCELED',
}

export interface TypeStatusQuotationInterface {
  name: string;
  code: string;
}

export enum statusColor {
    BLUE= 'BLUE' ,
    ORANGE= 'ORANGE',
    GREEN= 'GREEN'
}
