import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';
import { Presenter } from 'src/app/core/presenter';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { InvoiceSalesModel, SalesCategoryModel } from 'src/app/models/product.model';
import { ReportSalesPriceModel } from 'src/app/models/reportsalesprice.modal';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';
import { ReportSalesPricesView } from '../report-sales-prices.view';

@Injectable({
    providedIn: 'root',
})
export class ReportSalesPricesPresenter implements Presenter {
    view: ReportSalesPricesView;

    constructor(
        private reportService: ReportService,
        private productService: ProductService,
        private presenterSetting: SettingPresenter) { }

    getInvoicesForUserReport() {
        let initDate = '';
        let endDate = '';
        // const pageInvoice = this.view.pageInvoice;
        // const sizeInvoice = this.view.sizeInvoice;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        const numbreLines = 0;
        this.view.visible = false;
        if (!this.view.initDate || !this.view.endDate) {
            return;

        }
        let user = 'null';
        if (this.view.selectedUser) {
            user = this.view.selectedUser.dni;
        }
        let product = 'null';
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.referenceCode;
        }
        let invoice = 0;
        if (this.view.documentNo !== '') {
            invoice = Number(this.view.documentNo);
        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        this.view.blockUi();
        this.reportService.getInvoiceConsolidado(initDate, endDate, invoice, product, user).subscribe((data: ReportSalesPriceModel[]) => {
            this.view.resumeSalesPrice = data['resumeSalesPrices'];
            this.view.listSalesPrice = data['listSalesPrices'];
            this.view.invoices = [];
            this.view.pageInvoice = 0;
            if (numbreLines === 0) {
                this.view.visible = true;
                this.view.data = {
                    labels: ['ADM', 'ESPECIAL', 'LIMITE', 'BASE', 'MARKETING', 'MAYORISTA', 'PRECIO HOY', 'EMTOP', 'ADM EMTOP',
                    'LIM EMTOP', 'MAY EMTOP', 'ESP EMTOP'],
                    datasets: [
                        {
                            label:  'FERRETERÍA',
                            data: [this.view.resumeSalesPrice[0].adminF, this.view.resumeSalesPrice[0].specialF,
                                   this.view.resumeSalesPrice[0].limitF,
                                   (this.view.resumeSalesPrice[0].baseF + this.view.resumeSalesPrice[0].baseEmtopF).toFixed(2), this.view.resumeSalesPrice[0].marketingF,
                                   this.view.resumeSalesPrice[0].wholesalerF, this.view.resumeSalesPrice[0].dayPriceF,
                                   this.view.resumeSalesPrice[0].emtopF, this.view.resumeSalesPrice[0].adminEmtopF,
                                   this.view.resumeSalesPrice[0].limitEmtopF, this.view.resumeSalesPrice[0].wholesalerEmtopF,
                                   this.view.resumeSalesPrice[0].specialEmtopF ],
                            fill: false,
                            backgroundColor: 'tomato',
                            borderColor: 'tomato',

                        },
                        {
                            label:  'PESADO',
                            data: [this.view.resumeSalesPrice[0].adminP, this.view.resumeSalesPrice[0].specialP,
                            this.view.resumeSalesPrice[0].limitP,
                            this.view.resumeSalesPrice[0].baseP, 0,
                            this.view.resumeSalesPrice[0].wholesalerP, this.view.resumeSalesPrice[0].dayPriceP,
                                0, 0, 0, 0, 0],
                            fill: false,
                            backgroundColor: '#565656',
                            borderColor: '#565656',

                        }
                    ]
                };
            } else {
                this.view.visible = false;
                this.view.showInfo('', 'Busqueda sin registros');

            }
        }, (error: HttpErrorResponse) => {
            // this.view.productCategories = [];
            this.view.showError('Atención', error.error.message);
        });

    }

    findInvoicesByUser() {
        if (this.view.selectedUser && typeof this.view.selectedUser !== 'string') {
            this.view.showSelectedUser = true;
            this.getInvoicesForUserReport();
        }
    }

    clearUser() {
        this.view.showSelectedUser = false;
        this.view.selectedUser = null;
        this.getInvoicesForUserReport();
    }

    onSearchUser() {
        const searchValue = this.view.searchValue;
        if (searchValue.length < 3) {
            return;
        }
        this.reportService.usersByCriterion(searchValue, 0, 5).then(data => {
            const user: UserPresenter[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.users = user;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.view.productId = '';
        this.getInvoicesForUserReport();
    }

    findReportsByProduct() {
        if (
          this.view.selectedProduct &&
          typeof this.view.selectedProduct !== 'string'
        ) {
          this.view.showSelectedProduct = true;
          this.view.productId = this.view.selectedProduct.referenceCode;
          this.getInvoicesForUserReport();
        }
    }

    onSearchProduct() {
        const searchValue = this.view.searchProduct;
        const page = 0;
        const size = 20;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
          return;
        }
        this.productService
          .searchProduct(searchValue, page, size, prceListId, null)
          .then((data) => {
            this.view.products = data['data'];
            if (this.view.products.length <= 0) {
              this.view.showError('', 'Búsqueda sin registros');
              this.view.products = [];
            }
          })
          .catch((error) => {
            if (error instanceof HttpErrorResponse) {
              this.view.showError('Atención', error.error.message);
            } else {
              this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    getInvoicesHeavyOrHardware(type) {
        let initDate = '';
        let endDate = '';
        const pageInvoice = this.view.pageInvoice;
        const sizeInvoice = this.view.sizeInvoice;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        const numbreLines = 0;
        this.view.visible = false;
        if (!this.view.initDate || !this.view.endDate) {
            return;

        }
        let user = 'null';
        if (this.view.selectedUser) {
            user = this.view.selectedUser.dni;
        }
        let product = 'null';
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.referenceCode;
        }
        let invoice = 0;
        if (this.view.documentNo !== '') {
            invoice = Number(this.view.documentNo);
        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        this.view.categorySelected = type;
        this.reportService.getInvoiceHeavyOrhardware(initDate, endDate, invoice, product, user, type,
            pageInvoice, sizeInvoice).subscribe((data: ReportSalesPriceModel[]) => {
            this.view.totalElementsInvoice = data['totalElements'];
            this.view.invoices = data['data'];
            this.view.visible = true;
        }, (error: HttpErrorResponse) => {
            // this.view.productCategories = [];
            this.view.showError('Atención', error.error.message);
        });

    }

    dowloadReprot() {
        const reports = this.view.listSalesPrice.map((line) => {
          return {
            Fecha: line.date,
            Factura: line.invoice,
            Cedula: line.dni,
            Categoria: line.division,
            'Codigo producto': line.codeProduct,
            Descripcion: line.descProduct,
            'Cantidad vendida': line.salesCant,
            'Unidad vendida': line.salesUnit,
            'Precio venta': line.salesPrice,
            Subtotal: line.salesSubtotal,
            Extra: line.extra,
            'Tipo de precio': line.priceType,
            Marca: line.mark,
            Usuario: line.user,
            Observacion: line.observation,
            Vendedor: line.seller
          };
        });
        const reportsToCSV = JSON.parse(
          JSON.stringify(
            reports,
            [
                'Fecha',
                'Factura',
                'Cedula',
                'Categoria',
                'Codigo producto',
                'Descripcion',
                'Cantidad vendida',
                'Unidad vendida',
                'Precio venta',
                'Subtotal',
                'Extra',
                'Tipo de precio',
                'Marca',
                'Usuario',
                'Observacion',
                'Vendedor'
            ],
            2
          )
        );
        this.view.csvDownload(
          'Reporte Tipo de Precio.csv',
          reportsToCSV
        );
    }
}
