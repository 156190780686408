import { Presenter } from 'src/app/core/presenter';
import { Injectable } from '@angular/core';
import { CreditMemoVoidView } from '../credit-memo-void.view';
import { ClientService } from 'src/app/services/client.service';
import { CreditMemoService } from 'src/app/services/credit-memo.service';
import { ClientModel } from 'src/app/models/client.model';
import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { CreditMemoStatus, CreditMemoModel } from 'src/app/models/creditmemo.model';

@Injectable({
    providedIn: 'root',
})

export class CreditMemoVoidPresenter implements Presenter {
    view: CreditMemoVoidView;
    constructor(
        private clientService: ClientService,
        private creditMemoService: CreditMemoService,
    ) { }

    onSearchClient() {
        const searchValue = this.view.searchClient;
        const page = this.view.pageClient;
        const size = this.view.sizeClient;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.clients = clients;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearClient() {
        this.view.showSelectedClient = false;
        this.view.selectedClient = null;
        this.findCreditMemos();
    }

    findCreditMemosByClient() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            this.view.showSelectedClient = true;
            this.findCreditMemos();
        }
    }

    findCreditMemos() {
        let initDate = '';
        let endDate = '';
        let client = null;
        let docNo = '';
        const page = this.view.page;
        const size = this.view.size;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (this.view.selectedClient) {
            client = this.view.selectedClient;
        }
        if (this.view.documentNo) {
            docNo = this.view.documentNo;
        }

        this.creditMemoService.findCreditMemos(client, initDate, endDate, docNo, page, size, [CreditMemoStatus.REJECTED], true)
            .then(data => {
                this.view.creditMemos = data['data'];
                this.view.totalElements = data['totalElements'];

            }).catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salio mal');
                }
            });

    }

    getDetails() {
        this.creditMemoService.getCreditMemo(this.view.selectedCreditMemo.creditMemoId).
            subscribe((creditMemo: CreditMemoModel) => {
                this.view.selectedCreditMemo = creditMemo;
            });
    }
    getPdf() {
        this.view.blockUi();
        this.creditMemoService.getPdf(this.view.selectedCreditMemo.creditMemoId).then(data => {
            this.view.showPdf(data);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }
    setCreditMemoStatus() {
        this.view.blockUi();
        this.creditMemoService.setCreditMemoStatus(this.view.selectedCreditMemo).then((res: CreditMemoModel) => {
            this.view.creditMemos = this.view.creditMemos.filter(creditMemo => creditMemo.creditMemoId !== res.creditMemoId);
            if (this.view.selectedCreditMemo.creditMemoStatus === CreditMemoStatus.APPROVED) {
                this.view.showSuccess('', 'Nota de crédito aprobada');
            } else {
                this.view.showError('', 'Nota de crédito rechazada');
            }
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }
}
