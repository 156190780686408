import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MessageService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { AbstractView } from 'src/app/core/abstract_view';
import { OpenDayAdvanceView } from './open-day-advance.view';
import { SystemReportModel, ReportCloseDayModel, AdvanceModel } from 'src/app/models/report.model';

@Component({
  selector: 'app-open-day-advance',
  templateUrl: './open-day-advance.component.html',
  styleUrls: ['./open-day-advance.component.scss']
})
export class OpenDayAdvanceComponent extends AbstractView implements OnInit, OpenDayAdvanceView, AfterViewInit {


  advances: AdvanceModel[] = [];
  dailyReport: ReportCloseDayModel;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: MessageService,
    public router: Router, ) {
    super(service, router);

    this.advances = config.data.movementAdvancePresenters;

  }


  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }
}
