import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { SearchAdvancesView } from './search-advances.view';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { SearchAdvancesPresenter } from './presenter/search-advances.presenter';
import { ClientAdvanceModel } from 'src/app/models/client-advance.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-search-advances',
  templateUrl: './search-advances.component.html',
  styleUrls: ['./search-advances.component.scss']
})
export class SearchAdvancesComponent extends AbstractView implements OnInit, SearchAdvancesView, AfterViewInit {

  value = '';
  active = false;
  withBalanceAmount = false;
  excludeCreditCard = false;
  isCPOS = false;
  clientSelected: any;
  clientAdvances = [];
  clientAdvanceSelected: ClientAdvanceModel;
  constructor(
    public service: MessageService,
    public router: Router,
    public config: DynamicDialogConfig,
    public searchAdvancesPresenter: SearchAdvancesPresenter,
    public ref: DynamicDialogRef
  ) {
    super(service, router);
    this.clientSelected = config.data.client;
    searchAdvancesPresenter.view = this;
  }

  ngOnInit() {
    this.getClientAdvances();
  }

  getClientAdvances() {
    this.searchAdvancesPresenter.getClientAdvances();
  }

  ngAfterViewInit(): void {
  }

  getPdf(advance: ClientAdvanceModel) {
    this.clientAdvanceSelected = advance;
    this.searchAdvancesPresenter.getPdf();
  }

  synchronize(advance: ClientAdvanceModel) {
    this.clientAdvanceSelected = advance;
    this.searchAdvancesPresenter.postSynchronice();
  }

  cancel(advance: ClientAdvanceModel) {
    this.clientAdvanceSelected = advance;
    Swal.fire({
      text: '¿Desea anular este anticipo?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular Anticipo'
    }).then((result) => {
      if (result.value) {
        this.searchAdvancesPresenter.cancel();
      }
    });
  }

}
