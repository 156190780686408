import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { IdentificationType } from '../models/identificationtype.model';

@Injectable({
  providedIn: 'root'
})
export class IdentificationService {

  constructor(private http: HttpClient) { }

  public searchIdentificationType(): Promise<IdentificationType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<IdentificationType[]> (environment.apiUrl + '/identificationType')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
