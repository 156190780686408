import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(private http: HttpClient) { }

    public doLogin(username: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + '/login', JSON.stringify({ username: username, password: password }))
                .subscribe(response => {

                    if (response) {
                        resolve(response['token']);
                    } else {
                        reject('Autenticación fallida!');
                    }
                }, error => {
                    reject(error);
                });
        });
    }

    getValidUsers () {
        return this.http.get (environment.apiUrl + '/getValidUsers');
      }
}
