import { ClientModel } from './client.model';
import { PaymentTerm } from './paymentterm';
import { PaymentMethod } from './paymentmethod';
import { OrderLineToSave } from './orderline.model';
import { PriceList } from './pricelist.model';
import { EmissionPointPresenter } from './pointemission.model';

export interface Order {
    number?: number;
    orderId?: string;
    clientPresenter?: ClientModel;
    total?: number;
    subtotal?: number;
    tax?: number;
    paymentTermPresenter?: PaymentTerm;
    paymentMethod?: PaymentMethod;
    orderDetailPresenters?: OrderLineToSave[];
    priceListPresenter?: PriceList;
    transactionDate?: string;
    pdf?: any;
    emissionPointPresenter?: EmissionPointPresenter;
    observation?: string;
    defaultTax?: number;
    creditStatus?: CreditStatus;
    isCreditCardPayment?: boolean;
    billetonAmount?: number;
    invoiceAdminPercentage?: number;
    isAdminPrice?: boolean;
    discount?: number;
    discountIncrement?: number;
    discountPromotion?: number;
    discountRecycling?: number;
    isQuotation?: boolean;
    quotationId?: string;
    sendBusinessManager?: boolean;
    quotationNumber?: string;
}

export enum CreditStatus {
    NOT_REQUESTED = 'NOT_REQUESTED',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    APPROVED_OVERDRAFT = 'APPROVED_OVERDRAFT'
}
