import { Bank } from './bank.model';

export enum CreditCardType {
  CURRENT_PAYMENT = 'CURRENT_PAYMENT',
  CREDIT_WITH_INTEREST = 'CREDIT_WITH_INTEREST',
  CREDIT_WITHOUT_INTEREST = 'CREDIT_WITHOUT_INTEREST',
}

export enum TransactionType {
  CURRENT_TRANSACTION = 'CURRENT_TRANSACTION',
  DEFERRED_TRANSACTION = 'DEFERRED_TRANSACTION',
  CANCELLATIONS_PURCHASES_AND_DEFERRED = 'CANCELLATIONS_PURCHASES_AND_DEFERRED',
  REVERSAL_CURRENT_AND_DEFERRED_PURCHASES = 'REVERSAL_CURRENT_AND_DEFERRED_PURCHASES',
  ELECTRONIC_PAYMENTS_OTT = 'ELECTRONIC_PAYMENTS_OTT',
  CONSULTATION_PAYMENT_MAXIDOLAR = 'CONSULTATION_PAYMENT_MAXIDOLAR',
}

export enum ProcessType {
  CARD_READ = 'CARD_READ',
  PAYMENT_PROCESS = 'PAYMENT_PROCESS',
  CONTROLPROCESS = 'CONTROLPROCESS',
}

export enum CreditDeferredType {
  NORMAL_OR_CURRENT = 'NORMAL_OR_CURRENT',
  // DEFER_WITH_MONTHS_GRACE_WITH_INTEREST = 'DEFER_WITH_MONTHS_GRACE_WITH_INTEREST',
  DEFER_WITH_MONTHS_GRACE_WITHOUT_INTEREST = 'DEFER_WITH_MONTHS_GRACE_WITHOUT_INTEREST',
  // DEFER_PAY_MONTH_TO_MONTH_WITH_INTEREST = 'DEFER_PAY_MONTH_TO_MONTH_WITH_INTEREST',
  // DEFER_PAY_MONTH_WITHOUT_INTEREST = 'DEFER_PAY_MONTH_WITHOUT_INTEREST',
  DEFER_FIX_FEE_WITH_INTEREST = 'DEFER_FIX_FEE_WITH_INTEREST',
  DEFER_FIX_FEE_WITHOUT_INTEREST = 'DEFER_FIX_FEE_WITHOUT_INTEREST',
  // DEFER_PAY_MONTH_GRACE_WITH_INTEREST = 'DEFER_PAY_MONTH_GRACE_WITH_INTEREST',
  // SPECIAL_DEFER_WITHOUT_INTEREST = 'SPECIAL_DEFER_WITHOUT_INTEREST',*/
}

export class TransactionPresenter {
  transactionType?: TransactionType;
  processType?: ProcessType;
  creditDeferredType?: CreditDeferredType;
  deferredMonthTime?: number;
  monthGrace?: number;
  totalAmount: number;
  baseAmountWithTaxes: number;
  baseAmountWithoutTaxes: number;
  transactionTax: number;
  iva?: number;
  subtotal?: number;
  interest?: number;
  hour?: string;
  date?: string;
  cardType?: CardType;
  creditCardType?: CreditCardType;
  responseTransactionPresenter?: ResponseTransactionPresenter;
  userId: string;
  clientId: string;
  warehouseId: string;
  bankPresenter: Bank;
}
export class ResponseTransactionPresenter {
  authorizedResponseCode?: string;
  transactionReferenceSequence?: string;
  lotNumber?: string;
  transactionTime?: string;
  transactionDate?: string;
  authorizeNumberTransaction?: string;
  mid?: string;
  tid?: string;
  track2?: string;
  cardExpirationDate?: string;
  response?: string;
  networkCode?: string;
  truncatedCardNumber?: string;
  interest?: string;
  coBrand?: string;
  chipAppName?: string;
  accountType?: string;
  cardGroupName?: string;
  readingMode?: string;
  aidEmv?: string;
  tvr?: string;
  tsi?: string;
  pdf;
}

export enum CardType {
  DEBIT_CARD = 'DEBIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
}
