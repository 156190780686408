
import { Injectable } from '@angular/core';

import { ClientModel } from 'src/app/models/client.model';
import { Presenter } from 'src/app/core/presenter';
import { Store } from '@ngrx/store';
import { OrderLine } from 'src/app/models/orderline.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchCloseDayReportView } from '../search-report.view';
import { ReportService } from 'src/app/services/report.service';
import { formatDate } from '@angular/common';


@Injectable({
    providedIn: 'root',
})
export class SearchCloseDayReportPresenter implements Presenter {
    view: SearchCloseDayReportView;

    constructor(
        private reportService: ReportService,
    ) { }




    onSearchCloseDayReport() {
        let searchValue = '';
        let initDate = '';
        let endDate = '';
        let page = 0;
        if (this.view.page) {
            page = this.view.page;
        }
        let size = 20;
        if (this.view.size) {
            size = this.view.size;
        }
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }

        if (this.view.searchValue) {
            searchValue = this.view.searchValue;
        }

        this.reportService.findDayRegisters(searchValue, initDate, endDate, page, size).then(data => {
            this.view.reportCloseDays = data['data'];
            this.view.totalElements = data['totalElements'];
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

    getPdfReport() {
        this.view.blockUi();
        this.reportService.getPdfReport(this.view.selectedReport.dayRegisterId).then(data => {
            this.view.showPdf(data);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

}
