import { Component, OnInit } from '@angular/core';
import { TransferProvider } from '../../transfer.provider';
import { MessageService } from 'primeng/api';
import { AbstractView } from '../../../../../../../core/abstract_view';
import {
  leftToRigthAnimation,
  apearAnimation,
  leftToLeft,
} from '../../../../../../../animations';
import {
  DevolutionFile,
  TransferDetailModel,
  TransfersModel,
} from 'src/app/models/transfer.mode';
import { read, utils } from 'xlsx';
import { FileUpload } from 'primeng/primeng';

@Component({
  animations: [leftToRigthAnimation, apearAnimation, leftToLeft],
  selector: 'app-transfer-request',
  templateUrl: './transfer-request.component.html',
  styleUrls: ['./transfer-request.component.scss'],
})
export class TransferRequestComponent extends AbstractView implements OnInit {
  linesFile: TransferDetailModel[] = [];
  constructor(
    public transferProvider: TransferProvider,
    private messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit() { }
  validatePage(page: number) {
    if (page) {
      return page;
    } else {
      return 0;
    }
  }
  getTransfer(transfer: TransfersModel) {
    if (transfer.transferId) {
      this.transferProvider.getTransferToGenerateClaim(transfer);
    }
  }

  clearSearch() {
    this.transferProvider.selectedProductProd = null;
  }
  UploaderFiles(event, form: FileUpload) {
    const lines: DevolutionFile[] = [];
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      const workbook = read(myReader.result, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const arraylist: any = utils
        .sheet_to_json(worksheet, { raw: true })
        .filter((header: any) => header.codigo && header.motivo && header.cantidad);
      if (arraylist.length > 0) {
        arraylist.forEach((element) => {
          const findLine: DevolutionFile = lines.find(line => line.referenceCode === element.codigo);
          if (!findLine) {
            lines.push({
              quantity: element.cantidad,
              reason: element.motivo,
              referenceCode: element.codigo,
            });
          } else {
            findLine.quantity = findLine.quantity + element.cantidad;
          }
        });
        this.transferProvider.sendFile(lines);
      } else {
        this.showError(
          '',
          'Error: Archivo mal configurado, porfavor revisar documento'
        );
        form.clear();
      }
    };
    myReader.readAsArrayBuffer(event.files[0]);
    event.files = null;
  }

  getTransferDraft(transfer: TransfersModel) {
    if (transfer.transferId) {
      this.transferProvider.getTransferToGenerateDevolution(transfer, false);
    }
  }
}
