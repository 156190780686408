import { Component, OnInit } from '@angular/core';
import {
  DefaultConfigurationType,
  DefaultConfigurationPresenter
} from 'src/app/models/defaultconfigurations.model';
import { DynamicDialogRef, DynamicDialogConfig, MessageService, DialogService } from 'primeng/api';
import { Router } from '@angular/router';
import { AbstractView } from 'src/app/core/abstract_view';
import { SettingsView } from './settings.view';
import { SettingsPresenter } from './presenter/settings.presenter';
import { DomainAction } from 'src/app/models/rol.model';
import { AdminPricePasswordComponent } from '../../shared/admin-price-password/admin-price-password.component';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends AbstractView implements OnInit, SettingsView {

  defaultConfigurationPresenter: DefaultConfigurationPresenter[] = [];
  defaultConfigurationPresenterClone: DefaultConfigurationPresenter[] = [];
  defaultConfigurationPresenterSelected: DefaultConfigurationPresenter;
  currentValue: any;

  constructor(
    public ref: DynamicDialogRef,
    public service: MessageService,
    public config: DynamicDialogConfig,
    public settingsPresenter: SettingsPresenter,
    public router: Router,
    public dialogService: DialogService,
    public defaultconfigurationsService: DefaultconfigurationsService) {
    super(service, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_CONFIGURATIONS)) {
      this.showError('', 'Sin autorización');
      this.ref.close();
    }
    settingsPresenter.view = this;
    this.defaultConfigurationPresenter = JSON.parse(JSON.stringify(config.data));
    this.defaultConfigurationPresenterClone = JSON.parse(JSON.stringify(config.data));

    if (!this.getAuth('MANAGE_DEVELOPER')) {
      this.defaultConfigurationPresenter = this.defaultConfigurationPresenter.filter((obj: any) =>
        obj.defaultConfigurationType !== DefaultConfigurationType.DEFAULT_SHOW_DEVOLUTIONS);
    }
  }

  ngOnInit() {

  }

  setCurrentValue(value: any) {
    this.currentValue = value;
  }

  closeView(newDefaultConfigurationPresenter: DefaultConfigurationPresenter) {
    this.ref.close(newDefaultConfigurationPresenter);
  }
  saveDefaultconfigurations(defaultConf: DefaultConfigurationPresenter) {
    this.defaultConfigurationPresenterSelected = defaultConf;
    if (defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_SALES_EXPRESS &&
      this.settingsPresenter.dataProvider.defaultWarehouse.businessUnit !== 'agrotiendas') {
      this.defaultConfigurationPresenterSelected.value = !this.defaultConfigurationPresenterSelected.value;
      this.showInfo('Advertencia', 'Permiso denegado');
      return;
    }
    if (defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_ADMIN_PRICE ||
      defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_DISCOUNT_AMOUNT ||
      defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_CREDENTIALS_ADMIN_PRICE ||
      defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_PROMOTIONS_ACTIVE ||
      defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_BILLETON_DISCOUNT_AMOUNT ||
      defaultConf.defaultConfigurationType === DefaultConfigurationType.DEFAULT_SALES_EXPRESS) {
      this.showSupervisorPassword();
    } else {
      this.settingsPresenter.saveDefaultConfigurations();
    }


  }

  getName(defaultConfigurationPresenter: DefaultConfigurationPresenter) {
    switch (defaultConfigurationPresenter.defaultConfigurationType) {
      case DefaultConfigurationType.DEFAULT_AMOUNT_MOVEMENT_ADVANCE:
        return 'Monto mínimo de efectivo para realizar avance';
      case DefaultConfigurationType.DEFAULT_MAX_CREDIT_DEBIT_FINAL_CONSUMER_AMOUNT:
        return 'Monto máximo para débito y crédito de consumidor final';
      case DefaultConfigurationType.DEFAULT_MAX_FINAL_CONSUMER_AMOUNT:
        return 'Monto máximo de efectivo para consumidor final';
      case DefaultConfigurationType.DEFAULT_MIN_FINAL_CONSUMER_AMOUNT:
        return 'Monto mínimo para solicitar una observación a consumidor final';
      case DefaultConfigurationType.DEFAULT_TIME_AUTO_CANCEL_ORDERS:
        return 'Tiempo de cancelación de una orden (En minutos)';
      case DefaultConfigurationType.DEFAULT_TIME_AUTO_CANCEL_QUOTATIONS:
        return 'Tiempo de cancelación de una cotización (En minutos)';
      case DefaultConfigurationType.DEFAULT_SYNCHRONIZED_DATA:
        return 'Bandera para realizar la carga inicial de datos';
      case DefaultConfigurationType.DEFAULT_SALES_EXPRESS:
        return 'Ventas con distribuidor';
      case DefaultConfigurationType.DEFAULT_AMOUNT_CREDIT_DEBIT_CARD_SALES:
        return 'Porcentaje para pago con tarjeta ';
      case DefaultConfigurationType.DEFAULT_BILLETON_DISCOUNT_AMOUNT:
        return 'Valor de descuento de Billeton ';
      case DefaultConfigurationType.DEFAULT_MIN_BILLETON_AMOUNT:
        return 'Valor mínimo para descuento por Billeton';
      case DefaultConfigurationType.DEFAULT_DISCOUNT_AMOUNT:
        return 'Valor caja inicial';
      case DefaultConfigurationType.DEFAULT_CREDENTIALS_ADMIN_PRICE:
        return 'Solicitar credenciales para habilitar precios de administrador';
      case DefaultConfigurationType.DEFAULT_ADMIN_PRICE:
        return 'Precios de administrador';
      case DefaultConfigurationType.DEFAULT_PROMOTIONS_ACTIVE:
        return 'Habilitar promociones';
      case DefaultConfigurationType.DEFAULT_TIME_AUTO_CANCEL_QUOTATIONS_NOT_STOCK:
        return 'Tiempo adicional de cancelación de una cotización, sin stock (En minutos)';
      case DefaultConfigurationType.DEFAULT_DAYS_AUTO_CANCEL_CREDIT_MEMOS:
        return 'Tiempo para cancelación de una nota de crédito (En días)';
      case DefaultConfigurationType.DEFAULT_SHOW_DEVOLUTIONS:
        return 'Habilitar Devouluciones PDV';
      default:
        return defaultConfigurationPresenter.defaultConfigurationType;
    }
  }
  getVisibility(defaultConfigurationPresenter: DefaultConfigurationPresenter) {

    return this.settingsPresenter.getVisibility(defaultConfigurationPresenter);
  }
  getFilter(defaultConfigurationPresenter: DefaultConfigurationPresenter) {
    switch (defaultConfigurationPresenter.defaultConfigurationType) {
      case DefaultConfigurationType.DEFAULT_AMOUNT_MOVEMENT_ADVANCE:
        return 'pnum';
      case DefaultConfigurationType.DEFAULT_MAX_CREDIT_DEBIT_FINAL_CONSUMER_AMOUNT:
        return 'pnum';
      case DefaultConfigurationType.DEFAULT_MAX_FINAL_CONSUMER_AMOUNT:
        return 'pnum';
      case DefaultConfigurationType.DEFAULT_TIME_AUTO_CANCEL_ORDERS:
        return 'pint';
      case DefaultConfigurationType.DEFAULT_MAX_CREDIT_DEBIT_FINAL_CONSUMER_AMOUNT:
        return 'pnum';
      case DefaultConfigurationType.DEFAULT_SALES_EXPRESS:
        return 'ptext';
      case DefaultConfigurationType.DEFAULT_SYNCHRONIZED_DATA:
        return 'ptext';
      case DefaultConfigurationType.DEFAULT_BILLETON_DISCOUNT_AMOUNT:
        return 'pnum';
      case DefaultConfigurationType.DEFAULT_MIN_BILLETON_AMOUNT:
        return 'pnum';
      default:
        return 'pint';
    }
  }

  confirmPasword(defaultConfigurationPresenter: DefaultConfigurationPresenter) {
    switch (defaultConfigurationPresenter.defaultConfigurationType) {

      case DefaultConfigurationType.DEFAULT_SALES_EXPRESS:
        return true;
      case DefaultConfigurationType.DEFAULT_SYNCHRONIZED_DATA:
        return true;
      default:
        return false;
    }
  }
  isSwitch(defaultConfigurationPresenter: DefaultConfigurationPresenter) {
    switch (defaultConfigurationPresenter.defaultConfigurationType) {

      case DefaultConfigurationType.DEFAULT_SALES_EXPRESS:
        return true;
      case DefaultConfigurationType.DEFAULT_SYNCHRONIZED_DATA:
        return true;
      case DefaultConfigurationType.DEFAULT_ADMIN_PRICE:
        return true;
      case DefaultConfigurationType.DEFAULT_CREDENTIALS_ADMIN_PRICE:
        return true;
      case DefaultConfigurationType.DEFAULT_PROMOTIONS_ACTIVE:
        return true;
      case DefaultConfigurationType.DEFAULT_SHOW_DEVOLUTIONS:
        return true;
      default:
        return false;
    }
  }

  showSupervisorPassword() {
    const name = 'Credenciales de Supervisor';
    this.ref = this.dialogService.open(AdminPricePasswordComponent, {
      showHeader: true,
      closeOnEscape: true,
      height: '400px',
      width: '400px',
      data: { permissions: DomainAction.ALLOW_POS_APROVE_ADMIN_PRICES, name: name }
    });

    this.ref.onClose.subscribe((aproveAdmin: boolean) => {
      if (aproveAdmin) {
        this.settingsPresenter.saveDefaultConfigurations();
      } else {
        if (isNaN(this.defaultConfigurationPresenterSelected.value)) {
          this.defaultConfigurationPresenterSelected.value = !this.defaultConfigurationPresenterSelected.value;
        } else {
          this.defaultConfigurationPresenterSelected.value = this.currentValue;
        }
      }
    });
  }
  validateConexionCpos() {
    this.settingsPresenter.validateConexionCpos();

  }
}

