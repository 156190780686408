import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { LoginView } from './login.view';
import { LoginPresenter } from './presenter/login.presenter';
import { Router } from '@angular/router';
import { DialogService, MessageService } from 'primeng/api';
import { UiUtils } from 'src/app/core/ui-utils';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MessageService]
})


export class LoginComponent extends AbstractView implements OnInit, LoginView {
    user: string;
    password: string;
    public ref;
    constructor(
        public loginPresenter: LoginPresenter,
        public router: Router,
        public messageService: MessageService,
        public uiUtils: UiUtils,
        public dialogService: DialogService) {
        super(messageService, router);
        this.loginPresenter.view = this;
    }

    ngOnInit() {
        document.body.style.backgroundImage = 'url(/assets/layout/images/login/login-bg.jpg)';
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.height = '100%';
        this.loginPresenter.verifyAuthentication();
    }

    onClickAcceptButton() {
        this.loginPresenter.acceptLoging();
    }

    open() {
        this.redirectTo('/forgot-password');
    }

}
