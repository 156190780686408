import { Component, OnInit, ViewChild, OnDestroy, Injectable } from '@angular/core';
import { MessageService, DialogService } from 'primeng/api';
import { InvoiceHeaderView } from './invoice-header.view';
import { Client, ClientModel, UserModel } from 'src/app/models/client.model';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { InvoiceHeaderPresenter } from './presenter/invoice-header.presenter';
import { ManageClientComponent } from '../../client/manage-client/manage-client.component';
import { ProcessModel } from 'src/app/models/process.model';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { AutoComplete } from 'primeng/primeng';
import { CreditStatus } from 'src/app/models/order.model';
import { DefaultconfigurationsService, GeneralConfigurations } from '../../../../../services/defaultconfigurations.service';
import { DataProvider } from '../../../../../services/data.provider';
import { AdminPricePasswordComponent } from '../../../shared/admin-price-password/admin-price-password.component';
import { DomainAction } from 'src/app/models/rol.model';
import { trigger, transition, style, animate } from '@angular/animations';
import { GetSalesPreviusMonthService } from 'src/app/services/get-sales-previus-month.service';
import Decimal from 'decimal.js';
import { PromotionProvider } from '../../../../../services/promotion.provider';
import { Payment } from 'src/app/models/payment.model';
import Swal from 'sweetalert2';
import { ProductQuestPresenter } from '../product-quest/presenter/product-quest.presenter';
import { SearchAdvancesComponent } from '../../client/search-advances/search-advances.component';
@Component({
    animations: [trigger('leftToRigthAnimation', [
        transition(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('300ms', style({ transform: 'translateX(0)' })),
        ]),
        transition(':leave', [
            style({ transform: 'translateX(0)' }),
            animate('300ms', style({ transform: 'translateX(-100%)' })),
        ]),
    ])],
    selector: 'app-invoice-header',
    templateUrl: './invoice-header.component.html',
    styleUrls: ['./invoice-header.component.scss'],
    providers: [DialogService]
})
export class InvoiceHeaderComponent extends AbstractView implements OnInit, InvoiceHeaderView, OnDestroy {

    @ViewChild('txtSerachClient', { read: false, static: false }) private txtSearchClient: AutoComplete;
    proceses: { label: string, value: ProcessModel }[] = [
        { label: 'Factura', value: ProcessModel.INVOICE },
        // { label: 'Orden', value: ProcessModel.ORDER },
        { label: 'Cotización', value: ProcessModel.QUOTATION },
    ];
    procesesSelected: ProcessModel;
    clients: ClientModel[];
    selectedClient: ClientModel;
    search = '';
    page = 0;
    size = 6;
    sizeSeller = 10;
    totalElements: number;
    showSelectedClient = false;
    updateOrder = null;
    lines = [];
    creditCardPayment = true;
    advisers: ClientModel[];
    users: UserModel[];
    selectedAdviser: ClientModel;
    showSelectedAdviser = false;
    showSelectedSeller = false;
    selectedSeller: UserModel;
    butDisabled = false;
    salesExpress = false;
    isSellers = false;
    isBilleton = false;
    billetonAmount = 0;
    isAdminPriceDiscount = false;
    subTotal12 = 0;
    subTotal0 = 0;
    subTotal = 0;
    loading = false;
    payments: Payment[];
    ifProductCrediRed = false;
    adminPermison = false;

    public ref;
    constructor(
        private messageService: MessageService,
        private invoiceHeaderPresenter: InvoiceHeaderPresenter,
        public router: Router,
        public dialogService: DialogService,
        private hotKey: HotkeysService,
        public defaultConfigurationService: DefaultconfigurationsService,
        public dataProvider: DataProvider,
        public getSalesPreviusMonth: GetSalesPreviusMonthService,
        public productQuestPresenter: ProductQuestPresenter,
        public promotionProvider?: PromotionProvider


    ) {
        super(messageService, router);
        invoiceHeaderPresenter.view = this;
        invoiceHeaderPresenter.init();
        this.shortcuts();
        if (this.getAuth(DomainAction.ALLOW_POS_ADMIN_PRICES)) {
            this.adminPermison = true;
          }

    }

    ngOnDestroy(): void {
        this.hotKey.pause(this.hotKey.add(new Hotkey(['alt+down', 'ctrl+down'], (event: KeyboardEvent): boolean => {
            this.getFinalCostumer();
            return false;
        }, ['input', 'textarea'], 'Obtener consumidor final')));

        this.hotKey.pause(this.hotKey.add(new Hotkey(['alt+n', 'ctrl+n'], (event: KeyboardEvent): boolean => {
            this.showNewClientModal();
            return false;
        }, ['input', 'textarea'], 'nuevo cliente')));
    }

    shortcuts() {
        this.hotKey.add(new Hotkey(['alt+down', 'ctrl+down'], (event: KeyboardEvent): boolean => {
            this.getFinalCostumer();
            return false;
        }, ['input', 'textarea'], 'Obtener consumidor final'));
        this.hotKey.add(new Hotkey(['alt+f', 'ctrl+f'], (event: KeyboardEvent): boolean => {
            this.txtSearchClient.focusInput();
            return false;
        }, ['input', 'textarea'], 'Foco en cliente'));
        this.hotKey.add(new Hotkey(['alt+n', 'ctrl+n'], (event: KeyboardEvent): boolean => {
            this.showNewClientModal();
            return false;
        }, ['input', 'textarea'], 'nuevo cliente'));
    }

    ngOnInit() { }

    onSearchClient(event) {
        this.search = event.query;
        this.page = 0;
        this.invoiceHeaderPresenter.onSearchClient();
    }

    onSearchUser() {
        this.page = 0;
        this.invoiceHeaderPresenter.onSearchUser();
    }

    showNewClientModal() {
        console.log(this.selectedClient, this.showSelectedClient);

        this.hotKey.pause(this.hotKey.add(new Hotkey(['alt+n', 'ctrl+n'], (event: KeyboardEvent): boolean => {
            this.showNewClientModal();
            return false;
        }, ['input', 'textarea'], 'nuevo cliente')));
        this.ref = this.dialogService.open(ManageClientComponent, {
            showHeader: true,
            header: this.showSelectedClient ? 'Actualizar cliente' : 'Nuevo cliente',
            height: '450px',
            width: '70%',
            data: {
                isClient: this.showSelectedClient,
                client: this.showSelectedClient ? this.selectedClient : this.search
            }
        });

        this.ref.onClose.subscribe((newClient: ClientModel) => {
            this.hotKey.unpause(this.hotKey.add(new Hotkey(['alt+n', 'ctrl+n'], (event: KeyboardEvent): boolean => {
                this.showNewClientModal();
                return false;
            }, ['input', 'textarea'], 'nuevo cliente')));
            if (newClient) {
                this.invoiceHeaderPresenter.view.selectedClient = newClient;
                const cli = this.setName(this.invoiceHeaderPresenter.view.selectedClient);
                const action = this.showSelectedClient ? ' actualizado con éxito! ' : ' ingresado con éxito! ';
                this.showSuccess('', cli.toUpperCase() + action);
                this.addClient();
            }
            this.clearClient();
        });


    }
    setDeferredPayment() {
        if (this.payments.length >= 1) {
            Swal.fire({
                text: 'Se eliminarán pagos agregados ¿Desea continuar?',
                showCancelButton: true,
                cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#00687d',
                confirmButtonText: 'Continuar'

            }).then((result) => {
                if (result.value) {
                    this.dropPayments();
                    this.setCreditCardPayment();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.creditCardPayment = !this.creditCardPayment;
                }
            });
        } else {
            this.setCreditCardPayment();
        }
    }

    setCreditCardPayment() {
        GeneralConfigurations.creditCardPayment = this.creditCardPayment;
        this.invoiceHeaderPresenter.setCreditCartPayment();
    }
    dropPayments() {
        this.invoiceHeaderPresenter.dropPayments();
    }



    getFinalCostumer() {

        if (this.blockUiWhenOrderAproved()) {
            return;
        }
        this.invoiceHeaderPresenter.getFinalConsumer();
    }

    closeModal() {
        this.ref.close();
    }

    addClient() {
        this.invoiceHeaderPresenter.validateInvoicesOverdue();
    }
    addAdviser() {
        this.invoiceHeaderPresenter.addAdviserToRedux();
    }
    addSeller() {
        this.invoiceHeaderPresenter.addSellerToRedux();
    }
    addSameClientToAdviserToRedux() {
        this.invoiceHeaderPresenter.addSameClientToAdviserToRedux();
    }
    clearClient() {
        this.invoiceHeaderPresenter.clearClient();
        this.productQuestPresenter.view.showSelectedClient = false;
    }
    clearAdviser() {
        this.invoiceHeaderPresenter.clearAdviser();
    }
    clearSeller() {
        this.invoiceHeaderPresenter.clearSeller();
    }
    evaluateProcess() {
        this.invoiceHeaderPresenter.evaluateProcess();
        this.productQuestPresenter.view.showSelectedClient = false;
    }
    evaluateSalesExpress() {
        this.invoiceHeaderPresenter.evaluateSalesExpress();
    }
    evaluateSellers() {
        this.invoiceHeaderPresenter.evaluateSellers();
        this.onSearchUser();
    }
    evaluateBilleton() {
        this.invoiceHeaderPresenter.setBilleton();
    }

    blockUiWhenOrderAproved() {
        if (this.updateOrder && (this.updateOrder.creditStatus === CreditStatus.APPROVED ||
            this.updateOrder.creditStatus === CreditStatus.APPROVED_OVERDRAFT)) {
            return true;
        } else {
            return false;
        }
    }

    blockUiWhenEditQuotation() {
        if (this.dataProvider.quotationStatus === null) {
            return false;
        } else {
            return true;
        }
    }

    showConfirmAdminPassword() {
        if (this.productQuestPresenter.validateProductCredired()) {
            setTimeout(() => {
                this.isAdminPriceDiscount = false;
            }, 2000);
            this.showWarn('Atención', 'Tiene agregado código credired.');
        } else {
            this.showAdminPassword();
        }
    }

    showAdminPassword() {
        this.dataProvider.isAdminPriceDiscount = false;
        const name = 'Credenciales de Administrador';
        if (this.isAdminPriceDiscount && this.defaultConfigurationService.showCredentialsAdminPriceDiscount) {
            this.ref = this.dialogService.open(AdminPricePasswordComponent, {
                showHeader: true,
                closeOnEscape: true,
                height: '400px',
                width: '400px',
                data: { permissions: DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS, name: name }
            });

            this.ref.onClose.subscribe((aproveAdmin: boolean) => {
                this.dataProvider.isAdminPriceDiscount = aproveAdmin;
                if (!aproveAdmin) {
                    this.isAdminPriceDiscount = false;
                }
                this.invoiceHeaderPresenter.setAdminPriceDiscount();
            });
        }
        this.invoiceHeaderPresenter.setAdminPriceDiscount();

        if (this.isAdminPriceDiscount) {
            this.geTotalAmountPreviousMontht();
        }

    }

    showAdminPasswordCredired() {
        this.dataProvider.isAdminPriceDiscount = false;
        const name = 'Credenciales de Administrador';
        if (this.isAdminPriceDiscount && this.defaultConfigurationService.showCredentialsAdminPriceDiscount) {
            this.ref = this.dialogService.open(AdminPricePasswordComponent, {
                showHeader: true,
                closeOnEscape: true,
                height: '400px',
                width: '400px',
                data: { permissions: DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS, name: name }
            });

            this.ref.onClose.subscribe((aproveAdmin: boolean) => {
                this.dataProvider.isAdminPriceDiscount = aproveAdmin;
                if (!aproveAdmin) {
                    this.isAdminPriceDiscount = false;
                }
                this.invoiceHeaderPresenter.setAdminPriceDiscountCredired();
            });
        }
        this.invoiceHeaderPresenter.setAdminPriceDiscountCredired();

        if (this.isAdminPriceDiscount) {
            this.geTotalAmountPreviousMontht();
        }

    }

    geTotalAmountPreviousMontht() {
        return this.getSalesPreviusMonth.getMaxDiscountAdminPrice().toPromise().then((res: number) => {
            this.dataProvider.amountInitialBox = new Decimal(res).toDecimalPlaces(2).toNumber();
            this.dataProvider.amountInitialBox = this.dataProvider.amountInitialBox < 0 ? 0 : this.dataProvider.amountInitialBox;
        });
    }

    searchAdvance()  {
            this.ref = this.dialogService.open(SearchAdvancesComponent, {
                showHeader: true,
                header: 'ANTICIPOS ASOCIADOS AL CLIENTE',
                height: '350px',
                width: '75%',
                closeOnEscape: true,
                data: {
                    client: this.selectedClient,
                }
            });
    }
}
