import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SettingPresenter } from '../main-components/panel/shared/setting/presenter/setting.presenter';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    @BlockUI() blockUI: NgBlockUI;
    constructor(public router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        setTimeout(() => {
            SettingPresenter.doingSomething = true;

        }, 200);
        const authorization = localStorage.getItem('authorization');
        if (authorization !== null && req.url.indexOf('/login') < 0) {
            const headers = req.headers.set('Authorization', authorization);
            req = req.clone({ headers });
        }
        return next.handle(req).
            pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.blockUI.stop();
                        SettingPresenter.doingSomething = false;
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    SettingPresenter.doingSomething = false;
                    this.blockUI.stop();
                    if (error.status === 403) {
                        localStorage.clear();
                        this.router.navigate(['/']);
                        return;
                    }
                    return throwError(error);
                }));
    }
}
