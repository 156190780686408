import { Injectable } from '@angular/core';
import { ClientModel } from '../models/client.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GetFinalCostumerService {

  constructor(private http: HttpClient) {
     }
     public getFinalCostumer(): Promise<ClientModel> {
      return new Promise((resolve, reject) => {
        this.http.get<ClientModel>(environment.apiUrl + '/getFinalConsumer')
          .subscribe(response => {
            resolve(response);
          }, error => {
            reject(error);
          });
      });
    }


}
