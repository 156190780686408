
import * as $params from './adviser.action';
import { ClientModel } from '../models/client.model';

export interface IAppState {
    adviser: ClientModel;
    showAdviserSelected: boolean;
}

export const INITIAL_STATE: IAppState = {
    adviser: null,
    showAdviserSelected: false
};

export function AdviserReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {
    console.log(action);
    switch (action.type) {
        case $params.AdviserActions.ADD_ADVISER:
            return Object.assign({}, state, {
                adviser: action.adviser,
                showAdviserSelected: true
            });
        case $params.AdviserActions.CLEAR_ADVISER:
            return Object.assign({}, state, {
                adviser: null,
                showAdviserSelected: false
            });

        default:
            return state;
    }
}
