import { View } from './view';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PaymentMethod } from '../models/paymentmethod';
import { SettingPresenter } from '../main-components/panel/shared/setting/presenter/setting.presenter';
import { GeneralConfigurations } from '../services/defaultconfigurations.service';
import { ClientModel, UserModel } from '../models/client.model';


export abstract class AbstractView implements View {

    @BlockUI() blockUI: NgBlockUI;
    auth = JSON.parse(localStorage.getItem('permisions'));
    constructor(public service: MessageService, public router?: Router) { }

    showMessage(message: string) {
        this.showWarn('Advertencia', message);
    }

    showInfo(title: string, detail: string) {
        this.showToast('info', title, detail);

    }
    showSuccess(title: string, detail: string) {
        this.showToast('success', title, detail);

    }
    showWarn(title: string, detail: string) {
        this.showToast('warn', title, detail);
    }
    showError(title: string, detail: string) {
        this.showToast('error', title, detail);
    }
    showBackException(error: any) {
        if (error instanceof HttpErrorResponse) {
            this.showError('Atención', error.error.message);
        } else {
            this.showError('Atención', 'Algo salio mal buscando ordenes');
        }
    }
    showSwitchException(error: any) {
        this.showError('Error ' + error.message.code, error.message.description);
    }

    showToast(type: string, title: string, detail: string) {
        this.service.add({
            key: 'tst',
            severity: type,
            summary: title,
            detail: detail
        });
    }
    redirectTo(ruta: string) {
        this.router.navigate([ruta]);
    }

    blockUi() {
        SettingPresenter.doingSomething = true;
        this.blockUI.start('Cargando...');
    }
    allowUi() {
        SettingPresenter.doingSomething = false;
        this.blockUI.stop();
    }
    showPdf(data) {
        const pdfAsDataUri = 'data:application/pdf;base64,' + data.pdf;
        const array = this.convertDataURIToBinary(pdfAsDataUri);
        const pdfByte = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(pdfByte);

        if (GeneralConfigurations.blink) {
            const iframe = document.createElement('iframe');
            iframe.src = fileURL;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.contentWindow.print();
        } else {
            const a: HTMLAnchorElement = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        }
    }


    convertDataURIToBinary(dataURI) {
        const BASE64_MARKER = ';base64,';
        const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = dataURI.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    getAuth(permision: string) {
        if (this.auth) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.auth.length; i++) {
                if (this.auth[i].authority === permision) {
                    return true;
                }
            }
        }
        return false;
    }

    translatePaymentMethod(payment: PaymentMethod) {
        switch (payment) {
            case PaymentMethod.BANK_TRANSFER:
                return 'Transferencia bancaria';
            case PaymentMethod.CASH:
                return 'Efectivo';
            case PaymentMethod.CHECK:
                return 'Cheque';
            case PaymentMethod.CREDIT:
                return 'Crédito';
            case PaymentMethod.CREDIT_CARD:
                return 'Tarjeta de crédito';
            case PaymentMethod.CREDIT_MEMO:
                return 'Nota de crédito';
            case PaymentMethod.DEBIT:
                return 'Tarjeta de débito';
            case PaymentMethod.WITHHOLD:
                return 'Retencion';
            default:
                return payment;
        }

    }

    csvDownload(filename: string, rows: object[]) {
      if (!rows || !rows.length) {
        return;
      }
      const separator = ',';
      const keys = Object.keys(rows[0]);
      const csvContent =
        keys.join(separator) +
        '\n' +
        rows.map(row => {
          return keys.map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            cell = cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator);
        }).join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }

    setName(client: ClientModel): string {
        let name = '';
        if (client.firstName) {
            name = client.firstName;
        }
        if (client.middleName) {
            name = name + ' ' + client.middleName;
        }
        if (client.lastName) {
            name = name + ' ' + client.lastName;
        }
        return name;
    }
    setNameSeller(user: UserModel): string {
        let name = '';
        if (user.userName) {
            name = user.userName;
        }
        return name;
    }
}
