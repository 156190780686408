import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { SearchClientComponent } from './search-client/search-client.component';
import { ManageClientComponent } from './manage-client/manage-client.component';
import { AutoCompleteModule, MessageService, KeyFilterModule, OverlayPanelModule, CalendarModule, TooltipModule } from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ClientMenuComponent } from './client-menu/client-menu.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutes } from 'src/app/app.routes';
import { ReportClientsByProductsComponent } from './report-clients-by-products/report-clients-by-products.component';
import { MatExpansionModule, MatPaginatorModule } from '@angular/material';
import { SharedModule } from 'src/app/main-components/shared/shared/shared.module';
import { SearchAdvancesComponent } from './search-advances/search-advances.component';

@NgModule({
    declarations: [
        SearchClientComponent,
        ManageClientComponent,
        ClientMenuComponent,
        ReportClientsByProductsComponent,
        SearchAdvancesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TableModule,
        PaginatorModule,
        AutoCompleteModule,
        ButtonModule,
        ScrollPanelModule,
        ToastModule,
        KeyFilterModule,
        OverlayPanelModule,
        MatTabsModule,
        MatButtonModule,
        MatRippleModule,
        MatIconModule,
        AppRoutes,
        CalendarModule,
        TooltipModule,
        MatExpansionModule,
        MatPaginatorModule,
        SharedModule
    ],
    providers: [
        MessageService
    ]

})
export class ClientsModule {
}
