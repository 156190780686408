import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { ReportProductsByClient, ProductsByClient, ProductByClient } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { ProductService } from 'src/app/services/product.service';
import { ReportClientsByProductsView } from '../report-clients-by-products.view';

@Injectable({
    providedIn: 'root',
})
export class ReportClientsByProductsPresenter implements Presenter {
    view: ReportClientsByProductsView;
    dataExample: ProductsByClient[] = [];

    constructor(
        private productService: ProductService,
        private presenterSetting: SettingPresenter,
        private clientService: ClientService,

    ) {

    }

    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.getReport();
    }
    clearClient() {
        this.view.showSelectedClient = false;
        this.view.selectedClient = null;
        this.getReport();
    }
    findReportsByProduct() {
        if (this.view.selectedProduct && typeof this.view.selectedProduct !== 'string') {
            this.view.showSelectedProduct = true;
            this.getReport();
        }
    }
    findReportsByClient() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            this.view.showSelectedClient = true;
            this.getReport();
        }
    }
    getReport() {
        this.view.reports = [];
        let initDate = '';
        let endDate = '';
        let productId = '';
        let clientId = '';
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.selectedProduct) {
            productId = this.view.selectedProduct.productId;
        }
        if (this.view.selectedClient) {
            clientId = this.view.selectedClient.clientId;
        }

        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }

        this.view.blockUi();
        this.clientService.getProductByClient(clientId, productId, initDate, endDate).subscribe((res: ProductsByClient[]) => {
            this.view.reportsCSV = res;
            this.filterRecords(res);
        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar registro');
        });

    }
    getReportClient() {
        this.view.reports = [];
        let initDate = '';
        let endDate = '';
        let productId = '';
        let clientId = '';
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.selectedProduct) {
            productId = this.view.selectedProduct.productId;
        }
        if (this.view.selectedClient) {
            clientId = this.view.selectedClient.clientId;
        }

        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        this.view.blockUi();
        this.clientService.getReportClient(clientId, productId, initDate, endDate).subscribe((res: ProductsByClient[]) => {
            this.view.showPdf(res);
        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar registro');
        });
    }


    filterRecords(data: ProductsByClient[]) {
        data.forEach(line => {
            const client = this.view.reports.find(clientReport => line.clientId === clientReport.client.clientId);
            if (client) {
                const product: ProductByClient = {
                    productId: line.productId,
                    referenceCode: line.referenceCode,
                    productName: line.productName,
                    measureUnitName: line.measureUnitName.toUpperCase(),
                    quatity: line.quantity,
                    date: line.date,
                    unitPrice: line.unitPrice
                };
                client.products.push(product);
            } else {
                const newClient: ReportProductsByClient = {
                    client: {
                        clientId: line.clientId,
                        firstName: line.firstName,
                        middleName: line.middleName,
                        lastName: line.lastName,
                        cellPhone: line.cellPhone,
                        phone: line.phone,
                        email: line.email,
                        dni: line.dni,
                        allowClientAdvance: true
                    },
                    products: [{
                        productId: line.productId,
                        referenceCode: line.referenceCode,
                        productName: line.productName,
                        measureUnitName: line.measureUnitName.toUpperCase(),
                        quatity: line.quantity,
                        date: line.date,
                        unitPrice: line.unitPrice
                    }],
                    panelOpenState: true
                };
                this.view.reports.push(newClient);
            }
        });
        this.view.totalElements = this.view.reports.length;
        this.view.selectedResult = this.view.reports.slice(0, this.view.pageSize);
    }
    onSearchProduct() {
        const searchValue = this.view.searchProduct;
        const page = 0;
        const size = 20;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        this.productService.searchProduct(searchValue, page, size, prceListId, null).then(data => {

            this.view.products = data['data'];

            if (this.view.products.length <= 0) {
                this.view.showError('', 'Búsqueda sin registros');
                this.view.products = [];
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }
    onSearchClient() {
        const searchValue = this.view.searchClient;
        const page = 0;
        const size = 20;
        if (searchValue.length < 3) {
            return;
        }
        this.clientService.getClients(searchValue, page, size).subscribe((res: any) => {
            this.view.clients = res['data'];

        }, () => {
            this.view.clients = [];
            this.view.showError('', 'Error al buscar clientes');
        });
    }
    dowloadReprot() {
        if (this.view.totalElements <= 0) {
            return;
        }
        let initDate = '';
        let endDate = '';
        let date = '';
        const format = 'dd-MM-yyyy';
        const locale = 'en-US';
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        date = initDate + ' / ' + endDate;
        const reports = this.view.reportsCSV.map(line => {
            return {
                Dni: line.dni,
                Nombre: line.firstName + ' ' + line.middleName + ' ' + line.lastName,
                Telefono: line.cellPhone ? line.cellPhone : line.phone,
                Mail: line.email,
                Fecha: line.date,
                Codigo: line.referenceCode,
                Producto: line.productName,
                'Unidad de Medida': line.measureUnitName,
                Cantidad: line.quantity
            };

        });
        const reportsToCSV = JSON.parse(JSON.stringify(reports,
            [
                'Dni',
                'Nombre',
                'Telefono',
                'Mail',
                'Fecha',
                'Codigo',
                'Producto',
                'Unidad de Medida',
                'Cantidad',
            ], 2));
        this.view.csvDownload('Reporte clientes ' + ' ' + date + '.csv', reportsToCSV);
    }
}
