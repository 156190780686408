import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UiUtils } from '../../../../../core/ui-utils';
import { IdentificationType } from '../../../../../models/identificationtype.model';
import { AddressModel } from 'src/app/models/address.model';
import { Country } from 'src/app/models/country.model';
import { State } from 'src/app/models/state.model';
import { City } from 'src/app/models/city.model';
import { Sector } from 'src/app/models/sector.model';
import { TaxPayerType } from 'src/app/models/taxpayertype.model';
import { ManageClientView } from './manage-client.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { ManageClientPresenter } from './presenter/manage-client.presenter';
import { MessageService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { Client, ClientModel } from 'src/app/models/client.model';
import { PaymentTerm } from 'src/app/models/paymentterm';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { isNumber } from 'util';
import { Profession } from 'src/app/models/profession.model';


@Component({
    selector: 'app-manage-client',
    templateUrl: './manage-client.component.html',
    styleUrls: ['./manage-client.component.scss'],
    providers: [MessageService]
})


export class ManageClientComponent extends AbstractView implements OnInit, ManageClientView, AfterViewInit {


    @ViewChild('txtCed', { read: false, static: false }) txtCed: ElementRef;
    dni: string;
    identificationType: { label: string, value: IdentificationType }[];
    identificationTypeSelected: IdentificationType = { description: 'Cédula' };
    firstName: string;
    middleName = '';
    lastName: string;
    tradeName: string;
    taxPayerType: { label: string, value: TaxPayerType }[];
    taxPayerTypeSelected: TaxPayerType;
    allowClientAdvance: boolean;
    address: AddressModel = {
        addressId: '',
        street: '',
        description: '',
        sectorPresenter: {
            sectorId: '',
            name: ''
        }
        ,
        phone: '',
        cellPhone: ''
    };
    mail: string;
    countries: { label: string, value: Country }[];
    countrySelected: Country = {
        countryId: '',
        name: ''
    };

    states: { label: string, value: State }[];
    stateSelected: State = {
        stateId: '',
        name: '',
        isDefault: true
    };
    cities: { label: string, value: City }[];
    citySelected: City = {
        cityId: '',
        name: '',
        isDefault: true
    };
    sectors: { label: string, value: Sector }[];
    sectorSelected: Sector = {
        sectorId: '',
        name: ''
    };
    paymentTerm: { label: string, value: PaymentTerm }[];
    paymentTermSelected: PaymentTerm = {
        paymentTermId: '',
        description: ''
    };

    paymentMethod: { label: string, value: PaymentMethod }[];
    paymentMethodSelected: null;
    paymentMethodCash: PaymentMethod = PaymentMethod.CASH;
    filter = 'pint';
    maxLenght = 10;
    professions = [];
    professionSelected: Profession;
    clientSelected: any;
    isClientUpdate = false;
    search = '';
    totalElements = 0;
    isClientSearch = false;
    constructor(
        public service: MessageService,
        public router: Router,
        public util: UiUtils,
        public manageClientPresenter: ManageClientPresenter,
        public ref: DynamicDialogRef,
        private hotKey: HotkeysService,
        public config: DynamicDialogConfig,
    ) {
        super(service, router);
        manageClientPresenter.view = this;
        this.shortCuts();
        this.isClientUpdate = config.data.isClient;
        if (!this.isClientUpdate) {
            const id: string = config.data.client;
            if (!isNaN(Number(id))) {
                if (id.length === 10) {
                    this.dni = id;
                } else if (id.length === 13) {
                    this.dni = id;
                }
            }
        } else {
            this.clientSelected = config.data.client;
            this.dni = this.clientSelected.dni;
            this.firstName = this.clientSelected.firstName;
            this.middleName = this.clientSelected.middleName.length > 0 ? this.clientSelected.middleName : ' ';
            this.lastName = this.clientSelected.lastName.length > 0 ? this.clientSelected.lastName : '  ';
            this.taxPayerTypeSelected = this.clientSelected.taxPayerTypePresenter;
            this.mail = this.clientSelected.email;
            if (this.clientSelected.addressPresenters.length > 0) {
                this.address.addressId =
                    this.clientSelected.addressPresenters[0].addressId;
                this.address.description =
                    this.clientSelected.addressPresenters[0].description;
                this.address.cellPhone =
                    this.clientSelected.addressPresenters[0].cellPhone;
                this.address.phone = this.clientSelected.addressPresenters[0].phone;
            }
            this.tradeName = this.clientSelected.tradeName;
            this.paymentTermSelected = this.clientSelected.paymentTermPresenter;
        }


    }

    ngAfterViewInit(): void {
        this.txtCed.nativeElement.focus();
    }

    shortCuts() {
        this.hotKey.add(new Hotkey(['ctrl+g', 'alt+g', 'ctrl+s', 'alt+s'], (event: KeyboardEvent): boolean => {
            this.onSaveClient();
            return false;
        }, ['input', 'textarea'], 'Guardar'));
    }
    ngOnInit() {
        this.manageClientPresenter.loadInitData();
    }

    setStateClient() {
        if (this.clientSelected.addressPresenters.length > 0) {
            this.states.forEach(state => {
                if (state.value.stateId === this.clientSelected.addressPresenters[0].sectorPresenter.cityPresenter.statePresenter.stateId) {
                    this.stateSelected = state.value;
                }
            });
        }
    }

    setCityClient() {
        this.cities.forEach(city => {
            if (city.value.cityId === this.clientSelected.addressPresenters[0].sectorPresenter.cityPresenter.cityId) {
                this.citySelected = city.value;
            }
        });
    }

    setSectorClient() {
        this.sectors.forEach(sector => {
            if (sector.value.sectorId === this.clientSelected.addressPresenters[0].sectorPresenter.sectorId) {
                this.address.sectorPresenter = sector.value;
            }
        });
    }

    setTaxPayerClient() {
        this.taxPayerType.forEach(taxPayer => {
            if (taxPayer.value.taxPayerTypeId === this.clientSelected.taxPayerTypePresenter.taxPayerTypeId) {
                this.taxPayerTypeSelected = taxPayer.value;
            }
        });
    }

    setProfessionClient() {
        this.professions.forEach(profession => {
            if (profession.referenceId === this.clientSelected.professionPresenter.referenceId) {
                this.professionSelected = profession;
            }
        });
    }

    setIdentificationClient() {
        this.identificationType.forEach(identificationType => {
            if (identificationType.value.identificationTypeId === this.clientSelected.identificationTypePresenter.identificationTypeId) {
                this.identificationTypeSelected = identificationType.value;
            }
        });
    }

    closeView(newClient: ClientModel) {
        this.ref.close(newClient);
    }

    onCountryChanged() {
        this.stateSelected = {
            stateId: '',
            name: '',
            isDefault: true
        };
        this.manageClientPresenter.loadStates();
    }

    onStateChange() {
        this.citySelected = {
            cityId: '',
            name: '',
            isDefault: true
        };
        this.address.sectorPresenter = null;
        this.manageClientPresenter.loadCities();
        this.manageClientPresenter.loadSectors();

    }

    onCityChange() {
        this.manageClientPresenter.loadSectors();
    }

    onSaveClient() {
        this.manageClientPresenter.onSave();
    }

    onPaymentMethodChange() {
        this.manageClientPresenter.loadPaymentMethod();
    }
    evaluateFilter() {
        this.manageClientPresenter.evaluateFilter();
    }

    onSearchClient(event) {
        this.search = event.target.value;
        if (!this.isClientUpdate) {
            this.manageClientPresenter.onSearchClient();
        }
    }
}
