import { Injectable } from '@angular/core';
import { CashRegisterModel } from 'src/app/models/cash-register.model';
import { CashRegisterService } from 'src/app/services/cash-register.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GenerateAdvanceView } from '../generate-advance.view';
import { AdvanceService } from 'src/app/services/advance.service';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateAdvancePresenter {
  view: GenerateAdvanceView;
  constructor(
    public cashRegisterService: CashRegisterService,
    public advanceService: AdvanceService,
    public defConf?: DefaultconfigurationsService) { }

  validate() {
    if (isNaN(this.view.amount)) {
      this.view.showError('', 'Ingrese un monto correcto');
      return;
    }
    if (this.view.amount < this.defConf.advanceMinAmount) {
      this.view.showError('', 'el avance mínimo es de $' + this.defConf.advanceMinAmount);
      return;
    }
    const amount = this.view.amount;
    const generateAdvanceData: CashRegisterModel = { amount: amount, observation: this.view.observation };
    this.view.blockUi();
    this.cashRegisterService.generateAdvance(generateAdvanceData).then((data) => {
      this.view.showPdf(data);
      this.view.showSuccess('', 'Avance Realizado');
      this.view.closeModal();
      this.advanceService.advanceStatus();
    }).catch((error) => {

      if (error instanceof HttpErrorResponse) {
        this.view.showError('Atención', error.error.message);
      } else {
        this.view.showError('Atención', 'Algo salio mal');
      }
    });

  }
}
