import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { UiUtils } from '../../../../core/ui-utils';
import { AbstractView } from '../../../../core/abstract_view';
import { AdminPricePasswordView } from './admin-price.password.view';
import { AdminPricePasswordPresenter } from './presenter/admin-price-password.presenter';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-admin-price-password',
  templateUrl: './admin-price-password.component.html',
  styleUrls: ['./admin-price-password.component.scss']
})
export class AdminPricePasswordComponent extends AbstractView implements OnInit, AdminPricePasswordView, AfterViewInit {
  user: string;
  password: string;
  permission: DomainAction;
  name: string;
  @ViewChild('txtUser', { read: false, static: false }) txtUser: ElementRef;
  constructor(
    public adminPricePasswordPresenter: AdminPricePasswordPresenter,
    public router: Router,
    public messageService: MessageService,
    public uiUtils: UiUtils,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    super(messageService, router);
    this.permission = config.data.permissions;
    this.name = config.data.name;
    this.adminPricePasswordPresenter.view = this;
  }
  ngAfterViewInit() {
    this.txtUser.nativeElement.focus();
  }
  ngOnInit() {
  }

  onClickAcceptButton() {
    this.adminPricePasswordPresenter.acceptLoging();
  }
  closeView() {
    this.ref.close(true);
  }

}
