import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { DocumentType, KardexDetailModel, Product } from 'src/app/models/product.model';
import { KardexReportView } from './kardex-report.view';
import { KardexReportPresenter } from './presenter/kardex-report.presenter';

@Component({
  selector: 'app-kardex-report',
  templateUrl: './kardex-report.component.html',
  styleUrls: ['./kardex-report.component.scss']
})

export class KardexReportComponent extends AbstractView implements OnInit, KardexReportView {

  fileName = 'ExcelSheet.xlsx';

  search = '';
  page = 0;
  size = 30;
  products: Product[];
  selectedProduct: Product;
  creditCardPayment: false;
  showSelectedProduct: false;
  reports: KardexDetailModel[];
  initDate = new Date();
  endDate = new Date();
  visible = false;
  warehauses = null;

  constructor(
    private messageService: MessageService,
    public presenter: KardexReportPresenter,
    public router: Router,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService) {
    super(messageService, router);
    presenter.view = this;

  }

  ngOnInit() {

  }
  clearProduct() {
    this.presenter.clearProduct();
  }

  onClick() {
    this.presenter.getKarkexStock();
  }
  addProduct() {
    this.presenter.findReportsByProduct();
  }
  onSearchProduct(event) {
    this.search = event.query;
    this.presenter.onSearchProductPos();
  }

  onChangeListPrice() {
    this.presenter.onSearchProductPos();
  }

  clearSearch() {
    this.selectedProduct = null;
  }

  getNumber(rowData: number) {
    if (!rowData) {
      return 0;
    } else {
      return rowData;
    }

  }
  getTranslate(document: DocumentType) {
    switch (document) {
      case DocumentType.INVOICE: return 'Factura';
      case DocumentType.CREDIT_NOTE: return 'Nota de Crédito';
      case DocumentType.DEBIT_NOTE: return 'Nota de Débito';
      case DocumentType.REMISSION_GUIDE: return 'Despacho';
      case DocumentType.WITHHOLDING: return 'Retención';
      case DocumentType.ORDER: return 'Orden';
      case DocumentType.TRANSFER: return 'Transferencia';
      case DocumentType.ADJUSTMENT: return 'Ajuste';
      case DocumentType.INITIAL_STOCK: return 'Stock Inicial';
      case DocumentType.PURCHASE: return 'Compra';
      case DocumentType.QUOTATION: return 'Cotización';
      case DocumentType.OTHER: return 'Otro';
    }
  }

  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
