

export interface RolModel {
    roles: AuthorityModel[];
}

export interface AuthorityModel {
    authority: DomainAction;
}
export enum DomainAction {
    ALLOW_POS_LIST_INVOICES = 'ALLOW_POS_LIST_INVOICES',
    ALLOW_POS_LIST_CLIENTS = 'ALLOW_POS_LIST_CLIENTS',
    ALLOW_POS_LIST_ORDERS = 'ALLOW_POS_LIST_ORDERS',
    ALLOW_POS_LIST_CREDIT_MEMOS = 'ALLOW_POS_LIST_CREDIT_MEMOS',
    ALLOW_POS_REPORT_CASH_OPENING = 'ALLOW_POS_REPORT_CASH_OPENING',
    ALLOW_POS_REPORT_CASH_CLOSE = 'ALLOW_POS_REPORT_CASH_CLOSE',
    ALLOW_POS_REPORT_CLIENT_ADVANCE = 'ALLOW_POS_REPORT_CLIENT_ADVANCE',
    ALLOW_POS_REPORT_SALES = 'ALLOW_POS_REPORT_SALES',
    ALLOW_POS_CASH_OPEN = 'ALLOW_POS_CASH_OPEN',
    ALLOW_POS_CASH_CLOSE = 'ALLOW_POS_CASH_CLOSE',
    ALLOW_POS_REALIZE_ADVANCES = 'ALLOW_POS_REALIZE_ADVANCES',
    ALLOW_POS_CONFIGURATIONS = 'ALLOW_POS_CONFIGURATIONS',
    ALLOW_POS_LIST_SHORTCUTS = 'ALLOW_POS_LIST_SHORTCUTS',
    ALLOW_POS_APPROVE_CREDIT_MEMOS = 'ALLOW_POS_APPROVE_CREDIT_MEMOS',
    ALLOW_POS_APPROVE_MOVEMENT_CASH = 'ALLOW_POS_APPROVE_MOVEMENT_CASH',
    ALLOW_POS_APROVE_ADMIN_PRICES = 'ALLOW_POS_APROVE_ADMIN_PRICES',
    ALLOW_POS_RESEND_DOCUMENTS = 'ALLOW_POS_RESEND_DOCUMENTS',
    ALLOW_POS_STOCK_REPORT = 'ALLOW_POS_STOCK_REPORT',
    ALLOW_PAYMENTS_COLLECTIONS = 'ALLOW_PAYMENTS_COLLECTIONS',
    ALLOW_POS_ADMIN_PRICES = 'ALLOW_POS_ADMIN_PRICES'
}
