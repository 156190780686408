import { DatePipe, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { ClientModel } from 'src/app/models/client.model';
import { PaymentCollectionsPresenter } from 'src/app/models/payment-collections.model';
import { PaymentCollectionsAvailableServicesPresenter } from 'src/app/models/receipts-payments';
import { ClientService } from 'src/app/services/client.service';
import { ReceiptsPaymentsService } from 'src/app/services/receipts-payments.service';
import { ReportService } from 'src/app/services/report.service';

import { ReceiptsPaymentsReportView } from '../receipts-payments-report.view';

@Injectable({
  providedIn: 'root',
})
export class ReceiptsPaymentsReportPresenter implements Presenter {
  view: ReceiptsPaymentsReportView;

  datepipe: DatePipe = new DatePipe('en-US');
  constructor(
    private clientService: ClientService,
    private reportService: ReportService,
    private receiptsPaymentsService: ReceiptsPaymentsService
  ) { }
  searchReports() {
    this.view.blockUi();
    let initDate = '';
    let endDate = '';
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    if (this.view.initDate) {
      initDate = formatDate(this.view.initDate, format, locale);
    }
    if (this.view.endDate) {
      endDate = formatDate(this.view.endDate, format, locale);
    }
    let paymentCollectionsStatus = '';
    if (this.view.paymentCollectionsStatus) {
      paymentCollectionsStatus = this.view.paymentCollectionsStatus['value'];
    }
    this.receiptsPaymentsService
      .getPaymentCollectionsTransaction(
        this.view.idUser,
        initDate,
        endDate,
        this.view.documentNo,
        paymentCollectionsStatus,
        this.view.codeProduct,
        this.view.counterpart,
        this.view.dniClient,
        this.view.page,
        this.view.size
      )
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.view.paymentCollections = res['data'];
            this.view.totalElements = res.totalElements;
          } else {
            this.view.showSwitchException('Tu enlace sin registros');
          }
        },
        (error: HttpErrorResponse) => {
          this.view.paymentCollections = [];
          this.view.showBackException(error.error.message);
        }
      );
  }

  getAvailableServices() {
    this.view.blockUi();
    this.receiptsPaymentsService.getAvailableServices().subscribe(
      (res: PaymentCollectionsAvailableServicesPresenter) => {
        if (res.success) {
          this.view.respuesta = res;
          this.view.showSuccess('Consulta de servicios ', 'Ok');
        }
      },
      (errors: HttpErrorResponse) => {
        this.view.showBackException(errors.error.message);
      }
    );
  }
  printPaymentCollections() {
    this.view.blockUi();
    let initDate = '';
    let endDate = '';
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    if (this.view.initDate) {
      initDate = formatDate(this.view.initDate, format, locale);
    }
    if (this.view.endDate) {
      endDate = formatDate(this.view.endDate, format, locale);
    }
    let paymentCollectionsStatus = '';
    if (this.view.paymentCollectionsStatus) {
      paymentCollectionsStatus = this.view.paymentCollectionsStatus['value'];
    }
    this.receiptsPaymentsService
      .printPaymentCollections(
        this.view.idUser,
        initDate,
        endDate,
        this.view.documentNo,
        paymentCollectionsStatus,
        this.view.codeProduct,
        this.view.counterpart,
        this.view.dniClient
      )
      .subscribe(
        (res: any) => {
          this.view.showPdf(res);
        },
        (error: HttpErrorResponse) => {
          this.view.paymentCollections = [];
          this.view.showBackException(error.error.message);
        }
      );
  }

  onSearchClient() {
    const searchValue = this.view.searchClient;
    const page = this.view.pageClient;
    const size = this.view.sizeClient;
    if (searchValue.length < 3) {
      return;
    }
    if (page < 0) {
      return;
    }
    if (!size || size < 1) {
      return;
    }
    this.clientService
      .searchClient(searchValue, page, size)
      .then((data) => {
        const clients: ClientModel[] = data['data'];
        this.view.totalElements = data['totalElements'];
        this.view.clients = clients;
      })
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          this.view.showError('Atención', error.error.message);
        } else {
          this.view.showError('Atención', 'Algo salio mal');
        }
      });
  }
  cancelTransaction(transaction: PaymentCollectionsPresenter) {
    if (!transaction.idTransactionPaided) {
      this.view.showWarn('Advertencia', 'No se puede anular');
    }
    this.view.blockUi();
    this.receiptsPaymentsService.cancelTransaction(transaction.idTransactionPaided).subscribe((res: any) => {
      if (res.success !== null && res.success) {
        this.view.showSuccess('Éxito', 'Transacción anulada');
        this.searchReports();
        res.pdf = res.result.pdf;
        this.view.showPdf(res);
      } else {
        this.view.showExeption(res);
      }
    }, (error) => {
      this.view.showBackException(error);
    });
  }
  printConfirmTransaction(transaction: PaymentCollectionsPresenter) {
    if (!transaction.idTransactionPaided) {
      this.view.showWarn('Advertencia', 'No se puede imprimir');
    }
    this.view.blockUi();
    this.receiptsPaymentsService
      .printConfirmTransaction(transaction.idTransactionPaided)
      .subscribe(
        (res: any) => {
          if (res.success !== null && res.success) {
            this.view.showSuccess('Éxito', 'Transacción impresa');
            res.pdf = res.result.documentContent;
            this.view.showPdf(res);
          } else {
            this.view.showExeption(res);
          }
        },
        (error) => {
          this.view.showBackException(error);
        }
      );
  }

  printCancelTransaction(transaction: PaymentCollectionsPresenter) {
    if (!transaction.idTransactionPaided) {
      this.view.showWarn('Advertencia', 'No se puede imprimir');
    }
    this.view.blockUi();
    this.receiptsPaymentsService
      .printCancelTransaction(transaction.idTransactionPaided)
      .subscribe(
        (res: any) => {
          if (res.result.pdf) {
            this.view.showSuccess('Éxito', 'Transacción impresa');
            res.pdf = res.result.pdf;
            this.view.showPdf(res);
          } else {
            this.view.showExeption(res);
          }
        },
        (error) => {
          this.view.showBackException(error);
        }
      );
  }

  onSearchUser() {
    this.reportService
      .usersByCriterion(this.view.searchUser, 0, 5)
      .then((data) => {
        this.view.users = data['data'];
      })
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          this.view.showError('Atención', error.error.message);
        } else {
          this.view.showError('Atención', 'Algo salio mal');
        }
      });
  }
  getCSVPaymentCollections() {
    this.view.blockUi();
    let initDate = '';
    let endDate = '';
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    if (this.view.initDate) {
      initDate = formatDate(this.view.initDate, format, locale);
    }
    if (this.view.endDate) {
      endDate = formatDate(this.view.endDate, format, locale);
    }
    let paymentCollectionsStatus = '';
    if (this.view.paymentCollectionsStatus) {
      paymentCollectionsStatus = this.view.paymentCollectionsStatus['value'];
    }
    this.receiptsPaymentsService
      .printPaymentCollectionsCSV(
        this.view.idUser,
        initDate,
        endDate,
        paymentCollectionsStatus,
        this.view.codeProduct,
        this.view.counterpart,
        this.view.dniClient
      )
      .subscribe(
        (res: any) => {
          if (res) {
            this.view.paymentCollections = res;
            if (this.view.paymentCollections.length === 0) {
              this.view.paymentCollections = [];
              this.view.showError('', 'No hay datos para el reporte');
              return;
            } else {
              this.view.reportsCSV = this.view.paymentCollections.map(
                (line) => {
                  return {
                    FECHA: line.created,
                    USUARIO: line.userPresenter.userName,
                    PRODUCTO: line.nameProduct,
                    CLIENTE:
                      line.clientPresenter.fullName +
                      ' / ' +
                      line.clientPresenter.dni,
                    CONTRAPARTIDA: line.counterpart,
                    ESTADO: this.view.translatePaymentCollectionsStatus(
                      line.paymentCollectionsStatus
                    ),
                    VALOR: line.amountSinCommission,
                    COMISIÓN: line.commissionAmount,
                    TOTAL: line.amount,
                  };
                }
              );
            }
            this.productsToCSV();
          } else {
            this.view.showSwitchException('Tu enlace sin registros');
          }
        },
        (error: HttpErrorResponse) => {
          this.view.paymentCollections = [];
          this.view.showBackException(error.error.message);
        }
      );
  }

  productsToCSV() {
    const products = JSON.parse(
      JSON.stringify(
        this.view.reportsCSV,
        [
          'FECHA',
          'USUARIO',
          'PRODUCTO',
          'CLIENTE',
          'CONTRAPARTIDA',
          'ESTADO',
          'VALOR',
          'COMISIÓN',
          'TOTAL',
        ],
        2
      )
    );
    this.view.csvDownload('Reporte-Stock.csv', products);
  }
}
