import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, MessageService } from 'primeng/api';
import { bottomToTop, leftToLeft, righToLeft } from 'src/app/animations';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';
import { TransfersModel, TransferStatus } from 'src/app/models/transfer.mode';
import Swal from 'sweetalert2';
import { TransferService } from '../../services/transfer.service';
import { TransferProvider } from '../../transfer.provider';

@Component({
  animations: [
    leftToLeft,
    righToLeft,
    bottomToTop
  ],
  selector: 'app-aprove-transfer',
  templateUrl: './aprove-transfer.component.html',
  styleUrls: ['./aprove-transfer.component.scss']
})
export class AproveTransferComponent extends AbstractView implements OnInit {
  transfer: TransfersModel;
  selectedTransfer: TransfersModel;

  constructor(
    private ref: DynamicDialogRef,
    public messageService: MessageService,
    public transferProvider: TransferProvider,
    public transferService: TransferService,
  ) {
    super(messageService);

  }

  ngOnInit() {
    this.transfer = this.transferProvider.transfer;
    this.transferProvider.refAprove = this.ref;
    this.transferProvider.error = '';
  }
  showARejectTransfer() {
    this.transfer.transferStatus = TransferStatus.REJECTED;
    Swal.fire({
      text: '¿Desea rechazar la transferencia?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Rechazar'
    }).then((result) => {
      if (result.value) {
        this.transferProvider.setTransferStatus(this.transfer);
      }
    });
  }
  showApproveTransfer() {
    this.transfer.transferStatus = TransferStatus.ACCEPTED;

    Swal.fire({
      text: '¿Desea aprobar la transferencia?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aprobar'

    }).then((result) => {
      if (result.value) {
        this.transferProvider.setTransferStatus(this.transfer);
      }
    });
  }

  getTranslate(allowPartial: boolean) {
    if (allowPartial) {
      return 'SI';
    } else { return 'NO'; }
  }
  evaluateRole() {
    return this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS);
  }


}
