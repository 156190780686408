import { CreditMemoService } from 'src/app/services/credit-memo.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientModel } from 'src/app/models/client.model';
import { AdvanceService } from 'src/app/services/advance.service';
import { ClientService } from 'src/app/services/client.service';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { WarehouseService } from 'src/app/services/warehouse.service';

import { Presenter } from '../../../../../core/presenter';
import { Bank } from '../../../../../models/bank.model';
import { EmissionPointPresenter } from '../../../../../models/pointemission.model';
import { PriceList } from '../../../../../models/pricelist.model';
import { BankService } from '../../../../../services/bank.service';
import { DataProvider } from '../../../../../services/data.provider';
import { OrderService } from '../../../../../services/order.service';
import { PointEmissionService } from '../../../../../services/point-emission.service';
import { PricelistService } from '../../../../../services/pricelist.service';
import { TransferService } from '../../../pages/dashboard/tranfers/services/transfer.service';
import { SettingView } from '../setting.view';
import { CashMovementService } from 'src/app/services/cash-movement.service';
import { CashRegisterService } from 'src/app/services/cash-register.service';

@Injectable({
    providedIn: 'root',
})

export class SettingPresenter implements Presenter {
    public static doingSomething = false;
    view: SettingView;
    users: any[] = [];

    constructor(
        public orderService: OrderService,
        private pricelistService: PricelistService,
        private pointemissionlistService: PointEmissionService,
        public advanceService: AdvanceService,
        public defaultconfigurationsService: DefaultconfigurationsService,
        private wareHouseService?: WarehouseService,
        private dataProvider?: DataProvider,
        private clientService?: ClientService,
        private bankService?: BankService,
        private transferService?: TransferService,
        private invoiceService?: InvoiceService,
        private creditMemoService?: CreditMemoService,
        private cashMovementService?: CashMovementService,
        private cashRegisterService?: CashRegisterService
    ) {
    }

    loadInitData() {
        this.loadPriceList();
        this.forbiddenCustomer();
        this.loadPointemisionList();
        this.defaultconfigurationsService.getDefaultConf().catch(error => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Configuración por defecto', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal obteniendo configuracion por defecto');
            }
        });
        this.wareHouseService.getDefaultWarehouse();
        this.defaultconfigurationsService.getGeneralConfigurations();
        this.loadSpecialDayBank();
    }



    loadPriceList() {
        this.pricelistService.searchPriceList().then(priceLists => {
            const priceListToView: { label: string, value: PriceList }[] = [];
            priceLists.forEach(priceList => {
                priceListToView.push({ label: priceList.name, value: priceList });
            });
            this.view.priceList = priceListToView;
            this.view.selectedPrice = priceListToView[0].value;
        }).catch(() => {
            this.view.showError('Atención', 'Error al obtener lista de precios');
        });
    }

    loadPointemisionList() {
        this.pointemissionlistService.getPointEmissionList().then(pointEmissionList => {
            const pointEmissionListToView: { label: string, value: EmissionPointPresenter }[] = [];
            pointEmissionList.forEach(pEmissionList => {
                pointEmissionListToView.push({ label: pEmissionList.name, value: pEmissionList });
            });
            this.view.pointEmissionList = pointEmissionListToView;
            this.view.selectedPointEmission = pointEmissionListToView[0].value;
        }).catch(() => {
            this.view.showError('Atención', 'Ocurrio un error al obtener el punto de emission');
        });
    }

    getOrdersPending() {
        this.view.ordersPending = [];
        this.view.blockUi();
        this.orderService.getOrdersPending().subscribe((res: any) => {
            this.view.ordersPending = res;
            if (this.view.ordersPending && this.view.ordersPending.length > 0) {
                this.view.showDispachesPendingConfirmation();
            } else {
                this.view.showCloseCashRegisterCompponent();
            }
        }, (error: any) => {
            const err = JSON.parse(error.error);
            this.view.showError('', err.message);
        });
    }
    /* getPendingMovementCashes() {
        this.view.ordersPending = [];
        this.view.blockUi();
        this.orderService.getPendingMovementCashes().subscribe((data: boolean) => {
            if (data) {
                this.view.showPendingMovementCashes();
            } else {
                this.getOrdersPending();
            }
        }, (error: any) => {
            const err = JSON.parse(error.error);
            this.view.showError('', err.message);
        });
    } */
    forbiddenCustomer() {
        this.clientService.forbiddenCustomer().subscribe((res: ClientModel) => {
            this.dataProvider.forbiddenCustomer = res;
        }, () => {
            this.view.showError('', 'Algo salió mal al obtener clientes bloqueados');
        });
    }

    loadSpecialDayBank() {
        this.bankService.banksByCard().subscribe((res: Bank[]) => {
            res.forEach(bank => {
                if (bank.promoBank) {
                    this.dataProvider.banksSpecialDay.push(bank);
                    let name = bank.name.toLowerCase();
                    name = name[0].toUpperCase() + name.slice(1);

                    this.dataProvider.banksSpecialDayNames =
                        this.dataProvider.banksSpecialDayNames + name + ', ';
                }
            });
            if (this.dataProvider.banksSpecialDayNames.length) {
                this.dataProvider.banksSpecialDayNames = this.dataProvider.banksSpecialDayNames.slice(0, -2);
            }
        }, () => {
            this.view.showError('', 'Algo salió mal al buscar promociones bancarias');
        });
    }

    getPendingCreditMemos() {
        this.view.blockUi();
        this.creditMemoService.getPendingCreditMemos().subscribe((data: boolean) => {
            if (data) {
                this.view.showPendingCreditMemos();
            } else {
                this.getPendingCashMovements();
            }
        }, (error: any) => {
            this.view.showBackException(error);
        });
    }

    getPendingCashMovements() {
        this.view.blockUi();
        this.cashMovementService.getPendingMovementCash().subscribe((data: boolean) => {
            if (data) {
                this.view.showPendingCashMovements();
            } else {
                this.getPendingTransfers();
            }
        }, (error: any) => {
            this.view.showBackException(error);
        });
    }

    getPendingTransfers() {
        this.view.blockUi();
        this.transferService.getPendingTransfers().subscribe((data: boolean) => {
            if (data) {
                this.view.showPendingTransfers();
            } else {
                this.getIvoicesWhitOutDispatch();
            }
        }, (error: any) => {
            this.view.showBackException(error);
        });
    }
    getIvoicesWhitOutDispatch() {
        this.view.blockUi();
        this.invoiceService.getPendingInvoicesWhitOutDispatch().subscribe((data: boolean) => {
            if (data) {
                this.view.showPendingInvoicesWhitOutDispatch();
            } else {
                this.getOrdersPending();
            }
        }, (error: any) => {
            this.view.showBackException(error);
        });
    }

    loadValueLockedbox() {
        this.cashRegisterService.getValueLockedbox().subscribe(
          (locked: any) => {
            this.view.lockedCloseCash = locked;
          }
        );
      }

}
