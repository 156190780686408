import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { Product } from 'src/app/models/product.model';
import { StockCollectionCenter } from 'src/app/models/stockcollectioncenter';
import { StockReportAcopioPresenter } from './presenter/stock-report-acopio.presenter';
import { StockReportAcopioView } from './stock-report-acopio.view';

@Component({
  selector: 'app-stock-report-acopio',
  templateUrl: './stock-report-acopio.component.html',
  styleUrls: ['./stock-report-acopio.component.scss']
})
export class StockReportAcopioComponent extends AbstractView implements OnInit, StockReportAcopioView {

  fileName = 'ExcelSheet.xlsx';

  search = '';
  page = 0;
  size = 20;
  products: Product[];
  selectedProduct: Product;
  creditCardPayment: false;
  showSelectedProduct: false;
  reports: StockCollectionCenter[];

  pageProd = 0;
  sizeProd = 20;
  reportsCSV = [];
  totalElements: 0;

  constructor(
    private messageService: MessageService,
    public presenter: StockReportAcopioPresenter,
    public router: Router,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService) {
    super(messageService, router);
    presenter.view = this;
  }
  ngOnInit() {
    this.presenter.paginatedProductStock(false);

  }
  clearProduct() {
    this.presenter.clearProduct();
  }

  addProduct() {
    this.presenter.findReportsByProduct();
  }
  onSearchProduct(event) {
    this.search = event.query;
    this.presenter.onSearchProductPos();
  }

  onChangeListPrice() {
    this.presenter.onSearchProductPos();
  }

  clearSearch() {
    this.selectedProduct = null;
  }

  getNumber(rowData: number) {
    if (!rowData) {
      return 0;
    } else {
      return rowData;
    }

  }

  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  paginate($event) {
    this.page = $event['page'];
    this.presenter.paginatedProductStock(false);
  }
}

