import * as $params from './advance.action';
import { Payment } from '../models/payment.model';
export interface IAppState {
    paymentsAdvance: Payment[];
}
export const INITIAL_STATE: IAppState = {
    paymentsAdvance: []
};
export function AdvanceReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {
    switch (action.type) {
        case $params.AdvanceActions.ADD_PAYMENT_TO_ADVANCE:
            const paymentsAdvance: Payment[] = state.paymentsAdvance;
            paymentsAdvance.push(action.paymentsAdvance);
            return Object.assign({}, state, {
                paymentsAdvance: paymentsAdvance
            });
        case $params.AdvanceActions.CLEAR_ADVANCE:
            return Object.assign({}, state, {
                paymentsAdvance: []
            });
        case $params.AdvanceActions.DELETE_ADVANCE:
            state.paymentsAdvance.splice(action.index, 1);
            return Object.assign({}, state, {
                paymentsAdvance: state.paymentsAdvance
            });
        case $params.AdvanceActions.CLEAR_ADVANCES:
            return Object.assign({}, state, {
                paymentsAdvance: []
            });
        default:
            return state;
    }
}
