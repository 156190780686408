import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';
import { TransferProvider } from '../../transfer.provider';

@Component({
  selector: 'app-aprove-devolution',
  templateUrl: './aprove-devolution.component.html',
  styleUrls: ['./aprove-devolution.component.scss']
})
export class AproveDevolutionComponent extends AbstractView implements OnInit {

  constructor(
    public ref: DynamicDialogRef,
    public transferProvider: TransferProvider,
    public messageService: MessageService,

    ) {
      super(messageService);

     }

  ngOnInit() {
  }
  closeView() {
    this.ref.close(true);
  }
  evaluateRole() {
    return this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS);
  }

}
