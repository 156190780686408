import { Injectable } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { EnterPasswordView } from '../enter-password.view';
import { HttpErrorResponse } from '@angular/common/http';
import { RankDiscountModel } from 'src/app/models/rank-discount.model';
import { Store } from '@ngrx/store';
import { OrderLine } from 'src/app/models/orderline.model';
import { SetClientAgreementDiscount } from 'src/app/reducers/order.action';

@Injectable({
  providedIn: 'root'
})
export class EnterPasswordPresenter {
  view: EnterPasswordView;
  constructor(
    private orderService: OrderService,
    private store: Store<{ orderLines: OrderLine }>) { }

  getDiscount() {
    this.view.blockUi();
    this.orderService.getDiscount(this.view.order, this.view.key).subscribe((res: RankDiscountModel) => {
      this.view.rankDiscount = res;
    }, (error: HttpErrorResponse) => {
      this.view.showError('', error.error.message);
    });
  }

  aplyOrderDiscount() {
    this.view.blockUi();
    this.orderService.aplyDiscount(this.view.order).subscribe(res => {
      this.store.dispatch(new SetClientAgreementDiscount(res, true));
      this.view.showSuccess('', 'Descuento aplicado');
      this.view.closeModal();
    }, (error: HttpErrorResponse) => {
      this.view.showError('', error.error.message);
    });

  }
}
