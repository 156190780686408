
import * as $params from './user.action';
import { User } from 'src/app/models/user.model';

export interface IAppState {
    user: User;
}

export const INITIAL_STATE: IAppState = {
    user: null
};

export function userReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {
    switch (action.type) {
        case $params.ActionTypes.LOGIN:
            return action.user;
        default:
            return state;
    }
}
