import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';

import { ProductService } from 'src/app/services/product.service';
import { Store } from '@ngrx/store';
import { OrderLine } from 'src/app/models/orderline.model';

import { HttpErrorResponse } from '@angular/common/http';

import { ClientModel } from 'src/app/models/client.model';
import { ProcessModel } from 'src/app/models/process.model';
import { StockReportView } from '../stock-report.view';
import { ReportService } from '../../../../../../../services/report.service';
import { SettingPresenter } from '../../../../../shared/setting/presenter/setting.presenter';


@Injectable({
    providedIn: 'root',
})
export class StockReportPresenter implements Presenter {
    view: StockReportView;
    lines: OrderLine[] = [];
    orderLinesToRestore: OrderLine[] = [];
    procesesSelected: ProcessModel;

    constructor(
        private productService: ProductService,
        private reportService: ReportService,
        private presenterSetting: SettingPresenter,
        private store: Store<{ client: ClientModel, orderLines: OrderLine }>

    ) {
    }

    getReportStock() {
        this.view.blockUi();
        let product = null;
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.productId;
        }
        this.reportService.getReportStock(product).subscribe((res: any) => {
            this.view.reportsCSV = res;
            if (this.view.reportsCSV.length === 0) {
                this.view.reports = [];
                this.view.showError('', 'Producto sin stock');
                return;
            } else {
                this.view.reportsCSV = this.view.reportsCSV.map(line => {
                    return {
                        CODIGO: line.referenceCode,
                        NOMBRE: line.name,
                        TRANSACCIONES: line.transactions,
                        VENTAS: line.dispatch,
                        DEVOLUCIONES: line.creditMemo,
                        RESERVAS: line.assignedQuantity,
                        EXISTENCIA: line.availableQuantity,
                        'TRANSFERENCIAS MEGAPOS': line.transfersQuantity
                    };
                });
            }
            this.productsToCSV();
        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar stock');
        });
    }

    paginatedProductStock() {
        this.view.blockUi();
        let product = null;
        const page = this.view.page;
        const size = this.view.size;
        if (this.view.selectedProduct) {
            product = this.view.selectedProduct.productId;
        }
        this.reportService.paginatedProductStock(product, page, size).subscribe((data: any) => {
            this.view.reports = data['data'];
            this.view.totalElements = data.totalElements;
            if (this.view.reports.length <= 0) {
                this.view.reports = [];
                this.view.showError('', 'Producto sin stock');
            }
        }, () => {
            this.view.reports = [];
            this.view.showError('', 'Error al buscar stock');
        });
    }

    findReportsByProduct() {
        if (this.view.selectedProduct && typeof this.view.selectedProduct !== 'string') {
            this.view.showSelectedProduct = true;
            this.paginatedProductStock();
        }
    }
    clearProduct() {
        this.view.showSelectedProduct = false;
        this.view.selectedProduct = null;
        this.paginatedProductStock();
    }

    onSearchProductPos() {
        const searchValue = this.view.search;
        const pageProd = this.view.page;
        const sizeProd = this.view.size;
        const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
        if (searchValue.length < 4) {
            return;
        }
        if (pageProd < 0) {
            return;
        }
        if (!sizeProd || sizeProd < 1) {
            return;
        }
        this.productService.searchProduct(searchValue, pageProd, sizeProd, prceListId, null).then(data => {

            this.view.products = data['data'];
            if (this.view.products.length <= 0) {
                this.view.showError('', 'Búsqueda sin registros');
                this.view.products = [];
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    productsToCSV() {

        const products = JSON.parse(JSON.stringify(this.view.reportsCSV, ['CODIGO', 'NOMBRE', 'TRANSACCIONES', 'VENTAS',
            'DEVOLUCIONES', 'RESERVAS', 'EXISTENCIA', 'TRANSFERENCIAS MEGAPOS'], 2));
        this.view.csvDownload('Reporte-Stock.csv', products);
    }

}
