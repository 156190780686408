import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientAdvanceModel } from '../models/client-advance.model';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class ClientAdvanceService {

    constructor(private http: HttpClient) { }

    public saveClientAdvance(
        clientAdvance: ClientAdvanceModel
    ): Promise<ClientAdvanceModel> {
        return new Promise((resolve, reject) => {
            this.http
                .post<ClientAdvanceModel>(
                    environment.apiUrl + '/saveClientAdvance',
                    clientAdvance
                )
                .pipe(timeout(9000000))
                .subscribe(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getClientAdvance(clientId: string, excludeCreditCard: boolean) {
        return this.http.get(environment.apiUrl + '/findClientAdvancesToApplied?clientId=' + clientId
         + '&excludeCreditCard=' + excludeCreditCard);
    }

    getClientAdvances(clientId: string) {
        return this.http.get(environment.apiUrl + '/findClientAdvances?clientId=' + clientId);
    }

    getPdf(clientAdvanceId: string) {
        return this.http.get(
            environment.apiUrl + '/printClientAdvance?clientAdvanceDetailId=' + clientAdvanceId + '&reprint=true'
        );
    }

    postSyncronice(clientAdvanceId: string) {
        return this.http.get(
            environment.apiUrl + '/sendClientAdvance?clientAdvanceDetailId=' + clientAdvanceId
        );
    }

    cancel(clientAdvanceId: string, isCPOS: boolean) {
        return this.http.get(
            environment.apiUrl + '/cancelClientAdvance?clientAdvanceDetailId=' + clientAdvanceId
            + '&isCPOS=' + isCPOS
        );
    }

}
