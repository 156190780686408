import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DialogService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AbstractView } from 'src/app/core/abstract_view';
import { TransferMovementType, TransfersModel, TransferStatus } from 'src/app/models/transfer.mode';
import { DataProvider } from 'src/app/services/data.provider';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { TransferService } from '../../services/transfer.service';
import { TransferProvider } from '../../transfer.provider';
import { AproveSupplyingComponent } from '../aprove-supplying/aprove-supplying.component';

@Component({
  selector: 'app-search-supplying',
  templateUrl: './search-supplying.component.html',
  styleUrls: ['./search-supplying.component.scss']
})
export class SearchSupplyingComponent extends AbstractView implements OnInit {

  public ref;
  suscription: Subscription;
  selectedTransfer: TransfersModel;
  constructor(
    public messageService: MessageService,
    public transferProvider: TransferProvider,
    public dataProvider: DataProvider,
    public wareHauseService: WarehouseService,
    public transferService: TransferService,
    public dialogService: DialogService,
  ) {
    super(messageService);
  }

  ngOnInit() {
    this.transferProvider.searchSupplying = [];
    this.transferProvider.selectedUser = null;
    this.transferProvider.initDate = null;
    this.transferProvider.endDate = null;
    this.transferProvider.documentNo = null;
    this.transferProvider.transferMovementTypeSelected = null;
    this.transferProvider.transferStatusSelected = null;
    this.transferProvider.originSelected = null;
    this.transferProvider.destinySelected = null;
    this.transferProvider.getTransferWarehouses();
    this.suscription = this.wareHauseService.deafaultWarehouse$.subscribe((res) => {
      if (res) {
        this.findTransfers();
      }
    });
  }
  onSearchUser(event) {
    this.transferProvider.searchUser = event.query;
    this.transferProvider.pageUser = 0;
    this.transferProvider.sizeUser = 5;
    this.transferProvider.onSearchUser();
  }
  getTranslateTMT(transferMovementType: TransferMovementType) {
    switch (transferMovementType) {
      case TransferMovementType.BETWEEN_PDV: return 'VENTA PUTUAL';
      case TransferMovementType.CLAIM: return 'RECLAMO DE MATERIALES';
      case TransferMovementType.SPECIAL_ORDER: return 'ORDEN ESPECIAL';
      case TransferMovementType.SUPPLYING: return 'ABASTECIMIENTO MATRIZ';
      case TransferMovementType.DEVOLUTION: return 'DEVOLUCION PDV A MATRIZ';
    }
  }
  getTranslateTS(transferStatus: TransferStatus) {
    switch (transferStatus) {
      case TransferStatus.ACCEPTED: return 'ACEPTADA';
      case TransferStatus.PARTIAL_ACCEPTED: return 'PARCIALMENTE ACEPTADA';
      case TransferStatus.REJECTED: return 'RECHAZADA';
      case TransferStatus.REQUESTED: return 'SOLICITADA';
      case TransferStatus.SENT: return 'ENVIADA';
      case TransferStatus.VOIDED: return 'ANULADA';
      case TransferStatus.RECEIVED: return 'RECIBIDA';
      case TransferStatus.DRAFT: return 'BORRADOR';
    }
  }
  paginate($event) {
    this.transferProvider.page = $event['page'];
    this.findTransfers();
  }

  findTransfers() {
    this.transferProvider.transferMovementTypeSelected = [TransferMovementType.SUPPLYING];
    this.transferProvider.transferStatusSelected = TransferStatus.SENT;
    this.transferProvider.destinySelected = this.dataProvider.defaultWarehouse;
    this.transferProvider.findTransfers('searchSupplying');
  }
  getTransferDetails(transfer: TransfersModel) {
    this.selectedTransfer = transfer;
    this.blockUi();
    this.transferService.getTransferById(this.selectedTransfer.transferId, false).subscribe((res: TransfersModel) => {
      this.transferProvider.transfer = res;
      this.transferProvider.transfer.transferDetailPresenters.forEach(line => {
        line.realQuantity = line.quantity;
        line.missingQuantity = 0;
        line.surplusQuantity = 0;
        line.disrepairQuantity = 0;
        line.incompleteQuantity = 0;
        line.crossingQuantity = 0;
      });
      this.showAproveSupplyingComponent();
    }, (error: HttpErrorResponse) => {
      this.showError('Error al  burcar transferencia', error.error.message);
    });
  }

  showAproveSupplyingComponent() {
    this.ref = this.dialogService.open(AproveSupplyingComponent, {
      header: 'ABASTECIMIENTO',
      closeOnEscape: true,
      width: '85%',
      height: '520px',
      showHeader: true,
      closable: true
    });
  }
  findTransfersByUser() {
    if (this.transferProvider.selectedUser && typeof this.transferProvider.selectedUser !== 'string') {
      this.transferProvider.showSelectedUser = true;
      this.findTransfers();
    }
  }
  clearUser() {
    this.transferProvider.showSelectedUser = false;
    this.transferProvider.selectedUser = null;
    this.transferProvider.observation = '';
    this.findTransfers();
  }
}
