import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetSalesPreviusMonthService {

  constructor(private http: HttpClient) { }

  getMaxDiscountAdminPrice() {
    return this.http
      .get(environment.apiUrl + '/getMaxDiscountAdminPrice');
  }

}
