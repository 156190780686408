import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { apearAnimation } from 'src/app/animations';
import { Item } from 'src/app/models/promotion-message.model';

@Component({
  animations: [apearAnimation],
  selector: 'app-line-promotions',
  templateUrl: './line-promotions.component.html',
  styleUrls: ['./line-promotions.component.scss']
})
export class LinePromotionsComponent implements OnInit, OnDestroy {
  @Input() promotions: Item[] = [];
  label = '';
  timer: any;
  timer2: any;
  current = 0;
  constructor() {
  }

  ngOnInit() {
    this.label = this.promotions[0].promotionName;
    this.changeLabels();
  }

  changeLabels() {
    this.timer = setTimeout(() => {
      if (this.promotions.length > 1) {
        this.label = null;
      }
      this.timer2 = setTimeout(() => {
        this.current++;
        this.current = this.current >= this.promotions.length ? 0 : this.current;
        this.label = this.promotions[this.current].promotionName;
        this.changeLabels();
      }, 300);
    }, 5000);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    clearTimeout(this.timer2);
  }
}
