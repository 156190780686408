import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DispatchModel } from '../models/dispatch.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DispatchService {
  constructor(private http: HttpClient) { }
  public dispatch(dispatch: DispatchModel): Promise<DispatchModel> {
    return new Promise((resolve, reject) => {
      this.http.post<DispatchModel>(environment.apiUrl + '/dispatches', dispatch)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

}
