export interface PromotionMessageModel {

    ack?: string;
    companyId?: string;
    engine?: string;
    mapVersion?: string;
    messageId?: string;
    store?: string;
    terminal?: string;
    optional?: Optional;
    dateTime?: string;
    voidTrx?: boolean;
    response?: boolean;
    initTck?: boolean;
    evaluate?: boolean;
    status?: Status;
    storeChain?: string;
    msgVersion?: string;
    suggest?: boolean;
    suggestPerType?: boolean;
    suggestSeqType?: number;
    offline?: boolean;
    chosenOption?: number;
    tenderGroupCode?: string;
    customerAdd?: CustomerAdd[];
    itemAdd?: ItemAdd[];
    paymentAdd?: PaymentAdd[];
    suggestions?: { promo: PromotionModel[] };
}

export interface CustomerAdd {
    seq?: number;
    type?: string;
    id?: string;
    remainingAmount?: number;
    name?: string;
    lastName?: string;
    identifier?: string;
    cardNumber?: string;
    points?: number;
    creditCampaingCode?: string;
    profileCode?: string;
    segment?: string;
    email?: string;

}
export interface ItemAdd {
    seq?: number;
    unitprice?: number;
    xprice?: number;
    qty?: number;
    code?: string;
    pvp?: number;
    pvf?: number;
    magnitude?: number;
    description?: string;
    brand?: string;
    supplier?: string;
    discountable?: string;
    discontinuous?: boolean;
    lowTurnover?: boolean;
    keyProduct?: boolean;
    level1?: string;
    level2?: string;
    level3?: string;
    level4?: string;
    level5?: string;
    classProduct?: string;
    measureUnit?: string;
}

export interface PaymentAdd {
    seq?: number;
    type?: string;
    id?: string;
    amount?: number;
    plan?: string;
    bank?: string;
    itemamount?: number;
    balance?: boolean;
}
export interface EventAdd {
    seq?: number;
    type?: string;
    id?: string;
    value?: string;
    seqItem?: string;
    codePV?: string;
}
export interface Coupon {
    seq?: number;
    ack?: string;
    amount?: number;
    barcode?: string;
    couponId?: string;
    id?: string;
    type?: string;
    qty?: number;
}
export interface Loyaltycard {
    seq?: number;
    id?: string;
    ack?: string;
    amount?: number;
    customer?: string;
    type?: string;
    chargeAmount?: boolean;
    consumeAmount?: boolean;
    status?: string;
}
export interface EventAdd {
    seq?: number;
    type?: string;
    id?: string;
    value?: string;
    seqItem?: string;
    codePV?: string;
}

export interface Item {
    magnitude?: string;
    qty?: string;
    seq?: string;
    value?: number;
    valueWithTaxes?: string;
    xprice?: string;
    promotionName?: string;
    promotionCode?: string;
}

export interface Apply {
    item: Item[];
}

export interface Benefit {
    account?: string;
    applicationMethod?: string;
    baseAmount?: string;
    benefitType?: string;
    displayMessage?: string;
    name?: string;
    nro?: string;
    order?: string;
    printerMessage?: string;
    prorationMethod?: string;
    unit?: string;
    discountPercentage?: string;
    apply?: Apply;
    tlogmessage?: string;
    newPrice?: string;
}

export interface PromotionModel {
    code?: string;
    id?: string;
    nro?: string;
    benefit?: Benefit[];
    descriptor?: any;
    itemSeq?: number;
    customerSeq?: number;
}

export interface Optional {
    promo: PromotionModel[];
}

export enum Status {
    INIT = 'INIT',
    SALES = 'SALES',
    TOTAL = 'TOTAL',
    PAYMENT = 'PAYMENT',
    COMMIT = 'COMMIT',
    FINISH = 'FINISH',
    ROLLBACK = 'ROLLBACK',
    TRANSACTIONREQUEST = 'TRANSACTIONREQUEST',
    RETURNFINISH = 'RETURNFINISH',
    CATALOGREDEEMVALIDATION = 'CATALOGREDEEMVALIDATION',
}
