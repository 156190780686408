import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.scss']
})
export class UsersAdminComponent extends AbstractView implements OnInit {

  navLinks = [];

  constructor(
    public router: Router,
    public service: MessageService
  ) {
    super(service, router);
  }

  ngOnInit() {
    this.navLinks = [
      {
        label: 'Asignar precios',
        link: './assing-prices',
        index: 1,
        visible: this.getAuth('ALLOW_POS_ADMIN_PRICES')
      }];
  }

}
