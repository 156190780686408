import { Presenter } from 'src/app/core/presenter';
import { Injectable } from '@angular/core';
import { AproveCashReturnView } from '../aprove-cash-return.view';
import { CashMovementService } from 'src/app/services/cash-movement.service';
import { MovementCashPresenter, StatusEnum } from 'src/app/models/movement-cash.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AproveCashReturnPresenter implements Presenter {
    view: AproveCashReturnView;
    constructor(public cashMovementService: CashMovementService) { }

    getPendingReturnCashMovements() {
        this.cashMovementService.getPendingReturnCashMovements().subscribe((movements: MovementCashPresenter[]) => {
            this.view.movements = movements;
        }, (error: HttpErrorResponse) => {
            this.view.showError('Error al encontrar devoluciones', error.error.message);
        });
    }

    setCashMovementStatus() {
        this.cashMovementService.setCashMovementStatus(this.view.selectedMovement).subscribe((movements: MovementCashPresenter[]) => {
            this.view.movements = this.view.movements.filter(mov => mov.movementCashId !== this.view.selectedMovement.movementCashId);
            if (this.view.selectedMovement.status === StatusEnum.APPROVED) {
                this.view.showSuccess('Efectivo devuelto', '');
            } else {
                this.view.showError('Retorno de efectivo cancelado', '');
            }
        }, (error: HttpErrorResponse) => {
            this.view.showError('Error al  asignar estado', error.error.message);
        });
    }


}
