import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { GenerateCreditNoteView } from './generate-credit-note.view';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { InvoiceLine } from 'src/app/models/orderline.model';
import { GenerateCreditNotePresenter } from './presenter/generate-credit-note.presenter';
import { MeasureConversionModel } from 'src/app/models/measure-conversion.model';
import Swal from 'sweetalert2';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ReasonCreditMemoPresenter } from 'src/app/models/creditmemo.model';
import { leftToLeft, rightToRight } from 'src/app/animations';

@Component({
  animations: [
    leftToLeft,
    rightToRight
  ],
  selector: 'app-generate-credit-note',
  templateUrl: './generate-credit-note.component.html',
  styleUrls: ['./generate-credit-note.component.scss']
})
export class GenerateCreditNoteComponent extends AbstractView implements OnInit, GenerateCreditNoteView {

  @ViewChild('dt', { read: false, static: false }) dt: Table;

  invoice: InvoiceModel;
  lines: InvoiceLine[] = [];
  selectedLine: InvoiceLine;
  subtotal12: number;
  subtotal0: number;
  tax: number;
  total: number;
  defaultTax: number;
  reasons: ReasonCreditMemoPresenter[];
  reasonsCopy: ReasonCreditMemoPresenter[];
  selectedReason: ReasonCreditMemoPresenter;
  subtotal5: number;
  subtotal15: number;
  tax5: number;
  tax15: number;
  invoiceIsBefore = false;
  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private messageService: MessageService,
    private creditNotePresenter: GenerateCreditNotePresenter,
    private hotKey: HotkeysService,
    public router: Router) {
    super(messageService, router);
    this.invoice = config.data;
    creditNotePresenter.view = this;
    this.shortcuts();

  }
  shortcuts() {
    this.hotKey.add(new Hotkey(['alt+g', 'ctrl+g', 'alt+s', 'ctrl+s'], (event: KeyboardEvent): boolean => {
      this.validateData();
      return false;
    }, ['input', 'textarea']));
  }

  ngOnInit() {
    this.creditNotePresenter.getDefaultTax();
    this.creditNotePresenter.getCreditMemoReasons();
    this.creditNotePresenter.fillLines();
    this.creditNotePresenter.validateBilleton();

  }

  deleteLine(line: InvoiceLine) {
    this.selectedLine = line;
    this.creditNotePresenter.deleteLine();
  }

  changeLine(line: InvoiceLine) {
    this.selectedLine = line;
    this.creditNotePresenter.changeLine();
  }

  changeComboItem(line: InvoiceLine, measureUnitConversionPresenter: MeasureConversionModel) {
    this.selectedLine = line;
    this.selectedLine.measureUnitConversionPresenter = measureUnitConversionPresenter;
    this.creditNotePresenter.changeLine();

  }

  showConfirmation() {

    Swal.fire({
      text: '¿Desea generar la nota de crédito?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.value) {
        this.creditNotePresenter.saveCredirMemo();
      }
    });
  }

  closeView() {
    this.ref.close();
  }


  validateData() {
    this.creditNotePresenter.validateCreditMemo();
  }


}
