import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { VaultManagmentPresenter } from './presenter/vault-managment.presenter';
import { VaultManagmentView } from './vault-managment.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { Vault } from 'src/app/models/vault.model';
import { MovementVault } from 'src/app/models/movement-vault.model';
import { MovementVaultType } from 'src/app/models/movement-vault-type.model';

@Component({
    selector: 'app-vault',
    templateUrl: './vault-managment.component.html',
    styleUrls: ['./vault-managment.component.scss']
})
export class VaultManagmentComponent extends AbstractView implements OnInit, VaultManagmentView {

    vault: Vault;
    movementsVault: MovementVault[];
    movementVaultTypes: { label: string, value: MovementVaultType }[];
    movementVaultTypeSelected: MovementVaultType;
    newMovementAmount: number;
    constructor(public messageService: MessageService,
                public router: Router,
                public vaultManagmentPresenter: VaultManagmentPresenter) {
        super(messageService, router);
        this.vaultManagmentPresenter.view = this;
    }

    openVault() {
        this.vaultManagmentPresenter.openVault();
    }

    ngOnInit(): void {
        this.vaultManagmentPresenter.findOpenVault();
        this.vaultManagmentPresenter.findVaultMovements();
        this.vaultManagmentPresenter.findMovementVaultTypes();
    }

    clickNewMovementVault() {
        this.vaultManagmentPresenter.saveMovementVault();
    }
}
