import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { DynamicDialogRef, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UiUtils } from 'src/app/core/ui-utils';
import { InitDataPresenter } from './presenter/init-data.presenter';
import { DomainAction } from 'src/app/models/rol.model';
import { interval } from 'rxjs';



@Component({
  selector: 'app-init-data',
  templateUrl: './init-data.component.html',
  styleUrls: ['./init-data.component.scss']
})
export class InitDataComponent extends AbstractView implements OnInit {

  @ViewChild('txtAmount', { read: false, static: false }) txtAmount: ElementRef;

  ip: string;
  constructor(
    private ref: DynamicDialogRef,
    public service: MessageService,
    public router: Router,
    public util: UiUtils,
    private initDataPresenter: InitDataPresenter
  ) {
    super(service, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_REALIZE_ADVANCES)) {
      this.showError('', 'Sin autorización');
      this.ref.close();
    }
    this.initDataPresenter.view = this;
    const source = interval(1000);
  }
ngOnInit() {
  }
validate() {
    this.initDataPresenter.validate();
  }

onClickIni() {
    this.initDataPresenter.loadInitialData(this.ip);
  }

closeModal() {
    this.ref.close();
  }
}
