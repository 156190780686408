import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { User } from 'src/app/models/user.model';
import { LogIn } from 'src/app/reducers/user.action';
import { UserService } from 'src/app/services/user.service';
import * as jwt_decode from 'jwt-decode';
import { RolModel, DomainAction } from 'src/app/models/rol.model';
import { AdminPricePasswordView } from '../admin-price.password.view';
import { Presenter } from '../../../../../core/presenter';

@Injectable({
    providedIn: 'root',
})
export class AdminPricePasswordPresenter implements Presenter {
    view: AdminPricePasswordView;

    constructor(private store: Store<{ user: User }>, private userService: UserService) {
        this.store.pipe(select('userState')).subscribe(val => {
        });
    }


    acceptLoging() {

        const user = this.view.user;
        const password = this.view.password;
        if (!password || password.length === 0) {
            this.view.showMessage('Debe ingresar una contraseña');
            return;
        }
        if (!user || user.length === 0) {
            this.view.showMessage('Debe ingresar un usuario');
            return;
        }
        this.view.blockUi();
        this.userService.doLogin(user, password).then(data => {

            const permissions: any = JSON.parse(JSON.stringify(this.getAuthorities(data)));
            if (this.getAuth(this.view.permission, permissions)) {
                this.view.closeView();
            } else {
                this.view.showError('', 'Sin autorización');

            }
        }).catch((error: any) => {
            this.view.showError('Error', error.error.mensaje);
        });
    }

    getAuthorities(token: string) {
        const auth: RolModel = jwt_decode(token, { header: true });
        return auth.roles;
    }

    getAuth(permision: string, permissions: { authority: string }[]) {
        if (permissions) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < permissions.length; i++) {
                if (permissions[i].authority === permision) {
                    return true;
                }
            }
        }
        return false;
    }

}
