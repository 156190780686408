import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { interval } from 'rxjs';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { SetObservationView } from './set-observation.view';
import { MessageService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { SetObservationPresenter } from './presenter/set-observation.presenter';
import { DayRegisterModel } from 'src/app/models/cash-register.model';

@Component({
  selector: 'app-set-observation',
  templateUrl: './set-observation.component.html',
  styleUrls: ['./set-observation.component.scss']
})
export class SetObservationComponent extends AbstractView implements OnInit, SetObservationView, AfterViewInit {


  @ViewChild('txtObservation', { read: false, static: false }) txtObservation: ElementRef;

  dayRegister: DayRegisterModel;
  observation = '';
  hour = new Date();

  constructor(
    private messageService: MessageService,
    private setObservationPresenter: SetObservationPresenter,
    public router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {

    super(messageService, router);
    setObservationPresenter.view = this;
    const source = interval(1000);
    source.subscribe(() => this.hour = new Date());
    this.dayRegister = config.data;
  }



  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.txtObservation.nativeElement.focus();
  }

  setObservation() {
    this.setObservationPresenter.setObservation();
  }

  closeView() {
    this.ref.close();
  }

}
