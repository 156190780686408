
import { Injectable } from '@angular/core';

import { Presenter } from 'src/app/core/presenter';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportService } from 'src/app/services/report.service';
import { formatDate } from '@angular/common';
import { ClientAdvanceReportView } from '../client-advance-report.view';


@Injectable({
    providedIn: 'root',
})

export class ClientAdvanceReportPresenter implements Presenter {
    view: ClientAdvanceReportView;

    constructor(
        private reportService: ReportService,
    ) { }

    getClientAdvanceReport() {
        this.view.withData = false;
        if (!this.view.initDate || !this.view.endDate) {
            return;
        }
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        const endDate = formatDate(this.view.endDate, format, locale);
        const initDate = formatDate(this.view.initDate, format, locale);
        this.view.withData = true;
        this.reportService.findClientAdvanceReport(initDate, endDate).then(data => {
            this.view.reportClientAdvance = data;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    downloadClientAdvanceReport() {
        if (!this.view.withData) {
            this.view.showError('', 'Ingrese un intervalo de fechas válidos');
        } else {
            this.view.csvDownload('ReportesAnticipos.csv', this.view.reportClientAdvance);
        }
    }

}
