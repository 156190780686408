import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CashRegisterModel, DayRegisterModel } from '../models/cash-register.model';
import { environment } from 'src/environments/environment.prod';
import { DayCloseCash, DepositCloseCashModel } from '../models/deposit-close-cash.model';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {

  constructor(private http: HttpClient) { }



  public depositeCloseCash(depositCloseCash: DepositCloseCashModel): Promise<DepositCloseCashModel> {
    return new Promise((resolve, reject) => {
        this.http.post<DepositCloseCashModel>(environment.apiUrl + '/saveDepositDayDetail', depositCloseCash)
        .subscribe(response => {
            resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  openCashRegister(cashRegister: CashRegisterModel): Promise<CashRegisterModel> {
    return new Promise((resolve, reject) => {
      this.http.post<CashRegisterModel>(environment.apiUrl + '/saveOpenCashRegister', cashRegister)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  closeCashRegister(closeCashRegister: DayRegisterModel): Promise<DayRegisterModel> {
    return new Promise((resolve, reject) => {
      this.http.post<DayRegisterModel>(environment.apiUrl + '/saveDayRegister', closeCashRegister)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  generateAdvance(cashRegister: CashRegisterModel): Promise<CashRegisterModel> {
    return new Promise((res, reject) => {
      this.http.post<CashRegisterModel>(environment.apiUrl + '/saveMovementAdvance', cashRegister)
        .subscribe(response => {
          res(response);
        }, error => {
          reject(error);
        });
    });
  }

    findDayRegisters(
    searchValue: string, initDate: string, endDate: string,
    page: number, size: number): Promise<DayCloseCash[]> {
    return new Promise((resolve, reject) => {
      this.http.get<DayCloseCash[]>(environment.apiUrl + '/getAllDayCloseCash?initDate=' + initDate + '&endDate=' + endDate
        + '&searchValue=' + searchValue + '&page=' + page + '&size=' + size
        , { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }

  getChecksDetails() {
    return this.http.get(environment.apiUrl + '/getChecksDetails');
  }

  getDailyChecks() {
    return this.http.get(environment.apiUrl + '/getDailyChecks');
  }

  getCashRegisterResume() {
    return this.http.get(environment.apiUrl + '/getPaymentsQuantity');
  }

  validateCloseCash(userId: string) {
    return this.http.get(environment.apiUrl + '/validateCloseCash?userId=' + userId);
  }

  getDefaultConfigurationCpos() {
    return this.http.get(environment.apiUrl + '/findDefaultConfigurationCpos');
  }

  getValidateCashRegister(userId: string) {
    return this.http.get(environment.apiUrl + '/validateCloseCashRegister?userId=' + userId);
  }

  getOpenCashWithCpos() {
    return this.http.get(environment.apiUrl + '/getOpenCashWithCpos');
  }

  getCloseCashAmount (cashRegisterDate: string) {
    return this.http.get (environment.apiUrl + '/getCloseCashAmount?dateCloseCash=' + cashRegisterDate);
  }

  getAllDepositeCloseCash () {
    return this.http.get (environment.apiUrl + '/getAllDayCloseCash');
  }

  getDepositDayDetailById (DepositDetail: string) {
    return this.http.get (environment.apiUrl + '/getDepositDayDetailById?dayCloseCashId=' + DepositDetail);
  }

  justifiedDepositeCloseCash(justifiedDayCloseCash) {
    return this.http.post(environment.apiUrl + '/sendDepositJustification' , justifiedDayCloseCash);
  }

  getValueLockedbox () {
    return this.http.get (environment.apiUrl + '/getCompensationsLocked');
  }

  getAllDatesValidate () {
    return this.http.get (environment.apiUrl + '/getDayCloseCashPending');
  }

}
