import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { Product } from 'src/app/models/product.model';
import { ListSalesPrices, ReportSalesPriceModel, ResumeSalesCommissions, ResumeSalesPrices } from 'src/app/models/reportsalesprice.modal';
import { DataProvider } from 'src/app/services/data.provider';
import { ReportCommissionView } from './report-commission.view';
import { ReportCommissionPresenter } from './presenter/report-commission.presenter';

@Component({
  selector: 'app-report-commission',
  templateUrl: './report-commission.component.html',
  styleUrls: ['./report-commission.component.scss']
})

export class ReportCommissionComponent extends AbstractView implements OnInit, ReportCommissionView {

  initDate = new Date();
  endDate = new Date();
  visible = false;
  data: any;
  listSalesPrice: ListSalesPrices[];
  resumeSalesPrice: ResumeSalesCommissions[];
  users: UserPresenter[] = [];
  searchValue: '';
  totalElements: number;
  selectedUser: UserPresenter;
  showSelectedUser = false;
  showSelectedProduct: boolean;
  selectedProduct: Product;
  productId = '';
  products: Product[];
  searchProduct: string;
  documentNo = '';
  pageInvoice = 0;
  sizeInvoice = 20;
  invoices: ListSalesPrices[];
  totalElementsInvoice: number;
  categorySelected = '';
  reportSalesPrice: ReportSalesPriceModel[];
  minDate = new Date(2024, 3, 1);

  constructor(
    private messageService: MessageService,
    public router: Router,
    private reportCommissionPresenter: ReportCommissionPresenter,
    private dataProvider: DataProvider,
  ) {
    super(messageService, router);
    reportCommissionPresenter.view = this;
  }

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.reportCommissionPresenter.getInvoicesForUserCommission();
  }

  addUser() {
    this.reportCommissionPresenter.findInvoicesByUser();
  }

  clearUser() {
    this.reportCommissionPresenter.clearUser();
  }

  onSearchUser(event) {
    this.searchValue = event.query;
    this.reportCommissionPresenter.onSearchUser();
  }

  clearProduct() {
    this.reportCommissionPresenter.clearProduct();
  }

  addProduct() {
    this.reportCommissionPresenter.findReportsByProduct();
  }

  onSearchProduct(event) {
    this.searchProduct = event.query;
    this.reportCommissionPresenter.onSearchProduct();
  }

  hardwareDetail(type) {
    this.reportCommissionPresenter.getReportSalesCommission(type);
  }

  paginateInvoice($event) {
    this.pageInvoice = $event['page'];
    this.reportCommissionPresenter.getReportSalesCommission(this.categorySelected);
  }

  calculatePrice(price) {
    return price = 'P.V.R: ' + (price / 1.035).toFixed(4);
  }

}

