import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import Decimal from 'decimal.js';
import { DialogService, MessageService } from 'primeng/api';
import { Subscription, Subject } from 'rxjs';
import { AbstractView } from 'src/app/core/abstract_view';
import { TransferMovementType, TransfersModel, TransferStatus } from 'src/app/models/transfer.mode';
import { DataProvider } from 'src/app/services/data.provider';
import { WarehouseService } from 'src/app/services/warehouse.service';
import Swal from 'sweetalert2';
import { TransferService } from '../../services/transfer.service';
import { TransferProvider } from '../../transfer.provider';
import { AproveTransferComponent } from '../aprove-transfer/aprove-transfer.component';
import { WareHouse } from '../../../../../../../models/product.model';
import { debounceTime } from 'rxjs/operators';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-search-transfers',
  templateUrl: './search-transfers.component.html',
  styleUrls: ['./search-transfers.component.scss']
})
export class SearchTransfersComponent extends AbstractView implements OnInit, OnDestroy {
  transferMovementTypes: { label: string, value: TransferMovementType }[] = [
    { label: 'Venta puntual', value: TransferMovementType.BETWEEN_PDV },
    { label: 'Abastecimiento matriz', value: TransferMovementType.SUPPLYING },
    { label: 'Transferencia entre PDV', value: TransferMovementType.SUPPLYING_BETWEEN_PDV },
  ];
  transferStatus: { label: string, value: TransferStatus }[] = [
    { label: 'Aceptado', value: TransferStatus.ACCEPTED },
    { label: 'Aceptado parcial', value: TransferStatus.PARTIAL_ACCEPTED },
    { label: 'Rechazado', value: TransferStatus.REJECTED },
    { label: 'Solicitado', value: TransferStatus.REQUESTED },
    { label: 'Enviado', value: TransferStatus.SENT },
    { label: 'Anulado', value: TransferStatus.VOIDED },
  ];

  selectedTransfer: TransfersModel;
  transfer: TransfersModel;
  public ref;
  suscription: Subscription;

  docNum = new Subject<string>();
  docSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    public transferProvider: TransferProvider,
    public transferService: TransferService,
    public dialogService: DialogService,
    public dataProvider: DataProvider,
    public wareHauseService?: WarehouseService

  ) {
    super(messageService);
  }

  ngOnInit() {

    this.transferProvider.searchTransfer = [];
    this.transferProvider.selectedUser = null;
    this.transferProvider.initDate = null;
    this.transferProvider.endDate = null;
    this.transferProvider.documentNo = null;
    this.transferProvider.transferMovementTypeSelected = null;
    this.transferProvider.transferStatusSelected = null;
    this.transferProvider.originSelected = null;
    this.transferProvider.destinySelected = null;
    this.transferProvider.getTransferWarehouses();
    this.suscription = this.wareHauseService.deafaultWarehouse$.subscribe((res) => {
      if (res) {
        this.findTransfers();
      }
    });
    this.docSubscription = this.docNum.pipe(debounceTime(1000)).subscribe(() => {
      this.findTransfers();
    });
  }

  evaluateRole() {
    return this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS);
  }
  ngOnDestroy() {
    this.suscription.unsubscribe();
    this.docSubscription.unsubscribe();
  }
  paginate($event) {
    this.transferProvider.page = $event['page'];
    this.findTransfers();
  }
  getTransferDetails(transfer: TransfersModel, aprove: boolean) {
    this.transferProvider.transfer = transfer;
    if (!aprove) {
      this.showARejectTransfer(transfer);
      return;
    }
    this.blockUi();
    this.transferService.getTransferById(transfer.transferId, false).subscribe((res: TransfersModel) => {
      res.transferDetailPresenters.forEach(resT => {
        resT.measureUnitPresenter.name = resT.productPresenter.descriptionMeasure
          ? resT.productPresenter.descriptionMeasure : resT.measureUnitPresenter.name;
      });
      this.transferProvider.transfer = res;
      this.selectedTransfer = res;
      this.transferProvider.transfer.transferDetailPresenters.forEach(line => {
        line.acceptedEnteredQuantity = line.requestedEnteredQuantity;
        line.acceptedConvertedQuantity = line.requestedConvertedQuantity;
        line.maxQuantity = line.maxQuantity;
      });
      this.showTransferComponent();
    }, (error: HttpErrorResponse) => {
      this.showError('Error al  burcar transferencia', error.error.message);
    });
  }

  showARejectTransfer(transfer: TransfersModel) {
    this.selectedTransfer = transfer;
    this.selectedTransfer.transferStatus = TransferStatus.REJECTED;
    Swal.fire({
      text: '¿Desea rechazar la transferencia?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Rechazar'
    }).then((result) => {
      if (result.value) {
        this.setTransferStatus(this.selectedTransfer);
      }
    });
  }
  setTransferStatus(transfer: TransfersModel) {
    if (transfer.transferStatus !== TransferStatus.REJECTED) {
      transfer.transferDetailPresenters.forEach(line => {
        if (line.acceptedEnteredQuantity !== line.requestedEnteredQuantity && transfer.transferStatus === TransferStatus.ACCEPTED) {
          transfer.transferStatus = TransferStatus.PARTIAL_ACCEPTED;
        }
      });
    }
    this.blockUi();
    this.transferService.setTransferStatus(transfer).subscribe(() => {
      if (transfer.transferStatus === TransferStatus.ACCEPTED || transfer.transferStatus === TransferStatus.PARTIAL_ACCEPTED) {
        this.showSuccess('', 'Transferencia aprobada');
      } else {
        this.showSuccess('', 'Transferencia rechazada');
      }
      this.findTransfers();
    }, () => {
      this.showError('', 'Error al  asignar estado');
    });
  }

  showTransferComponent() {
    const label = this.transferProvider.transfer.transferMovementType === TransferMovementType.SUPPLYING_BETWEEN_PDV ?
      'PARA ABASTECIMIETNO' : 'PARA VENTA PUNTUAL';
    this.ref = this.dialogService.open(AproveTransferComponent, {
      header: 'TRANSFERENCIA ' + label,
      closeOnEscape: true,
      width: '85%',
      height: '520px',
      showHeader: true,
      closable: true
    });
  }
  onSearchUser(event) {
    this.transferProvider.searchUser = event.query;
    this.transferProvider.pageUser = 0;
    this.transferProvider.sizeUser = 5;
    this.transferProvider.onSearchUser();
  }
  getTranslateTMT(transferMovementType: TransferMovementType) {
    switch (transferMovementType) {
      case TransferMovementType.BETWEEN_PDV: return 'VENTA PUNTUAL';
      case TransferMovementType.CLAIM: return 'RECLAMO DE MATERIALES';
      case TransferMovementType.SPECIAL_ORDER: return 'ORDEN ESPECIAL';
      case TransferMovementType.SUPPLYING: return 'ABASTECIMIENTO MATRIZ';
      case TransferMovementType.SUPPLYING_BETWEEN_PDV: return 'TRANSFERENCIA ENTRE PDV';
      case TransferMovementType.DEVOLUTION: return 'DEVOLUCION PDV A MATRIZ';
    }
  }
  getTranslateTS(transferStatus: TransferStatus, origin?: WareHouse) {
    switch (transferStatus) {
      case TransferStatus.ACCEPTED: return 'ACEPTADA';
      case TransferStatus.PARTIAL_ACCEPTED: return 'PARCIALMENTE ACEPTADA';
      case TransferStatus.REJECTED: return 'RECHAZADA';
      case TransferStatus.REQUESTED: return 'SOLICITADA';
      case TransferStatus.SENT: return origin.warehouseId === this.dataProvider.defaultWarehouse.warehouseId
        ? 'POR REVISAR' : 'ENVIADA';
      case TransferStatus.VOIDED: return 'ANULADA';
      case TransferStatus.RECEIVED: return 'RECIBIDA';
      case TransferStatus.DRAFT: return 'BORRADOR';
    }
  }
  findTransfers() {
    this.transferProvider.transferMovementTypeSelected = ['BETWEEN_PDV', 'SUPPLYING_BETWEEN_PDV'];
    this.transferProvider.transferStatusSelected = TransferStatus.SENT;
    this.transferProvider.originSelected = this.dataProvider.defaultWarehouse;
    this.transferProvider.findTransfers('searchTransfer');
  }

  findTransfersByUser() {
    if (this.transferProvider.selectedUser && typeof this.transferProvider.selectedUser !== 'string') {
      this.transferProvider.showSelectedUser = true;
      this.findTransfers();
    }
  }
  clearUser() {
    this.transferProvider.showSelectedUser = false;
    this.transferProvider.selectedUser = null;
    this.transferProvider.observation = '';
    this.findTransfers();
  }

}
