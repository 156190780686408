import { Presenter } from 'src/app/core/presenter';
import { TopBarView } from '../topbar.view';
import { Injectable } from '@angular/core';
import { UsernameService } from '../../../../../services/currentUSer.service';
import { AdvanceService } from 'src/app/services/advance.service';
import { DataProvider } from 'src/app/services/data.provider';
import { CashRegisterService } from 'src/app/services/cash-register.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import { CposService } from 'src/app/services/cpos.service';
import { CreditRedService } from 'src/app/services/credit-red.service';
import Swal from 'sweetalert2';


@Injectable({
    providedIn: 'root',
})
export class TopbarPresenter implements Presenter {

    view: TopBarView;
    needAdvance = false;
    constructor(
        private usernameService: UsernameService,
        public getAdvanceService: AdvanceService,
        public dataProvider: DataProvider,
        public cashRegisterService: CashRegisterService,
        public creditCardService: CreditCardService,
        public cposService: CposService,
        public creditRedService: CreditRedService
    ) {
    }
    init() {
        this.getAdvanceService.advanceStatus();
        this.getCurrentUser();
    }

    logout() {
        localStorage.clear();
        const status = localStorage.getItem('authorization');
        if (status == null) {
            this.view.redirectTo('/');
        }
    }

    getCurrentUser() {
        this.usernameService.getCurrentUser().subscribe(
            (data) => {
                this.view.fullUser = data;
                this.dataProvider.defaultUser = data;
                if (this.view.fullUser.resetPassword) {
                    this.view.changePassword(false, 'OBLIGATORIO');
                } else {
                    this.validateCloseCash(this.view.fullUser.userId);
                    this.getConnectionCpos(this.view.fullUser.userId);
                    this.getDefaultConfigurationCpos();
                    this.getOpenCashWithCpos();
                    this.getIsSaleHeavy();
                    this.getIsCreditRed();
                    this.getCommissionCard();
                }
            },
            () => {
                this.view.showError(
                    'Atención',
                    'Ocurrió un problema al intentar obtener la información de usuario'
                );
            }
        );
    }

    getCommissionCard() {
        this.view.blockUi();
        this.creditCardService.getCommissionCard().subscribe((res: any) => {
            this.dataProvider.commissionCard = res.commission / 100;
        }, (error) => {
        });
    }

    getConnectionCpos(userId: string) {
        this.creditCardService
            .getConnectionCpos(userId)
            .subscribe((res: boolean) => {
                this.dataProvider.isConnectionCpos = res;
                this.cashRegisterService
                    .getValidateCashRegister(userId)
                    .subscribe((resp: any) => {
                        resp ? this.dataProvider.isOpenCash = false : this.dataProvider.isOpenCash = true;
                        if (this.dataProvider.isOpenCash && !this.dataProvider.isConnectionCpos) {
                            this.dataProvider.needOpenCpos = true;
                        } else {
                            this.dataProvider.needOpenCpos = false;
                        }
                    });
            });
    }

    getDefaultConfigurationCpos() {
        this.cashRegisterService
            .getDefaultConfigurationCpos()
            .subscribe((res: any) => {
                this.dataProvider.isConfigurationCpos = res;
            });
    }

    getOpenCashWithCpos() {
        this.cashRegisterService.getOpenCashWithCpos()
        .subscribe((res: any) => {
            this.dataProvider.isConnectCashWithCpos = res;
        });
    }

    showReminder(): void {
        if (this.getAdvanceService.status === 'Avance pendiente') {
            this.view.showInfo('', this.getAdvanceService.status);
        }
    }

    showComponent() {
        if (this.getAdvanceService.status === 'Debe abrir caja') {
            this.view.showOpenCashRegisterCompponent();
        } else if (this.getAdvanceService.status === 'Avance pendiente') {
            this.view.showAdvanceCompponent();
        }
    }

    showOpenCpos() {
        this.creditCardService.saveConnectionCpos().subscribe((res: any) => {
            this.dataProvider.isConnectionCpos = res;
            if (res) {
                if (this.dataProvider.isOpenCash &&
                    !this.dataProvider.isConnectionCpos
                ) {
                    this.dataProvider.needOpenCpos = true;
                } else {
                    this.dataProvider.needOpenCpos = false;
                }
                this.view.showSuccess('Correcto', 'Dispositivo Conectado');
            } else {
                this.view.showError('Atención', 'Sin conección con CPOS');
            }

        }, (error) => {
            this.view.showError('Atención', 'Sin conección con CPOS');
        });
    }

    validateCloseCash(userId: string) {
        this.view.blockUi();
        this.cashRegisterService.validateCloseCash(userId).subscribe((res: boolean) => {
            if (!res) {
                this.view.showCloseCashRegisterCompponent(false, 'OBLIGATORIO');
            }
        }, (error) => {
            this.view.showBackException(error);
            // this.view.showCloseCashRegisterCompponent(false, 'OBLIGATORIO');

        });

    }
    getIsSaleHeavy() {
        this.creditRedService.getIsHeavy()
        .subscribe((res: boolean) => {
          this.dataProvider.isHeavy = res;
        });
      }
      getIsCreditRed() {
        this.creditRedService.getIsCreditRed()
        .subscribe((res: boolean) => {
          this.dataProvider.isCreditRed = res;
        });
      }

      loadValueLockedbox() {
        this.cashRegisterService.getValueLockedbox().subscribe(
          (locked: any) => {
            this.view.lockedCloseCash = locked;
          }
        );
      }

      loadStatusDeposit() {
        this.cashRegisterService.getAllDatesValidate().subscribe(
          (status: any) => {
            this.view.statusCloseCash = status;
          }
        );
      }
}
