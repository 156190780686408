import { InvoiceModel } from '../models/invoice.model';
import * as $params from './invoice.action';
import { Payment } from '../models/payment.model';
export interface IAppState {
    invoice: InvoiceModel;
    payments: Payment[];
}
export const INITIAL_STATE: IAppState = {
    invoice: null,
    payments: []
};
export function InvoiceReducer(state = INITIAL_STATE, action: $params.ActionsUnion) {
    switch (action.type) {
        case $params.InvoiceActions.SELECT_INVOICE:
            return Object.assign({}, state, {
                invoice: action.invoice
            });
        case $params.InvoiceActions.ADD_PAYMENT_TO_INVOICE:
            const payments: Payment[] = state.payments;
            payments.push(action.payment);
            return Object.assign({}, state, {
                payments: payments
            });
        case $params.InvoiceActions.CLEAR_INVOICE:
            return Object.assign({}, state, {
                invoice: null,
                payments: []
            });
        case $params.InvoiceActions.DELETE_PAYMENT:
            state.payments.splice(action.index, 1);
            return Object.assign({}, state, {
                payments: state.payments
            });
        case $params.InvoiceActions.CLEAR_PAYMENTS:
            return Object.assign({}, state, {
                payments: []
            });
        default:
            return state;
    }
}
