import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, MessageService } from 'primeng/api';
import { leftToLeft } from 'src/app/animations';
import { AbstractView } from 'src/app/core/abstract_view';
import { Transaction } from 'src/app/models/search-transactions-response-presenter';

import { ReceiptsPaymentsHistoricPresenter } from './presenter/receipts-payments-historic.presenter';
import { ReceiptsPaymentsHistoricView } from './receipts-payments-historic.view';

@Component({
  animations: [leftToLeft],
  selector: 'app-receipts-payments-historic',
  templateUrl: './receipts-payments-historic.component.html',
  styleUrls: ['./receipts-payments-historic.component.scss'],
})
export class ReceiptsPaymentsHistoricComponent
  extends AbstractView
  implements ReceiptsPaymentsHistoricView, OnInit {
  displayedColumns = [
    'product',
    'identification',
    'client',
    'amount',
    'date',
    'actions',
  ];

  transaccion: Transaction;
  transaccions: Transaction[] = [];
  show = false;
  showSelectedService = false;
  valueSelected: any;
  search = '';
  filteredValues: any[] = [];
  page = 0;
  size = 20;

  constructor(
    private messageService: MessageService,
    public router: Router,
    public receiptsPaymentsHistoricPresenter: ReceiptsPaymentsHistoricPresenter,
    public dialogService: DialogService
  ) {
    super(messageService, router);
    receiptsPaymentsHistoricPresenter.view = this;
  }
  ngOnInit(): void {}

  loadReport() {
    this.show = true;
  }


  searchServices() {
    if (this.search === '' || this.search.length === 0) {
      this.showWarn('Advertencia', 'No existen registros para buscar');
      return;
    }
    this.receiptsPaymentsHistoricPresenter.searchTransactions();
  }
  clearAll() {
    this.transaccions = null;
    this.search = null;
  }
  createTable(transactions: any[]) {
    this.transaccions = [];
    transactions.forEach((trans) => {
      const transaction: Transaction = {
        id: trans.transaction.id,
        product: trans.product.name,
        identification: trans.customer.identification,
        client: trans.customer.name,
        amount: trans.transaction.amount,
        date: trans.transaction.date,
        cancel: trans.transaction.couldCancel,
        actions: '',
      };
      this.transaccions.push(transaction);
    });
    this.loadReport();
  }
}
