import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchProductComponent } from './search-product/search-product.component';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import {PaginatorModule} from 'primeng/paginator';


@NgModule({
  declarations: [
    SearchProductComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    PaginatorModule
  ],

})
export class ProductsModule { }
