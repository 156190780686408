import { Action } from '@ngrx/store';
import { Payment } from '../models/payment.model';
export enum AdvanceActions {
    ADD_PAYMENT_TO_ADVANCE = 'ADD_PAYMENT_TO_ADVANCE',
    CLEAR_ADVANCE = 'CLEAR_ADVANCE',
    CLEAR_ADVANCES = 'CLEAR_ADVANCES',
    DELETE_ADVANCE = 'DELETE_ADVANCE',
}

export class AddPaymentToAdvance implements Action {
    constructor(public paymentsAdvance: Payment) { }
    readonly type = AdvanceActions.ADD_PAYMENT_TO_ADVANCE;
}
export class ClearAdvance implements Action {
    constructor() { }
    readonly type = AdvanceActions.CLEAR_ADVANCE;
}
export class ClearAdvances implements Action {
    constructor() { }
    readonly type = AdvanceActions.CLEAR_ADVANCES;
}
export class DeleteAdvance implements Action {
    constructor(public index: number) { }
    readonly type = AdvanceActions.DELETE_ADVANCE;
}
export type ActionsUnion =
    AddPaymentToAdvance |
    ClearAdvances |
    DeleteAdvance |
    ClearAdvance;
