import { Injectable } from '@angular/core';
import { SearchSalesDepositView } from '../search-sales-deposit.view';
import { Presenter } from 'src/app/core/presenter';
import { CashRegisterService } from 'src/app/services/cash-register.service';
import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SearchSalesDepositPresenter implements Presenter {
  view: SearchSalesDepositView;
  constructor(

    private cashRegisterService: CashRegisterService,
  ) { }

  loadAllDepositCloseCash() {
    this.cashRegisterService.getAllDepositeCloseCash().subscribe(
      (res: any) => {
        this.view.allDepositCloseCash = res;
      }
    );
  }

  loadDepositDayDetailById (rowdepositDetailId: string) {
    this.cashRegisterService.getDepositDayDetailById(rowdepositDetailId).subscribe(
      (res: any) => {
        this.view.depositDaydetailId = res;
      }

    );
  }

  sendJustification (justifiedDayCloseCash) {
      this.cashRegisterService.justifiedDepositeCloseCash(justifiedDayCloseCash).subscribe(
        (res: any) => {
          console.log(res);
          this.view.showSuccess('Justificación enviada', '');
          this.onSearchCloseDayReport();
  }, (error: HttpErrorResponse) => {
    this.view.showError('', error.error.message);
}
);

}

loadValueLockedbox() {
  this.cashRegisterService.getValueLockedbox().subscribe(
    (locked: any) => {
      this.view.lockedCloseCash = locked;
    }
  );
}

onSearchCloseDayReport() {
  let searchValue = '';
  let initDate = '';
  let endDate = '';
  let page = 0;
  if (this.view.page) {
      page = this.view.page;
  }
  let size = 20;
  if (this.view.size) {
      size = this.view.size;
  }
  const format = 'yyyy-MM-dd hh:mm:ss';
  const locale = 'en-US';
  if (this.view.initDate) {
      initDate = formatDate(this.view.initDate, format, locale);
  }
  if (this.view.endDate) {
      endDate = formatDate(this.view.endDate, format, locale);
  }

  if (this.view.searchValue && this.view.searchValue.replace(/\s/g, '') !== '') {
      searchValue = this.view.searchValue;
  }

  this.cashRegisterService.findDayRegisters(searchValue, initDate, endDate, page, size).then(data => {
      this.view.allDepositCloseCash = data['data'];
      this.view.totalElements = data['totalElements'];
  }).catch((error) => {
      if (error instanceof HttpErrorResponse) {
          this.view.showError('Atención', error.error.message);
      } else {
          this.view.showError('Atención', 'Algo salio mal');
      }
  });
}

}
