import { Component, OnInit } from '@angular/core';
import { AssingPricesView } from './assing-prices.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserModel } from 'src/app/models/client.model';
import { AssingPricesPresenter } from './presenter/assing-prices.presenter';
import { DataProvider } from 'src/app/services/data.provider';
import { UserPriceModel } from 'src/app/models/invoice.model';

@Component({
  selector: 'app-assing-prices',
  templateUrl: './assing-prices.component.html',
  styleUrls: ['./assing-prices.component.scss']
})
export class AssingPricesComponent extends AbstractView implements OnInit, AssingPricesView {

  value = '';
  display = false;
  displayModal = false;
  selectedRoles: string[] = [];
  search = '';
  userFullName = '';
  page = 0;
  size = 6;
  sizeSeller = 30;
  users: UserModel[];
  totalElements: number;
  userPrice: UserPriceModel;
  loadUserPrice: UserPriceModel;
  idUserPrice = '';

  roles1: any[] = [
    { label: 'Administrador', value: 'ADMINISTRATOR', selected: false },
    { label: 'Base', value: 'BASE', selected: false },
    { label: 'Especial', value: 'ESPECIAL', selected: false },
    { label: 'Limite', value: 'LIMIT', selected: false },
    { label: 'Mayorista', value: 'WHOLE_SALER', selected: false }
  ];

  roles: any[] = [
    { label: 'Especial', value: 'ESPECIAL', selected: false },
    { label: 'Mayorista', value: 'WHOLE_SALER', selected: false },
    { label: 'Administrador', value: 'ADMINISTRATOR', selected: false },
    { label: 'Limite', value: 'LIMIT', selected: false },
    { label: 'Base', value: 'BASE', selected: false }
  ];

  constructor(
    public router: Router,
    public service: MessageService,
    private assingPricesPresenter: AssingPricesPresenter,
    public dataProvider: DataProvider,
  ) {
    super(service, router);
    assingPricesPresenter.view = this;
    this.loadUserPrice = { price: '' };
  }

  ngOnInit() {
    this.assingPricesPresenter.onSearchUser();
  }

  toggleRole(role: string) {
    const index = this.selectedRoles.indexOf(role);
    if (index === -1) {
      this.selectedRoles.push(role);
    } else {
      this.selectedRoles.splice(index, 1);
    }
  }

  isRoleSelected(role: string): boolean {
    return this.selectedRoles.includes(role);
  }

  assingPrices() {
    this.display = false;
    this.userPrice.price = this.selectedRoles.toString();
    this.assingPricesPresenter.sendUserPrice(this.userPrice);
  }

  showPrices(rowData) {

    this.assingPricesPresenter.loadUserPriceId(rowData.userId);
    this.userFullName = rowData.fullName;
    this.userPrice = {
      user: rowData,
      createdBy: this.dataProvider.defaultUser.userName,
      updatedBy: this.dataProvider.defaultUser.userName
    };

    this.display = true;

  }
}
