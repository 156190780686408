import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { OrderLine } from 'src/app/models/orderline.model';
import { ProcessModel } from 'src/app/models/process.model';
import { AdvanceService } from 'src/app/services/advance.service';
import { BankService } from 'src/app/services/bank.service';
import Decimal from 'decimal.js';
import { CouponService } from 'src/app/services/coupon.service';
import { CreditCardService } from 'src/app/services/credit-card.service';
import {
  DefaultconfigurationsService, GeneralConfigurations
} from 'src/app/services/defaultconfigurations.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Presenter } from '../../../../../../core/presenter';
import { ClientModel } from '../../../../../../models/client.model';
import { InvoiceModel } from '../../../../../../models/invoice.model';
import { ClientService } from '../../../../../../services/client.service';
import { DataProvider } from '../../../../../../services/data.provider';
import { PaymentMethod } from '../../../../../../models/paymentmethod';
import { OrderService } from '../../../../../../services/order.service';
import { InvoiceBalancePresenter } from '../../invoice-balance/presenter/invoice-balance.presenter';
import { AdvancesDetailView } from '../advances-detail.view';
import { select, Store } from '@ngrx/store';
import { Payment, PaymentToShow } from '../../../../../../models/payment.model';
import {
  CardType, CreditCardType, CreditDeferredType, ProcessType,
  TransactionPresenter, TransactionType
} from 'src/app/models/creditcardtype.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BankAccount } from 'src/app/models/bankaccount.model';
import { ClearClient } from 'src/app/reducers/client.action';
import { AddPaymentToAdvance, DeleteAdvance } from 'src/app/reducers/advance.action';
import { ClientAdvanceModel } from 'src/app/models/client-advance.model';
import { ClientAdvanceService } from 'src/app/services/client-advance.service';
import { ClearAdvances } from 'src/app/reducers/advance.action';
import { Bank, GraceMonthsBank, PaymentFees } from 'src/app/models/bank.model';

@Injectable({
  providedIn: 'root',
})
export class AdvancesDetailPresenter implements Presenter {
  view: AdvancesDetailView;
  procesesSelected: ProcessModel;
  creditControl = 0;
  debitControl = 0;
  creditCardPayment: boolean;
  orderLines: OrderLine[] = [];
  paymentsBanksPromo = false;
  businessUnit = '';
  constructor(
    private store: Store<{
      client: ClientModel;
      adviser: ClientModel;
      orderLines: OrderLine;
      paymentsAdvance: Payment;
    }>,
    private clientService: ClientService,
    private bankService: BankService,
    private invoiceService: InvoiceService,
    public advanceService: AdvanceService,
    public settingPresenter: SettingPresenter,
    private orderService: OrderService,
    private dataProvider: DataProvider,
    private defaultconfigurationsService?: DefaultconfigurationsService,
    private creditCardService?: CreditCardService,
    private invoiceBalancePresenter?: InvoiceBalancePresenter,
    private couponService?: CouponService,
    private clientAdvanceService?: ClientAdvanceService,
  ) { }

  init() {
    this.businessUnit = this.dataProvider.defaultWarehouse.businessUnit;
    this.dataProvider.processPayment = 'current';
    this.view.clientAdvance.isCPOS = this.dataProvider.isConfigurationCpos;
    this.store.pipe(select('paymentsAdvance')).subscribe((state) => {
      this.view.paymentsAdvance = [];
      if (state && state.paymentsAdvance) {
        state.paymentsAdvance.forEach((payment) => {
          this.view.paymentsAdvance.push(payment);
        });
      }
    });

    this.store.pipe(select('client')).subscribe((valor) => {
      this.view.client = null;
      this.view.clientBankAccountSelected = null;
      this.view.clientBankAccounts = [];
      if (valor.client) {
        this.view.client = valor.client;
      }
    });
    this.clientService
      .findBankAccount(this.view.client)
      .then((bankAccounts) => {
        this.view.clientBankAccounts = [];
        bankAccounts.forEach((bankAccount) => {
          this.view.clientBankAccounts.push({
            label: bankAccount.accountNumber,
            value: bankAccount,
          });
        });
        if (this.view.clientBankAccounts.length) {
          this.view.clientBankAccountSelected = this.view.clientBankAccounts[0];
        }
      });
    this.bankService.findAllBanks().then((banks) => {
      this.view.banks = [];
      banks.forEach((bank) => {
        this.view.banks.push({
          label: bank.name,
          value: bank,
        });
      });
      if (this.view.banks.length > 0) {
        this.view.bankSelected = this.view.banks[0];
      }
    });
    this.view.paymentsAdvance = [];
  }

  addPaymentCpos() {
    if (!this.view.paymentMethodSelectedAdvances) {
      this.view.showWarn('Validación', 'Seleccionar un método de pago');
    } else if (
      this.view.paymentMethodSelectedAdvances.value.valueOf() ===
      PaymentMethod.CASH.valueOf()
    ) {
      if (!this.view.cashAmount || !(this.view.cashAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.dataProvider.isCrediRedPrice && !this.view.isEntryByCredired) {
        this.view.showWarn('', 'No tiene activada la opción de entrada para pago de credired');
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CASH,
        referenceNumber: null,
        amount: this.view.cashAmount,
        bankAccountPresenter: null,
        name: 'Efectivo',
      };
      this.store.dispatch(new AddPaymentToAdvance(checkPayment));
      this.view.cashAmount = null;
    } else if (
      this.view.paymentMethodSelectedAdvances.value.valueOf() ===
      PaymentMethod.CREDIT_CARD.valueOf()
    ) {
      if (!this.view.creditCardAmount || !(this.view.creditCardAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (
        this.view.client.dni.valueOf() === '9999999999999' &&
        this.creditControl + Number(this.view.creditCardAmount) >
        this.settingPresenter.defaultconfigurationsService
          .defaultMaxCreditDebitFinalConsumerAmount
      ) {
        this.view.showError(
          '',
          'Consumidor final no puede exceder $' +
          this.settingPresenter.defaultconfigurationsService
            .defaultMaxCreditDebitFinalConsumerAmount
        );
        return;
      }
      if (
        !(this.view.creditBankSelected && this.view.creditBankSelected.bankId)
      ) {
        this.view.showWarn('', 'Debe seleccionar un banco');
        return;
      }
      if (!this.view.creditCardSelected) {
        this.view.showWarn('', 'Debe seleccionar una tarjeta de crédito');
        return;
      }
      if (!this.view.creditCardTypeSelected) {
        this.view.showWarn('', 'Debe seleccionar tipo de crédito');
        return;
      }
      if ((this.dataProvider.isConfigurationCpos && this.dataProvider.isConnectionCpos) && !this.view.creditDeferredTypeSelected) {
        this.view.showWarn('', 'Debe seleccionar tipo de diferido');
        return;
      }
      if (!this.view.paymentFeeSelected) {
        this.view.showWarn('', 'Debe seleccionar el numero de cuotas');
        return;
      }
      this.view.showConfirmCreditCardPayment();
    } else if (
      this.view.paymentMethodSelectedAdvances.value.valueOf() ===
      PaymentMethod.BANK_TRANSFER.valueOf()
    ) {
      let transferNumber = 0;
      if (!this.view.transferAmount || !(this.view.transferAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (this.dataProvider.isCrediRedPrice && !this.view.isEntryByCredired) {
        this.view.showWarn('', 'No tiene activada la opción de entrada para pago de credired');
        return;
      }
      if (
        !this.view.transferNumber ||
        this.view.transferNumber.trim().length === 0
      ) {
        this.view.showWarn(
          'Validación',
          'Debe ingresar un número de transferencia'
        );
        return;
      }
      // tslint:disable-next-line: radix
      transferNumber = parseInt(this.view.transferNumber);
      if (transferNumber <= 0) {
        this.view.showWarn(
          'Validación',
          'Número de transferencia ingresado incorrecto'
        );
        return;
      }
      if (!this.view.selectedBank) {
        this.view.showWarn('Validación', 'Debe  seleccionar un banco.');
        return;
      }
      if (!this.view.bankAccountSelected) {
        this.view.showWarn(
          'Validación',
          'Debe  seleccionar una cuenta bancaria.'
        );
        return;
      }
      const checkPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.BANK_TRANSFER,
        referenceNumber: this.view.transferNumber,
        amount: this.view.transferAmount,
        name: 'Transferencia bancaria',
        bankAccountPresenter: this.view.bankAccountSelected,
      };
      this.store.dispatch(new AddPaymentToAdvance(checkPayment));
      this.view.transferNumber = null;
      this.view.transferAmount = null;
      this.view.selectedBank = null;
      this.view.bankAccountSelected = null;
    } else if (
      this.view.paymentMethodSelectedAdvances.value.valueOf() ===
      PaymentMethod.DEBIT.valueOf()
    ) {
      if (!this.view.debitCardAmount || !(this.view.debitCardAmount > 0)) {
        this.view.showWarn('Validación', 'Debe ingresar un monto válido');
        return;
      }
      if (
        this.view.client.dni.valueOf() === '9999999999999' &&
        this.debitControl + Number(this.view.debitCardAmount) >
        this.settingPresenter.defaultconfigurationsService
          .defaultMaxCreditDebitFinalConsumerAmount
      ) {
        this.view.showError(
          '',
          'Consumidor final no puede exceder $' +
          this.settingPresenter.defaultconfigurationsService
            .defaultMaxCreditDebitFinalConsumerAmount
        );
        return;
      }
      if (!this.view.debitBankSelected) {
        this.view.showWarn('Validación', 'Debe seleccionar un banco');
        return;
      }
      if (!this.view.debitCardSelected) {
        this.view.showWarn('Validación', 'Debe seleccionar una tarjeta');
        return;
      }
      this.view.showConfirmDebitCardPayment();
    }
  }

  addCreditCardPayment() {
    if (this.view.clientAdvance.isCPOS) {
      let transactionType: TransactionType = TransactionType.DEFERRED_TRANSACTION;
      let creditCardType: CreditCardType = null;
      if (!this.creditCardPayment) {
        if (
          this.view.creditDeferredTypeSelected ===
          CreditDeferredType.NORMAL_OR_CURRENT
        ) {
          transactionType = TransactionType.CURRENT_TRANSACTION;
        }
      }
      if (!this.creditCardPayment) {
        if (this.view.creditCardTypeSelected === CreditCardType.CURRENT_PAYMENT) {
          creditCardType = CreditCardType.CURRENT_PAYMENT;
        } else if (
          this.view.creditCardTypeSelected === CreditCardType.CREDIT_WITH_INTEREST
        ) {
          creditCardType = CreditCardType.CREDIT_WITH_INTEREST;
        } else {
          creditCardType = CreditCardType.CREDIT_WITHOUT_INTEREST;
        }
      } else {
        creditCardType = CreditCardType.CREDIT_WITHOUT_INTEREST;
      }
      const transactionPresenter: TransactionPresenter = {
        transactionType: transactionType,
        processType: ProcessType.PAYMENT_PROCESS,
        creditDeferredType: this.view.creditDeferredTypeSelected,
        deferredMonthTime: this.view.paymentFeeSelected.number,
        monthGrace: this.view.selectedGrace ? this.view.selectedGrace.number : 0,
        totalAmount: this.view.creditCardAmount,
        iva: 0,
        cardType: CardType.CREDIT_CARD,
        creditCardType: creditCardType,
        userId: this.dataProvider.defaultUser.userId,
        clientId: '',
        warehouseId: this.dataProvider.defaultWarehouse.warehouseId,
        baseAmountWithTaxes: 0,
        baseAmountWithoutTaxes: 0,
        transactionTax: 0,
        bankPresenter: this.view.creditBankSelected
      };
      this.view.blockCard = true;
      this.view.blockUi();
      this.creditCardService.addPayment(transactionPresenter).subscribe(
        (transaction: TransactionPresenter) => {
          const response = transaction.responseTransactionPresenter;
          const lote = this.getPadStart(response.lotNumber, '0', 16);
          const reference = this.getPadStart(
            response.transactionReferenceSequence,
            '0',
            16
          );
          const auth = this.getPadStart(
            response.authorizeNumberTransaction,
            '0',
            8
          );
          const voucherNumber = lote.concat('-', reference, '-', auth);
          const creditCartPayment: PaymentToShow = {
            paymentMethod: PaymentMethod.CREDIT_CARD,
            referenceNumber: voucherNumber,
            amount: this.view.creditCardAmount,
            cardPresenter: this.view.creditCardSelected,
            name: 'Tarjeta de crédito',
            bankPresenter: this.view.creditBankSelected,
            creditType: this.view.creditCardTypeSelected,
            paymentFees: this.view.paymentFeeSelected.number,
            graceMonths: this.view.selectedGrace
              ? this.view.selectedGrace.number
              : 0,
            auth: response.authorizeNumberTransaction,
          };
          this.store.dispatch(new AddPaymentToAdvance(creditCartPayment));
          this.view.voucherNumber = null;
          this.view.creditCardAmount = null;
          this.view.creditEntitySelected = null;
          this.view.creditLote = null;
          this.view.creditReference = null;
          this.view.creditAuth = null;
          this.view.selectedCreditCardBank = null;
          this.view.creditCardTypeSelected = null;
          this.view.paymentFeeSelected = null;
          this.view.creditBankSelected = null;
          this.view.creditCardSelected = null;
          this.view.selectedGrace = null;
          this.view.creditDeferredTypeSelected = null;
          this.view.blockCard = false;
          this.view.showSuccess('', 'Transacción generada correctamente');
          this.view.showPdf(response);
        },
        (error: HttpErrorResponse) => {
          this.view.showError('Error al  agregar el pago', error.error.message);
          this.view.blockCard = false;
        }
      );
    } else {
      this.view.creditLote = this.getPadStart(this.view.creditLote, '0', 16);
      this.view.creditReference = this.getPadStart(this.view.creditReference, '0', 16);
      this.view.creditAuth = this.getPadStart(this.view.creditAuth, '0', 8);
      this.view.voucherNumber = this.view.creditLote.concat('-', this.view.creditReference, '-', this.view.creditAuth);

      const creditCartPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.CREDIT_CARD,
        referenceNumber: this.view.voucherNumber,
        amount: this.view.creditCardAmount,
        cardPresenter: this.view.creditCardSelected,
        name: 'Tarjeta de crédito',
        bankPresenter: this.view.creditBankSelected,
        creditType: this.view.creditCardTypeSelected,
        paymentFees: this.view.paymentFeeSelected.number,
        graceMonths: this.view.selectedGrace ? this.view.selectedGrace.number : 0
      };
      this.store.dispatch(new AddPaymentToAdvance(creditCartPayment));
      this.view.voucherNumber = null;
      this.view.creditCardAmount = null;
      this.view.creditEntitySelected = null;
      this.view.creditLote = null;
      this.view.creditReference = null;
      this.view.creditAuth = null;
      this.view.selectedCreditCardBank = null;
      this.view.creditCardTypeSelected = null;
      this.view.paymentFeeSelected = null;
      this.view.creditBankSelected = null;
      this.view.creditCardSelected = null;
      this.view.selectedGrace = null;
    }
  }

  getPadStart(value: string, replace: string, length: number) {
    return value.padStart(length, replace);
  }

  addDebitCardPayment() {
    if (this.view.clientAdvance.isCPOS) {
      const transactionPresenter: TransactionPresenter = {
        transactionType: TransactionType.CURRENT_TRANSACTION,
        processType: ProcessType.PAYMENT_PROCESS,
        creditDeferredType: CreditDeferredType.NORMAL_OR_CURRENT,
        deferredMonthTime: 1,
        monthGrace: 0,
        totalAmount: this.view.debitCardAmount,
        iva: 0,
        cardType: CardType.DEBIT_CARD,
        userId: this.dataProvider.defaultUser.userId,
        clientId: '',
        warehouseId: this.dataProvider.defaultWarehouse.warehouseId,
        baseAmountWithTaxes: 0,
        baseAmountWithoutTaxes: 0,
        transactionTax: 0,
        bankPresenter: this.view.debitBankSelected
      };
      this.view.blockCard = true;
      this.view.blockUi();
      this.creditCardService.addPayment(transactionPresenter).subscribe(
        (transaction: TransactionPresenter) => {
          const response = transaction.responseTransactionPresenter;
          const lote = this.getPadStart(response.lotNumber, '0', 16);
          const reference = this.getPadStart(
            response.transactionReferenceSequence,
            '0',
            16
          );
          const auth = this.getPadStart(
            response.authorizeNumberTransaction,
            '0',
            8
          );
          const voucherNumber = lote.concat('-', reference, '-', auth);
          const debitCartPayment: PaymentToShow = {
            paymentMethod: PaymentMethod.DEBIT,
            referenceNumber: voucherNumber,
            amount: this.view.debitCardAmount,
            cardPresenter: this.view.debitCardSelected,
            bankPresenter: this.view.debitBankSelected,
            name: 'Tarjeta de débito',
            auth: response.authorizeNumberTransaction,
          };
          this.store.dispatch(new AddPaymentToAdvance(debitCartPayment));
          this.view.voucherNumberDebit = null;
          this.view.debitCardAmount = null;
          this.view.debitEntitySelected = null;
          this.view.debitLote = null;
          this.view.debitReference = null;
          this.view.debitAuth = null;
          this.view.selectedDebitCardBank = null;
          this.view.debitBankSelected = null;
          this.view.debitCardSelected = null;
          this.view.blockCard = false;
          this.view.showSuccess('', 'Transacción generada correctamente');
          this.view.showPdf(response);
        },
        (error: HttpErrorResponse) => {
          this.view.showError('Error al  agregar el pago', error.error.message);
          this.view.blockCard = false;
        }
      );
    } else {
      this.view.debitLote = this.getPadStart(this.view.debitLote, '0', 16);
      this.view.debitReference = this.getPadStart(this.view.debitReference, '0', 16);
      this.view.debitAuth = this.getPadStart(this.view.debitAuth, '0', 8);
      this.view.voucherNumberDebit = this.view.debitLote.concat('-', this.view.debitReference, '-', this.view.debitAuth);

      const debitCartPayment: PaymentToShow = {
        paymentMethod: PaymentMethod.DEBIT,
        referenceNumber: this.view.voucherNumberDebit,
        amount: this.view.debitCardAmount,
        cardPresenter: this.view.debitCardSelected,
        bankPresenter: this.view.debitBankSelected,
        name: 'Tarjeta de débito'
      };
      this.store.dispatch(new AddPaymentToAdvance(debitCartPayment));
      this.view.voucherNumberDebit = null;
      this.view.debitCardAmount = null;
      this.view.debitEntitySelected = null;
      this.view.debitLote = null;
      this.view.debitReference = null;
      this.view.debitAuth = null;
      this.view.selectedDebitCardBank = null;
      this.view.debitBankSelected = null;
      this.view.debitCardSelected = null;
    }
  }

  filterBankAccounts() {
    this.view.bankAccountSelected = null;
    this.view.filteredBankAccounts = this.dataProvider.bankAcounts.filter(
      (bankAcount) =>
        this.view.selectedBank.bankId === bankAcount.bankPresenter.bankId
    );
  }

  loadBanksFromAtix() {
    this.bankService.ownBanks().subscribe(
      (res: BankAccount[]) => {
        this.view.ownBanks = [];
        res.forEach((bankAcount) => {
          const bankIsPresent = this.view.ownBanks.find(
            (bank) => bank.bankId === bankAcount.bankPresenter.bankId
          );
          if (!bankIsPresent) {
            this.view.ownBanks.push(bankAcount.bankPresenter);
          }
        });
        this.view.selectedBank = null;
        this.dataProvider.bankAcounts = res;
      },
      () => {
        this.view.showError('', 'No hay bancos ');
      }
    );
  }

  validateCpos() {
    const differentPayments = this.view.paymentsAdvance.filter(
      (pay) => pay.paymentMethod === PaymentMethod.CREDIT_CARD
    ).length;

    if (
      (this.creditCardPayment ||
        this.dataProvider.processPayment === 'deferred') &&
      !differentPayments) {
      this.view.showError('Pago diferido activado', 'Añadir pago con tarjeta');
      return;
    }
    this.view.showConfirmation();
  }

  generateClientAdvance() {
    if (this.view.blockDetails) {
      return;
    }
    const payments: Payment[] = [];
    if (this.view.paymentsAdvance) {
      this.view.paymentsAdvance.forEach((line) => {
        const pay: Payment = {
          amount: line.amount,
          bankAccountPresenter: line.bankAccountPresenter,
          cardPresenter: line.cardPresenter,
          confirmationCode: line.confirmationCode,
          effectiveDate: line.effectiveDate,
          paymentMethod: line.paymentMethod,
          referenceNumber: line.referenceNumber,
          bankPresenter: line.bankPresenter,
          creditType: line.creditType,
          paymentFees: line.paymentFees,
          graceMonths: line.graceMonths,
          affectedInvoices: line.affectedInvoices,
        };
        payments.push(pay);
      });
    }
    console.log(this.view.client);
    this.view.clientAdvance.clientPresenter = this.view.client;
    this.view.clientAdvance.user = this.dataProvider.defaultUser as any;
    this.view.clientAdvance.warehouse = this.dataProvider.defaultWarehouse;
    this.view.clientAdvance.isCPOS = this.dataProvider.isConfigurationCpos;
    this.view.clientAdvance.paymentLinePresenters = payments;
    this.clientAdvanceService.saveClientAdvance(this.view.clientAdvance).then((clientAdvanceResult) => {
      console.log(clientAdvanceResult);
      this.view.showInfo('Anticipo', 'Se generó correctamente');
      this.view.showPdf(clientAdvanceResult);
      this.dataProvider.processPayment = 'current';
      this.view.dispose(true);
      this.store.dispatch(new ClearClient());
      this.store.dispatch(new ClearAdvances());
    })
      .catch((error) => {
        this.view.blockDetails = false;
        if (error instanceof HttpErrorResponse) {
          this.view.showError('Atención', error.error.message);
        } else {
          this.view.showError('Atención', 'Algo salio mal facturando');
        }
      });

  }
  filterCards() {
    if (this.view.creditCardSelected) {
      this.view.creditCardSelected.graceMonthsBank = [];
      this.view.creditCardSelected.paymentFeesBank = [];
      const names: string[] = [];
      const namespayments: string[] = [];
      // tslint:disable-next-line: only-arrow-functions
      const graces = this.view.creditCardSelected.graceMonths
        .split(',')
        .map(function (item) {
          const graceMonthsBank: GraceMonthsBank = {
            number: parseInt(item, 10),
            name: item.trim(),
          };
          return graceMonthsBank;
        });
      // tslint:disable-next-line: only-arrow-functions
      const payments = this.view.creditCardSelected.paymentFees
        .split(',')
        .map(function (item) {
          const paymentsFees: PaymentFees = {
            number: parseInt(item, 10),
            name: item.trim(),
          };
          return paymentsFees;
        });
      graces.forEach((grace) => {
        if (
          !names.includes(grace.name) &&
          grace.name.length >= 1 &&
          !isNaN(grace.number)
        ) {
          this.view.creditCardSelected.graceMonthsBank.push(grace);
          names.push(grace.name);
        }
      });
      payments.forEach((payment) => {
        if (
          !namespayments.includes(payment.name) &&
          payment.name.length >= 1 &&
          !isNaN(payment.number)
        ) {
          this.view.creditCardSelected.paymentFeesBank.push(payment);
          namespayments.push(payment.name);
        }
      });
      if (this.view.isPromoDay) {
        if (
          this.view.creditCardAmount &&
          this.view.creditCardAmount >=
          GeneralConfigurations.defaultAmountCreditCard
        ) {
          this.view.creditCardSelected.paymentFeesBank = null;
          this.view.creditCardSelected.paymentFeesBank = [
            { number: 3, name: '3' },
            { number: 6, name: '6' },
            { number: 9, name: '9' },
            { number: 12, name: '12' },
            { number: 15, name: '15' },
            { number: 18, name: '18' },
            { number: 21, name: '21' },
            { number: 24, name: '24' },
          ];
        } else {
          this.view.creditCardSelected.paymentFeesBank = null;
          this.view.creditCardSelected.paymentFeesBank = [
            { number: 3, name: '3' },
            { number: 6, name: '6' },
            { number: 9, name: '9' },
            { number: 12, name: '12' },
          ];
        }
        this.view.creditCardSelected.graceMonthsBank = null;
        this.view.creditCardSelected.graceMonthsBank = [
          { number: 2, name: '2' },
        ];
        this.view.selectedGrace = { number: 2, name: '2' };
      }
    }
  }

  getBanksByCriteria() {
    this.view.blockUi();
    this.bankService.banksByCard().subscribe(
      (res: Bank[]) => {
        this.view.cardBanks = res;
        if (this.view.cardBanks.length === 0) {
          this.view.cardBanks = [];
          this.view.showError('Bancos', 'Búsqueda sin registros');
        } else {
          // tslint:disable-next-line: only-arrow-functions
          this.view.cardBanks = res.sort(function (a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.dataProvider.banksSpecialDay = [];
          this.dataProvider.banksSpecialDayNames = '';
          this.view.cardBanks.forEach((bank) => {
            if (bank.promoBank) {
              this.dataProvider.banksSpecialDay.push(bank);
              let name = bank.name.toLowerCase();
              name = name[0].toUpperCase() + name.slice(1);

              this.dataProvider.banksSpecialDayNames =
                this.dataProvider.banksSpecialDayNames + name + ', ';
            }
          });
        }
      },
      () => {
        this.view.cardBanks = [];
        this.view.showError('', 'Error al buscar bancos');
      }
    );
  }

  deletePayment() {
    if (this.view.selectedRowIndex !== -1) {
      this.store.dispatch(new DeleteAdvance(this.view.selectedRowIndex));
      this.view.selectedRowIndex = -1;
      this.view.showError('', 'Pago eliminado');
    }
  }
  deleteCardPayment(payment: Payment) {
    this.view.blockCard = true;
    this.view.blockUi();
    this.creditCardService
      .deletePayment(payment.auth)
      .subscribe(
        (res: any) => {
          this.deletePayment();
          this.view.showPdf(res.responseTransactionPresenter);
          this.view.blockCard = false;
        },
        (error: HttpErrorResponse) => {
          this.view.showError(
            'Error al  eliminar el pago',
            error.error.message
          );
          this.view.blockCard = false;
        }
      );
  }

  filterCardsPresenter() {
    if (this.view.paymentMethodSelectedAdvances.value === PaymentMethod.CREDIT_CARD) {
      this.view.creditCards =
        this.view.creditBankSelected.cardPresenters.filter(
          (card) => card.credit
        );
    } else if (this.view.paymentMethodSelectedAdvances.value === PaymentMethod.DEBIT) {
      this.view.debitCards = this.view.debitBankSelected.cardPresenters.filter(
        (card) => card.debit
      );
    }
  }

  onTypeCreditChange() {
    if (
      this.view.creditCardTypeSelected.valueOf() ===
      CreditCardType.CREDIT_WITH_INTEREST
    ) {
      this.view.creditDeferredTypes = [
        {
          label: 'Diferido cuota fija con interes',
          value: CreditDeferredType.DEFER_FIX_FEE_WITH_INTEREST,
        },
        // { label: 'Diferido pago mes a mes con interes', value: CreditDeferredType.DEFER_PAY_MONTH_TO_MONTH_WITH_INTEREST },
        // { label: 'Diferido pago mes gracia con interes', value: CreditDeferredType.DEFER_PAY_MONTH_GRACE_WITH_INTEREST },
        // { label: 'Diferido con meses de gracia con interes', value: CreditDeferredType.DEFER_WITH_MONTHS_GRACE_WITH_INTEREST },
      ];
    } else if (
      this.view.creditCardTypeSelected.valueOf() ===
      CreditCardType.CREDIT_WITHOUT_INTEREST
    ) {
      this.view.creditDeferredTypes = [
        {
          label: 'Diferido cuota fija sin intereses',
          value: CreditDeferredType.DEFER_FIX_FEE_WITHOUT_INTEREST,
        },
      ];
    }
    if (
      this.view.creditCardTypeSelected.valueOf() !==
      CreditCardType.CREDIT_WITHOUT_INTEREST
    ) {
      this.view.selectedGrace = null;
    }
  }

  validatePromoBank() {
    if (this.dataProvider.processPayment === 'deferred') {
      this.view.creditCardTypes = [
        {
          label: 'Crédito sin intereses',
          value: CreditCardType.CREDIT_WITHOUT_INTEREST,
        },
        // { label: 'Crédito con intereses', value: CreditCardType.CREDIT_WITH_INTEREST },
      ];
    } else if (this.dataProvider.processPayment === 'current') {
      this.view.creditCardTypes = [
        { label: 'Corriente', value: CreditCardType.CURRENT_PAYMENT },
        {
          label: 'Crédito con intereses',
          value: CreditCardType.CREDIT_WITH_INTEREST,
        },
        /* {
          label: 'Crédito sin intereses',
          value: CreditCardType.CREDIT_WITHOUT_INTEREST,
        }, */
      ];
    }
  }

}
