import { Component, OnInit } from '@angular/core';
import { ShortcutModel } from 'src/app/models/shortcuts';
import { DynamicDialogRef, MessageService, DynamicDialogConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent extends AbstractView implements OnInit {

  constructor(
    public ref: DynamicDialogRef,
    public service: MessageService,
    public config: DynamicDialogConfig,
    public router: Router) {
    super(service, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_LIST_SHORTCUTS)) {
      this.showError('', 'Sin autorización');
      this.ref.close();
    }
  }
  shortcuts: ShortcutModel[] = [];

  ngOnInit() {
    this.initShortcuts();
  }
  initShortcuts() {
    this.shortcuts = [
      { labels: ['f1'], action: 'Mostrar menú de atajos' },
      { labels: ['f2'], action: 'Mostrar permisos' },
      { labels: ['esc'], action: 'Foco en búsqueda de productos' },
      { labels: ['alt + o'], action: 'Foco en observación' },
      { labels: ['alt + f'], action: 'Foco en cliente' },
      { labels: ['alt + n'], action: 'Nuevo cliente' },
      { labels: ['alt + up'], action: 'Mostrar ordenes' },
      { labels: ['alt + down'], action: 'Obtener consumidor final' },
      { labels: ['alt + g', 'alt + s'], action: 'Guardar en ventanas emergentes' },
      { labels: ['alt + del', 'alt +  backspace'], action: 'Limpiar pantalla' },
      { labels: ['alt + shift + g', 'alt + shift + s'], action: 'Guardar orden | factura' },
    ];
  }
}
