import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { OrderLine, OrderLineToSave } from '../models/orderline.model';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpClient) { }

  public verifyCouponApply(orderDetailPresenters: OrderLine[]) {
    return this.http.post(environment.apiUrl + '/checkCouponApplies', orderDetailPresenters);
  }
}
