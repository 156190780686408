import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { SetObservationView } from '../set-observation.view';
import { Presenter } from 'src/app/core/presenter';
import { ReportService } from 'src/app/services/report.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class SetObservationPresenter implements Presenter {
    view: SetObservationView;

    constructor(
        private reportService: ReportService


    ) {

    }


    setObservation() {
        if (!this.view.observation) {
            this.view.showError('', 'Ingrese una observación');
            return;
        }
        this.view.dayRegister.dayRegisterObservationPresenters = [{ observation: this.view.observation }];
        this.reportService.saveObservation(this.view.dayRegister).then(() => {
            this.view.showSuccess('', 'Observación añadida');
            this.view.closeView();
        }).catch(error => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        }
        );
    }


}
