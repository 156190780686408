import { Presenter } from 'src/app/core/presenter';
import { LoginView } from '../login.view';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { User } from 'src/app/models/user.model';
import { LogIn } from 'src/app/reducers/user.action';
import { UserService } from 'src/app/services/user.service';
import * as jwt_decode from 'jwt-decode';
import { RolModel } from 'src/app/models/rol.model';

@Injectable({
    providedIn: 'root',
})
export class LoginPresenter implements Presenter {
    view: LoginView;

    constructor(private store: Store<{ user: User }>, private userService: UserService) {
        this.store.pipe(select('userState')).subscribe(val => {
        });
    }

    verifyAuthentication() {
        const autentication = localStorage.getItem('authorization');
        if (autentication != null) {
            this.view.redirectTo('/panel/pos');
        }
    }



    acceptLoging() {
        const user = this.view.user;
        const password = this.view.password;
        if (!password || password.length === 0) {
            this.view.showMessage('Debe ingresar una contraseña');
            return;
        }
        if (!user || user.length === 0) {
            this.view.showMessage('Debe ingresar un usuario');
            return;
        }
        this.view.blockUi();
        this.userService.doLogin(user, password).then(data => {
            localStorage.setItem('authorization', data);
            localStorage.setItem('permisions', JSON.stringify(this.getAuthorities(data)));
            this.store.dispatch(new LogIn({
                username: user,
                name: user
            }));
            this.view.redirectTo('/panel/pos');
        }).catch((error: any) => {

            this.view.showError('Error', error.error.error);
        });
    }

    getAuthorities(token: string) {
        const auth: RolModel = jwt_decode(token, { header: true });
        return auth.roles;
    }

}
