import { Injectable } from '@angular/core';
import { Bank } from '../models/bank.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Client } from '../models/client.model';

@Injectable()
export class BankService {

  constructor(private http: HttpClient) { }
  findAllBanks(): Promise<Bank[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Bank[]>(environment.apiUrl + '/bank')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  banksByCriteria(searchValue: string, page: number = 0, size: number = 50) {
    return this.http.get(environment.apiUrl + '/banksByCriterion?searchValue=' + searchValue + '&page=' + page + '&size=' + size);
  }

  banksByCard() {
    return this.http.get(environment.apiUrl + '/bank/cards');
  }

  ownBanks() {
    return this.http.get(environment.apiUrl + '/ownBanks');
  }
}
