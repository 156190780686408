import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import {
  Product,
  ProductSupplierDetailPresenter,
  ProductSupplierPresenter,
} from 'src/app/models/product.model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ProductSupplierReportView } from './product-supplier-report.view';
import { ProductSupplierReportPresenter } from './presenter/product-supplier-report.presenter';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'app-product-supplier-report',
  templateUrl: './product-supplier-report.component.html',
  styleUrls: ['./product-supplier-report.component.scss'],
})
export class ProductSupplierReportComponent
  extends AbstractView
  implements OnInit, ProductSupplierReportView {
  view: ProductSupplierReportView;

  constructor(
    private messageService: MessageService,
    public router: Router,
    public productSupplierReportPresenter: ProductSupplierReportPresenter
  ) {
    super(messageService, router);
    productSupplierReportPresenter.view = this;
  }
  reportsCSV: ProductSupplierPresenter[];
  subtotal: number;
  taxed: number;
  total: number;
  productSupplierDetailPresenters: ProductSupplierDetailPresenter[];
  showSelectedProduct: boolean;
  selectedProduct: Product;
  searchProduct: string;
  products: Product[];
  page = 0;
  size = 20;
  totalElements: number;
  initDate = new Date();
  since = new Date('2022-04-01T01:00:00');
  endDate = new Date();
  productId = '';
  searchValue = '';
  paginator: any;
  productSupplierPresenter: ProductSupplierPresenter[] = [];
  selectedResult: ProductSupplierPresenter[] = [];
  pageSizeOptions: number[] = [5, 10, 15, 20];
  pageSize = 20;

  ngOnInit() {
    this.getProductSupplierReport();
  }

  getProductSupplierReport() {
    this.productSupplierReportPresenter.getProductSupplierReport();
  }
  paginateInvoice($event) {
    this.page = $event['page'];
    this.productSupplierReportPresenter.getProductSupplierReport();
  }
  onSearchProduct(event) {
    this.searchProduct = event.query;
    this.productSupplierReportPresenter.onSearchProduct();
  }
  getData(event?: PageEvent) {
    this.selectedResult = this.productSupplierPresenter.slice(event.pageIndex * event.pageSize,
      event.pageIndex * event.pageSize + event.pageSize);
    console.log(this.selectedResult);
    return event;
    // this.page = event.pageIndex;
    // this.productSupplierReportPresenter.getProductSupplierReport();
  }
  searchSupplier(event: any) {
    if (event.target.value.length >= 4) {
      this.getProductSupplierReport();
    } else if (event.target.value.length <= 0) {
      this.getProductSupplierReport();
    }
  }
  addProduct() {
    this.productSupplierReportPresenter.findReportsByProduct();
  }
  clearProduct() {
    this.productSupplierReportPresenter.clearProduct();
  }
  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map((row) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/'/g, '""');
              if (cell.search(/('|,|\n)/g) >= 0) {
                cell = `'${cell}'`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
