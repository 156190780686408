import { Component } from '@angular/core';
import { MessageService, DialogService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { ManageClientComponent } from '../../pages/client/manage-client/manage-client.component';
import { ClientModel } from 'src/app/models/client.model';
import isOnline from 'is-online';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-breadscrumb',
    templateUrl: './breadscrumb.component.html',
    styleUrls: ['./breadscrumb.component.scss'],
    providers: [DialogService]
})
export class BreadscrumbComponent extends AbstractView {



    public ref;

    constructor(public router: Router, public messageService: MessageService, public dialogService: DialogService) {
        super(messageService, router);

    }

    show() {
        this.ref = this.dialogService.open(ManageClientComponent, {
            header: 'Nuevo Cliente',
            height: '60%',
            width: '70%',
            closeOnEscape: true,
        });

        this.ref.onClose.subscribe((newClient: ClientModel) => {
            if (newClient) {
                const message = this.setName(newClient) + ' ingresado con éxito! ';
                this.showSuccess('', message);
            }
        });


    }

    closeModal() {
        this.ref.close();
    }

}
