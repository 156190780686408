
import { Injectable } from '@angular/core';

import { ClientModel } from 'src/app/models/client.model';
import { Presenter } from 'src/app/core/presenter';
import { Store } from '@ngrx/store';
import { OrderLine } from 'src/app/models/orderline.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportService } from 'src/app/services/report.service';
import { formatDate } from '@angular/common';
import { ChartsView } from '../charts.view';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { DataProvider } from 'src/app/services/data.provider';


@Injectable({
    providedIn: 'root',
})
export class ChartsPresenter implements Presenter {
    view: ChartsView;

    constructor(
        private reportService: ReportService,
    ) {
    }


    onSearchUser() {
        const searchValue = this.view.searchValue;
        const page = this.view.page;
        const size = this.view.size;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.reportService.usersByCriterion(searchValue, page, size).then(data => {
            const user: UserPresenter[] = data['data'];
            this.view.totalElements = data['totalElements'];

            this.view.users = user;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearUser() {
        this.view.showSelectedUser = false;
        this.view.selectedUser = null;
        this.getInvoicesForUserReport();
    }

    findInvoicesByUser() {
        if (this.view.selectedUser && typeof this.view.selectedUser !== 'string') {
            this.view.showSelectedUser = true;
            this.getInvoicesForUserReport();
        }
    }

    getInvoicesForUserReport() {
        let initDate = '';
        let endDate = '';
        let user = '';
        const pageInvoice = this.view.pageInvoice;
        const sizeInvoice = this.view.sizeInvoice;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (!this.view.initDate || !this.view.endDate) {
            return;

        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        if (this.view.selectedUser) {
            user = this.view.selectedUser.userId;
        }
        this.view.blockUi();
        this.reportService.getInvoices(initDate, endDate, user, pageInvoice, sizeInvoice).then(data => {
            this.view.invoices = data['data'];
            this.view.totalElementsInvoice = data['totalElements'];
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
        this.reportService.getTotalAmount(initDate, endDate, user).then(data => {
            this.view.getTotalAmount = data;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
        this.reportService.getSystemPaymentsAmount(initDate, endDate, user).then(data => {
            if (this.view.businessUnit === 'agrotiendas') {

                this.view.data = {
                    labels: ['Efectivo', 'Cheque', 'Debito', 'Crédito', 'Tarjeta de crédito', 'Nota de crédito',
                        'Transferencia Bancaria', 'Retención', 'Credired', 'Cont. sin despacho', 'Subvención', 'Cobros pagos'],
                    datasets: [
                        {
                            label: 'Ventas por método de pago',
                            backgroundColor: '#42A5F5',
                            borderColor: '#1E88E5',
                            data: [data.CASH, data.CHECK, data.DEBIT, data.CREDIT, data.CREDIT_CARD, data.CREDIT_MEMO,
                            data.BANK_TRANSFER, data.WITHHOLD, data.CREDIRED, data.TOTAL_WITHOUT_DISPATCH, data.SUBVENTION,
                            data.PAYMENT_COLLECTIONS]
                        }
                    ]
                };
            } else {
                /* REPORT OF SALES */
                this.view.data = {
                    labels: ['Efectivo', 'Cheque', 'Debito', 'Crédito', 'Tarjeta de crédito', 'Nota de crédito',
                        'Transferencia Bancaria', 'Retención', 'Cont. sin despacho', 'Cobros pagos', 'De Una'],
                    datasets: [
                        {
                            label: 'Ventas por método de pago',
                            backgroundColor: '#42A5F5',
                            borderColor: '#1E88E5',
                            data: [data.CASH, data.CHECK, data.DEBIT, data.CREDIT, data.CREDIT_CARD, data.CREDIT_MEMO,
                            data.BANK_TRANSFER, data.WITHHOLD, data.TOTAL_WITHOUT_DISPATCH, data.PAYMENT_COLLECTIONS, data.DE_UNA]
                        }
                    ]
                };
            }
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });


    }

    printSalesReport() {

        let initDate = '';
        let endDate = '';
        let user = '';

        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (!this.view.initDate || !this.view.endDate) {
            return;

        }
        initDate = formatDate(this.view.initDate, format, locale);
        endDate = formatDate(this.view.endDate, format, locale);
        if (this.view.selectedUser) {
            user = this.view.selectedUser.userId;
        }
        this.view.blockUi();
        this.reportService.printSalesReport(initDate, endDate, user).then(data => {
            this.view.showPdf(data);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }

}
