import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { ReportService } from 'src/app/services/report.service';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductService } from 'src/app/services/product.service';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ProductSupplierReportView } from '../product-supplier-report.view';

@Injectable({
  providedIn: 'root',
})
export class ProductSupplierReportPresenter implements Presenter {
  view: ProductSupplierReportView;

  constructor(
    private reportService: ReportService,
    private productService: ProductService,
    private presenterSetting: SettingPresenter
  ) {}

  getProductSupplierReport() {
    let initDate = '';
    let endDate = '';
    const page = this.view.page;
    const size = this.view.size;
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    if (!this.view.initDate || !this.view.endDate) {
      return;
    }
    initDate = formatDate(this.view.initDate, format, locale) + ' 00:00:00';
    endDate = formatDate(this.view.endDate, format, locale) + ' 00:00:00';
    this.view.blockUi();
    this.view.productSupplierPresenter = [];
    this.reportService
      .getProductSupplierReport(
        page,
        size,
        this.view.productId,
        this.view.searchValue,
        initDate,
        endDate
      )
      .subscribe(
        (response: any[]) => {
          this.view.productSupplierPresenter = response;
          this.view.productSupplierPresenter.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
          this.view.reportsCSV = this.view.productSupplierPresenter;
          this.view.totalElements = response.length;
          this.view.selectedResult = this.view.productSupplierPresenter.slice(0, this.view.pageSize);
        },
        (error: HttpErrorResponse) => {
          this.view.paginator = [];
          this.view.showError('Atención', error.error.message);
        }
      );
  }

  onSearchProduct() {
    const searchValue = this.view.searchProduct;
    const page = 0;
    const size = 20;
    const prceListId = this.presenterSetting.view.selectedPrice.priceListId;
    if (searchValue.length < 4) {
      return;
    }
    this.productService
      .searchProduct(searchValue, page, size, prceListId, null)
      .then((data) => {
        this.view.products = data['data'];

        if (this.view.products.length <= 0) {
          this.view.showError('', 'Búsqueda sin registros');
          this.view.products = [];
        }
      })
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          this.view.showError('Atención', error.error.message);
        } else {
          this.view.showError('Atención', 'Algo salio mal');
        }
      });
  }
  findReportsByProduct() {
    if (
      this.view.selectedProduct &&
      typeof this.view.selectedProduct !== 'string'
    ) {
      this.view.showSelectedProduct = true;
      this.view.productId = this.view.selectedProduct.productId;
      this.getProductSupplierReport();
    }
  }
  clearProduct() {
    this.view.showSelectedProduct = false;
    this.view.selectedProduct = null;
    this.view.productId = '';
    this.getProductSupplierReport();
  }
  dowloadReprot() {
    if (this.view.totalElements <= 0) {
      return;
    }
    let initDate = '';
    let endDate = '';
    let date = '';
    const format = 'dd-MM-yyyy';
    const locale = 'en-US';
    initDate = formatDate(this.view.initDate, format, locale);
    endDate = formatDate(this.view.endDate, format, locale);
    date = initDate + ' / ' + endDate;
    const reports = this.view.reportsCSV.map((line) => {
      return {
        Proveedor: line.mark,
        Subtotal: line.subtotal,
        Iva: line.taxed,
        Total: line.total,
        // 'Detalle de productos': line.productSupplierDetailPresenters[0].description,
      };
    });
    const reportsToCSV = JSON.parse(
      JSON.stringify(
        reports,
        [
          'Proveedor',
          'Subtotal',
          'Iva',
          'Total',
          // 'Detalle de productos',
        ],
        2
      )
    );
    this.view.csvDownload(
      'Reporte productos ' + ' ' + date + '.csv',
      reportsToCSV
    );
  }
}
