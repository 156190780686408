import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { timeout } from 'rxjs/operators';
import { Presenter } from 'src/app/core/presenter';
import { ClientModel } from 'src/app/models/client.model';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { InvoiceStatus } from 'src/app/models/invoice.status';
import { OrderLine } from 'src/app/models/orderline.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';

import { SearchInvoiceView } from '../search-invoice.view';



@Injectable({
    providedIn: 'root',
})
export class SearchInvoicePresenter implements Presenter {
    view: SearchInvoiceView;
    constructor(
        private clientService: ClientService,
        private invoiceService: InvoiceService,
        private store: Store<{ client: ClientModel, orderLines: OrderLine }>
    ) { }

    onSearchClient() {
        const searchValue = this.view.searchClient;
        const page = this.view.pageClient;
        const size = this.view.sizeClient;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.clients = clients;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearClient() {
        this.view.showSelectedClient = false;
        this.view.selectedClient = null;
        this.findInvoices();
    }

    findInvoicesByClient() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            this.view.showSelectedClient = true;
            this.findInvoices();
        }
    }

    findInvoices() {
        let initDate = '';
        let endDate = '';
        let client = null;
        let docNo = '';
        const page = this.view.page;
        const size = this.view.size;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (this.view.selectedClient) {
            client = this.view.selectedClient;
        }
        if (this.view.documentNo) {
            docNo = this.view.documentNo;
        }
        this.invoiceService.findInvoices(client, initDate, endDate, docNo, page, size).then(data => {
            this.view.invoices = data['data'];
            this.view.totalElements = data['totalElements'];
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });

    }
    getInvoiceAndGenerateCreditMemo() {
        if (this.view.selectedInvoice.isExpired) {
            this.view.showError('Error', 'Factura expirada');
            return;
        }
        this.view.blockUi();
        this.invoiceService.verifyAcopio(this.view.selectedInvoice.invoiceId).subscribe((data: boolean) => {
            if (data) {
                this.getInvoice();
            }
        }, (error: HttpErrorResponse) => {
            if (error) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }
    getInvoice() {
        this.view.blockUi();
        this.invoiceService.getInvoice(this.view.selectedInvoice.invoiceId).subscribe((invoice: InvoiceModel) => {
            let ac = 0;
            invoice.invoiceDetailPresenters.forEach(line => {
                if (line.product.availableQuantity > 0) {
                    ac = line.product.availableQuantity;
                }
            });
            if (ac > 0) {
                invoice.observation = null;
                this.view.showCreditNoteComponent(invoice);
            } else {
                this.view.showError('Factura devuelta en su totalidad', '');
            }
        }, (error: HttpErrorResponse) => {
            if (error) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    getInvoiceAndDispatch() {
        if (this.view.selectedInvoice.isExpired) {
            this.view.showError('Error', 'Factura expirada');
            return;
        }
        this.view.blockUi();
        this.invoiceService.getInvoiceAndDIspatch(this.view.selectedInvoice.invoiceId).then(dispatch => {
            if (dispatch.dispatchDetailPresenters.length) {
                dispatch.totalWithoutDispatch = dispatch.invoicePresenter.paymentLinePresenters
                    .filter(line => line.paymentMethod === PaymentMethod.TOTAL_WITHOUT_DISPATCH)
                    .length > 0;
                dispatch.disableDispatch = !dispatch.invoicePresenter.isPaid && dispatch.totalWithoutDispatch;
                this.view.showDispatchComponent(dispatch);
            } else {
                this.view.showError('Factura despachada en su totalidad', '');
            }

        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }


    getPdf() {
        this.view.blockUi();
        this.invoiceService.getPdf(this.view.selectedInvoice.invoiceId).pipe(timeout(300000)).subscribe((data) => {
            this.view.showPdf(data);
        }, () => {
            this.view.allowUi();
            this.view.showError('', 'Error imprimiendo factura');
        });
    }

    postSyncroniceInvoiceAndDispatch() {
        this.view.blockUi();
        this.invoiceService.postSyncroniceInvoiceAndDispatch(this.view.selectedInvoice.invoiceId).subscribe((data) => {
            this.view.allowUi();
            this.view.showSuccess('', 'Resincronización exitósa.');
        }, () => {
            this.view.allowUi();
            this.view.showError('', 'Ocurrió un error al resincronizar la información.');
        });
    }

    cancelInvoice() {
        this.view.blockUi();
        this.invoiceService.cancelInvoice(this.view.selectedInvoice).subscribe(res => {
            this.view.allowUi();
            this.view.showSuccess('', 'Factura cancelada');
            this.view.selectedInvoice.isExpired = true;
        }, () => {
            this.view.allowUi();
            this.view.showError('', 'Ocurrió un anulando la factura');
        });
    }
    rezyncInvoices(invNumbers: number[]) {
        this.view.blockUi();
        this.invoiceService.rezyncInvoices(invNumbers).subscribe(res => {
            this.view.allowUi();
            this.view.showSuccess('', 'Facturas Rezincronizada');
            this.view.selectedInvoice.isExpired = true;
        }, () => {
            this.view.allowUi();
            this.view.showError('', 'Ocurrió un anulando la factura');
        });
    }

    getVoucher() {
        this.view.blockUi();
        this.invoiceService.getVoucher(this.view.selectedInvoice.invoiceId).pipe(timeout(300000)).subscribe((data) => {
            this.view.showPdf(data);
        }, (error: HttpErrorResponse) => {
            this.view.allowUi();
            this.view.showError('', error.error.message);
        });
    }

}
