import {
    Component,
    Input,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    Renderer,
    ViewChild
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MenuItem, ScrollPanel, DialogService, MessageService, ConfirmationService, Message } from 'primeng/primeng';
import { PanelMasterComponent } from '../../panelmaster.component';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';
import { MenuView } from './menu.view';
import { MenuPresenter } from './presenter/menu.presenter';
import Swal from 'sweetalert2';
import { DataProvider } from 'src/app/services/data.provider';
import { CurrentPriceService } from 'src/app/services/current-price.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { CashRegisterService } from 'src/app/services/cash-register.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends AbstractView implements OnInit, AfterViewInit {

    @Input() reset: boolean;

    model: any[] = [];

    @ViewChild('scrollPanel', { read: false, static: false }) layoutMenuScrollerViewChild: ScrollPanel;

    public ref;
    constructor(
        public app: PanelMasterComponent,
        private hotKey: HotkeysService,
        public router: Router,
        public dialogService: DialogService,
        public service: MessageService,
        public menuPresenter: MenuPresenter,
        public dataProvider: DataProvider,
        public cashRegisterService: CashRegisterService
    ) {
        super(service, router);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.layoutMenuScrollerViewChild.moveBar();
        }, 100);
    }

    ngOnInit() {
        setTimeout(() => {
          this.menuPresenterData();
        }, 1000);
    }

    menuPresenterData() {
        this.model.push({ tooltip: 'POS', label: 'POS', icon: 'dashboard', routerLink: ['/panel/pos'] });
        if (this.getAuth(DomainAction.ALLOW_POS_LIST_CLIENTS)) {
            this.model.push({ tooltip: 'Clientes', label: 'Clientes', icon: 'face', routerLink: ['/panel/clients/search-clients'] });
        }
        if (this.getAuth(DomainAction.ALLOW_POS_LIST_INVOICES)) {
            this.model.push({
                tooltip: 'Facturas', label: 'Facturas', icon: 'find_in_page', routerLink: ['/panel/invoices/search-invoices']
            });
            this.model.push({
                tooltip: 'Movimientos',
                label: 'Movimientos',
                icon: 'sync_alt',
                routerLink: ['/panel/transfers/for-me'],
            });
        }
        if (this.getAuth(DomainAction.ALLOW_POS_LIST_CREDIT_MEMOS)) {
            this.model.push({
                tooltip: 'Notas de crédito', label: 'NC',
                icon: 'assignment_return', routerLink: ['/panel/credit-memos/search-credit-memos']
            });
        }
        if (this.getAuth(DomainAction.ALLOW_PAYMENTS_COLLECTIONS)) {
            this.model.push({
                tooltip: 'Cobros Pagos',
                label: 'Servicios',
                icon: 'payments',
                routerLink: ['/panel/transactions/search'],
                badgeStyleClass: 'heart'
            });
        }

        // if (this.getAuth(DomainAction.ALLOW_POS_REPORT_CASH_OPENING)) {
        //     this.model.push({
        //         tooltip: 'Reporte de tarjetas', label: 'Tarjetas',
        //         icon: 'credit_card', routerLink: ['/panel/credit-card-report']
        //     });
        // }
        if (this.getAuth(DomainAction.ALLOW_POS_REPORT_SALES)) {
            this.model.push({
                tooltip: 'Reporte de ventas', label: 'Ventas',
                icon: 'show_chart', routerLink: ['/panel/graficas/payment_method-report']
            });
        }
        if (this.getAuth(DomainAction.ALLOW_POS_REPORT_CASH_OPENING)) {
            this.model.push({
                tooltip: 'Reportes caja', label: 'Apertura',
                icon: 'lock_open', routerLink: ['/panel/cash-register/open-day-report']
            });
        }
        if (this.getAuth(DomainAction.ALLOW_POS_REPORT_SALES)) {
            this.model.push({
                tooltip: 'Reporte de stock', label: 'Stock',
                icon: 'store', routerLink: ['/panel/stock/stock-report']
            });
            this.model.push({
                tooltip: 'Impresión de etiquetas',
                label: 'Etiquetas',
                icon: 'print',
                routerLink: ['/panel/manage/print-actual-prices'],
                badgeStyleClass: 'heart'
            });

        }

        if (this.getAuth(DomainAction.ALLOW_POS_ADMIN_PRICES)) {
            this.model.push({
                tooltip: 'Administrar usuarios', label: 'Asignar precios',
                icon: 'key', routerLink: ['/panel/users-admin/assing-prices']
            });
        }


        // if (this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS)) {
        //     this.model.push({
        //         tooltip: 'Aprobar nota de crédito',
        //         label: 'Aprobar NC',
        //         icon: 'playlist_add_check',
        //         routerLink: ['/panel/aprove-credit-memo']
        //     });
        // }
        // if (this.getAuth(DomainAction.ALLOW_POS_APPROVE_MOVEMENT_CASH)) {
        //     this.model.push({
        //         tooltip: 'Devolución de efectivo Notas de crédito',
        //         label: 'Devolucion nc',
        //         icon: 'attach_money',
        //         routerLink: ['/panel/aprove-cash-return']
        //     });
        // }

        // this.model.push({
        //     tooltip: 'Gestión de Bóveda',
        //     label: 'Bóveda',
        //     icon: 'work',
        //     routerLink: ['/panel/vault-managment'],
        // });
    }

    public changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement>
            document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    public changeLayout(theme) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>
            document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }

}


@Component({
    // tslint:disable-next-line: component-selector
    selector: '[app-submenu]',
    styleUrls: ['./menu.component.scss'],
    templateUrl: './sub-menu.component.html',
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class SubMenuComponent extends AbstractView implements MenuView {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    rreset: boolean;

    pparentActive: boolean;

    activeIndex: number;
    msgs: Message[] = [];

    codesProducts: string;

    constructor(
        public app: PanelMasterComponent,
        public router: Router,
        public location: Location,
        public appMenu: MenuComponent,
        public service: MessageService,
        public menuPresenter: MenuPresenter,
        public dataProvider: DataProvider) {
        super(service, router);
        menuPresenter.view = this;
        this.menuPresenter.getTimeTransfer();
        this.menuPresenter.getNumberOfLabels();
    }

    showConfirmation() {
        Swal.fire({
            text: 'Existen productos que cambiaron de precio',
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonColor: 'rgba(0, 89, 255, 0.637)',
            cancelButtonText: 'PDF',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Etiquetas',
        }).then((result) => {
            if (result.dismiss.toString() === 'cancel') {
                this.dataProvider.print = true;
                this.router.navigate(['/panel/print-actual-prices']);
            } else if (result.value) {
                this.dataProvider.print = false;
                this.router.navigate(['/panel/print-actual-prices']);
            }
        });
    }

    showConfirmationPendingMovements(codes) {
        const messages =
            '<p> Tiene transferencias de abastecimiento pendientes de aprobar, debe hacerlo hasta antes de ' +
            'las 12h00, caso contrario el sistema confirmará automaticamente. ' +
            '<br>' +
            '<p><b>' + codes + '<b>';
        Swal.fire({
            html: messages,
            confirmButtonColor: '#00687d',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.value) {
                Swal.close();
            }
        });
    }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
            event.preventDefault();
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex as number === index) ? -1 : index;
        }

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.appMenu.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }

            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this.rreset;
    }

    set reset(val: boolean) {
        this.rreset = val;

        if (this.rreset && (this.app.isHorizontal() || this.app.isOverlay())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this.pparentActive;
    }

    set parentActive(val: boolean) {
        this.pparentActive = val;

        if (!this.pparentActive) {
            this.activeIndex = null;
        }
    }
}
