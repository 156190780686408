import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { AbstractView } from 'src/app/core/abstract_view';
import { DispatchLine, DispatchModel } from 'src/app/models/dispatch.model';
import { MeasureConversionModel } from 'src/app/models/measure-conversion.model';
import Swal from 'sweetalert2';

import { GenerateDispatchView } from './generate-dispatch.view';
import { GenerateDispatchPresenter } from './presenter/generate-dispatch.presenter';

@Component({
  selector: 'app-generate-dispatch',
  templateUrl: './generate-dispatch.component.html',
  styleUrls: ['./generate-dispatch.component.scss']
})
export class GenerateDispatchComponent extends AbstractView implements OnInit, GenerateDispatchView, AfterViewInit {


  @ViewChild('dt', { read: false, static: false }) dt: Table;
  @ViewChild('buttonDispatch', { read: false, static: false }) buttonDispatch: ElementRef;

  dispatch: DispatchModel;
  lines: DispatchLine[] = [];
  selectedLine: DispatchLine;
  subtotal12: number;
  subtotal0: number;
  tax: number;
  total: number;


  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private generateDispatchPresenter: GenerateDispatchPresenter,
    private hotKey: HotkeysService,
    private messageService: MessageService,
    public router: Router) {
    super(messageService, router);
    this.dispatch = config.data;
    generateDispatchPresenter.view = this;
    this.shortcuts();

  }

  shortcuts() {
    this.hotKey.add(new Hotkey(['alt+g', 'ctrl+g', 'alt+s', 'ctrl+s'], (event: KeyboardEvent): boolean => {
      this.saveDispatch();
      return false;
    }, ['input', 'textarea']));
  }

  ngOnInit() {
    this.generateDispatchPresenter.fillLines();
  }
  ngAfterViewInit(): void {
    this.buttonDispatch.nativeElement.focus();
  }

  deleteLine(line: DispatchLine) {
    this.selectedLine = line;
    this.generateDispatchPresenter.deleteLine();
  }

  changeLine(line: DispatchLine) {
    this.selectedLine = line;
    this.generateDispatchPresenter.changeLine();
  }

  changeComboItem(line: DispatchLine, measureUnitConversionPresenter: MeasureConversionModel) {
    this.selectedLine = line;
    this.selectedLine.measureUnitConversionPresenter = measureUnitConversionPresenter;
    this.generateDispatchPresenter.changeLine();
  }

  saveDispatch() {
    this.generateDispatchPresenter.validateDispatch();
  }
  closeView() {
    this.ref.close();
  }
  showConfirmation() {
    Swal.fire({
      text: '¿Desea generar el despacho?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.value) {
        this.generateDispatchPresenter.saveDispatch();
      }
    });
  }
}
