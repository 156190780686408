import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { InfoModel } from 'src/app/models/infoversion.model';
import { interval } from 'rxjs';
import { DataProvider } from 'src/app/services/data.provider';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  infoVersion: InfoModel;
  hour = new Date();
  constructor(
    public ref: DynamicDialogRef,
    config: DynamicDialogConfig,
    public dataProvider: DataProvider) {
    this.infoVersion = config.data;
    const source = interval(1000);
    source.subscribe(() => this.hour = new Date());
  }

  ngOnInit() {
  }

}
