import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Decimal } from 'decimal.js';
import { environment } from 'src/environments/environment';

import { CollectionCenter, Product, ProudctCategoryType } from '../models/product.model';
import { GeneralConfigurations } from './defaultconfigurations.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  creditCardPayment = GeneralConfigurations.creditCardPayment;
  constructor(private http: HttpClient) {}

  public searchProduct(
    searchValue: string,
    page: number,
    size: number,
    priceListId: string,
    clientId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let filterClient = '';
      if (clientId !== null) {
        filterClient = filterClient + '&client=' + clientId;
      }
      this.http
        .get(
          environment.apiUrl +
            '/productsByName?searchValue=' +
            encodeURIComponent(searchValue) +
            '&page=' +
            page +
            '&size=' +
            size +
            '&priceListId=' +
            priceListId +
            filterClient
        )
        .subscribe(
          (response: { data: Product[] }) => {
            response.data.forEach((product) => {
              const unitConvertions = [];
              const prodCategory = product.productCategoryPresenters.find(
                (category) =>
                  category.productCategoryType === ProudctCategoryType.TRADEMARK
              );
              product.trademark = prodCategory
                ? prodCategory.name.toLocaleUpperCase()
                : 'Sin Marca';
              product.priceWithSurcharge = 0;
              product.normalPriceWithoutTax = new Decimal(
                product.normalPriceWithoutTax
              )
                .toDecimalPlaces(6)
                .toNumber();
              product.priceWithoutTax = new Decimal(product.priceWithoutTax)
                .toDecimalPlaces(6)
                .toNumber();
              product.priceLimitWithoutTax = new Decimal(
                product.priceLimitWithoutTax
              )
                .toDecimalPlaces(6)
                .toNumber();
              product.basePrice = new Decimal(
                product.basePrice
              )
                .toDecimalPlaces(6)
                .toNumber();
              product.specialPrice = product.specialPrice
                ? new Decimal(product.specialPrice)
                    .toDecimalPlaces(6)
                    .toNumber()
                : product.priceLimitWithoutTax;
              product.wholesalerPrice = product.wholesalerPrice
                ? new Decimal(product.wholesalerPrice)
                    .toDecimalPlaces(6)
                    .toNumber()
                : product.priceLimitWithoutTax;
              product.administratorPrice = product.administratorPrice
                ? new Decimal(product.administratorPrice)
                    .toDecimalPlaces(6)
                    .toNumber()
                : product.priceLimitWithoutTax;
              product.measureUnitConversionPresenters.forEach(
                (unitConvertion) => {
                  if ( unitConvertion.active ) {
                  unitConvertion.price = new Decimal(unitConvertion.price)
                    .toDecimalPlaces(6)
                    .toNumber();
                  unitConvertion.priceLimit = new Decimal(
                    unitConvertion.priceLimit
                  )
                    .toDecimalPlaces(6)
                    .toNumber();
                  unitConvertion.specialPrice = unitConvertion.specialPrice
                    ? new Decimal(unitConvertion.specialPrice)
                        .toDecimalPlaces(6)
                        .toNumber()
                    : unitConvertion.priceLimit;
                  unitConvertion.wholesalerPrice =
                    unitConvertion.wholesalerPrice
                      ? new Decimal(unitConvertion.wholesalerPrice)
                          .toDecimalPlaces(6)
                          .toNumber()
                      : unitConvertion.priceLimit;
                  unitConvertion.administratorPrice =
                    unitConvertion.administratorPrice
                      ? new Decimal(unitConvertion.administratorPrice)
                          .toDecimalPlaces(6)
                          .toNumber()
                      : unitConvertion.priceLimit;
                  unitConvertions.push(unitConvertion);
                  }
                }
              );
              product.measureUnitConversionPresenters = unitConvertions;
              if (this.creditCardPayment) {
                product.priceWithSurcharge = product.normalPriceWithoutTax;
              }
            });
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getCollectionCenterStock(
    collections: CollectionCenter[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + '/productStockCollectionCenter', collections)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getProductDiscounts(clientId: string, productIds: string[]) {
    return this.http.post(
      environment.apiUrl + '/getProductsDiscounts?clientId=' + clientId,
      productIds
    );
  }
}
