import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditRedService {

  constructor(public hhtp: HttpClient) { }

  getIsHeavy() {
    return this.hhtp.get(environment.apiUrl + '/getIsHeavy');
  }

  getIsCreditRed() {
    return this.hhtp.get(environment.apiUrl + '/getIsCreditRed');
  }
}
