import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuItem, MessageService, DialogService } from 'primeng/api';
import { CreditMemoModel, CreditMemoStatus } from 'src/app/models/creditmemo.model';
import { ClientModel } from 'src/app/models/client.model';
import { AproveCreditMemoPresenter } from './presenter/aprove-credit-memo.presenter';
import { Router } from '@angular/router';
import { AproveCreditMemoView } from './aprove-credit-memo.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { DomainAction } from 'src/app/models/rol.model';
import Swal from 'sweetalert2';
import { OverlayPanel } from 'primeng/primeng';

@Component({
  selector: 'app-aprove-credit-memo',
  templateUrl: './aprove-credit-memo.component.html',
  styleUrls: ['./aprove-credit-memo.component.scss']
})
export class AproveCreditMemoComponent extends AbstractView implements OnInit, AproveCreditMemoView {

  @ViewChild('docNo', { read: false, static: false }) docNo: ElementRef;

  items: MenuItem[];

  showCreditNote = false;
  creditMemos: CreditMemoModel[];
  selectedCreditMemo: CreditMemoModel;

  searchClient: '';
  pageClient = 0;
  sizeClient = 20;
  clients: ClientModel[];
  selectedClient: ClientModel;
  initDate = null;
  endDate = null;
  documentNo = null;
  showSelectedClient = false;
  public ref;

  page = 0;
  size = 20;

  totalElements: number;

  constructor(
    private searchCreditMemoPresenter: AproveCreditMemoPresenter,
    private messageService: MessageService,
    public router: Router,
    public dialogService: DialogService,

  ) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS)) {
      this.redirectTo('/panel/pos');
    }
    searchCreditMemoPresenter.view = this;
    this.blockUi();
    searchCreditMemoPresenter.findCreditMemos();
  }

  ngOnInit() {
  }


  findCreditMemos() {
    this.page = 0;
    this.searchCreditMemoPresenter.findCreditMemos();
  }

  onSearchClient(event) {
    this.searchClient = event.query;
    this.pageClient = 0;
    this.sizeClient = 20;
    this.searchCreditMemoPresenter.onSearchClient();
  }

  clearClient() {
    this.searchCreditMemoPresenter.clearClient();
  }

  addClient() {
    this.searchCreditMemoPresenter.findCreditMemosByClient();
  }




  paginate($event) {
    this.page = $event['page'];
    this.searchCreditMemoPresenter.findCreditMemos();
  }

  getPdf(creditMemo: CreditMemoModel) {
    this.selectedCreditMemo = creditMemo;
    this.searchCreditMemoPresenter.getPdf();
  }

  showApproveCreditMemo(creditMemo: CreditMemoModel) {
    this.selectedCreditMemo = creditMemo;
    this.selectedCreditMemo.creditMemoStatus = CreditMemoStatus.APPROVED;
    Swal.fire({
      text: '¿Desea aprobar la nota de crédito?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aprobar'
    }).then((result) => {
      if (result.value) {
        this.searchCreditMemoPresenter.setCreditMemoStatus();
      }
    });
  }
  showARejectCreditMemo(creditMemo: CreditMemoModel) {
    this.selectedCreditMemo = creditMemo;
    this.selectedCreditMemo.creditMemoStatus = CreditMemoStatus.REJECTED;
    Swal.fire({
      text: '¿Desea rechazar la nota de crédito?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Rechazar'
    }).then((result) => {
      if (result.value) {
        this.searchCreditMemoPresenter.setCreditMemoStatus();
      }
    });
  }

  getCreditMemoDetails(event, overlaypanel: OverlayPanel, creditMemo: CreditMemoModel) {
    if (!this.selectedCreditMemo || this.selectedCreditMemo.creditMemoId !== creditMemo.creditMemoId) {
      this.selectedCreditMemo = creditMemo;
      this.searchCreditMemoPresenter.getDetails();
    }
    overlaypanel.toggle(event);
  }


}
