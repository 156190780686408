import { Injectable } from '@angular/core';
import { CreditMemoModel, CreditMemoStatus } from '../models/creditmemo.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientModel } from '../models/client.model';

@Injectable({
  providedIn: 'root'
})
export class CreditMemoService {

  constructor(private http: HttpClient) { }
  public saveCreditMemo(newCreditMemo: CreditMemoModel): Promise<CreditMemoModel> {
    return new Promise((resolve, reject) => {
      this.http.post<CreditMemoModel>(environment.apiUrl + '/saveCreditMemo', newCreditMemo)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public findCreditMemos(
    clientPresenter: ClientModel, initDate: string, endDate: string,
    documentNo: string, page: number, size: number, creditMemoStatuses: CreditMemoStatus[], filter: boolean): Promise<CreditMemoModel[]> {
    return new Promise((resolve, reject) => {
      this.http.post<CreditMemoModel[]>(environment.apiUrl + '/findCreditMemos?initDate=' + initDate +
        '&endDate=' + endDate
        + '&documentNo=' + documentNo + '&page=' + page + '&size=' + size + '&byStatus=' + filter +
        '&creditMemoStatuses=' + creditMemoStatuses
        , clientPresenter, { headers: { 'Content-Type': 'application/json' } })
        .subscribe(response => {

          resolve(response);

        }, error => {
          reject(error);
        });
    });
  }


  public getPdf(creditMemoId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + '/printCreditMemo?creditMemoId=' + creditMemoId)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  public getCreditMemo(creditMemoId: string) {
    return this.http.get(environment.apiUrl + '/findCreditMemo?creditMemoId=' + creditMemoId);
  }




  public setCreditMemoStatus(creditMemo: CreditMemoModel) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + '/setCreditMemoStatus', creditMemo)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
  public createMovementCashToCreditMemo(creditMemoToCash: CreditMemoModel) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + '/createMovementCashToCreditMemo', creditMemoToCash)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  public sendResyncronizationCreditMemo(creditMemoId: string) {
    return this.http.get(environment.apiUrl + '/resyncroniceCreditMemo/' + creditMemoId);
  }
  public getCreditMemoReasons() {
    return this.http.get(environment.apiUrl + '/reasonCreditMemo'  );
  }

  public rezyncCreditMemos(creNumbers: number[]) {
    return this.http.get(
        environment.apiUrl + '/rezyncCreditMemos?creNumbers=' + creNumbers
    );
  }

  getPendingCreditMemos() {
    return this.http.get(environment.apiUrl + '/isPendingCreditMemos');
  }
}
