import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, MessageService } from 'primeng/api';
import { leftToLeft } from 'src/app/animations';
import Swal from 'sweetalert2';
import { TransferProvider } from '../../transfer.provider';
import { rightToRight } from '../../../../../../../animations';
import { DomainAction } from 'src/app/models/rol.model';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  animations: [
    leftToLeft,
    rightToRight
  ],
  selector: 'app-aprove-supplying',
  templateUrl: './aprove-supplying.component.html',
  styleUrls: ['./aprove-supplying.component.scss']
})
export class AproveSupplyingComponent extends AbstractView implements OnInit {
  constructor(
    private ref: DynamicDialogRef,
    public transferProvider: TransferProvider,
    public messageService: MessageService

  ) {
    super(messageService);
  }

  ngOnInit() {
    this.transferProvider.transfer.transferDetailPresenters.forEach(line => {
      this.transferProvider.validateSupplyingdLine(line);
    });

    this.transferProvider.refAproveSupplying = this.ref;

  }

  showApproveSupplying() {

    Swal.fire({
      text: '¿Desea confirmar la transferencia?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Confirmar'

    }).then((result) => {
      if (result.value) {
        this.transferProvider.saveSupplying();
      }
    });
  }
  evaluateRole() {
    return this.getAuth(DomainAction.ALLOW_POS_APPROVE_CREDIT_MEMOS);
  }


}
