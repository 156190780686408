import { Injectable } from '@angular/core';

import { Bank } from '../models/bank.model';
import { BankAccount } from '../models/bankaccount.model';
import { ClientModel } from '../models/client.model';
import { UserPresenter } from '../models/fulluser.model';
import { WareHouse } from '../models/product.model';
import { PromotionMessageModel } from '../models/promotion-message.model';

@Injectable({
  providedIn: 'root',
})
export class DataProvider {
  isSalesExpress = false;
  isBilleton = false;
  bankAcounts: BankAccount[] = [];
  priceListAdmin = '';
  amountInitialBox = 0;
  defaultWarehouse?: WareHouse = null;
  forbiddenCustomer?: ClientModel = null;
  disableBilleton?: boolean;
  disableRecycling?: boolean;
  processPayment?: string;
  isOrder?: boolean;
  isDisabledScreen?: boolean;
  lastPromotion?: PromotionMessageModel;
  defaultUser?: UserPresenter;
  blockPaymentsByAdminPrice?: boolean;
  banksSpecialDay?: Bank[] = [];
  banksSpecialDayNames = '';
  print: boolean;
  defaultTimeToClaim = 48;
  isConnectionCpos = false;
  isConfigurationCpos = false;
  isNewQuotation?: boolean;
  quotationId ? = '';
  quotationStatus ? = null;
  sendBusinessManagerQuotation?: boolean;
  isAdminPriceDiscount ? = false;
  needOpenCpos = false;
  isOpenCash = false;
  numberReferenceQuotation ? = '';
  isConnectCashWithCpos ? = false;
  isHeavy = false;
  isCreditRed = false;
  isCrediRedPrice = false;
  isSellers = false;
  commissionCard = 0;
  showConfigDevolutions = false;
}
