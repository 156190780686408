import { Action } from '@ngrx/store';
import { ClientModel } from '../models/client.model';


export enum AdviserActions {
    ADD_ADVISER = 'ADD_ADVISER',
    CLEAR_ADVISER = 'CLEAR_ADVISER',
}

export class AddAdviser implements Action {
    constructor(public adviser: ClientModel) { }
    readonly type = AdviserActions.ADD_ADVISER;
}

export class ClearAdviser implements Action {
    constructor() { }
    readonly type = AdviserActions.CLEAR_ADVISER;
}


export type ActionsUnion = AddAdviser | ClearAdviser;
