import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { TransferDetailModel } from 'src/app/models/transfer.mode';
import { TransferProvider } from '../../transfer.provider';

@Component({
  selector: 'app-add-lines',
  templateUrl: './add-lines.component.html',
  styleUrls: ['./add-lines.component.scss']
})
export class AddLinesComponent extends AbstractView implements OnInit {
  selectedline: TransferDetailModel;
  @Output() selectLine = new EventEmitter<TransferDetailModel>();

  expanded = false;
  scroll = false;
  constructor(
    private messageService: MessageService,
    public transferProvider?: TransferProvider,
  ) {
    super(messageService);
  }


  ngOnInit() {
  }



  toogle() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      // clearTimeout(this.timer);
      setTimeout(() => {
        this.scroll = true;
      }, 300);
    } else {
      this.scroll = false;
      // this.showTransferLines();
    }
  }


  emitLine(line: TransferDetailModel) {
    this.selectLine.emit(line);
  }

  onClick() {
    this.redirectTo('./receipts-payments');
  }



}
