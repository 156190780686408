import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { PromotionMessageModel, PromotionModel } from '../models/promotion-message.model';
import { Store } from '@ngrx/store';
import { ApplyPromotions } from '../reducers/order.action';
import { OrderLine } from '../models/orderline.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionProvider {

  constructor(private store?: Store<{ orderLines: OrderLine }>) { }
  enabled$ = new BehaviorSubject<boolean>(false);
  suggestions$ = new Subject<PromotionModel[]>();
  promotionMessage$ = new BehaviorSubject<PromotionMessageModel>(null);
  set suggestions(suggests: PromotionModel[]) {
    this.suggestions$.next(suggests);
  }
  set status(value: boolean) {
    this.enabled$.next(value);
  }
  set promotionMessage(promotion: PromotionMessageModel) {
    this.promotionMessage$.next(promotion);
  }
  get promotionMessage() {
    return this.promotionMessage$.value;
  }
  get enabled(): boolean {
    return this.enabled$.value;
  }
  set enabled(en: boolean) {
    if (!en) {
      this.suggestions = [];
      this.promotionMessage = null;
      this.store.dispatch(new ApplyPromotions([]));
    }
    this.enabled$.next(en);
  }
}
