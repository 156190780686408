import { Action } from '@ngrx/store';
import { UserModel } from '../models/client.model';

export enum SellerActions {
    ADD_SELLER = 'ADD_SELLER',
    CLEAR_SELLER = 'CLEAR_SELLER',
}

export class AddSeller implements Action {
    constructor(public seller: UserModel) { }
    readonly type = SellerActions.ADD_SELLER;
}

export class ClearSeller implements Action {
    constructor() { }
    readonly type = SellerActions.CLEAR_SELLER;
}


export type ActionsUnion = AddSeller | ClearSeller;
