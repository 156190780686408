import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MovementCashPresenter } from '../models/movement-cash.model';

@Injectable({
  providedIn: 'root'
})
export class CashMovementService {

  constructor(public http: HttpClient) { }

  getPendingReturnCashMovements() {
    return this.http.get(environment.apiUrl + '/listPendingMovementCash');
  }

  setCashMovementStatus(cashMovement: MovementCashPresenter) {
    return this.http.post(environment.apiUrl + '/changeMovementCashState', cashMovement);
  }

  getPendingMovementCash() {
    return this.http.get(environment.apiUrl + '/isPendingMovementCash');
  }

}
