import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { UserPresenter } from '../models/fulluser.model';

@Injectable({
    providedIn: 'root'
})
export class UsernameService {

    constructor(private http: HttpClient) {
    }

    public getCurrentUser() {
        return this.http.get<UserPresenter>(environment.apiUrl + '/getUser');
    }

    public findUser(searchValue: string, page: number, size: number) {
        return this.http.get(environment.apiUrl + '/findUser?searchValue=' + searchValue + '&page=' + page + '&size=' + size);

    }
}
