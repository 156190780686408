import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  selector: 'app-credit-memo',
  templateUrl: './credit-memo.component.html',
  styleUrls: ['./credit-memo.component.scss']
})
export class CreditMemoComponent extends AbstractView implements OnInit {

  navLinks = [];
  constructor(
    public router: Router,
    public service: MessageService
  ) {
    super(service, router);
  }
  ngOnInit() {
    this.navLinks = [
      {
        label: 'Notas de crédito',
        link: './search-credit-memos',
        index: 1,
        visible: this.getAuth('ALLOW_POS_LIST_CREDIT_MEMOS')
      },
      {
        label: 'Aprobar nota de crédito ',
        link: './aprove-credit-memo',
        index: 2,
        visible: this.getAuth('ALLOW_POS_APPROVE_CREDIT_MEMOS')
      },
      {
        label: 'Notas de crédito rechazadas',
        link: './credit-memo-void',
        index: 3,
        visible: this.getAuth('ALLOW_POS_LIST_CREDIT_MEMOS')
      },
      {
        label: 'Devolución de efectivo notas de crédito',
        link: './aprove-cash-return',
        index: 4,
        visible: this.getAuth('ALLOW_POS_APPROVE_MOVEMENT_CASH')
      }
    ];
  }

}
