import { ClientModel } from './client.model';
import { UserPresenter } from './fulluser.model';

export interface MovementCashPresenter {
    movementCashId?: string;
    numberCreditMemo?: string;
    userPresenter?: UserPresenter;
    date?: string;
    amount?: number;
    clientPresenter?: ClientModel;
    status?: StatusEnum;
}

export enum StatusEnum {
    ENTERED = 'ENTERED',
    INVOICED = 'INVOICED',
    CANCELED = 'CANCELED',
    APPROVED = 'APPROVED'
}
