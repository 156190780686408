import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';

@Component({
  selector: 'app-receipts-payments-menu',
  templateUrl: './receipts-payments-menu.component.html',
  styleUrls: ['./receipts-payments-menu.component.scss'],
})
export class ReceiptsPaymentsMenuComponent
  extends AbstractView
  implements OnInit {
  navLinks = [];
  constructor(public router: Router, public service: MessageService) {
    super(service, router);
  }
  ngOnInit() {
    this.navLinks = [
      {
        label: 'Transacciones',
        link: './search',
        index: 1,
        visible: true,
      },
      /*       {
        label: 'Historial',
        link: './historic',
        index: 2,
        visible: true
      }, */
      {
        label: 'Reportes',
        link: './report',
        index: 2,
        visible: true,
      }, {
        label: 'Reporte diario',
        link: './daily-report',
        index: 3,
        visible: this.getAuth('ALLOW_POS_RESEND_DOCUMENTS'),
      }
    ];
  }
}
