import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DynamicDialogConfig, MessageService, DynamicDialogRef } from 'primeng/api';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { DayRegisterDetailToShowModel, CloseCashRegisterResumeModel } from 'src/app/models/cash-register.model';
import { UiUtils } from 'src/app/core/ui-utils';
import { CloseCashRegisterPresenter } from './presenter/close-cash-register.presenter';
import { CloseCashRegisterView } from './close-cash-register.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { interval } from 'rxjs';
import { DomainAction } from 'src/app/models/rol.model';
import { DenominationEquivalent, DenominationEnum } from 'src/app/models/currency.model';
import { SystemReportModel } from 'src/app/models/report.model';
import { CheckDetailModel } from 'src/app/models/payment.model';
import { DataProvider } from 'src/app/services/data.provider';

@Component({
  selector: 'app-close-cash-register',
  templateUrl: './close-cash-register.component.html',
  styleUrls: ['./close-cash-register.component.scss']
})
export class CloseCashRegisterComponent extends AbstractView implements OnInit, CloseCashRegisterView, AfterViewInit {


  @ViewChild('txtObservation', { read: false, static: false }) txtObservation: ElementRef;

  checkDetail: DayRegisterDetailToShowModel;
  cashDetail: DayRegisterDetailToShowModel;
  observation: string;
  total = 0;
  hour = new Date();
  coins: DenominationEquivalent[] = [];
  bills: DenominationEquivalent[] = [];
  resume: SystemReportModel = {
    BANK_TRANSFER: 0,
    CASH: 0,
    CHECK: 0,
    CREDIT: 0,
    CREDIT_CARD: 0,
    CREDIT_MEMO: 0,
    DEBIT: 0,
    WITHHOLD: 0
  };
  selectedDenomination: DenominationEquivalent;
  checkDetails: CheckDetailModel[] = [];
  dailyChecks: CheckDetailModel[] = [];
  constructor(
    private dataProvider: DataProvider,
    private ref: DynamicDialogRef,
    public service: MessageService,
    public router: Router,
    private config: DynamicDialogConfig,
    public util: UiUtils,
    private closeCashRegisterPresenter: CloseCashRegisterPresenter,
    ) {
    super(service, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_CASH_CLOSE)) {
      this.showError('', 'Sin autorización');
      this.ref.close();
    }
    closeCashRegisterPresenter.view = this;
    const source = interval(1000);
    source.subscribe(() => this.hour = new Date());
    this.checkDetail = { amount: 0, paymentMethod: PaymentMethod.CHECK, text: 'Total cheques efectivizados al día' };
    this.cashDetail = { amount: 0, paymentMethod: PaymentMethod.CASH, text: 'Total efectivo' };
    this.setCoins();
    this.setBills();
    this.closeCashRegisterPresenter.getCloseCashRegisterResume();
    this.closeCashRegisterPresenter.getCeckDetails();
    this.closeCashRegisterPresenter.getDailyChecks();
    if (config.data) {
      this.dataProvider.isDisabledScreen = true;

    }
  }
  setCoins() {
    this.coins.push({ name: '1', denomination: DenominationEnum.COIN_DOLLAR, equivalent: 1, quantity: null, total: 0 });
    this.coins.push({ name: '0.50 ', denomination: DenominationEnum.COIN_FIFTY, equivalent: 0.50, quantity: null, total: 0 });
    this.coins.push({ name: '0.25 ', denomination: DenominationEnum.COIN_TWENTY_FIVE, equivalent: 0.25, quantity: null, total: 0 });
    this.coins.push({ name: '0.10 ', denomination: DenominationEnum.COIN_TEN, equivalent: 0.10, quantity: null, total: 0 });
    this.coins.push({ name: '0.05 ', denomination: DenominationEnum.COIN_FIVE, equivalent: 0.05, quantity: null, total: 0 });
    this.coins.push({ name: '0.01 ', denomination: DenominationEnum.COIN_ONE, equivalent: 0.01, quantity: null, total: 0 });
  }
  setBills() {
    this.bills.push({ name: '50', denomination: DenominationEnum.BILL_FIFTY, equivalent: 50, quantity: null, total: 0 });
    this.bills.push({ name: '20', denomination: DenominationEnum.BILL_TWENTY, equivalent: 20, quantity: null, total: 0 });
    this.bills.push({ name: '10', denomination: DenominationEnum.BILL_TEN, equivalent: 10, quantity: null, total: 0 });
    this.bills.push({ name: '5', denomination: DenominationEnum.BILL_FIVE, equivalent: 5, quantity: null, total: 0 });
    this.bills.push({ name: '2', denomination: DenominationEnum.BILL_TWO, equivalent: 2, quantity: null, total: 0 });
    this.bills.push({ name: '1', denomination: DenominationEnum.BILL_ONE, equivalent: 1, quantity: null, total: 0 });
  }

  ngOnInit() {
  }
  closeCashRegister() {
    this.closeCashRegisterPresenter.validate();
  }
  ngAfterViewInit(): void {
    this.txtObservation.nativeElement.focus();
  }
  closeModal() {
    this.ref.close();
  }

  showConfirmation() {
    Swal.fire({
      text: '¿Desea cerrar caja en $0.00?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Cerrar caja'
    }).then((result) => {
      if (result.value) {
        this.closeCashRegisterPresenter.closeCashRegister();
      }
    });
  }
  showCheckConfirmation() {
    Swal.fire({
      text: 'Usted no ha confirmado todos los cheques, ¿Desea cerrar caja ?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Cerrar caja'
    }).then((res) => {
      if (res.value) {
        this.closeCashRegisterPresenter.closeCashRegister();
      }
    });
  }

  calculateTotal(denomination: DenominationEquivalent) {
    this.selectedDenomination = denomination;
    this.closeCashRegisterPresenter.calculateTotal();
  }
  calculateTotalCheck() {
    // this.closeCashRegisterPresenter.calculateTotalCheck();
  }

}
