import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const token = localStorage.getItem('authorization');
        try {
            this.getTokenInfo(token);
            return true;
        } catch (Error) {
            localStorage.clear();
            this.router.navigate(['/']);
            return false;
        }
    }

    getTokenInfo(token: string) {
        return jwt_decode(token, { header: true });
    }

}
