import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { CustomValidationService } from 'src/app/services/custom-validation.service';
import { DataProvider } from 'src/app/services/data.provider';
import Swal from 'sweetalert2';
import { ChangePasswordView } from './change-password.view';
import { ChangePasswordPresenter } from './presenter/change-password.presenter';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends AbstractView implements OnInit, ChangePasswordView {

  user: UserPresenter = {};
  visiblePassword = true;
  visibleOldPassword = true;
  visibleConfirmPassword = true;
  form = this.formBuilder.group({});
  errorMessage: string;
  submitted = false;


  constructor(
    private dataProvider: DataProvider,
    public formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private changePasswordPresenter: ChangePasswordPresenter,
    public router: Router,
    public customValidator: CustomValidationService) {
    super(messageService, router);
    changePasswordPresenter.view = this;
    this.dataProvider.isDisabledScreen = true;

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      confirmPassword: ['', [Validators.required]],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      });
  }
  get registerFormControl() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.changePasswordPresenter.validatePassword();
  }
  closeView() {
    this.ref.close();
  }
  showConfirmation(user: UserPresenter) {

    Swal.fire({
      text: '¿Desea guardar contraseña?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.value) {
        this.changePasswordPresenter.changePassword(user);
      }
    });
  }
}
