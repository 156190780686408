
import { Injectable } from '@angular/core';

import { SearchCreditMemoView } from '../search-credit-memo.view';
import { ClientService } from 'src/app/services/client.service';
import { ClientModel } from 'src/app/models/client.model';
import { DatePipe, formatDate } from '@angular/common';
import { Presenter } from 'src/app/core/presenter';
import { Store } from '@ngrx/store';
import { OrderLine } from 'src/app/models/orderline.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CreditMemoService } from 'src/app/services/credit-memo.service';
import { CreditMemoModel } from 'src/app/models/creditmemo.model';


@Injectable({
    providedIn: 'root',
})
export class SearchCreditMemoPresenter implements Presenter {
    view: SearchCreditMemoView;
    constructor(
        private clientService: ClientService,
        private creditMemoService: CreditMemoService,
        private store: Store<{ client: ClientModel, orderLines: OrderLine }>
    ) { }

    onSearchClient() {
        const searchValue = this.view.searchClient;
        const page = this.view.pageClient;
        const size = this.view.sizeClient;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.clients = clients;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearClient() {
        this.view.showSelectedClient = false;
        this.view.selectedClient = null;
        this.findCreditMemos();
    }

    findCreditMemosByClient() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            this.view.showSelectedClient = true;
            this.findCreditMemos();
        }
    }

    findCreditMemos() {
        let initDate = '';
        let endDate = '';
        let client = null;
        let docNo = '';
        const page = this.view.page;
        const size = this.view.size;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (this.view.selectedClient) {
            client = this.view.selectedClient;
        }
        if (this.view.documentNo) {
            docNo = this.view.documentNo;
        }

        this.creditMemoService.findCreditMemos(client, initDate, endDate, docNo, page, size, [], false).
            then(data => {
                this.view.creditMemos = data['data'];
                this.view.totalElements = data['totalElements'];
            }).catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salio mal');
                }
            });

    }


    getPdf() {
        this.view.blockUi();
        this.creditMemoService.getPdf(this.view.selectedCreditMemo.creditMemoId).then(data => {
            this.view.showPdf(data);
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    createMovementCashToCreditMemo() {
        this.view.blockUi();
        this.creditMemoService.createMovementCashToCreditMemo(this.view.selectedCreditMemo).then(data => {
            this.view.selectedCreditMemo.containValidMovementCash = true;
            this.view.showSuccess('', 'Solicitud para devolución de efectivo enviada');
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }
    getDetails() {
        this.creditMemoService.getCreditMemo(this.view.selectedCreditMemo.creditMemoId).
            subscribe((creditMemo: CreditMemoModel) => {
                this.view.selectedCreditMemo = creditMemo;
        });
    }

    sendResyncronizationCreditMemo() {
        this.view.blockUi();
        this.creditMemoService.sendResyncronizationCreditMemo(this.view.selectedCreditMemo.creditMemoId)
            .subscribe((resp: any) => {
                this.view.allowUi();
                this.view.showSuccess('', 'Resincronización exitósa.');
            }, (error: HttpErrorResponse) => {
                this.view.allowUi();
                this.view.showError('', error.error.message);
            });
    }
    rezyncCreditMemos(credNumbers: number[]) {
        this.view.blockUi();
        this.creditMemoService.rezyncCreditMemos(credNumbers).subscribe(res => {
            this.view.allowUi();
            this.view.showSuccess('', 'Notas Credito Rezincronizadas');
           // this.view.selectedCreditMemo.isExpired = true;
        }, () => {
            this.view.allowUi();
            this.view.showError('', 'Ocurrió un enviado la Nota Credito');
        });
    }
}
