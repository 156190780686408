import { Component, OnInit } from '@angular/core';
import { PrintPriceView } from './print-price.view';
import { AbstractView } from 'src/app/core/abstract_view';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { read, utils } from 'xlsx';
import { PrintPricePresenter } from './presenter/print-price.presenter';
import { DataProvider } from 'src/app/services/data.provider';

@Component({
  selector: 'app-print-price',
  templateUrl: './print-price.component.html',
  styleUrls: ['./print-price.component.scss']
})
export class PrintPriceComponent extends AbstractView implements OnInit, PrintPriceView {
  from: number;
  to: number;
  code: string;
  codes: string[] = [];
  nameFile: string;
  products: any[];
  productsAutoservice: any[];
  constructor(
    public messageService: MessageService,
    public router: Router,
    public printPricePresenter: PrintPricePresenter,
    public dataProvider: DataProvider) {
    super(messageService, router);
    printPricePresenter.view = this;
  }

  ngOnInit() {
    if (this.dataProvider.print) {
      this.getAutoServiceFilePDF();
    }
  }

  printCurrentPrices(currentPrices: any): void {
    this.blockUi();
    const myblob = new Blob([currentPrices], {
      type: 'text/plain'
    });
    const fileURL = URL.createObjectURL(myblob);
    const iframe = document.createElement('iframe');
    iframe.src = fileURL;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
    this.allowUi();
  }

  getCurrentPrices() {
    this.printPricePresenter.getCurrentPrices();
  }
  getRangePrices() {
    this.printPricePresenter.getRangePrices();
  }

  getEnabled(): boolean {
    if (Number(this.from) === 0 || Number(this.to) === 0) {
      return true;
    }
    return false;
  }

  getAllPrices() {
    this.printPricePresenter.getAllPrices();
  }
  getPricebyCode() {
    this.printPricePresenter.getPriceBycode();
  }

  UploaderFiles(event) {
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      const workbook = read(myReader.result, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const arraylist = utils.sheet_to_json(worksheet, { raw: true }).filter((el: any) => el.codigo);
      if (arraylist.length > 0) {
        this.codes = arraylist.map((elemen: { codigo: string }) => elemen.codigo.toUpperCase());
        this.printPricePresenter.sentCodes();
        return;
      }
      this.showError('', 'Archivo sin etiquetas válidas');
    };
    myReader.readAsArrayBuffer(event.files[0]);
    event.files = null;
  }

  downloadAsset(file: string) {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/files/' + file;
    link.download = file;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  getAutoServiceFile() {
    this.printPricePresenter.getAutoServiceFile();
  }

  getProductsWithStockFile() {
    this.printPricePresenter.getProductsWithStockFile();
  }

  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  getAutoServiceFilePDF() {
    this.printPricePresenter.getAutoServiceFilePDF();
  }

}
