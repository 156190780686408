const urlDepositLoad = '../../pages/dashboard/cash-register/search-sales-deposit/presenter/search-sales-deposit.presenter';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceList } from '../../../../models/pricelist.model';
import { ProductQuestPresenter } from '../../pages/pos/product-quest/presenter/product-quest.presenter';
import { SettingView } from './setting.view';
import { AbstractView } from '../../../../core/abstract_view';
import { MessageService, DialogService } from 'primeng/api';
import { Router } from '@angular/router';
import { SettingPresenter } from './presenter/setting.presenter';
import { EmissionPointPresenter } from '../../../../models/pointemission.model';
import { CloseCashRegisterComponent } from '../../pages/cash-register/close-cash-register/close-cash-register.component';
import { GenerateAdvanceComponent } from '../../pages/cash-register/generate-advance/generate-advance.component';
import { SettingsComponent } from '../../pages/settings/settings.component';
import Swal from 'sweetalert2';
import { Order } from '../../../../models/order.model';
import { SearchOrderComponent } from '../../pages/pos/search-order/search-order.component';
import { InitDataComponent } from '../../pages/init-data/init-data.component';
import { TransferDetailModel } from 'src/app/models/transfer.mode';
import { GenerateCashRegisterComponent } from '../../pages/cash-register/generate-cash-register/generate-cash-register.component';
import { SearchSalesDepositPresenter } from '../../pages/dashboard/cash-register/search-sales-deposit/presenter/search-sales-deposit.presenter';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
})
export class SettingComponent extends AbstractView implements OnInit, SettingView {

    @Input() isdisplay: boolean;
    @Output() notDisplay = new EventEmitter<boolean>();

    priceList: { label: string, value: PriceList }[];
    selectedPrice: PriceList = {
        priceListId: '',
        name: ''
    };
    pointEmissionList: { label: string, value: EmissionPointPresenter }[];
    selectedPointEmission: EmissionPointPresenter = {
        emissionPointId: '',
        number: '',
        name: ''
    };
    linesPendingToInvoice: TransferDetailModel[];
    ordersPending: Order[];
    lockedCloseCash: [];
    public ref;
    constructor(
        private messageService: MessageService,
        public router: Router,
        public searchSalesDepositPresenter: SearchSalesDepositPresenter,
        public settingPresenter: SettingPresenter,
        private productQuestPresenter: ProductQuestPresenter,
        private dialogService: DialogService
    ) {
        super(messageService, router);
        settingPresenter.view = this;
    }



    ngOnInit() {
        this.settingPresenter.loadInitData();
        this.settingPresenter.loadValueLockedbox();
    }

    onHideSidebar() {
        this.notDisplay.emit(false);
    }

    onChangeListPrice(event) {
        this.selectedPrice = event.value;
        this.productQuestPresenter.onSearchProductPos();
    }
    closeModal() {
        this.ref.close();
    }

    onChangePointEmission(event) {
        this.selectedPointEmission = event.value;
    }

    showOpenCashRegisterCompponent() {
        if (this.lockedCloseCash.length > 0) {
            Swal.fire({
                title: 'Caja bloqueada',
                text: 'Su caja se encuentra bloqueada por falta de depositos, por favor comuniquese con su gestor de operaciones.',
                confirmButtonColor: '#00687d',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.value) {
                }
            });
        } else {
            this.ref = this.dialogService.open(GenerateCashRegisterComponent, {
                showHeader: true,
                closeOnEscape: true,
            });
            this.ref.onClose.subscribe((res) => {
            this.searchSalesDepositPresenter.onSearchCloseDayReport();
            });
        }
    }

    showCloseCashRegisterCompponent() {
        this.ref = this.dialogService.open(CloseCashRegisterComponent, {
            showHeader: true,
            closeOnEscape: true,
            height: '550px',
            width: '700px',
            header: 'Cerrar caja',
            data: false
        });

    }

    showOrdersModal() {
        this.ref = this.dialogService.open(SearchOrderComponent, {
            showHeader: true,
            header: 'Búsqueda de órdenes',
            closeOnEscape: true,
            height: '475px',
            width: '850px'
        });

    }
    showDispachesPendingConfirmation() {
        Swal.fire({
            text: 'Tiene ordenes pendientes por facturar, ¿Desea cerrar caja ?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'No',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                this.showCloseCashRegisterCompponent();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.router.navigate(['/panel/pos']);
                this.showOrdersModal();
            }
        });
    }
    /* showPendingMovementCashes() {
        Swal.fire({
            text: 'Usted tiene DEVOLUCIONES DE EFECTIVO pendientes por aprobar, ¿Desea cerrar caja ?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'No',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                this.settingPresenter.getOrdersPending();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // this.router.navigate(['/panel/pos']);
                // this.showOrdersModal();
            }
        });
    } */

    showAdvanceCompponent() {
        this.ref = this.dialogService.open(GenerateAdvanceComponent, {
            showHeader: true,
            closeOnEscape: true,
            header: 'Avances',
            height: '475px',
            width: '850px'
        });
    }

    showDefaultSettings(btn: any) {
        btn.blur();
        this.ref = this.dialogService.open(SettingsComponent, {
            showHeader: true,
            header: 'Configuraciones por defecto',
            closeOnEscape: true,
            data: this.settingPresenter.defaultconfigurationsService.defaultConfigurationPresenter
        });
    }

    showInitDataCompponent() {
        this.ref = this.dialogService.open(InitDataComponent, {
            showHeader: true,
            closeOnEscape: true,
            header: 'Carga Inicial'
        });
    }

    getPendingCreditMemos() {
        this.settingPresenter.getPendingCreditMemos();
    }

    showPendingCreditMemos() {
        Swal.fire({
            text: 'Tiene Notas de Crédito pendientes, debe Aprobar para cerrar caja.',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/panel/credit-memos/aprove-credit-memo']);
            }
        });
    }

    showPendingCashMovements() {
        Swal.fire({
            text: 'Tiene Devoluciones de Efectivo pendientes, debe Aprobar para cerrar caja.',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/panel/credit-memos/aprove-cash-return']);
            }
        });
    }

    getPendingTransfers() {
        this.settingPresenter.getPendingTransfers();
    }
    showPendingTransfers() {
        Swal.fire({
            text: 'Tiene transferencias de Abastecimiento Matriz pendiente por confirmar, ¿Desea cerrar caja ?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'No',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                this.settingPresenter.getIvoicesWhitOutDispatch();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.router.navigate(['/panel/transfers/supplying']);
            }
        });
    }
    showPendingInvoicesWhitOutDispatch() {
        Swal.fire({
            text: 'Tiene facturas de Contado sin Despacho pendientes, ¿Desea cerrar caja ?',
            showCancelButton: true,
            cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
            cancelButtonText: 'No',
            confirmButtonColor: '#00687d',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                this.settingPresenter.getOrdersPending();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.router.navigate(['/panel/invoices/invoices-pending']);
            }
        });
    }


}
