import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';

import { InvoiceHeaderView } from '../invoice-header.view';
import { ClientService } from 'src/app/services/client.service';
import { ClientModel } from 'src/app/models/client.model';
import { Store, select } from '@ngrx/store';
import { AddClient, ClearClient } from 'src/app/reducers/client.action';
import { GetFinalCostumerService } from 'src/app/services/get-final-costumer.service';
import {
    AllowInvoiceProcess,
    DenyInvoiceProcess,
    ClearEditOrder,
    DeleteAllOrderLines,
    SetObservationInRedux,
    SetDiscountToCero,
    SetClientAgreementDiscount,
    ChangeProcess,
    SetCreditCardPayment,
    SetDefaultPrice,
    SetAdminPriceDiscount,
    SetDefaultPriceCredired
} from '../../../../../../reducers/order.action';
import { ProcessModel } from 'src/app/models/process.model';
import { ClearInvoice, ClearPayments, DeletePayment } from 'src/app/reducers/invoice.action';
import { ProductService } from 'src/app/services/product.service';
import { AddAdviser, ClearAdviser } from '../../../../../../reducers/adviser.action';
import { AddIncrement, SetBilleton, ApplyPromotions } from '../../../../../../reducers/order.action';
import { DefaultconfigurationsService, GeneralConfigurations } from '../../../../../../services/defaultconfigurations.service';
import { DataProvider } from '../../../../../../services/data.provider';
import { round } from 'src/app/core/utils';
import { TransferProvider } from '../../../dashboard/tranfers/transfer.provider';
import { HttpResponse } from '@angular/common/http';
import { OrderLine } from 'src/app/models/orderline.model';
import { ClearAdvances } from 'src/app/reducers/advance.action';
import { AddSeller, ClearSeller } from 'src/app/reducers/seller.action';

@Injectable({
    providedIn: 'root',
})
export class InvoiceHeaderPresenter implements Presenter {
    view: InvoiceHeaderView;
    constructor(
        private clientService: ClientService,
        private store: Store<{ client: ClientModel, adviser: ClientModel }>,
        private finalConsumerService: GetFinalCostumerService,
        private productService?: ProductService,
        public defaultconfigurationsService?: DefaultconfigurationsService,
        private dataProvider?: DataProvider,
        private transferProvider?: TransferProvider,
    ) { }

    init() {
        this.store.pipe(select('client')).subscribe(value => {
            this.view.showSelectedClient = value.showSelectedClient;
            this.view.selectedClient = value.client;
        });

        this.store.pipe(select('adviser')).subscribe(value => {
            console.log('adviser', value);
            this.view.showSelectedAdviser = value.showSelectedAdviser;
            this.view.selectedAdviser = value.adviser;
        });
        this.store.pipe(select('seller')).subscribe(value => {
            console.log('seller', value);
            this.view.showSelectedSeller = value.showSelectedSeller;
            this.view.selectedSeller = value.seller;
        });
        this.store.pipe(select('invoice')).subscribe(value => {
            this.view.payments = value.payments;
        });

        this.store.pipe(select('orderLines')).subscribe(value => {
            if (value) {
                value.orderLines.forEach(element => {
                    if (element.product.referenceCode === 'L20179') {
                        this.view.ifProductCrediRed = true;
                    }
                });
                this.view.isAdminPriceDiscount = value.isAdminPriceDiscount;
                this.view.isBilleton = value.isBilleton;
                this.view.updateOrder = value.updateOrder;
                this.view.procesesSelected = value.procesesSelected;
                this.view.lines = value.orderLines;
                this.view.creditCardPayment = value.creditCardPayment;
                this.view.billetonAmount = this.dataProvider.processPayment === 'current'
                    ? value.billetonAmount.subC : value.billetonAmount.subD;
                if (!this.view.billetonAmount) {
                    this.dataProvider.isBilleton = false;
                }
                this.view.subTotal12 = value.subTotal12;
                this.view.subTotal0 = value.subTotal0;
                this.view.subTotal12 = round(this.view.subTotal12, 2);
                this.view.subTotal0 = round(this.view.subTotal0, 2);
                this.view.subTotal = this.view.subTotal12 + this.view.subTotal0;
                if (this.dataProvider.disableRecycling && this.view.isBilleton) {
                    this.view.isBilleton = false;
                    this.dataProvider.isBilleton = false;
                    this.store.dispatch(new SetBilleton(false));
                }
            }
        });

    }

    onSearchClient() {
        const searchValue = this.view.search;
        const page = this.view.page;
        const size = this.view.size;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.clients = clients;
            this.view.advisers = clients;
        }).catch(() => {
            this.view.showError('Atención', 'Algo salio mal buscando el cliente');
        });
    }

    onSearchUser() {
        const page = this.view.page;
        const sizeSeller = this.view.sizeSeller;
        if (page < 0) {
            return;
        }
        if (!sizeSeller || sizeSeller < 1) {
            return;
        }
        this.clientService.searchSeller(page, sizeSeller).then(data => {
            const users: ClientModel[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.users = users;
        }).catch(() => {
            this.view.showError('Atención', 'Algo salio mal buscando el cliente');
        });
    }



    getFinalConsumer() {
        this.view.loading = true;
        this.finalConsumerService.getFinalCostumer().then(data => {
            this.view.loading = false;
            this.view.selectedClient = data;
            this.addClientToRedux();
        }).catch(() => {
            this.view.loading = false;
            this.view.showError('Atención', 'Ocurrió un problema al intentar obtener el consumidor final');
        });
    }

    validateInvoicesOverdue() {
        if (!this.view.selectedClient || (this.view.selectedClient && !this.view.selectedClient.clientId)) {
            return;
        }
        this.clientService.validateInvoicesOverdue(this.view.selectedClient.referenceId).subscribe((res: boolean) => {
            this.view.selectedClient.invoicesOverdue = res;
            this.addClientToRedux();
        }, (error: any) => {
            this.view.selectedClient.invoicesOverdue = false;
            this.view.showInfo('Info', error.error.message);
            this.addClientToRedux();
        });
    }
    addClientToRedux() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            if (this.dataProvider.forbiddenCustomer.dni === this.view.selectedClient.dni) {
                this.view.showWarn('Atención', 'Cliente no permitido');
                this.view.selectedClient = null;
                return;
            }
            const client = this.view.selectedClient;
            this.store.dispatch(new AddClient(client));
            this.getClientAgreementDiscount();
            if (this.view.selectedClient.adviserPresenter) {
                this.view.showInfo('', 'El cliente posee asesor ');
            }
        }
    }

    addAdviserToRedux() {
        if (this.view.selectedAdviser && typeof this.view.selectedAdviser !== 'string') {
            const adviser = this.view.selectedAdviser;
            this.store.dispatch(new AddAdviser(adviser));
            this.view.showSelectedAdviser = true;
            this.view.showWarn('', 'Esto es una venta del sistema VECINO EXPRESS');
        }

    }
    addSellerToRedux() {
        if (this.view.selectedSeller && typeof this.view.selectedSeller !== 'string') {
            console.log(this.view.selectedSeller);
            const seller = this.view.selectedSeller;
            this.store.dispatch(new AddSeller(seller));
            this.view.showSelectedSeller = true;
            this.view.showWarn('', 'Esta venta pertenece a: ' + this.view.selectedSeller.userName);
        }
    }
    addSameClientToAdviserToRedux() {
        if (this.view.selectedClient && this.view.selectedClient.adviserPresenter) {
            // this.view.selectedAdviser = this.view.selectedClient.adviserPresenter;
            this.addAdviserToRedux();
        } else {
            if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
                const client = this.view.selectedClient;
                this.store.dispatch(new AddAdviser(client));
                this.view.showSelectedAdviser = true;
                this.view.showWarn('', 'Esto es una venta del sistema VECINO EXPRESS');
            }
        }

    }

    getClientAgreementDiscount() {
        if (this.view.lines.length > 0 && this.view.selectedClient) {
            const client = this.view.selectedClient;
            this.store.dispatch(new AddClient(client));
            const clientAgreement = { clientId: client.clientId, productIds: [] };
            this.view.lines.forEach(line => {
                clientAgreement.productIds.push(line.product.productId);
            });
            this.view.blockUi();
            this.productService.getProductDiscounts(clientAgreement.clientId, clientAgreement.productIds).subscribe(res => {
                this.store.dispatch(new SetClientAgreementDiscount(res, false));
            }
            );
        }
    }

    clearClient() {
        this.store.dispatch(new ClearClient());
        this.store.dispatch(new SetDefaultPrice());
        this.store.dispatch(new SetDiscountToCero());
        this.store.dispatch(new ClearAdviser());
        this.store.dispatch(new ClearAdvances());
        this.store.dispatch(new ClearSeller());
    }
    clearAdviser() {
        this.store.dispatch(new ClearAdviser());
    }
    clearSeller() {
        this.store.dispatch(new ClearSeller());
    }

    setCreditCartPayment() {
        this.store.dispatch(new SetCreditCardPayment(this.view.creditCardPayment
            , this.dataProvider.commissionCard, this.dataProvider.processPayment));
        this.store.dispatch(new AddIncrement(this.defaultconfigurationsService.defaultCreditDebitPercentageAmount));
        if (this.view.creditCardPayment) {
            this.store.dispatch(new ApplyPromotions([]));

        }
        if (!this.view.isAdminPriceDiscount && !this.view.creditCardPayment) {
            this.getClientAgreementDiscount();
        }
    }

    setBilleton() {
        this.store.dispatch(new SetBilleton(this.view.isBilleton));
    }


    evaluateProcess() {
        if (this.view.procesesSelected === ProcessModel.QUOTATION) {
            this.store.dispatch(new ClearEditOrder());
            this.store.dispatch(new ClearClient());
            this.store.dispatch(new ClearAdviser());
            this.store.dispatch(new DeleteAllOrderLines());
            this.store.dispatch(new ClearInvoice());
            this.store.dispatch(new SetObservationInRedux(''));
            this.store.dispatch(new ChangeProcess(ProcessModel.QUOTATION));
            this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.store.dispatch(new SetAdminPriceDiscount(false));
            this.dataProvider.isOrder = false;
            this.dataProvider.processPayment = undefined;
            this.dataProvider.isNewQuotation = true;
            this.dataProvider.quotationStatus = null;
            this.dataProvider.quotationId = null;
            this.dataProvider.isAdminPriceDiscount = false;
            return;
        }

        if (this.view.procesesSelected === ProcessModel.ORDER) {
            this.store.dispatch(new ClearEditOrder());
            this.store.dispatch(new ClearClient());
            this.store.dispatch(new ClearAdviser());
            this.store.dispatch(new DeleteAllOrderLines());
            this.store.dispatch(new ClearInvoice());
            this.store.dispatch(new SetObservationInRedux(''));
            this.store.dispatch(new DenyInvoiceProcess());
            this.store.dispatch(new SetCreditCardPayment(false, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.store.dispatch(new SetAdminPriceDiscount(false));
            this.dataProvider.processPayment = 'current';
            this.dataProvider.isOrder = true;
            this.dataProvider.isNewQuotation = true;
            this.dataProvider.quotationStatus = null;
            this.dataProvider.quotationId = null;
            this.dataProvider.isAdminPriceDiscount = false;
        }

        if (this.view.procesesSelected === ProcessModel.INVOICE) {
            this.store.dispatch(new ClearEditOrder());
            this.store.dispatch(new ClearClient());
            this.store.dispatch(new ClearAdviser());
            this.store.dispatch(new DeleteAllOrderLines());
            this.store.dispatch(new ClearInvoice());
            this.store.dispatch(new SetObservationInRedux(''));
            this.store.dispatch(new AllowInvoiceProcess());
            this.store.dispatch(new SetCreditCardPayment(true, this.dataProvider.commissionCard, this.dataProvider.processPayment));
            this.store.dispatch(new SetAdminPriceDiscount(false));
            this.dataProvider.processPayment = undefined;
            this.dataProvider.isOrder = false;
            this.dataProvider.isNewQuotation = true;
            this.dataProvider.quotationStatus = null;
            this.dataProvider.quotationId = null;
            this.transferProvider.getTransferDetailsToInvoice();
            this.dataProvider.isAdminPriceDiscount = false;
        }
    }

    evaluateSalesExpress() {
        if (!this.view.salesExpress) {
            this.store.dispatch(new ClearAdviser());

        }
    }
    evaluateSellers() {
        if (!this.view.isSellers) {
            this.store.dispatch(new ClearSeller());

        }
    }
    setAdminPriceDiscount() {
        this.store.dispatch(new SetAdminPriceDiscount(this.view.isAdminPriceDiscount));


        if (!this.view.isAdminPriceDiscount) {
            this.store.dispatch(new SetDiscountToCero());
            this.store.dispatch(new SetDefaultPrice());
            this.getClientAgreementDiscount();
        }
    }
    setAdminPriceDiscountCredired() {
        this.store.dispatch(new SetAdminPriceDiscount(this.view.isAdminPriceDiscount));


        if (!this.view.isAdminPriceDiscount) {
            this.store.dispatch(new SetDiscountToCero());
            this.store.dispatch(new SetDefaultPriceCredired());
            this.getClientAgreementDiscount();
        }
    }
    dropPayments() {
        this.store.dispatch(new ClearPayments());
    }

    showAdminPasswordCredired() {
        this.view.showAdminPasswordCredired();
    }
}
