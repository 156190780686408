export interface IdentificationType {
    identificationTypeId?: string;
    description?: string;
    identificationPattern?: IdentificationPattern;
}


export enum IdentificationPattern {
    IDENTIFICATION_CARD = 'IDENTIFICATION_CARD',
    RUC = 'RUC',
    PASSPORT = 'PASSPORT',
    PLATE = 'PLATE',
    FINAL_CONSUMER = 'FINAL_CONSUMER',
    FOREIGN_RUC = 'FOREIGN_RUC'
}

