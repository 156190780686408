import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { SearchTransactionsResponsePresenter } from 'src/app/models/search-transactions-response-presenter';
import { ReceiptsPaymentsService } from 'src/app/services/receipts-payments.service';

import { ReceiptsPaymentsHistoricView } from '../receipts-payments-historic.view';

@Injectable({
  providedIn: 'root',
})
export class ReceiptsPaymentsHistoricPresenter implements Presenter {
  view: ReceiptsPaymentsHistoricView;

  constructor(private receiptsPaymentsService: ReceiptsPaymentsService) {}
  searchTransactions() {
    this.view.blockUi();
    this.receiptsPaymentsService.searchTransactions(this.view.search).subscribe(
      (res: SearchTransactionsResponsePresenter) => {
        if (res.success !== null && res.success) {
          if (res.result.transactions.length > 0) {
            this.view.createTable(res.result.transactions);
          }
        } else {
          this.view.showSwitchException(res);
          this.view.show = false;
        }
      },
      (error) => {
        this.view.showBackException(error);
        this.view.show = false;
      }
    );
  }
}
