import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { SearchClientView } from './search-client.view';
import { Client, ClientModel } from 'src/app/models/client.model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { SearchClientPresenter } from './presenter/search-client.presenter';
import { UiUtils } from 'src/app/core/ui-utils';
import { DomainAction } from 'src/app/models/rol.model';
import { OverlayPanel } from 'primeng/primeng';

@Component({
    selector: 'app-search-client',
    templateUrl: './search-client.component.html',
    styleUrls: ['./search-client.component.scss']
})
export class SearchClientComponent extends AbstractView implements OnInit, SearchClientView {

    search = '';
    page = 0;
    size = 20;
    clients: ClientModel[];
    selectedClient: ClientModel;
    totalElements: number;

    constructor(
        private messageService: MessageService,
        public router: Router,
        private searchClientPresenter: SearchClientPresenter,
        public uiUtils: UiUtils) {
        super(messageService, router);
        if (!this.getAuth(DomainAction.ALLOW_POS_LIST_CLIENTS)) {
            this.redirectTo('/panel/pos');
        }
        searchClientPresenter.view = this;
    }

    ngOnInit() {
    }

    onKeyUp() {
        this.page = 0;
        this.searchClientPresenter.onSearchClient();
    }

    paginate($event) {
        this.page = $event['page'];
        this.searchClientPresenter.onSearchClient();
    }

    clearInput(nameModel) {
        nameModel.search = '';
    }

    showClientDir(event, overlaypanel: OverlayPanel, client: ClientModel) {
        this.selectedClient = client;
        overlaypanel.toggle(event);
    }

}
