import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { SettingPresenter } from './main-components/panel/shared/setting/presenter/setting.presenter';
import { GeneralConfigurations } from './services/defaultconfigurations.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(private platform: Platform) {
        GeneralConfigurations.blink = platform.BLINK;
    }

    getDoingSomething() {
        return SettingPresenter.doingSomething;
    }

}
