import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { leftToLeft } from 'src/app/animations';
import { AbstractView } from 'src/app/core/abstract_view';
import { ClientModel } from 'src/app/models/client.model';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { PaymentCollectionsStatus } from 'src/app/models/payment-collections-status';
import { PaymentCollectionsPresenter } from 'src/app/models/payment-collections.model';
import { PaymentCollectionsAvailableServicesPresenter } from 'src/app/models/receipts-payments';
import { DataProvider } from 'src/app/services/data.provider';
import Swal from 'sweetalert2';

import { ReceiptsPaymentsReportPresenter } from './presenter/receipts-payments-report.presenter';
import { ReceiptsPaymentsReportView } from './receipts-payments-report.view';

@Component({
  animations: [leftToLeft],
  selector: 'app-receipts-payments-report',
  templateUrl: './receipts-payments-report.component.html',
  styleUrls: ['./receipts-payments-report.component.scss'],
})
export class ReceiptsPaymentsReportComponent
  extends AbstractView
  implements OnInit, ReceiptsPaymentsReportView {
  datepipe: DatePipe = new DatePipe('en-US');
  selectedClient: ClientModel;
  showSelectedUser = false;
  showSelectedService = false;
  showSelectedClient = false;
  filteredValues: any[] = [];
  valueProductSelected: any;
  initDate = null;
  endDate = null;
  /*   initate = this.datepipe.transform(Date.now(), 'yyyy-MM-dd 00:00:00');
  endDate = this.datepipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss'); */
  documentNo = '';
  paymentCollectionsStatus: PaymentCollectionsStatus;
  codeProduct = '';
  counterpart = '';
  page = 0;
  size = 20;
  paymentCollections: PaymentCollectionsPresenter[] = [];
  totalElements: number;
  sizeClient: number;
  pageClient: number;
  searchClient: any;
  queryProduct = '';
  clients: ClientModel[];
  dniClient = '';
  idUser = '';
  invoiceStatus: { label: string; value: PaymentCollectionsStatus | string }[] =
    [
      { label: 'TODAS', value: '' },
      { label: 'CONSULTADO', value: PaymentCollectionsStatus.REQUESTED },
      { label: 'ACEPTADO', value: PaymentCollectionsStatus.ACCEPTED },
      { label: 'CONFIRMADO', value: PaymentCollectionsStatus.CONFIRMATIONED },
      { label: 'ANULADO', value: PaymentCollectionsStatus.VOIDED },
    ];
  respuesta: PaymentCollectionsAvailableServicesPresenter;
  selectedUser: UserPresenter;
  users: UserPresenter[];
  searchUser: string;
  constructor(
    public router: Router,
    private messageService: MessageService,
    public receiptsPaymentsReportPresenter: ReceiptsPaymentsReportPresenter,
    private dataProvider: DataProvider
  ) {
    super(messageService, router);
    receiptsPaymentsReportPresenter.view = this;
  }
  reportsCSV: any[];

  ngOnInit() {
    this.loadProducts();
    this.searchReports();
  }
  loadProducts() {
    this.receiptsPaymentsReportPresenter.getAvailableServices();
  }

  translatePaymentCollectionsStatus(paymentCollectionsStatus: string) {
    switch (paymentCollectionsStatus) {
      case PaymentCollectionsStatus.ACCEPTED:
        return 'ACEPTADO';
      case PaymentCollectionsStatus.CONFIRMATIONED:
        return 'CONFIRMADO';
      case PaymentCollectionsStatus.REQUESTED:
        return 'CONSULTADO';
      case PaymentCollectionsStatus.VOIDED:
        return 'ANULADO';
      case PaymentCollectionsStatus.ALL:
        return 'TODAS';
      default:
        return PaymentCollectionsStatus;
    }
  }
  searchReports() {
    this.receiptsPaymentsReportPresenter.searchReports();
  }
  paginate($event) {
    this.page = $event['page'];
    this.receiptsPaymentsReportPresenter.searchReports();
  }
  printPDFReport() {
    this.receiptsPaymentsReportPresenter.printPaymentCollections();
  }
  printCVSReport() {
    this.receiptsPaymentsReportPresenter.getCSVPaymentCollections();
  }
  onSearchClient(event) {
    this.searchClient = event.query;
    this.pageClient = 0;
    this.sizeClient = 20;
    this.receiptsPaymentsReportPresenter.onSearchClient();
  }

  clearClient() {
    this.showSelectedClient = false;
    this.selectedClient = null;
    this.dniClient = '';
    this.searchReports();
  }
  addClient() {
    if (this.selectedClient && typeof this.selectedClient !== 'string') {
      this.showSelectedClient = true;
      this.dniClient = this.selectedClient.dni;
      this.searchReports();
    }
  }
  searchServices(event) {
    this.queryProduct = event.query.toLowerCase();
    this.searchProduct();
  }
  searchProduct() {
    if (!this.respuesta) {
      this.showWarn('Advertencia', 'No existen registros para buscar');
      return;
    }
    this.filteredValues = this.respuesta.result.services
      .filter(
        (service) =>
          service.product.keywords.includes(this.queryProduct) ||
          service.product.name.toLowerCase().includes(this.queryProduct)
      )
      .slice(0, 20);
  }
  addProduct() {
    if (
      this.valueProductSelected &&
      typeof this.valueProductSelected !== 'string'
    ) {
      setTimeout(() => {
        console.log('this.valueProductSelected', this.valueProductSelected);
        this.showSelectedService = true;
        this.codeProduct = this.valueProductSelected.product.code;
        this.searchReports();
      }, 200);
    }
  }
  clearProduct() {
    this.showSelectedService = false;
    this.valueProductSelected = null;
    this.codeProduct = '';
    this.searchReports();
  }
  cancelTransaction(transaction: any) {
    Swal.fire({
      text: '¿Desea anular esta transacción ?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Anular transacción',
    }).then((result) => {
      if (result.value) {
        this.receiptsPaymentsReportPresenter.cancelTransaction(transaction);
      }
    });
  }
  printInvoice(transaction: any) {
    console.log('transaction', transaction);
    Swal.fire({
      text: '¿Desea reimprimir esta transacción ?',
      showCancelButton: true,
      cancelButtonColor: 'rgba(255, 0, 0, 0.637)',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Reimprimir transacción',
    }).then((result) => {
      console.log('transaction', transaction);
      if (result.value) {
        if (transaction.paymentCollectionsStatus === 'CONFIRMATIONED') {
          this.receiptsPaymentsReportPresenter.printConfirmTransaction(
            transaction
          );
        } else if (transaction.paymentCollectionsStatus === 'VOIDED') {
          this.receiptsPaymentsReportPresenter.printCancelTransaction(
            transaction
          );
        }
      }
    });
  }

  findTransfersByUser() {
    if (this.selectedUser && typeof this.selectedUser !== 'string') {
      this.showSelectedUser = true;
      this.idUser = this.selectedUser.userId;
      this.searchReports();
    }
  }
  clearUser() {
    this.idUser = '';
    this.showSelectedUser = false;
    this.selectedUser = null;
    this.searchReports();
  }

  onSearchUser(event) {
    this.searchUser = event.query;
    this.receiptsPaymentsReportPresenter.onSearchUser();
  }

  showExeption(res: any) {
    Swal.fire({
      title: 'Atención: ' + res.message.code,
      text: res.message.description,
      showCancelButton: false,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    });
  }
}
