import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  navLinks = [];
  constructor() { }
  ngOnInit() {
    this.navLinks = [{
      label: 'Reporte de Facturas',
      link: './search-invoices',
      index: 0,
      visible: true
    }, {
      label: 'Facturas Pendientes Por Despachar',
      link: './invoices-pending',
      index: 1,
      visible: true
    }];
  }

}
