import { Presenter } from 'src/app/core/presenter';
import { Injectable } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { select, Store } from '@ngrx/store';
import { OrderLine } from 'src/app/models/orderline.model';
import { ClientModel } from 'src/app/models/client.model';
import { DefaultconfigurationsService } from 'src/app/services/defaultconfigurations.service';
import { DataProvider } from 'src/app/services/data.provider';
import { CollectionCenter, Product } from 'src/app/models/product.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MeasureConversionModel } from 'src/app/models/measure-conversion.model';
import { MeasureConversionComboModel } from 'src/app/models/measure-combo.model';
import {
    AddOrderLine, ChangeAutoAddStatus, ChangeLineDiscount, ChangeLineQuantity,
    ChangeLineUnitMeasure, ClearEditOrder, DeleteAllOrderLines, DeleteOrderLine,
    SetCreditCardPayment, SetDefaultPrice, SetObservationInRedux
} from 'src/app/reducers/order.action';
import Decimal from 'decimal.js';
import { ClearClient } from 'src/app/reducers/client.action';
import { ClearInvoice } from 'src/app/reducers/invoice.action';
import { CreateTransfersView } from '../create-transfers.view';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';
import { DataProviderTransfers } from 'src/app/services/data.provider.transfers';
import { TransferProvider } from '../../../transfer.provider';


@Injectable({
    providedIn: 'root',
})
export class TransfersPresenter implements Presenter {
    view: CreateTransfersView;
    lines: OrderLine[] = [];
    orderLinesToRestore: OrderLine[] = [];

    constructor(
    ) { }


}
