import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { TaxPayerType } from '../models/taxpayertype.model';

@Injectable({
  providedIn: 'root'
})
export class TaxPayerService {
  constructor(private http: HttpClient) { }

  public searchTaxPayerType(): Promise<TaxPayerType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<TaxPayerType[]>(environment.apiUrl + '/taxPayerType')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
