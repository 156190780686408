import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { ChartsView } from './charts.view';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ChartsPresenter } from './presenter/charts.presenter';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { OverlayPanel } from 'primeng/primeng';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { DomainAction } from 'src/app/models/rol.model';
import { DataProvider } from 'src/app/services/data.provider';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent extends AbstractView implements OnInit, ChartsView {
  users: UserPresenter[] = [];
  selectedUser: UserPresenter;
  showSelectedUser = false;
  invoices: InvoiceModel[];
  searchValue: '';
  initDate = new Date();
  endDate = new Date();
  page = 0;
  size = 20;
  pageInvoice = 0;
  sizeInvoice = 20;
  totalElements: number;
  totalElementsInvoice: number;
  public ref;
  invoiceSelected: InvoiceModel;
  data: any;
  getTotalAmount: any;

  dognut: any;
  line: any;
  businessUnit: string;
  constructor(
    private messageService: MessageService,
    public router: Router,
    private chartsPresenter: ChartsPresenter,
    private dataProvider: DataProvider,


  ) {
    super(messageService, router);
    this.businessUnit = this.dataProvider.defaultWarehouse.businessUnit;
    if (!this.getAuth(DomainAction.ALLOW_POS_REPORT_SALES)) {
      this.redirectTo('/panel/pos');
    }
    chartsPresenter.view = this;
    if (this.businessUnit === 'agrotiendas') {
      this.data = {
        labels: ['Efectivo', 'Cheque', 'Debito', 'Crédito', 'Tarjeta de crédito', 'Nota de crédito',
          'Transferencia Bancaria', 'Retención', 'Cont. sin despacho', 'Subvención'],
        datasets: [
          {
            label: 'Ventas por método de pago',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      };
    } else {
      this.data = {
        labels: ['Efectivo', 'Cheque', 'Debito', 'Crédito', 'Tarjeta de crédito', 'Nota de crédito',
          'Transferencia Bancaria', 'Retención', 'Cont. sin despacho', 'Cobros pagos'],
        datasets: [
          {
            label: 'Ventas por método de pago',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      };
    }

  }


  ngOnInit() {
    this.chartsPresenter.getInvoicesForUserReport();
  }

  onSearchUser(event) {
    this.searchValue = event.query;
    this.page = 0;
    this.size = 5;
    this.chartsPresenter.onSearchUser();
  }
  addUser() {
    this.chartsPresenter.findInvoicesByUser();
  }
  clearUser() {
    this.chartsPresenter.clearUser();
  }
  getInvoices() {
    this.chartsPresenter.getInvoicesForUserReport();
  }

  selectInvoice(event, invoice: InvoiceModel, overlaypanel: OverlayPanel) {
    this.invoiceSelected = invoice;
    overlaypanel.toggle(event);
  }
  printSalesReport() {
    this.chartsPresenter.printSalesReport();
  }
  getTranslate(paymentMethod: PaymentMethod) {
    switch (paymentMethod) {
      case PaymentMethod.CASH: return 'Efectivo';
      case PaymentMethod.BANK_TRANSFER: return 'Transferencia Bancaria';
      case PaymentMethod.CHECK: return 'Cheque';
      case PaymentMethod.CREDIT: return 'Crédito';
      case PaymentMethod.CREDIT_CARD: return 'Tarjeta de crédito';
      case PaymentMethod.CREDIT_MEMO: return 'Nota de crédito';
      case PaymentMethod.DEBIT: return 'Tarjeta de débito';
      case PaymentMethod.WITHHOLD: return 'Retención';
      case PaymentMethod.SUBVENTION: return 'Subvención';
      case PaymentMethod.PAYMENT_COLLECTIONS: return 'Cobros pagos';
    }
  }
  paginateInvoice($event) {
    this.pageInvoice = $event['page'];
    this.chartsPresenter.getInvoicesForUserReport();
  }
}
