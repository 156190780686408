import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-pos',
    templateUrl: './pos.component.html',
    styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {

    urlArray: string[] = [
        '/assets/layout/images/promo/banner_1.jpg',
        '/assets/layout/images/promo/banner_2.jpg',
    ];

    constructor() {
    }

    ngOnInit() {


    }


}
