import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuotationModel } from '../models/quotation.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor(private http: HttpClient) { }
  saveNewQuotation(newQuotation: QuotationModel) {
    return this.http.post<QuotationModel>(environment.apiUrl + '/saveQuotation', newQuotation);
  }
  findQuotations(clientId: string, initDate: string, endDate: string, documentNo: string, page = 0, size = 20) {
    let filter = '';
    if (clientId) {
      filter += '&clientId=' + clientId;
    }
    if (initDate) {
      filter += '&initDate=' + initDate;
    }
    if (endDate) {
      filter += '&endDate=' + endDate;
    }
    if (documentNo) {
      filter += '&documentNo=' + documentNo;
    }
    return this.http.post<{ data: QuotationModel[], totalElements: number }>(environment.apiUrl + '/findQuotations?page='
      + page + '&size=' + size + filter,
      null);
  }
  findQuotation(quotationId: string) {
    return this.http.get<QuotationModel>(environment.apiUrl + '/findQuotation?quotationId=' + quotationId);
  }
  getPdf(quotationId: string) {
    return this.http.get(environment.apiUrl + '/printQuotation?quotationId=' + quotationId);
  }
  updateQuotation(quotation: QuotationModel) {
    return this.http.post<QuotationModel>(environment.apiUrl + '/updateQuotation', quotation);
  }

  resynchronizeQuotation(quotationId: string) {
    return this.http.get<QuotationModel>(environment.apiUrl + '/resynchronizeQuotation?quotationId=' + quotationId);
  }
}
