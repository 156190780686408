import { PaymentTerm } from './paymentterm';
import { PaymentMethod } from './paymentmethod';
import { OrderLine, OrderLineToSave, InvoiceLineToSave } from './orderline.model';
import { PriceList } from './pricelist.model';
import { EmissionPointPresenter } from './pointemission.model';
import { Product } from './product.model';
import { InvoiceModel } from './invoice.model';
import { MeasureConversionModel } from './measure-conversion.model';
import { Tax } from './tax.model';
import { ClientModel } from './client.model';

export interface CreditMemoModel {
    creditMemoId?: string;
    clientPresenter?: ClientModel;
    invoicePresenter?: InvoiceModel;
    total?: number;
    subtotal?: number;
    tax?: number;
    paymentTermPresenter?: PaymentTerm;
    paymentMethod?: PaymentMethod;
    priceListPresenter?: PriceList;
    transactionDate?: Date;
    accessKey?: string;
    sequential?: string;
    pdf?: any;
    subtotalZeroTax?: number;
    subtotalTaxed?: number;
    emissionPointPresenter?: EmissionPointPresenter;
    creditMemoDetailPresenters?: CreditMemoDetailModel[];
    observation?: string;
    creditMemoStatus?: CreditMemoStatus;
    reasonCreditMemoPresenter?: ReasonCreditMemoPresenter;
    containValidMovementCash?: boolean;
}

export interface CreditMemoDetailModel {
    creditMemoDetailId?: string;
    product?: Product;
    invoiceDetailPresenter?: InvoiceLineToSave;
    convertedQuantity?: number;
    enteredQuantity?: number;
    measureUnitConversionPresenter?: MeasureConversionModel;
    unitPrice?: number;
    tax?: Tax;
    lineNumber?: number;
    unitPriceWithTax?: number;
    total?: number;
    subtotal?: number;
}


export enum CreditMemoStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}



export interface ReasonCreditMemoPresenter {
    reasonCreditMemoId?: string;
    reason?: string;
    referenceId?: string;
}


