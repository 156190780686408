import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { MessageService, DialogService } from 'primeng/api';
import { SearchCloseDayReportPresenter } from './presenter/search-report.presenter';
import { Router } from '@angular/router';
import { UiUtils } from 'src/app/core/ui-utils';
import { DayRegisterDetailModel, DayRegisterModel } from 'src/app/models/cash-register.model';
import { SearchCloseDayReportView } from './search-report.view';
import { ReportCloseDayModel } from 'src/app/models/report.model';
import { DomainAction } from 'src/app/models/rol.model';
import { SetObservationComponent } from '../../set-observation/set-observation.component';
import { CloseDayReportComponent } from '../../close-day-report/close-day-report.component';

@Component({
  selector: 'app-search-report',
  templateUrl: './search-report.component.html',
  styleUrls: ['./search-report.component.scss']
})
export class SearchReportComponent extends AbstractView implements OnInit, SearchCloseDayReportView {
  reportCloseDays: DayRegisterDetailModel[];
  searchValue: '';
  initDate = null;
  endDate = null;
  page = 0;
  size = 20;
  totalElements: number;
  public ref;
  selectedReport: ReportCloseDayModel;
  constructor(
    private messageService: MessageService,
    public router: Router,
    private searchReportPresenter: SearchCloseDayReportPresenter,
    public uiUtils: UiUtils,
    public dialogService: DialogService) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_REPORT_CASH_CLOSE)) {
      this.redirectTo('/panel/pos');
    }
    searchReportPresenter.view = this;
    this.blockUi();
    searchReportPresenter.onSearchCloseDayReport();

  }

  ngOnInit() {
  }

  paginate($event) {
    this.page = $event['page'];
    this.searchReportPresenter.onSearchCloseDayReport();
  }

  searchClosedayReports() {
    this.page = 0;
    this.searchReportPresenter.onSearchCloseDayReport();
  }

  showObservationReportComponent(report: ReportCloseDayModel) {
    const data: DayRegisterModel = { dayRegisterId: report.dayRegisterId};
    this.ref = this.dialogService.open(SetObservationComponent, {
      header: 'Añadir observación',
      closeOnEscape: true,
      data: data
    });
  }

  showCloseDayReportComponent(report: ReportCloseDayModel) {
    this.ref = this.dialogService.open(CloseDayReportComponent, {
      header: 'CIERRE DE CAJA ( ' + report.userPresenter.fullName.toUpperCase() + ' )',
      height: '620px',
      closeOnEscape: true,
      data: report
    });
  }

  getPdfReport(report: ReportCloseDayModel) {
    this.selectedReport = report;
    this.searchReportPresenter.getPdfReport();
  }

}
