
import { Component, OnInit } from '@angular/core';
import { AbstractView } from 'src/app/core/abstract_view';
import { Router } from '@angular/router';
import { MessageService, DynamicDialogRef } from 'primeng/api';
import { UiUtils } from 'src/app/core/ui-utils';
import { AproveView } from './aprove.view';
import { AprovePresenter } from './presenter/aprove.presenter';

@Component({
  selector: 'app-aprove',
  templateUrl: './aprove.component.html',
  styleUrls: ['./aprove.component.scss'],
  providers: [MessageService]
})


export class AproveComponent extends AbstractView implements OnInit, AproveView {
  user: string;
  password: string;

  constructor(
    public loginPresenter: AprovePresenter,
    public router: Router,
    public messageService: MessageService,
    public ref: DynamicDialogRef,
    public uiUtils: UiUtils) {
    super(messageService, router);
    this.loginPresenter.view = this;
  }

  ngOnInit() {

  }

  onClickAcceptButton() {
    this.loginPresenter.acceptLoging();
  }

  closeAproveComponent(bool: boolean) {
    this.ref.close(bool);
  }

}
