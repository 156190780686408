import { Injectable } from '@angular/core';
import { DayRegisterModel, DayRegisterDetailModel, CloseCashRegisterResumeModel } from 'src/app/models/cash-register.model';
import { CashRegisterService } from 'src/app/services/cash-register.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CloseCashRegisterView } from '../close-cash-register.view';
import { AdvanceService } from 'src/app/services/advance.service';
import { DenominationEnum } from 'src/app/models/currency.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { SystemReportModel } from 'src/app/models/report.model';
import { Payment, CheckDetailModel } from 'src/app/models/payment.model';
import { DataProvider } from 'src/app/services/data.provider';


@Injectable({
    providedIn: 'root',
})
export class CloseCashRegisterPresenter {
    view: CloseCashRegisterView;
    constructor(
        public cashRegisterService: CashRegisterService,
        public advanceService: AdvanceService,
        public dataProvider: DataProvider) {
    }

    validate() {
        if (this.view.cashDetail.amount === 0) {
            this.view.showError('Atención', 'No puede cerrar caja en $0');
            return;
        }
        if (this.view.checkDetail && isNaN(this.view.checkDetail.amount)) {
            this.view.showError('Atención', 'Valor de cheque incorrecto');
            return;
        }
        let inactive = false;
        this.view.checkDetails.forEach(detail => {
            if (!detail.active) {
                inactive = true;
            }
        });
        if (inactive) {
            this.view.showCheckConfirmation();
            return;
        }

        this.closeCashRegister();
    }

    getCloseCashRegisterResume() {
        this.cashRegisterService.getCashRegisterResume().subscribe((resume: SystemReportModel) => {
            this.view.resume = resume;
        }, (error: HttpErrorResponse) => {
            this.view.showError('Ups', error.error.message);
        });
    }
    getCeckDetails() {
        this.cashRegisterService.getChecksDetails().subscribe((payments: Payment[]) => {
            const checkDetails: CheckDetailModel[] = [];
            payments.forEach(payment => {
                checkDetails.push({
                    active: true,
                    authorization: payment.confirmationCode,
                    bank: payment.bankAccountPresenter.bankName,
                    number: payment.referenceNumber,
                    value: payment.amount,
                    efectiveDate: payment.effectiveDate
                });
            });
            this.view.checkDetails = checkDetails;

        }, (error: HttpErrorResponse) => {
            this.view.showError('Ups', error.error.message);
        });
    }
    getDailyChecks() {
        this.cashRegisterService.getDailyChecks().subscribe((dailyChecks: Payment[]) => {
            const checkDetails: CheckDetailModel[] = [];
            dailyChecks.forEach(ckeck => {
                checkDetails.push({
                    active: true,
                    authorization: ckeck.confirmationCode,
                    bank: ckeck.bankAccountPresenter.bankName,
                    number: ckeck.referenceNumber,
                    value: ckeck.amount,
                    efectiveDate: ckeck.effectiveDate
                });
            });
            this.view.dailyChecks = checkDetails;
            this.view.checkDetail.amount = this.calculateTotalCheck(this.view.dailyChecks);
        }, (error: HttpErrorResponse) => {
            this.view.showError('Ups', error.error.message);
        });
    }

    closeCashRegister() {
        const details: DayRegisterDetailModel[] = [];
        details.push({ amount: this.view.cashDetail.amount, paymentMethod: PaymentMethod.CASH });


        const denominationDetailPresenters: { denominationType: DenominationEnum, quantity: number }[] = [];
        this.view.coins.forEach(coin => {
            denominationDetailPresenters.push({ denominationType: coin.denomination, quantity: coin.quantity ? coin.quantity : 0 });
        });
        this.view.bills.forEach(bill => {
            denominationDetailPresenters.push({ denominationType: bill.denomination, quantity: bill.quantity ? bill.quantity : 0 });
        });

        const closeCashRegister: DayRegisterModel = {
            dayRegisterObservationPresenters: [],
            dayRegisterDetailPresenters: details,
            denominationDetailPresenters: denominationDetailPresenters
        };
        if (this.view.observation) {
            closeCashRegister.dayRegisterObservationPresenters.push({ observation: this.view.observation });
        }
        this.view.blockUi();
        this.cashRegisterService
            .getDefaultConfigurationCpos()
            .subscribe((res: any) => {
                this.dataProvider.isConfigurationCpos = res;
            });
        this.cashRegisterService.closeCashRegister(closeCashRegister).then((data) => {
            this.dataProvider.isConnectionCpos = false;
            this.dataProvider.isOpenCash = false;
            this.dataProvider.needOpenCpos = false;
            this.view.showSuccess('', 'Caja cerrada');
            this.view.closeModal();
            this.advanceService.advanceStatus();
            if (data.pdf) {
                this.view.showPdf(data);
            }
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }
    calculateTotal() {
        if (this.view.selectedDenomination.quantity) {
            this.view.selectedDenomination.total = this.view.selectedDenomination.quantity * this.view.selectedDenomination.equivalent;
        } else {
            this.view.selectedDenomination.total = 0;
        }
        let total = 0;
        this.view.coins.forEach(det => {
            if (det.total) {
                total += Number(det.total);
            }
        });
        this.view.bills.forEach(bill => {
            if (bill.total) {
                total += Number(bill.total);
            }
        });
        this.view.cashDetail.amount = total;
    }
    calculateTotalCheck(checks: CheckDetailModel[]) {
        let total = 0;
        checks.forEach(check => {
            if (check.active) {
                total += check.value;
            }
        });
        return total;

    }

}
