import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';


import { MeasureConversionComboModel } from 'src/app/models/measure-combo.model';
import { MeasureConversionModel } from 'src/app/models/measure-conversion.model';
import { GenerateDispatchView } from '../generate-dispatch.view';
import { DispatchLine, DispatchLinetoSave } from 'src/app/models/dispatch.model';
import { Product } from 'src/app/models/product.model';
import { DispatchService } from 'src/app/services/dispatch.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingPresenter } from 'src/app/main-components/panel/shared/setting/presenter/setting.presenter';


@Injectable({
    providedIn: 'root',
})
export class GenerateDispatchPresenter implements Presenter {
    view: GenerateDispatchView;

    constructor(
        private dispatchService: DispatchService,
        private settingPresenter: SettingPresenter
    ) {
    }


    fillLines() {

        this.view.dispatch.dispatchDetailPresenters.forEach(line => {
            if (line.productPresenter.availableQuantity > 0) {
                const lineToPush: DispatchLine = {
                    dispatchDetailId: line.dispatchDetailId,
                    productPresenter: line.productPresenter,
                    convertedQuantity: line.convertedQuantity,
                    enteredQuantity: line.enteredQuantity,
                    lineNumber: line.lineNumber,
                    measureUnitConversionPresenter: this.getDefault(line.productPresenter, line.measureUnitConversionPresenter),
                    measureCombo: this.getCombo(line.productPresenter),
                    maxQuantity: line.productPresenter.availableQuantity * line.measureUnitConversionPresenter.multiplyRate,
                    warehousePresenter: line.warehousePresenter
                };
                this.view.lines.push(lineToPush);
            }
        });
    }

    private getCombo(productPresenter: Product): MeasureConversionComboModel[] {
        const comboMeasure: MeasureConversionComboModel[] = [];
        if (productPresenter.measureUnitConversionPresenters) {
            productPresenter.measureUnitConversionPresenters.forEach(unit => {
                comboMeasure.push({ label: unit.measureUnitPresenterTo.name, value: unit });
            });
        }
        return comboMeasure;
    }

    getDefault(productPresenter: Product, measure: MeasureConversionModel): MeasureConversionModel {
        let returnValue: MeasureConversionModel = null;
        if (productPresenter.measureUnitConversionPresenters) {
            productPresenter.measureUnitConversionPresenters.forEach(unit => {
                if (unit.measureUnitPresenterTo.referenceId === measure.measureUnitPresenterTo.referenceId) {
                    returnValue = unit;
                    return returnValue;
                }
            });
        }
        return returnValue;

    }

    deleteLine() {
        this.view.lines = this.view.lines.filter(line =>
            !(line.productPresenter.productId === this.view.selectedLine.productPresenter.productId
                && line.warehousePresenter.warehouseId === this.view.selectedLine.warehousePresenter.warehouseId)
        );
    }

    changeLine() {
        this.view.lines.forEach(line => {

            if (line.productPresenter.productId === this.view.selectedLine.productPresenter.productId &&
                line.warehousePresenter.warehouseId === this.view.selectedLine.warehousePresenter.warehouseId) {
                line.measureUnitConversionPresenter = this.view.selectedLine.measureUnitConversionPresenter;
                line.maxQuantity = line.measureUnitConversionPresenter.multiplyRate * line.productPresenter.availableQuantity;
                line.enteredQuantity = Number(this.view.selectedLine.enteredQuantity);

                if (isNaN(this.view.selectedLine.enteredQuantity) ||
                    this.view.selectedLine.enteredQuantity > line.maxQuantity) {
                    this.view.selectedLine.enteredQuantity = line.maxQuantity;
                    line.enteredQuantity = line.maxQuantity;
                    this.view.showError('cantidad incorrecta', 'maxima cantidad asignada');
                }

                line.convertedQuantity = Number((this.view.selectedLine.enteredQuantity *
                    this.view.selectedLine.measureUnitConversionPresenter.divideRate));
            }

        });

    }

    validateDispatch() {
        let quantity = 0;
        this.view.lines.forEach(line => {
            if (line.enteredQuantity > 0) {
                quantity += line.enteredQuantity;
            }
        });
        if (quantity <= 0) {
            this.view.showError('', 'Debe ingresar una cantidad a despachar');
            return;
        }
        this.view.showConfirmation();

    }
    saveDispatch() {
        const dispatchLines: DispatchLinetoSave[] = [];
        this.view.lines.forEach(line => {
            const lineToPush: DispatchLinetoSave = {
                convertedQuantity: line.convertedQuantity,
                dispatchDetailId: line.dispatchDetailId,
                enteredQuantity: line.enteredQuantity,
                lineNumber: line.lineNumber,
                measureUnitConversionPresenter: line.measureUnitConversionPresenter,
                productPresenter: line.productPresenter,
                warehousePresenter: line.warehousePresenter
            };
            if (lineToPush.enteredQuantity > 0) {
                dispatchLines.push(lineToPush);
            }
        });

        this.view.dispatch.dispatchDetailPresenters = dispatchLines;
        this.view.dispatch.emissionPointPresenter = this.settingPresenter.view.selectedPointEmission;
        this.view.blockUi();
        this.dispatchService.dispatch(this.view.dispatch).then(dispatch => {
            this.view.showSuccess('', 'Factura despachada');
            this.view.showPdf(dispatch);
            this.view.closeView();
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }



}
