export enum DefaultConfigurationType {
    DEFAULT_TIME_AUTO_CANCEL_ORDERS = 'DEFAULT_TIME_AUTO_CANCEL_ORDERS',
    DEFAULT_AMOUNT_MOVEMENT_ADVANCE = 'DEFAULT_AMOUNT_MOVEMENT_ADVANCE',
    DEFAULT_MAX_FINAL_CONSUMER_AMOUNT = 'DEFAULT_MAX_FINAL_CONSUMER_AMOUNT',
    DEFAULT_MIN_FINAL_CONSUMER_AMOUNT = 'DEFAULT_MIN_FINAL_CONSUMER_AMOUNT',
    DEFAULT_MAX_CREDIT_DEBIT_FINAL_CONSUMER_AMOUNT = 'DEFAULT_MAX_CREDIT_DEBIT_FINAL_CONSUMER_AMOUNT',
    DEFAULT_TIME_AUTO_CANCEL_QUOTATIONS = 'DEFAULT_TIME_AUTO_CANCEL_QUOTATIONS',
    DEFAULT_SYNCHRONIZED_DATA = 'DEFAULT_SYNCHRONIZED_DATA',
    DEFAULT_SALES_EXPRESS = 'DEFAULT_SALES_EXPRESS',
    DEFAULT_AMOUNT_CREDIT_DEBIT_CARD_SALES = 'DEFAULT_AMOUNT_CREDIT_DEBIT_CARD_SALES',
    DEFAULT_MIN_BILLETON_AMOUNT = 'DEFAULT_MIN_BILLETON_AMOUNT',
    DEFAULT_BILLETON_DISCOUNT_AMOUNT = 'DEFAULT_BILLETON_DISCOUNT_AMOUNT',
    DEFAULT_ADMIN_PRICE = 'DEFAULT_ADMIN_PRICE',
    DEFAULT_CREDENTIALS_ADMIN_PRICE = 'DEFAULT_CREDENTIALS_ADMIN_PRICE',
    DEFAULT_DISCOUNT_AMOUNT = 'DEFAULT_DISCOUNT_AMOUNT',
    SPECIAL_PRICE_LIMIT = 'SPECIAL_PRICE_LIMIT',
    WHOLE_SALER_PRICE_LIMIT = 'WHOLE_SALER_PRICE_LIMIT',
    ADMINISTRATOR_PRICE_LIMIT = 'ADMINISTRATOR_PRICE_LIMIT',
    INITIAL_BOX_PERCENTAGE = 'INITIAL_BOX_PERCENTAGE',
    INVOICE_PERCENTAGE = 'INVOICE_PERCENTAGE',
    DEFAULT_PROMOTIONS_ACTIVE = 'DEFAULT_PROMOTIONS_ACTIVE',
    AMOUNT_CREDIT_CARD_DAY_PROMO = 'AMOUNT_CREDIT_CARD_DAY_PROMO',
    DEFAULT_EXPIRE_TIME_TO_CLAIMS = 'DEFAULT_EXPIRE_TIME_TO_CLAIMS',
    MINIMUM_AMOUNT_DEFERRED = 'MINIMUM_AMOUNT_DEFERRED',
    DEFAULT_TIME_AUTO_CANCEL_QUOTATIONS_NOT_STOCK = 'DEFAULT_TIME_AUTO_CANCEL_QUOTATIONS_NOT_STOCK',
    DEFAULT_TIME_TRANSFER_AUTOMATIC = 'DEFAULT_TIME_TRANSFER_AUTOMATIC',
    DEFAULT_DAYS_AUTO_CANCEL_CREDIT_MEMOS = 'DEFAULT_DAYS_AUTO_CANCEL_CREDIT_MEMOS',
    DEFAULT_SHOW_DEVOLUTIONS = 'DEFAULT_SHOW_DEVOLUTIONS'
}

export interface DefaultConfigurationPresenter {
    defaultConfigurationId?: string;
    defaultConfigurationType?: DefaultConfigurationType;
    generalConfigurationType?: DefaultConfigurationType;
    value?: any;
}

