import { Action } from '@ngrx/store';

import { InvoiceModel } from '../models/invoice.model';
import { Order } from '../models/order.model';
import { OrderLine } from '../models/orderline.model';
import { ProcessModel } from '../models/process.model';
import { Product } from '../models/product.model';
import { PromotionModel } from '../models/promotion-message.model';




export enum OrderActions {
    ADD_ORDER_LINE = 'ADD_ORDER_LINE',
    DELETE_ORDER_LINE = 'DELETE_ORDER_LINE',
    DELETE_ALL_ORDER_LINES = 'DELETE_ALL_ORDER_LINES',
    CHANGE_LINE_QUIANTITY = 'CHANGE_LINE_QUIANTITY',
    CHANGE_LINE_QUIANTITY_RECYCLING = 'CHANGE_LINE_QUIANTITY_RECYCLING',
    CHANGE_LINE_DISCOUNT = 'CHANGE_LINE_DISCOUNT',
    CHANGE_LINE_UNIT_MEASURE = 'CHANGE_LINE_UNIT_MEASURE',
    UPDATE_ORDER = 'EDIT_ORDER_LINE',
    ADD_MULTIPLE_LINES = 'ADD_MULTIPLE_LINES',
    CLEAR_EDIT_ORDER = 'CLEAR_EDIT_ORDER',
    ADD_INVOICE = 'ADD_INVOICE',
    AllOW_INVOICE = 'AllOW_INVOICE',
    DENY_INVOCE = 'DENY_INVOCE',
    ALLOW_INVOICE_PROCESS = 'ALLOW_INVOICE_PROCESS',
    DENY_INVOICE_PROCESS = 'DENY_INVOICE_PROCESS',
    CHANGE_PROCESS = 'CHANGE_PROCESS',
    SET_OBSERVATION = 'SET_OBSERVATION',
    CHANGE_MAX_QUANTITY = 'CHANGE_MAX_QUANTITY',
    CHANGE_AUTO_ADD_STATUS = 'CHANGE_AUTO_ADD_STATUS',
    SET_DISCOUNT_TO_CERO = 'SET_DISCOUNT_TO_CERO',
    SET_DISCOUNT_TO_CERO_IF_CREDIT_CARD = 'SET_DISCOUNT_TO_CERO_IF_CREDIT_CARD',
    SET_CLIENT_AGREEMENT_DISCOUNT = 'SET_CLIENT_AGREEMENT_DISCOUNT',
    SET_CREDIT_CARD_PAYMENT = 'SET_CREDIT_CARD_PAYMENT',
    SET_BILLETON = 'SET_BILLETON',
    SET_DEFAULT_PRICE = 'SET_DEFAULT_PRICE',
    SET_DEFAULT_PRICE_CREDIRED = 'SET_DEFAULT_PRICE_CREDIRED',
    ADD_INCREMENT = 'ADD_INCREMENT',
    SET_ADMIN_PRICE_DISCOUNT = 'SET_ADMIN_PRICE_DISCOUNT',
    APPLY_PROMOTIONS = 'APPLY_PROMOTIONS',
    CHANGE_SUGGESTED_PRICE = 'CHANGE_SUGGESTED_PRICE',
    CHANGE_OBSERVATION = 'CHANGE_OBSERVATION'
}

export class AddOrderLine implements Action {
    constructor(public orderLine: OrderLine, public commissionCard, public processPayment) { }
    readonly type = OrderActions.ADD_ORDER_LINE;
}

export class DeleteOrderLine implements Action {
    constructor(public orderLine: OrderLine) { }
    readonly type = OrderActions.DELETE_ORDER_LINE;
}
export class ChangeLineQuantity implements Action {
    constructor(public orderLine: OrderLine) { }
    readonly type = OrderActions.CHANGE_LINE_QUIANTITY;
}
export class ChangeLineDiscountRecycling implements Action {
    constructor(public orderLine: OrderLine) { }
    readonly type = OrderActions.CHANGE_LINE_QUIANTITY_RECYCLING;
}
export class ChangeLineSuggestedPrice implements Action {
  constructor(public orderLine: OrderLine) { }
  readonly type = OrderActions.CHANGE_SUGGESTED_PRICE;
}
export class ChangeLineDiscount implements Action {
    constructor(public orderLine: OrderLine) { }
    readonly type = OrderActions.CHANGE_LINE_DISCOUNT;
}
export class ChangeLineUnitMeasure implements Action {
    constructor(public orderLine: OrderLine) { }
    readonly type = OrderActions.CHANGE_LINE_UNIT_MEASURE;
}

export class DeleteAllOrderLines implements Action {
    readonly type = OrderActions.DELETE_ALL_ORDER_LINES;
}
export class UpdateOrder implements Action {
    constructor(public editOrder: Order) { }
    readonly type = OrderActions.UPDATE_ORDER;
}
export class AddMultipleLines implements Action {
    constructor(public orderLines: OrderLine[]) { }
    readonly type = OrderActions.ADD_MULTIPLE_LINES;
}
export class ClearEditOrder implements Action {
    constructor() { }
    readonly type = OrderActions.CLEAR_EDIT_ORDER;
}
export class AddInvoice implements Action {
    constructor(public invoice: InvoiceModel) { }
    readonly type = OrderActions.ADD_INVOICE;
}
export class AllowInvoice implements Action {
    constructor() { }
    readonly type = OrderActions.AllOW_INVOICE;
}
export class DenyInvoce implements Action {
    constructor() { }
    readonly type = OrderActions.DENY_INVOCE;
}
export class AllowInvoiceProcess implements Action {
    constructor() { }
    readonly type = OrderActions.ALLOW_INVOICE_PROCESS;
}
export class DenyInvoiceProcess implements Action {
    constructor() { }
    readonly type = OrderActions.DENY_INVOICE_PROCESS;
}
export class ChangeProcess implements Action {
    constructor(public process: ProcessModel) { }
    readonly type = OrderActions.CHANGE_PROCESS;
}
export class SetObservationInRedux implements Action {
    constructor(public observation: string) { }
    readonly type = OrderActions.SET_OBSERVATION;
}
export class ChangeMAxQuantity implements Action {
    constructor(public products: Product[]) { }
    readonly type = OrderActions.CHANGE_MAX_QUANTITY;
}
export class ChangeAutoAddStatus implements Action {
    constructor(public state: boolean) { }
    readonly type = OrderActions.CHANGE_AUTO_ADD_STATUS;
}
export class SetDiscountToCero implements Action {
    constructor() { }
    readonly type = OrderActions.SET_DISCOUNT_TO_CERO;
}
export class SetClientAgreementDiscount implements Action {
    constructor(public productsDiscount: any, public agreementType: boolean) { }
    readonly type = OrderActions.SET_CLIENT_AGREEMENT_DISCOUNT;
}
export class SetCreditCardPayment implements Action {
    constructor(public creditCardPayment, public commissionCard, public processPayment) { }
    readonly type = OrderActions.SET_CREDIT_CARD_PAYMENT;
}
export class SetBilleton implements Action {
    constructor(public isBilleton: boolean) { }
    readonly type = OrderActions.SET_BILLETON;
}
export class SetAdminPriceDiscount implements Action {
    constructor(public isAdminPriceDiscount: boolean) { }
    readonly type = OrderActions.SET_ADMIN_PRICE_DISCOUNT;
}

export class SetDefaultPrice implements Action {
    constructor() { }
    readonly type = OrderActions.SET_DEFAULT_PRICE;
}
export class SetDefaultPriceCredired implements Action {
    constructor() { }
    readonly type = OrderActions.SET_DEFAULT_PRICE_CREDIRED;
}
export class AddIncrement implements Action {
    constructor(public value: number) { }
    readonly type = OrderActions.ADD_INCREMENT;
}
export class ApplyPromotions implements Action {
    constructor(public promotions: PromotionModel[]) { }
    readonly type = OrderActions.APPLY_PROMOTIONS;
}


export type ActionsUnion =
    AddOrderLine |
    DeleteOrderLine |
    DeleteAllOrderLines |
    ChangeLineQuantity |
    ChangeLineDiscountRecycling |
    ChangeLineDiscount |
    AddMultipleLines |
    ClearEditOrder |
    UpdateOrder |
    AllowInvoice |
    DenyInvoce |
    AddInvoice |
    AllowInvoiceProcess |
    SetObservationInRedux |
    ChangeMAxQuantity |
    ChangeLineUnitMeasure |
    ChangeAutoAddStatus |
    SetDiscountToCero |
    SetClientAgreementDiscount |
    DenyInvoiceProcess |
    SetCreditCardPayment |
    SetBilleton |
    SetAdminPriceDiscount |
    SetDefaultPrice |
    SetDefaultPriceCredired |
    ChangeProcess |
    ApplyPromotions |
    AddIncrement |
    ChangeLineSuggestedPrice;
