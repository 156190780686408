
import { Injectable } from '@angular/core';

import { ClientService } from 'src/app/services/client.service';
import { ClientModel } from 'src/app/models/client.model';
import { DatePipe, formatDate } from '@angular/common';
import { Presenter } from 'src/app/core/presenter';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { CreditCardReportView } from '../credit-card-report.view';
import { ReportService } from '../../../../../../services/report.service';
import { Payment } from '../../../../../../models/payment.model';
import { CreditCardType } from 'src/app/models/creditcardtype.model';


@Injectable({
    providedIn: 'root',
})
export class CreditCardReportPresenter implements Presenter {
    view: CreditCardReportView;
    constructor(
        private clientService: ClientService,
        private reportService: ReportService,
        private store: Store<{ client: ClientModel }>
    ) { }

    onSearchClient() {
        this.view.blockUi();
        const searchValue = this.view.searchClient;
        const page = this.view.pageClient;
        const size = this.view.sizeClient;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.clients = clients;
        }).catch((error) => {
            if (error instanceof HttpErrorResponse) {
                this.view.showError('Atención', error.error.message);
            } else {
                this.view.showError('Atención', 'Algo salio mal');
            }
        });
    }

    clearClient() {
        this.view.showSelectedClient = false;
        this.view.selectedClient = null;
        this.findReports();
    }

    findReportsByClient() {
        if (this.view.selectedClient && typeof this.view.selectedClient !== 'string') {
            this.view.showSelectedClient = true;
            this.findReports();
        }
    }

    findReports() {
        let initDate = '';
        let endDate = '';
        let client = null;
        let docNo = '';
        const page = this.view.page;
        const size = this.view.size;
        const format = 'yyyy-MM-dd hh:mm:ss';
        const locale = 'en-US';
        if (this.view.initDate) {
            initDate = formatDate(this.view.initDate, format, locale);
        }
        if (this.view.endDate) {
            endDate = formatDate(this.view.endDate, format, locale);
        }
        if (this.view.selectedClient) {
            client = this.view.selectedClient;
        }
        if (this.view.documentNo) {
            docNo = this.view.documentNo;
        }

        this.reportService.getCreditCardReport(client, initDate, endDate, docNo, page, size).then(data => {
            this.view.reports = data['data'];
            this.view.totalElements = data['totalElements'];
        }).catch((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.view.showError('Atención', error.error.message);
                } else {
                    this.view.showError('Atención', 'Algo salio mal');
                }
            });

    }




}
