export enum DenominationEnum {
    COIN_ONE = 'COIN_ONE',
    COIN_FIVE = 'COIN_FIVE',
    COIN_TEN = 'COIN_TEN',
    COIN_TWENTY_FIVE = 'COIN_TWENTY_FIVE',
    COIN_FIFTY = 'COIN_FIFTY',
    COIN_DOLLAR = 'COIN_DOLLAR',
    BILL_ONE = 'BILL_ONE',
    BILL_TWO = 'BILL_TWO',
    BILL_FIVE = 'BILL_FIVE',
    BILL_TEN = 'BILL_TEN',
    BILL_TWENTY = 'BILL_TWENTY',
    BILL_FIFTY = 'BILL_FIFTY'
}


export interface DenominationEquivalent {
    denomination?: DenominationEnum;
    equivalent?: number;
    total?: number;
    quantity?: number;
    name?: string;
}
