import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WareHouse } from '../models/product.model';
import { environment } from 'src/environments/environment';
import { DataProvider } from './data.provider';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  deafaultWarehouse$ = new BehaviorSubject<WareHouse>(null);
  constructor(private http: HttpClient, private dataProvider: DataProvider) {
  }

  get deafaultWarehouse(): WareHouse {
    return this.deafaultWarehouse$.value;
  }

  set deafaultWarehouse(wareHouse: WareHouse) {
    this.deafaultWarehouse$.next(wareHouse);
  }
  getDefaultWarehouse() {
    this.http.get(environment.apiUrl + '/warehouse/local ').subscribe((defWare: WareHouse) => {
      this.dataProvider.defaultWarehouse = defWare;
      this.deafaultWarehouse = defWare;
    });
  }

}
