import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Decimal from 'decimal.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { isEmpty, timeout } from 'rxjs/operators';
import { UserPresenter } from 'src/app/models/fulluser.model';
import { DefaultconfigurationsService, GeneralConfigurations } from 'src/app/services/defaultconfigurations.service';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';
import Swal from 'sweetalert2';

import { InventoryModel } from '../../../../../models/inventory.model';
import { MeasureConversionComboModel } from '../../../../../models/measure-combo.model';
import { MeasureConversionModel, MeasureUnitPresenter } from '../../../../../models/measure-conversion.model';
import { Product, WareHouse } from '../../../../../models/product.model';
import {
  ReasonClaim,
  DevolutionFile,
  TransferDetailModel,
  TransferMovementType,
  TransfersModel,
  TransferStatus,
  DevolutionReasons,
} from '../../../../../models/transfer.mode';
import { DataProvider } from '../../../../../services/data.provider';
import { SettingPresenter } from '../../../shared/setting/presenter/setting.presenter';
import { TransferService } from './services/transfer.service';

@Injectable({
  providedIn: 'root',
})
export class TransferProvider {
  public refAprove;
  public refAproveSupplying;
  filteredRequestedLines: TransferDetailModel[] = [];
  requestedLines: TransferDetailModel[] = [];
  lines: TransferDetailModel[] = [];
  linesSupplying: TransferDetailModel[] = [];
  selectedTransferLine: TransferDetailModel = {};
  selectedWarehouse: WareHouse;
  actualWarehouse: WareHouse;
  error = '';
  errorClaim = '';
  errorReturn = '';
  totalSubTotal: number;

  selectedProduct: Product;

  transfers: TransfersModel[] = [];
  searchTransfer: TransfersModel[] = [];
  transfersReport: TransfersModel[] = [];
  searchSupplying: TransfersModel[] = [];
  filteredTransfers: TransfersModel[] = [];
  selectedTransfer: TransfersModel;
  transfer: TransfersModel;
  searchUser: string;
  pageUser: number;
  sizeUser: number;
  users: UserPresenter[];
  selectedUser: UserPresenter;
  initDate: Date;
  endDate: Date;
  documentNo: string;
  showSelectedUser: boolean;
  page = 0;
  size = 20;
  totalElements = 0;
  totalElementsUser = 0;
  transferMovementTypeSelected: string[];
  movementTypeSelected: { name: string; value: TransferMovementType };
  transferStatusSelected: TransferStatus;
  wareHouses: WareHouse[] = [];
  originSelected: WareHouse;
  destinySelected: WareHouse;
  observation: string;
  linesPendingToInvoice$ = new BehaviorSubject<TransferDetailModel[]>([]);
  filteredLinesToInvoice: TransferDetailModel[] = [];

  searchProd = '';
  pageProd = 0;
  sizeProd = 30;
  productsProd: Product[];
  selectedProductProd: Product;
  totalElementsProd: number;
  addToProductQuestProd: boolean;

  selectedTransferClaim: TransfersModel;
  searchTransferClaim = '';
  linesClaim: TransferDetailModel[] = [];
  linesReturn: TransferDetailModel[] = [];
  transfersClaim: TransfersModel[] = [];
  transfersDevolution: TransfersModel[] = [];
  transferId: string;
  sequential: string;
  updateDevolution: string;
  showupdate = false;
  packages = 0;
  dateToSend = new Date();
  transferMovementTypes: { name: string, value: TransferMovementType }[] = [];


  @BlockUI() blockUI: NgBlockUI;
  reasonsClaim: { label: string; value: ReasonClaim }[] = [
    { label: '', value: ReasonClaim.EMPTY },
    { label: 'Garantía', value: ReasonClaim.WARRANTY },
    { label: 'Faltantes', value: ReasonClaim.MISSING },
    { label: 'Sobrantes', value: ReasonClaim.SURPLUS },
    { label: 'Mal estado', value: ReasonClaim.DISREPAIR },
    { label: 'Incompleto', value: ReasonClaim.INCOMPLETE },
    { label: 'Cruce de material', value: ReasonClaim.CROSSING },
  ];
  reasonsDevolution: { label: string; value: DevolutionReasons }[] = [
    { label: '', value: DevolutionReasons.EMPTY },
    { label: 'BAJA ROTACION', value: DevolutionReasons.BAJAROTACION },
    { label: 'SOBRE STOCK', value: DevolutionReasons.SOBRESTOCK },
  ];

  constructor(
    private dataProvider?: DataProvider,
    private transferService?: TransferService,
    private messageService?: MessageService,
    private reportService?: ReportService,
    public dialogService?: DialogService,
    public settingPresenter?: SettingPresenter,
    public productService?: ProductService,
    public router?: Router,
    public defaultConfigurationService?: DefaultconfigurationsService,
  ) {
    this.transferMovementTypes = [
      // { name: 'Venta Puntual', value: TransferMovementType.BETWEEN_PDV },
      { name: 'Transferencia entre PDV', value: TransferMovementType.SUPPLYING_BETWEEN_PDV },
      { name: 'Reclamo de Materiales', value: TransferMovementType.CLAIM },
      // { name: 'Devolución', value: TransferMovementType.DEVOLUTION }
    ];
    this.movementTypeSelected = this.transferMovementTypes[0];
  }
  get linesPendingToInvoice(): TransferDetailModel[] {
    return this.linesPendingToInvoice$.value;
  }
  set linesPendingToInvoice(transferDetailModel: TransferDetailModel[]) {
    this.linesPendingToInvoice$.next(transferDetailModel);
  }
  showWarn(title: string, detail: string) {
    this.showToast('warn', title, detail);
  }
  showError(title: string, detail: string) {
    this.showToast('error', title, detail);
  }
  showInfo(title: string, detail: string) {
    this.showToast('info', title, detail);
  }
  showSuccess(title: string, detail: string) {
    this.showToast('success', title, detail);
  }
  showToast(type: string, title: string, detail: string) {
    this.messageService.add({
      key: 'tst',
      severity: type,
      summary: title,
      detail: detail,
    });
  }
  getProductsInPdv(product: Product) {
    this.selectedProduct = product;
    this.requestedLines = [];
    this.filteredRequestedLines = [];
    this.getProductStock(product);
  }

  getProductStock(product: Product) {
    this.blockUi();
    this.transferService.getAvailableStock(product.referenceId, this.movementTypeSelected.value).subscribe(
      (res: InventoryModel[]) => {
        res.forEach((inventory) => {
          const line: TransferDetailModel = {
            availableQuantity: inventory.availableQuantity,
            requestedEnteredQuantity: 0,
            loading: false,
            productPresenter: product,
            wareHouse: inventory.warehousePresenter,
            error: '',
            measureUnits: this.getMeasures(product),
            selectedMeasure: this.getDefault(product),
          };
          this.requestedLines.push(line);
        });
        this.filterRequestedLines();
        if (this.filteredRequestedLines.length <= 0) {
          this.showError('', 'Sin disponibilidad de stock');
        }
      },
      () => {
        this.showError('', 'Error al buscar stock');
      }
    );
  }

  getDefault(product: Product): MeasureConversionModel {
    let comboMeasure: MeasureConversionModel = null;
    if (product.measureUnitConversionPresenters) {
      product.measureUnitConversionPresenters.forEach((unit) => {
        if (unit.default) {
          comboMeasure = unit;
          return comboMeasure;
        }
      });
    }
    return comboMeasure;
  }

  getMeasures(product: Product): MeasureConversionComboModel[] {
    const comboMeasure: MeasureConversionComboModel[] = [];
    if (product.measureUnitConversionPresenters) {
      product.measureUnitConversionPresenters.forEach((unit) => {
        comboMeasure.push({
          label: unit.measureUnitPresenterTo.name,
          value: unit,
        });
      });
    }
    return comboMeasure;
  }

  addLine(line: TransferDetailModel) {
    if (!line.availableQuantity) {
      this.showWarn('Advertencia', 'Producto sin stock disponible');
      return;
    }
    if (this.selectedWarehouse && this.selectedWarehouse.referenceId !== line.wareHouse.referenceId) {
      this.showWarn('Advertencia', 'No puede agregar líneas de diferente almacén');
      return;
    }
    if (this.lines.length >= 2 && this.movementTypeSelected.value === TransferMovementType.BETWEEN_PDV) {
      this.showWarn('Advertencia', 'No puede agregar más de dos líneas');
      return;
    }
    line.requestedEnteredQuantity = 1;
    line.allowPartial = false;
    line.productPresenter.subTotal = line.productPresenter.priceWithoutTax;
    this.lines.push(line);
    this.checkWarehouse();
    this.validateLine(line);
  }

  deleteLine(line: TransferDetailModel) {
    this.lines = this.lines.filter(
      (l) =>
        !(
          l.productPresenter.productId === line.productPresenter.productId &&
          l.wareHouse.referenceId === line.wareHouse.referenceId
        )
    );
    this.checkWarehouse();
  }
  deleteClaimLine(line: TransferDetailModel) {
    this.linesClaim = this.linesClaim.filter(
      (l) => !(l.productPresenter.productId === line.productPresenter.productId)
    );
    this.linesClaim.forEach(linec => this.validateLineClaim(linec));
  }

  checkWarehouse() {
    if (this.lines.length) {
      this.selectedWarehouse = this.lines[0].wareHouse;
    } else {
      this.selectedWarehouse = null;
    }
    this.filterRequestedLines();
  }

  filterRequestedLines() {
    this.filteredRequestedLines = this.requestedLines.filter(
      (l) =>
        !this.lines.find(
          (line) =>
            line.productPresenter.productId === l.productPresenter.productId &&
            line.wareHouse.referenceId === l.wareHouse.referenceId
        )
    );
  }

  blockUi() {
    SettingPresenter.doingSomething = true;
    this.blockUI.start('Cargando...');
  }

  findTransfers(nameComponent: string) {
    let initDate = '';
    let endDate = '';
    let user = null;
    let docNo = '';
    let transferMovementTypeSelected = null;
    let transferStatusSelected = null;
    let originSelected = null;
    let destinySelected = null;
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    if (this.initDate) {
      initDate = formatDate(this.initDate, format, locale);
    }
    if (this.endDate) {
      endDate = formatDate(this.endDate, format, locale);
    }
    if (this.selectedUser) {
      user = this.selectedUser.userId;
    }
    if (this.documentNo) {
      docNo = this.documentNo;
    }
    if (this.transferMovementTypeSelected) {
      transferMovementTypeSelected = this.transferMovementTypeSelected;
    }
    if (this.transferStatusSelected) {
      transferStatusSelected = this.transferStatusSelected;
    }
    if (this.originSelected) {
      originSelected = this.originSelected.warehouseId;
    }
    if (this.destinySelected) {
      destinySelected = this.destinySelected.warehouseId;
    }
    this.transfers = [];
    this.blockUi();
    this.transferService.getTransfers(
      user,
      initDate,
      endDate,
      docNo,
      this.page,
      this.size,
      transferMovementTypeSelected,
      transferStatusSelected,
      originSelected,
      destinySelected
    ).subscribe((data: any) => {
      if (nameComponent === 'searchTransfer') {
        this.searchTransfer = data['data'];
        this.transfers = data['data'];
      } else if (nameComponent === 'transfersReport') {
        this.transfersReport = data['data'];
        this.transfers = data['data'];
      } else if (nameComponent === 'searchSupplying') {
        this.searchSupplying = data['data'];
        this.transfers = data['data'];
      } else if (nameComponent === 'searchTransferClaim') {
        this.transfersClaim = data['data'];
        this.transfers = data['data'];
      } else if (nameComponent === 'searchTransferDevolution') {
        this.transfersDevolution = data['data'];
        this.transfers = data['data'];
      }
      if (!this.transfers.length) {
        this.showInfo('', 'Búsqueda sin registros');
      }
      this.totalElements = data.totalElements;
    },
      () => {
        this.transfers = [];
        this.showError('', 'Error al buscar transferencias');
      }
    );
  }

  validateLine(line: TransferDetailModel) {
    line.productPresenter.priceWithoutTax = line.selectedMeasure.price;
    line.maxQuantity = new Decimal(line.availableQuantity || 0)
      .mul(line.selectedMeasure.multiplyRate)
      .toNumber();
    line.multiplyRate = line.selectedMeasure.multiplyRate;
    line.divideRate = line.selectedMeasure.divideRate;
    line.measureUnitPresenter = line.selectedMeasure.measureUnitPresenterTo;
    line.requestedConvertedQuantity = new Decimal(
      line.requestedEnteredQuantity || 0
    )
      .mul(line.selectedMeasure.divideRate)
      .toNumber();
    line.productPresenter.subTotal = new Decimal(
      line.requestedEnteredQuantity || 0
    )
      .mul(line.productPresenter.priceWithoutTax)
      .toDecimalPlaces(2);
    line.error = '';
    if (!line.requestedEnteredQuantity || line.requestedEnteredQuantity < 1) {
      line.error = 'Cantidad mínima 1';
    }
    if (line.requestedEnteredQuantity > line.maxQuantity) {
      line.error = 'Cantidad no disponible en punto de venta';
    }

    this.checkErrors();
  }

  checkErrors() {
    this.error = '';
    this.totalSubTotal = 0;
    this.lines.forEach((line) => {
      this.totalSubTotal = new Decimal(this.totalSubTotal)
        .add(line.productPresenter.subTotal)
        .toDecimalPlaces(2)
        .toNumber();
      if (line.error) {
        this.error = 'No se puede realizar la solicitud';
      }
    });
  }

  onSearchUser() {
    if (this.pageUser < 0) {
      return;
    }
    if (!this.sizeUser || this.sizeUser < 1) {
      return;
    }
    this.reportService.usersByCriterion(this.searchUser, this.pageUser, this.sizeUser).then((data) => {
      this.users = data['data'];
      this.totalElementsUser = data['totalElements'];
    })
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          this.showError('Atención', error.error.message);
        } else {
          this.showError('Atención', 'Algo salio mal');
        }
      });
  }

  saveTransfer() {
    const transfer: TransfersModel = this.createDocument();
    if (transfer.observation && transfer.observation.length > 250) {
      this.showWarn('Advertencia', 'Observación no puede ser mayor a 250 caractéres');
      return;
    }
    this.blockUi();
    this.transferService.saveTransfer(transfer).subscribe(
      (res: TransfersModel) => {
        this.clear();
        this.showSuccess('Transferencia', 'Solicitada con éxito');
      },
      (error: HttpErrorResponse) => {
        this.showError('Error al  asignar estado', error.error.message);
      }
    );
  }

  createDocument(): TransfersModel {
    let lineNumber = 1;
    this.lines.forEach((line) => {
      line.lineNumber = lineNumber;
      lineNumber++;
    });

    const transfer: TransfersModel = {
      number: null,
      transferId: null,
      sequential: null,
      origin: this.selectedWarehouse,
      destiny: this.dataProvider.defaultWarehouse,
      originReference: null,
      transferStatus: TransferStatus.REQUESTED,
      transferMovementType:
        this.movementTypeSelected.value === TransferMovementType.BETWEEN_PDV
          ? TransferMovementType.BETWEEN_PDV
          : TransferMovementType.SUPPLYING_BETWEEN_PDV,
      userApproval: this.selectedUser,
      active: true,
      observation: this.observation,
      transferDetailPresenters: this.lines,
      emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
    };
    return transfer;
  }
  createClaimDocument(): TransfersModel {
    let lineNumber = 1;
    this.linesClaim.forEach((line) => {
      if (line.claimReason === ReasonClaim.CROSSING && line.crossProductPresenter) {
        line.lineObservation = line.lineObservation
          .concat(' Producto: ' + line.crossProductPresenter.referenceCode + ' - ' + line.crossProductPresenter.name);
      }
      line.lineNumber = lineNumber;
      lineNumber++;
    });
    const transfer: TransfersModel = {
      number: this.selectedTransferClaim.number,
      transferId: this.selectedTransferClaim.transferId,
      sequential: this.selectedTransferClaim.sequential,
      origin: this.selectedTransferClaim.origin,
      destiny: this.selectedTransferClaim.destiny,
      originReference: this.selectedTransferClaim.originReference,
      transferStatus: TransferStatus.SENT,
      transferMovementType: this.selectedTransferClaim.transferMovementType,
      userApproval: this.selectedTransferClaim.userApproval,
      active: true,
      observation: this.observation,
      transferDetailPresenters: this.linesClaim,
      emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
      referenceId: this.selectedTransferClaim.referenceId,
      page: this.selectedTransferClaim.page,
    };
    return transfer;
  }

  clear() {
    this.lines = [];
    this.filteredRequestedLines = [];
    this.selectedWarehouse = null;
    this.selectedProduct = null;
    this.selectedProductProd = null;
    this.observation = '';
    this.transfersClaim = [];
    this.linesClaim = [];
    this.searchTransferClaim = null;
    this.linesReturn = [];
    this.transfersDevolution = [];
    this.transferId = '';
    this.sequential = '';
    this.updateDevolution = '';
    this.showupdate = false;
    this.dateToSend = new Date();
    this.packages = 0;
  }
  changeItemQuiantity(transferLine: TransferDetailModel) {
    this.selectedTransferLine = transferLine;
    if (this.selectedTransferLine) {
      const line = this.selectedTransferLine;
      if (
        !line.requestedEnteredQuantity ||
        isNaN(line.requestedEnteredQuantity)
      ) {
        this.showError('', 'cantidad erronea');
        if (line.productPresenter.availableQuantity > 1) {
          line.requestedEnteredQuantity = 1;
        } else {
          line.requestedEnteredQuantity = line.acceptedEnteredQuantity;
        }
      } else {
        if (line.requestedEnteredQuantity <= 0) {
          this.showError('', 'cantidad erronea');
          line.requestedEnteredQuantity = 1;
        }
      }

      line.requestedEnteredQuantity = line.acceptedEnteredQuantity;
    }
  }

  validateRequestedLine(line: TransferDetailModel) {
    line.acceptedConvertedQuantity = new Decimal(
      line.acceptedEnteredQuantity || 0
    )
      .mul(line.divideRate)
      .toNumber();
    line.error = '';
    if (
      line.acceptedEnteredQuantity === null ||
      line.acceptedEnteredQuantity < 1
    ) {
      line.error = 'Cantidad incorrecta';
    }
    if (line.acceptedEnteredQuantity > line.requestedEnteredQuantity) {
      line.error = 'Cantidad superior a la requerida';
    }
    if (!line.acceptedEnteredQuantity) {
      line.error = 'Cantidad incorrecta';
    }

    this.checkErrorsRequested();
  }
  checkErrorsRequested() {
    this.error = '';
    this.transfer.transferDetailPresenters.forEach((line) => {
      if (line.error) {
        this.error = 'No se puede realizar la solicitud';
      }
    });
  }

  setTransferStatus(transfer: TransfersModel) {
    if (transfer.originObservation && transfer.originObservation.length > 250) {
      this.showWarn('Advertencia', 'Observación no puede ser mayor a 250 caractéres');
      return;
    }
    transfer.transferDetailPresenters.forEach((line) => {
      if (
        line.acceptedEnteredQuantity !== line.requestedEnteredQuantity &&
        transfer.transferStatus === TransferStatus.ACCEPTED
      ) {
        transfer.transferStatus = TransferStatus.PARTIAL_ACCEPTED;
      }
    });
    this.blockUi();
    this.transferService.setTransferStatus(transfer).subscribe((data) => {
      if (
        transfer.transferStatus === TransferStatus.ACCEPTED ||
        transfer.transferStatus === TransferStatus.PARTIAL_ACCEPTED
      ) {
        this.showSuccess('', 'Transferencia aprobada');
      } else {
        this.showSuccess('', 'Transferencia rechazada');
      }
      this.closeView();
      this.findTransfers('searchTransfer');
      this.showPdf(data);
    },
      (error: HttpErrorResponse) => {
        this.showError('Error al  guardar transferencia', error.error.message);
      }
    );
  }
  getTransferPdf(transferId: string) {
    this.blockUi();
    this.transferService.getPdf(transferId).subscribe((data) => {
      this.showPdf(data);
    }, () => {
      this.showError('', 'Error imprimiendo Transferencia');
    });
  }
  getDevolutionPdf(transferId: string) {
    this.blockUi();
    this.transferService.getDevolutionPdf(transferId).subscribe((data) => {
      this.showPdf(data);
    }, () => {
      this.showError('', 'Error imprimiendo devolución');
    });
  }
  showPdf(data) {
    const pdfAsDataUri = 'data:application/pdf;base64,' + data.pdf;
    const array = this.convertDataURIToBinary(pdfAsDataUri);
    const pdfByte = new Blob([array], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(pdfByte);

    if (GeneralConfigurations.blink) {
      const iframe = document.createElement('iframe');
      iframe.src = fileURL;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } else {
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
    }
  }
  convertDataURIToBinary(dataURI) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
  closeView() {
    this.refAprove.close();
  }
  closeViewSupplying() {
    this.refAproveSupplying.close();
  }

  getTransferWarehouses() {
    this.transferService.getTransferWarehouses().subscribe((res: WareHouse[]) => {
      this.wareHouses = res;
      this.wareHouses = this.wareHouses.sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.wareHouses.unshift({ name: 'TODAS', warehouseId: null });
    },
      () => {
        this.showError('', 'Error al buscar transferencias');
      }
    );
  }

  getTransferDetailsToInvoice() {
    this.transferService.getTransferDetailsToInvoice().subscribe(
      (res: TransferDetailModel[]) => {
        res.forEach((line) => {
          line.productPresenter.priceWithoutTax = new Decimal(
            line.productPresenter.priceWithoutTax
          )
            .toDecimalPlaces(6)
            .toNumber();
          line.productPresenter.priceLimitWithoutTax = new Decimal(
            line.productPresenter.priceLimitWithoutTax
          )
            .toDecimalPlaces(6)
            .toNumber();
          line.productPresenter.basePrice = new Decimal(
            line.productPresenter.basePrice
          )
            .toDecimalPlaces(6)
            .toNumber();
          line.productPresenter.specialPrice = line.productPresenter
            .specialPrice
            ? new Decimal(line.productPresenter.specialPrice)
              .toDecimalPlaces(6)
              .toNumber()
            : line.productPresenter.priceLimitWithoutTax;
          line.productPresenter.wholesalerPrice = line.productPresenter
            .wholesalerPrice
            ? new Decimal(line.productPresenter.wholesalerPrice)
              .toDecimalPlaces(6)
              .toNumber()
            : line.productPresenter.priceLimitWithoutTax;
          line.productPresenter.administratorPrice = line.productPresenter
            .administratorPrice
            ? new Decimal(line.productPresenter.administratorPrice)
              .toDecimalPlaces(6)
              .toNumber()
            : line.productPresenter.priceLimitWithoutTax;
          line.productPresenter.measureUnitConversionPresenters.forEach(
            (unitConvertion) => {
              unitConvertion.price = new Decimal(unitConvertion.price)
                .toDecimalPlaces(6)
                .toNumber();
              unitConvertion.priceLimit = new Decimal(unitConvertion.priceLimit)
                .toDecimalPlaces(6)
                .toNumber();
              unitConvertion.basePrice = new Decimal(unitConvertion.basePrice)
                .toDecimalPlaces(6)
                .toNumber();
              unitConvertion.specialPrice = unitConvertion.specialPrice
                ? new Decimal(unitConvertion.specialPrice)
                  .toDecimalPlaces(6)
                  .toNumber()
                : unitConvertion.priceLimit;
              unitConvertion.wholesalerPrice = unitConvertion.wholesalerPrice
                ? new Decimal(unitConvertion.wholesalerPrice)
                  .toDecimalPlaces(6)
                  .toNumber()
                : unitConvertion.priceLimit;
              unitConvertion.administratorPrice =
                unitConvertion.administratorPrice
                  ? new Decimal(unitConvertion.administratorPrice)
                    .toDecimalPlaces(6)
                    .toNumber()
                  : unitConvertion.priceLimit;
            }
          );
        });
        this.filteredLinesToInvoice = res;
        this.linesPendingToInvoice = res;
        if (this.dataProvider.quotationId != null) {
          if (this.dataProvider.quotationId.length > 6) {
            this.defaultConfigurationService.showAdminPriceDiscount = false;
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.showError(
          'Error al  buscar lineas de transferencias',
          error.error.message
        );
      }
    );
  }

  onSearchProductPos() {
    const searchValue = this.searchProd;
    const page = this.pageProd;
    const size = this.sizeProd;
    const prceListId = this.settingPresenter.view.selectedPrice.priceListId;
    if (searchValue.length < 4) {
      return;
    }
    if (page < 0) {
      return;
    }
    if (!size || size < 1) {
      return;
    }
    this.productService.searchProduct(searchValue, page, size, prceListId, null).then((data) => {
      const title: Product = {
        referenceCode: 'CÓDIGO',
        name: 'NOMBRE',
      };
      const products: Product[] = data['data'];
      this.totalElementsProd = data['totalElements'];
      if (products.length <= 0) {
        this.showError('', 'Búsqueda sin registros');
        return;
      }
      products.unshift(title);
      this.productsProd = products;
    }).catch((error) => {
      if (error instanceof HttpErrorResponse) {
        this.showError('Atención', error.error.message);
      } else {
        this.showError('Atención', 'Algo salio mal');
      }
    });
  }

  validateSupplyingdLine(line: TransferDetailModel) {
    line.missingQuantity =
      line.realQuantity > line.quantity ? 0 : line.quantity - line.realQuantity;
    line.surplusQuantity =
      line.realQuantity < line.quantity ? 0 : line.realQuantity - line.quantity;
    line.error = '';
    const egress = new Decimal(line.disrepairQuantity || 0)
      .add(line.crossingQuantity || 0)
      .add(line.incompleteQuantity || 0)
      .toNumber();
    line.ingress = new Decimal(line.realQuantity || 0).minus(egress).toNumber();
    if (line.realQuantity > line.quantity) {
      line.missingQuantity = 0;
    }
    if (!line.realQuantity && line.realQuantity !== 0) {
      line.error = 'Cantidad real incorrecta';
    }
    if (!line.disrepairQuantity && line.disrepairQuantity !== 0) {
      line.error = 'Cantidad mal estado incorrecta';
    }
    if (!line.crossingQuantity && line.crossingQuantity !== 0) {
      line.error = 'Cantidad cruce incorrecta';
    }
    if (!line.incompleteQuantity && line.incompleteQuantity !== 0) {
      line.error = 'Cantidad incompleto incorrecta';
    }
    if (egress > line.realQuantity) {
      line.error =
        'Sumatoria de mal estado, cruce y incompleto mayor a cantidad real';
    }
    if (line.disrepairQuantity > line.realQuantity) {
      line.error = 'Cantidad mal estado mayor a cantidad real';
    }
    if (line.crossingQuantity > line.realQuantity) {
      line.error = 'Cantidad cruce mayor a cantidad real';
    }
    if (line.incompleteQuantity > line.realQuantity) {
      line.error = 'Cantidad incompleto mayor a cantidad real';
    }
    if (line.lineObservation && line.lineObservation.length > 255) {
      line.error = 'Observación no puede ser mayor a 250 caractéres';
    }

    this.checkErrorsSupplying();
  }
  checkErrorsSupplying() {
    this.error = '';
    this.transfer.transferDetailPresenters.forEach((line) => {
      if (line.error) {
        this.error = 'No se puede realizar la solicitud';
      }
    });
  }

  saveSupplying() {
    if (this.transfer.observation && this.transfer.observation.length > 250) {
      this.showWarn('Advertencia', 'Observación no puede ser mayor a 250 caractéres');
      return;
    }
    this.blockUi();
    this.transferService.supplyReception(this.transfer).subscribe((transfer: TransfersModel) => {
      if (transfer.missingClaimReception || transfer.disrepairClaimReception
        || transfer.crossingClaimReception || transfer.incompleteClaimReception
      ) {
        this.showClaimConfirmation(transfer.missingClaimReception, transfer.disrepairClaimReception,
          transfer.crossingClaimReception, transfer.incompleteClaimReception, null, null);
      }
      this.showSuccess('Transferencia', 'Confirmada con éxito');
      this.closeViewSupplying();
      this.findTransfers('searchSupplying');
    },
      (error: HttpErrorResponse) => {
        this.showError('Error al  confirmartransferencia', error.error.message);
      }
    );
  }

  evaluateMovementType() {
    this.lines = [];
    this.selectedProduct = null;
    this.selectedWarehouse = null;
    this.filteredRequestedLines = [];
    this.selectedTransferClaim = {};
    this.searchTransferClaim = '';
    this.linesClaim = [];
    this.transfersClaim = [];
    this.transferId = null;
    this.sequential = null;
    this.updateDevolution = null;
    this.showupdate = false;
    this.linesReturn = [];
    this.transfersDevolution = [];
    this.transferId = null;
    this.sequential = null;
    this.updateDevolution = null;
    this.showupdate = false;
    this.dateToSend = new Date();
    this.packages = 0;
  }
  syncroniceTransfer(transfer: TransfersModel) {
    this.blockUi();
    this.transferService.syncroniceTransfer(transfer.transferId).subscribe(() => {
      this.showSuccess('', 'Resincronización exitósa.');
    },
      () => {
        this.showError('', 'Ocurrió un error al resincronizar la información.');
      }
    );
  }

  getTransferToGenerateClaim(transfer: TransfersModel) {
    if (!transfer.transferId) {
      return;
    }
    this.blockUi();
    this.transferService.getTransferById(transfer.transferId, true).subscribe((res: TransfersModel) => {
      if (res.isExpired) {
        this.reasonsClaim = [];
        this.reasonsClaim = [
          { label: '', value: ReasonClaim.EMPTY },
          { label: 'Garantía', value: ReasonClaim.WARRANTY },
          { label: 'Mal estado', value: ReasonClaim.DISREPAIR },
          { label: 'Incompleto', value: ReasonClaim.INCOMPLETE }
        ];
      } else {
        this.reasonsClaim = [];
        this.reasonsClaim = [
          { label: '', value: ReasonClaim.EMPTY },
          { label: 'Garantía', value: ReasonClaim.WARRANTY },
          { label: 'Faltantes', value: ReasonClaim.MISSING },
          { label: 'Sobrantes', value: ReasonClaim.SURPLUS },
          { label: 'Mal estado', value: ReasonClaim.DISREPAIR },
          { label: 'Incompleto', value: ReasonClaim.INCOMPLETE },
          { label: 'Cruce de material', value: ReasonClaim.CROSSING },
        ];
      }
      this.selectedTransferClaim = res;
      this.linesClaim = res.transferDetailPresenters;
      this.addLineClaim();
    },
      () => {
        this.showError('Atención', 'Algo salio mal');
      }
    );
  }
  addLineClaim() {
    this.linesClaim.forEach((claim) => {
      claim.claimQuantity = 0;
      claim.claimReason = null;
      this.validateLineClaim(claim);
    });
  }

  validateLineClaim(line: TransferDetailModel) {
    line.disponible = line.realQuantity - line.claimedProductStock;
    line.error = '';
    line.cross = '';
    if (line.claimReason !== ReasonClaim.CROSSING && line.crossProductPresenter) {
      line.showSelectedCrossProduct = false;
      line.crossProductPresenter = null;
    }
    if (line.claimReason === ReasonClaim.CROSSING && !line.crossProductPresenter) {
      line.cross = 'Seleccione el producto cruzado';
    }
    if (!line.lineObservation) {
      line.error = 'Observación requerida';
    }
    if (line.lineObservation && line.lineObservation.length <= 10) {
      line.error = 'Observación demasiado corta';
    }
    if (!line.claimReason || line.claimReason === ReasonClaim.EMPTY) {
      line.error = 'Razón requerida';
    }
    if (!line.claimQuantity || line.claimQuantity < 1) {
      line.error = 'Cantidad de reclamo incorrecto';
    }
    if (line.claimQuantity > line.disponible) {
      line.error = 'Cantidad de reclamo no puede ser mayor a cantidad disponible';
    }
    if (line.claimQuantity > line.availableProductStock) {
      line.error = 'Cantidad de reclamo no puede ser mayor a cantidad de stock';
    }

    this.checkErrorsClaim();
  }

  checkErrorsClaim() {
    this.errorClaim = '';
    this.linesClaim.forEach((line) => {
      if (line.error) {
        this.errorClaim = 'No se puede realizar la solicitud';
      }
    });
  }

  generateClaim() {
    const transfer: TransfersModel = this.createClaimDocument();
    if (transfer.observation && transfer.observation.length > 250) {
      this.showWarn('Advertencia', 'Observación no puede ser mayor a 250 caractéres');
      return;
    }
    this.blockUi();
    this.transferService.saveClaim(transfer).subscribe((res: TransfersModel) => {
      this.clear();
      if (res.missingClaimReception || res.disrepairClaimReception
        || res.crossingClaimReception || res.incompleteClaimReception
        || res.warrantyClaimReception || res.surplusClaimReception
      ) {
        this.showClaimConfirmation(res.missingClaimReception, res.disrepairClaimReception,
          res.crossingClaimReception, res.incompleteClaimReception,
          res.warrantyClaimReception, res.surplusClaimReception);
      }
    }, (error: HttpErrorResponse) => {
      this.showError('Error', error.error.message);
    });
  }
  showConfirmationMessage(sequential: string) {
    Swal.fire({
      text: 'Se generó la devolución N° ' + sequential,
      showCancelButton: false,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.value) {
        this.showSuccess('Reclamo N°' + sequential, 'Generado con éxito');
      }
    });
  }
  showClaimConfirmation(
    missingClaimReception: number,
    disrepairClaimReception: number,
    crossingClaimReception: number,
    incompleteClaimReception: number,
    warrantyClaimReception: number,
    surplusClaimReception: number
  ) {
    let message = '';

    message = crossingClaimReception
      ? '<p>Reclamo por cruce <strong>' +
      'N° ' +
      crossingClaimReception +
      '</strong></p>'
      : message;
    message = missingClaimReception
      ? message +
      '<p>Reclamo por faltante <strong>' +
      'N° ' +
      missingClaimReception +
      '</strong></p>'
      : message;
    message = disrepairClaimReception
      ? message +
      '<p>Reclamo por mal estado <strong>' +
      'N° ' +
      disrepairClaimReception +
      '</strong></p>'
      : message;
    message = incompleteClaimReception
      ? message +
      '<p>Reclamo por incompleto <strong>' +
      'N° ' +
      incompleteClaimReception +
      '</strong></p>'
      : message;
    message = warrantyClaimReception
      ? message +
      '<p>Reclamo por incompleto <strong>' +
      'N° ' +
      warrantyClaimReception +
      '</strong></p>'
      : message;
    message = surplusClaimReception
      ? message +
      '<p>Reclamo por incompleto <strong>' +
      'N° ' +
      surplusClaimReception +
      '</strong></p>'
      : message;
    Swal.fire({
      html: message,
      showCancelButton: false,
      confirmButtonColor: '#00687d',
      confirmButtonText: 'Aceptar',
      type: 'success',
    });
  }


  // LÓGICA PARA DEVOLUCIONES

  addProductToLineReturn(product: Product, fileLine: TransferDetailModel) {
    if (product && !product.isHomologate) {
      this.showWarn('No se puede agregar', 'Producto ' + product.referenceCode + ' no homologado');
      return;
    }
    if (fileLine && !fileLine.productPresenter.isHomologate) {
      this.showWarn('No se puede agregar', 'Producto ' + fileLine.productPresenter.referenceCode + ' no homologado');
      return;
    }
    if (this.linesReturn.length <= 0) {
      if (fileLine) {
        this.linesReturn.push(fileLine);
        this.validateReturLine(fileLine);

      } else {
        const selectedMeasure: MeasureConversionModel = this.getDefault(product);
        const transferLine: TransferDetailModel = {
          productPresenter: product,
          devolutionReason: '',
          devolutionConfirm: false,
          devolutionEnteredQuantity: 1,
          devolutionConvertedQuantity: new Decimal(1).mul(selectedMeasure.divideRate).toNumber(),
          availableQuantity: product.warehousePresenters[0].availableQuantity,
          maxQuantity: product.warehousePresenters[0].availableQuantity,
          measureUnits: this.getMeasures(product),
          selectedMeasure: selectedMeasure,

        };
        this.linesReturn.push(transferLine);
        this.validateReturLine(transferLine);
      }
    } else {
      if (fileLine) {
        const findLine: TransferDetailModel = this.linesReturn
          .find(line => line.productPresenter.referenceCode === fileLine.productPresenter.referenceCode);
        if (!findLine) {
          this.linesReturn.push(fileLine);
          this.validateReturLine(fileLine);
        } else {
          const quantity = findLine.devolutionEnteredQuantity + fileLine.devolutionEnteredQuantity;
          findLine.devolutionEnteredQuantity = quantity;
          findLine.devolutionConvertedQuantity = new Decimal(quantity).mul(findLine.selectedMeasure.divideRate).toNumber(),
            this.showInfo('Advertencia', 'Código ' + fileLine.productPresenter.referenceCode + ' duplicado');
        }
      } else {
        const findLine: TransferDetailModel = this.linesReturn.find(line => line.productPresenter.referenceId === product.referenceId);
        if (findLine) {
          findLine.devolutionEnteredQuantity++;
          this.validateReturLine(findLine);
        } else {
          const selectedMeasure: MeasureConversionModel = this.getDefault(product);
          const transferLine: TransferDetailModel = {
            productPresenter: product,
            devolutionReason: '',
            devolutionConfirm: false,
            devolutionEnteredQuantity: 1,
            devolutionConvertedQuantity: new Decimal(1).mul(selectedMeasure.divideRate).toNumber(),
            availableQuantity: product.warehousePresenters[0].availableQuantity,
            maxQuantity: product.warehousePresenters[0].availableQuantity,
            measureUnits: this.getMeasures(product),
            selectedMeasure: selectedMeasure,
          };
          this.linesReturn.push(transferLine);
          this.validateReturLine(transferLine);
        }

      }
    }
  }



  validateReturLine(line: TransferDetailModel) {
    // line.productPresenter.priceWithoutTax = line.selectedMeasure.price;
    line.maxQuantity = new Decimal(line.availableQuantity || 0)
      .mul(line.selectedMeasure.multiplyRate || line.multiplyRate)
      .toNumber();
    line.multiplyRate = line.selectedMeasure.multiplyRate;
    line.divideRate = line.selectedMeasure.divideRate;
    line.devolutionConvertedQuantity = new Decimal(line.devolutionEnteredQuantity).mul(line.selectedMeasure.divideRate).toNumber(),

      line.measureUnitPresenter = line.selectedMeasure.measureUnitPresenterTo;
    line.error = '';
    if (!line.devolutionConfirm) {
      line.error = 'Confirmación necesaria';
    }
    if (!line.devolutionReason || line.devolutionReason === DevolutionReasons.EMPTY) {
      line.error = 'Motivo incorrecto';
    }
    if (!line.selectedMeasure) {
      line.error = 'Unidad de Medida incorrecta';
    }
    if (!line.devolutionEnteredQuantity) {
      line.error = 'Cantidad incorrecta';
    }
    if (line.devolutionEnteredQuantity > line.maxQuantity) {
      line.error = 'Cantidad ingresada mayor a cantidad disponible';
    }
    if (!line.productPresenter.isHomologate) {
      line.error = 'Producto no homologado';
    }

    this.checkErrorsReturn();
  }

  checkErrorsReturn() {
    this.errorReturn = '';
    this.linesReturn.forEach((line) => {
      if (line.error) {
        this.errorReturn = 'No se puede realizar la solicitud';
      }
    });
  }
  deleteReturnLine(line: TransferDetailModel) {
    this.linesReturn = this.linesReturn.filter(
      (l) => !(l.productPresenter.productId === line.productPresenter.productId)
    );
    this.linesReturn.forEach(liner => this.validateReturLine(liner));
  }

  createReturnDocument(): TransfersModel {
    let lineNumber = 1;
    this.linesReturn.forEach((line) => {
      line.lineNumber = lineNumber;
      lineNumber++;
    });
    const format = 'yyyy-MM-dd hh:mm:ss';
    const locale = 'en-US';
    const transfer: TransfersModel = {
      number: null,
      transferId: this.transferId ? this.transferId : '',
      sequential: null,
      origin: this.dataProvider.defaultWarehouse,
      destiny: null,
      originReference: null,
      transferStatus: TransferStatus.SENT,
      transferMovementType: TransferMovementType.DEVOLUTION,
      userApproval: null,
      active: true,
      observation: this.observation,
      transferDetailPresenters: this.linesReturn,
      emissionPointPresenter: this.settingPresenter.view.selectedPointEmission,
      referenceId: this.transfer != null ? this.transfer.referenceId : '',
      userRequested: this.selectedUser,
      page: null,
      packages: this.packages,
      dateToSend: formatDate(this.dateToSend, format, locale)
    };
    return transfer;
  }
  sendFile(file: DevolutionFile[]) {
    this.blockUi();
    this.transferService.loadReturnFile(file).subscribe((res: TransferDetailModel[]) => {
      res.forEach((line) => {
        line.availableQuantity = line.productPresenter.warehousePresenters[0].availableQuantity;
        line.maxQuantity = line.productPresenter.warehousePresenters[0].availableQuantity;
        line.measureUnits = this.getMeasures(line.productPresenter);
        line.selectedMeasure = this.getDefault(line.productPresenter);
        line.error = '';
        this.addProductToLineReturn(null, line);

      });
    },
      (data: HttpErrorResponse) => {
        this.showError('', data.error.message);
      }
    );
  }


  deleteTransferDetail(status: TransferStatus) {
    if (this.linesReturn.length <= 0) { return; }
    if (status !== TransferStatus.DRAFT) { this.blockUi(); }
    this.transferService.deleteTransferDetail(this.transferId || '').subscribe(() => {
      this.saveDevolution(status);
    },
      (error: HttpErrorResponse) => {
        this.showError('Error', error.error.message);
      }
    );
  }


  saveDevolution(status: TransferStatus) {
    this.transferId = '';
    this.sequential = '';
    this.updateDevolution = '';
    const transfer: TransfersModel = this.createReturnDocument();
    transfer.transferStatus = status;
    if (transfer.observation && transfer.observation.length > 250) {
      this.showWarn(
        'Advertencia',
        'Observación no puede ser mayor a 250 caractéres'
      );
      return;
    }
    if (status !== TransferStatus.DRAFT) { this.blockUi(); }
    this.transferService.saveDraftDevolution(transfer).subscribe((resp: TransfersModel) => {
      if (status === TransferStatus.SENT) { this.showConfirmationMessage(resp.sequential); this.clear(); return; }
      resp.transferDetailPresenters.forEach(line => {
        this.linesReturn.filter(item => {
          if (line.lineNumber === item.lineNumber && line.productPresenter.referenceId === item.productPresenter.referenceId) {
            item.transferDetailId = line.transferDetailId;
          }
        });
      });
      this.transferId = resp.transferId;
      this.sequential = resp.sequential;
      this.updateDevolution = resp.transactionDate;
      this.showupdate = true;
    },
      (error: HttpErrorResponse) => {
        this.showError('Error', error.error.message);
      }
    );
  }

  saveAutomaticDevolution() {
    setInterval(() => {
      if (this.movementTypeSelected.value === TransferMovementType.DEVOLUTION) {
        this.deleteTransferDetail(TransferStatus.DRAFT);
      }
    }, 180000);
    setInterval(() => {
      if (this.movementTypeSelected.value === TransferMovementType.DEVOLUTION) {
        if (this.transferId && this.sequential && this.updateDevolution) {
          this.showupdate = false;
        }
      }
    }, 30000);

  }

  getTransferToGenerateDevolution(transfer: TransfersModel, isReport: boolean) {
    if (!transfer.transferId) {
      return;
    }
    this.blockUi();
    this.transferService.getTransferById(transfer.transferId, true).subscribe((res: TransfersModel) => {
      this.linesReturn = res.transferDetailPresenters;
      this.transferId = res.transferId;
      this.sequential = res.sequential;
      this.updateDevolution = res.transactionDate;
      this.showupdate = true;
      this.addLineDevolution();
      if (isReport) {
        this.setDraftDevolution();
      }
    },
      () => {
        this.showError('Atención', 'Algo salio mal');
      }
    );
  }
  addLineDevolution() {
    this.linesReturn.forEach((dev) => {
      dev.availableQuantity = dev.productPresenter.warehousePresenters[0].availableQuantity;
      dev.maxQuantity = dev.productPresenter.warehousePresenters[0].availableQuantity;
      dev.selectedMeasure = this.getDefaultLoad(dev.productPresenter, dev.measureUnitPresenter);
      dev.measureUnits = this.getMeasures(dev.productPresenter),

        this.validateReturLine(dev);
    });
  }
  getDefaultLoad(product: Product, measure: MeasureUnitPresenter): MeasureConversionModel {
    let returnValue: MeasureConversionModel = null;
    if (product.measureUnitConversionPresenters) {
      product.measureUnitConversionPresenters.forEach(unit => {
        if (unit.measureUnitPresenterTo.referenceId === measure.referenceId) {
          returnValue = unit;
          return returnValue;
        }
      });
    }
    return returnValue;

  }
  setDraftDevolution() {
    this.movementTypeSelected = this.transferMovementTypes[3];
    this.redirectTo('/panel/transfers/create-transfer');
  }
  redirectTo(ruta: string) {
    this.router.navigate([ruta]);
  }
}
