import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { leftToRigthAnimation, bottomToTop } from 'src/app/animations';
import { PromotionModel } from 'src/app/models/promotion-message.model';
import { PromotionProvider } from 'src/app/services/promotion.provider';
import { Subscription } from 'rxjs';
import { DataProvider } from 'src/app/services/data.provider';

@Component({
  animations: [leftToRigthAnimation, bottomToTop],
  selector: 'app-promotion-sugestions',
  templateUrl: './promotion-sugestions.component.html',
  styleUrls: ['./promotion-sugestions.component.scss']
})
export class PromotionSugestionsComponent implements OnInit, OnDestroy {
  selectedPromo: PromotionModel;
  suggestions: PromotionModel[] = [];
  expanded = false;
  currentPromo = 0;
  timer: any;
  timer2: any;
  scroll = false;
  subscription: Subscription;
  constructor(
    public provider: PromotionProvider,
    public dataProvider: DataProvider
  ) { }
  ngOnDestroy(): void {
    this.clearTimers();
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.showPromotions();
    this.subscription = this.provider.suggestions$.subscribe(suggestions => {
      this.suggestions = suggestions;
      if (suggestions.length && !this.selectedPromo) {
        this.selectedPromo = suggestions[0];
      }
    });
  }

  showPromotions() {
    this.timer = setTimeout(() => {
      if (this.suggestions.length > 1) {
        this.selectedPromo = null;
      }
      this.timer2 = setTimeout(() => {
        this.currentPromo++;
        this.currentPromo = this.currentPromo >= this.suggestions.length ? 0 : this.currentPromo;
        this.selectedPromo = this.suggestions[this.currentPromo];
        this.showPromotions();
      }, 300);
    }, 5000);
  }

  toogle() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      // clearTimeout(this.timer);
      setTimeout(() => {
        this.scroll = true;
      }, 300);
    } else {
      this.scroll = false;
      // this.showPromotions();
    }
  }

  clearTimers() {
    clearTimeout(this.timer);
    clearTimeout(this.timer2);

  }

}
