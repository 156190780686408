import { Product, WareHouse } from './product.model';
import { MeasureConversionComboModel } from './measure-combo.model';
import {
  MeasureConversionModel,
  MeasureUnitPresenter,
} from './measure-conversion.model';
import { UserPresenter } from './fulluser.model';
import { EmissionPointPresenter } from './pointemission.model';
export interface TransferDetailModel {
  transferDetailId?: string;
  productPresenter?: Product;
  loading?: boolean;
  availableQuantity?: number;
  requestedEnteredQuantity?: number;
  acceptedEnteredQuantity?: number;
  maxQuantity?: number;
  wareHouse?: WareHouse;
  error?: string;
  cross?: string;
  measureUnits?: MeasureConversionComboModel[];
  selectedMeasure?: MeasureConversionModel;
  allowPartial?: boolean;
  lineNumber?: number;
  active?: boolean;
  warehousePresenter?: WareHouse;
  origin?: WareHouse;
  requestedConvertedQuantity?: number;
  acceptedConvertedQuantity?: number;
  multiplyRate?: number;
  divideRate?: number;
  measureUnitPresenter?: MeasureUnitPresenter;
  observation?: string;
  quantity?: number;
  realQuantity?: number;
  missingQuantity?: number;
  surplusQuantity?: number;
  disrepairQuantity?: number;
  crossingQuantity?: number;
  incompleteQuantity?: number;
  lineObservation?: string;
  ingress?: number;
  showObservation?: boolean;
  claimQuantity?: number;
  claimReason?: ReasonClaim;
  availableProductStock?: number;
  claimedProductStock?: number;
  disponible?: number;
  devolutionReason?: string;
  devolutionConfirm?: boolean;
  devolutionEnteredQuantity?: number;
  devolutionConvertedQuantity?: number;
  crossProductPresenter ?: Product;
  showcrossProduct?: boolean;
  showSelectedCrossProduct?: boolean;
}

export interface TransfersModel {
  transferId?: string;
  number?: number;
  sequential?: string;
  secondarySequential?: string;
  createdDate?: Date;
  origin?: WareHouse;
  destiny?: WareHouse;
  originReference?: number;
  transferStatus?: TransferStatus;
  transferMovementType?: TransferMovementType;
  userApproval?: UserPresenter;
  userRequested?: UserPresenter;
  active?: boolean;
  observation?: string;
  transferDetailPresenters?: TransferDetailModel[];
  emissionPointPresenter?: EmissionPointPresenter;
  originObservation?: string;
  page?: number;
  loading?: boolean;
  generationType?: GenerationType;
  isExpired?: boolean;
  missingClaimReception?: number;
  disrepairClaimReception?: number;
  crossingClaimReception?: number;
  incompleteClaimReception?: number;
  warrantyClaimReception?: number;
  surplusClaimReception?: number;
  referenceId?: string;
  transactionDate?: string;
  packages?: number;
  dateToSend?: string;
}

export enum TransferMovementType {
  BETWEEN_PDV = 'BETWEEN_PDV',
  SUPPLYING = 'SUPPLYING',
  SPECIAL_ORDER = 'SPECIAL_ORDER',
  CLAIM = 'CLAIM',
  SUPPLYING_BETWEEN_PDV = 'SUPPLYING_BETWEEN_PDV',
  DEVOLUTION = 'DEVOLUTION',
}
export enum TransferStatus {
  SENT = 'SENT',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  PARTIAL_ACCEPTED = 'PARTIAL_ACCEPTED',
  REJECTED = 'REJECTED',
  VOIDED = 'VOIDED',
  RECEIVED = 'RECEIVED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT'
}
export enum ReasonClaim {
  MISSING = 'MISSING',
  DISREPAIR = 'DISREPAIR',
  INCOMPLETE = 'INCOMPLETE',
  CROSSING = 'CROSSING',
  WARRANTY = 'WARRANTY',
  EMPTY = 'EMPTY',
  SURPLUS = 'SURPLUS',
}

export enum GenerationType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}
export enum DevolutionReasons {
  EMPTY = 'EMPTY',
  SOBRESTOCK = 'SOBRESTOCK',
  BAJAROTACION = 'BAJA ROTACIÓN',
}

export interface DevolutionFile {
  referenceCode: string;
  quantity: number;
  reason: string;
}
