import { Presenter } from 'src/app/core/presenter';
import { SearchAdvancesView } from '../search-advances.view';
import { ClientAdvanceService } from 'src/app/services/client-advance.service';
import { DataProvider } from '../../../../../../services/data.provider';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class SearchAdvancesPresenter implements Presenter {
    view: SearchAdvancesView;
    constructor(
        private dataProvider: DataProvider,
        private clientAdvanceService: ClientAdvanceService
    ) {

    }

    init() {
        this.view.isCPOS = this.dataProvider.isConfigurationCpos;
    }

    getClientAdvances() {
        this.clientAdvanceService.getClientAdvances(this.view.clientSelected.clientId).subscribe((res: any) => {
                this.view.clientAdvances = res;
                console.log(res);
            });
    }

    getPdf() {
        this.view.blockUi();
        this.clientAdvanceService
            .getPdf(this.view.clientAdvanceSelected.clientAdvanceId)
            .subscribe(
                (data) => {
                    this.view.showPdf(data);
                },
                () => {
                    this.view.allowUi();
                    this.view.showError('', 'Error imprimiendo Anticipo');
                }
            );
    }

    postSynchronice() {
        this.view.blockUi();
        this.clientAdvanceService.postSyncronice(this.view.clientAdvanceSelected.clientAdvanceId).subscribe((data) => {
            this.view.allowUi();
            this.view.showSuccess('', 'Resincronización exitosa');
        }, () => {
            this.view.allowUi();
            this.view.showError('', 'Ocurrió un error al resincronizar el Anticipo');
        });
    }

    cancel() {
        this.view.blockUi();
        this.clientAdvanceService.cancel(this.view.clientAdvanceSelected.clientAdvanceId, this.view.isCPOS).subscribe(
            (res: boolean) => {
                this.view.allowUi();
                if (res) {
                    this.view.clientAdvanceSelected.state = 'ANULADO';
                    this.view.showSuccess('', 'Anticipo cancelado con éxito');
                } else {
                    this.view.showError('', 'Anticipo no puede ser cancelado. Ya se encuentra cerrada la caja o el método de pago no se encuentra permitido');
                }
            },
            (error: HttpErrorResponse) => {
                this.view.allowUi();
                console.log(error.message);
                this.view.showError('Error', 'Ocurrió un ERROR INESPERADO CANCELANDO ANTICIPO ');
            }
        );
    }

}
