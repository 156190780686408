import { AppRoutes } from './app.routes';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';

import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './reducers/user.reducer';
import { ProductsModule } from './main-components/panel/pages/product/products.module';
import { PanelMasterModule } from './main-components/panel/panelmaster.module';
import { LoginComponent } from './main-components/single/login/login.component';
import { BreadcrumbService } from './services/breadcrumb.service';
import { AuthGuard } from './core/auth.guard';
import { DialogService, MessageService } from 'primeng/api';
import { HeaderInterceptor } from './core/auth.interceptor';
import { UiUtils } from './core/ui-utils';
import { ClientsModule } from './main-components/panel/pages/client/clients.module';
import { OrderReducer } from './reducers/order.reducer';
import { ClientReducer } from './reducers/client.reducer';
import { PaymentsDetailComponent } from './main-components/panel/pages/pos/payments-detail/payments-detail.component';
import { BankService } from './services/bank.service';
import { KeyFilterModule } from 'primeng/primeng';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InvoiceReducer } from './reducers/invoice.reducer';
import { BlockUIModule } from 'ng-block-ui';
import { HotkeyModule } from 'angular2-hotkeys';
import localeEc from '@angular/common/locales/es-US';
import { registerLocaleData } from '@angular/common';
import { AdviserReducer } from './reducers/adviser.reducer';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { ForgetPasswordComponent } from './main-components/single/forget-password/forget-password.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule
} from '@angular/material';
import { ResetPasswordComponent } from './main-components/single/reset-password/reset-password.component';
import { ResetPasswordPresenter } from './main-components/single/reset-password/presenter/reset-password.presenter';
import { SearchReportComponent } from './main-components/panel/pages/dashboard/cash-register/search-report/search-report.component';
import { AdvancesDetailComponent } from './main-components/panel/pages/pos/advances-detail/advances-detail.component';
import { AdvanceReducer } from './reducers/advance.reducer';
import { SellerReducer } from './reducers/seller.reducer';

registerLocaleData(localeEc, 'es-US');
declare global {
  interface Navigator {
     msSaveBlob: (blob: Blob, fileName: string) => boolean;
     }
  }
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        GrowlModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        ProductsModule,
        ClientsModule,
        PanelMasterModule,
        StoreModule.forRoot({
            orderLines: OrderReducer, client: ClientReducer, userState: userReducer,
            invoice: InvoiceReducer, adviser: AdviserReducer, paymentsAdvance: AdvanceReducer, seller: SellerReducer
        }),
        HttpClientModule,
        PaginatorModule,
        KeyFilterModule,
        ScrollPanelModule,
        BlockUIModule.forRoot(),
        HotkeyModule,
        DynamicDialogModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatAutocompleteModule,
    ],
    declarations: [
        AppComponent,
        SearchReportComponent,
        LoginComponent,
        PaymentsDetailComponent,
        ForgetPasswordComponent,
        ResetPasswordComponent,
        AdvancesDetailComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-US' },
        UiUtils,
        BreadcrumbService,
        AuthGuard,
        MessageService,
        BankService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        DialogService,
        ResetPasswordPresenter
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
        ForgetPasswordComponent
    ]

})
export class AppModule { }
