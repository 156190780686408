import { PrintPriceView } from '../print-price.view';
import { Injectable } from '@angular/core';
import { Presenter } from 'src/app/core/presenter';
import { CurrentPriceService } from 'src/app/services/current-price.service';
import { ReportService } from 'src/app/services/report.service';
import { Product } from 'src/app/models/product.model';

@Injectable({
    providedIn: 'root',
})
export class PrintPricePresenter implements Presenter {
    view: PrintPriceView;
    constructor(
        public currentPriceService: CurrentPriceService,
        public reportService: ReportService,
    ) { }
    getCurrentPrices() {
        this.view.blockUi();
        this.currentPriceService.getCurrentPrices().subscribe(res => {
            this.view.printCurrentPrices(res);
            this.currentPriceService.size = this.currentPriceService.available;
            this.currentPriceService.available = 0;

        }, (error: any) => {
            const err = JSON.parse(error.error);
            this.view.showError('', err.message);
        });
    }
    getAllPrices() {
        this.view.blockUi();
        this.currentPriceService.getAllPrices().subscribe(allLalbels => {
            this.view.printCurrentPrices(allLalbels);
            this.currentPriceService.size = this.currentPriceService.available;
            this.currentPriceService.available = 0;
        }, (error: any) => {
            const err = JSON.parse(error.error);
            this.view.showError('', err.message);
        });
    }
    getPriceBycode() {
        this.view.blockUi();
        this.currentPriceService.getPriceBycode(this.view.code).subscribe(codeLabel => {
            this.view.printCurrentPrices(codeLabel);
            this.currentPriceService.size = this.currentPriceService.available;
            this.currentPriceService.available = 0;
        }, (error: any) => {
            const err = JSON.parse(error.error);
            this.view.showError('', err.message);
        });
    }
    getRangePrices() {
        if (this.view.to > this.currentPriceService.size || this.view.from > this.currentPriceService.size) {
            this.view.showError('', 'Ingrese un rango válido');
            return;
        }
        if (this.view.from > this.view.to) {
            this.view.showError('', 'Ingrese un rango válido');
            return;
        }
        this.view.blockUi();
        this.currentPriceService.getRangePrices(this.view.from, this.view.to).subscribe(res => {
            this.view.printCurrentPrices(res);
            this.currentPriceService.size = this.view.to - this.view.from + 1 ;
            this.view.to = null;
            this.view.from = null;
        }, (error: any) => {
            const err = JSON.parse(error.error);
            this.view.showError('', err.message);
        });
    }
    sentCodes() {
        this.view.blockUi();
        this.view.codes = Array.from(new Set(this.view.codes));
        this.currentPriceService.getCodeLabel(this.view.codes).subscribe(res => {
            this.view.printCurrentPrices(res);
        }, () => {
            this.view.showError('', 'Archivo sin etiquetas válidas');
        });
    }

    getProductsWithStockFile() {
        this.view.blockUi();
        this.reportService.getReportStock(null).subscribe((res: Product[]) => {
            this.view.products = res.map(product => {
                return { codigo: product.referenceCode };
            });
            if (this.view.products.length <= 0) {
                this.view.products = [];
                this.view.showError('', 'Sin etiquetas para imprimir');
            }
            this.productsToCSV();
        }, () => {
            this.view.showError('', 'No hay códigos para descargar');

        });
    }
    getAutoServiceFile() {
        this.view.blockUi();
        this.currentPriceService.getAutoServiceFile().subscribe((res: any[]) => {
            this.view.products = res.map(code => {
                return {
                    codigo: code
                };
            });
            if (this.view.products.length <= 0) {
                this.view.products = [];
                this.view.showError('', 'Sin etiquetas para imprimir');
            }
            this.productsToCSV();
        }, () => {
            this.view.showError('', 'No hay códigos para descargar 1');

        });
    }

    productsToCSV() {

        const products = JSON.parse(JSON.stringify(this.view.products, ['codigo'], 2));
        this.view.csvDownload('Códigos.csv', products);
    }

    getAutoServiceFilePDF() {
        this.view.blockUi();
        this.currentPriceService.getAutoServiceFilePDF().subscribe((data: any) => {
            this.view.showPdf(data);
        }, () => {
            this.view.showError('', 'Sin registros');
        });
    }
}
