import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/primeng';
import { AbstractView } from 'src/app/core/abstract_view';
import { InvoiceModel } from 'src/app/models/invoice.model';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { InvoiceSalesModel, SalesCategoryModel } from 'src/app/models/product.model';
import { DomainAction } from 'src/app/models/rol.model';
import { DataProvider } from 'src/app/services/data.provider';
import { ProductCategorySalesReportPresenter } from './presenter/product-category-sales-report.presenter';
import { ProductCategorySalesReportView } from './product-category-sales-report.view';

@Component({
  selector: 'app-product-category-sales-report',
  templateUrl: './product-category-sales-report.component.html',
  styleUrls: ['./product-category-sales-report.component.scss']
})

export class ProductCategorySalesReportComponent extends AbstractView implements OnInit, ProductCategorySalesReportView {

  invoices: InvoiceModel[];
  searchValue: '';
  labelFE: string;
  labelPE: string;
  initDate = new Date();
  endDate = new Date();
  page = 0;
  size = 20;
  pageInvoice = 0;
  sizeInvoice = 20;
  totalElements: number;
  totalElementsInvoice: number;
  public ref;
  invoiceSelected: InvoiceModel;
  data: any;
  getTotalAmount: any;

  dognut: any;
  line: any;
  visible = false;
  productCategories: InvoiceSalesModel[] = [];
  totalIsCreditMemo = 0;
  totalIstCreditMemo = 0;
  total = 0;
  constructor(
    private messageService: MessageService,
    public router: Router,
    public salesPresenter: ProductCategorySalesReportPresenter,
    public dataProvider: DataProvider

  ) {
    super(messageService, router);
    if (!this.getAuth(DomainAction.ALLOW_POS_REPORT_SALES)) {
      this.redirectTo('/panel/pos');
    }
    salesPresenter.view = this;

    if (dataProvider.defaultWarehouse.businessUnit === 'agrotiendas') {
      this.labelFE = 'Fertilizantes';
      this.labelPE = 'Fitosanitario y otros';
    } else {
      this.labelFE = 'Pesado';
      this.labelPE = 'Ferretero';
    }
    this.data = {
      labels: this.productCategories.map(line => line.created),
      datasets: [
        {
          label: this.labelFE,
          data: 0,
          fill: false,
          borderColor: 'tomato',

        },
        {
          label: this.labelPE,
          data: 0,
          fill: false,
          borderColor: '#565656',

        }
      ]
    };
  }
  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.salesPresenter.getInvoicesForUserReport();
  }

  selectInvoice(invoice: InvoiceModel) {
    this.invoiceSelected = invoice;
  }
  printSalesReport() {
    this.salesPresenter.printSalesReport();
  }
  getTranslate(paymentMethod: PaymentMethod) {
    switch (paymentMethod) {
      case PaymentMethod.CASH: return 'Efectivo';
      case PaymentMethod.BANK_TRANSFER: return 'Transferencia Bancaria';
      case PaymentMethod.CHECK: return 'Cheque';
      case PaymentMethod.CREDIT: return 'Crédito';
      case PaymentMethod.CREDIT_CARD: return 'Tarjeta de crédito';
      case PaymentMethod.CREDIT_MEMO: return 'Nota de crédito';
      case PaymentMethod.DEBIT: return 'Tarjeta de débito';
      case PaymentMethod.WITHHOLD: return 'Retención';
    }
  }
  paginateInvoice($event) {
    this.pageInvoice = $event['page'];
    this.salesPresenter.getInvoicesForUserReport();
  }

  csvDownload(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  selectData(event) {
    this.messageService.add({
      severity: 'info',
      summary: 'Data Selected',
      detail: this.data.datasets[event.element._datasetIndex].data[event.element._index]
    });
  }

}
