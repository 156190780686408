import { Presenter } from 'src/app/core/presenter';

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { User } from 'src/app/models/user.model';
import { LogIn } from 'src/app/reducers/user.action';
import { UserService } from 'src/app/services/user.service';
import { SearchClientView } from '../search-client.view';
import { ClientService } from 'src/app/services/client.service';
import { Client, ClientModel } from 'src/app/models/client.model';


@Injectable({
    providedIn: 'root',
})
export class SearchClientPresenter implements Presenter {
    view: SearchClientView;
    constructor(private clientService: ClientService) {
    }

    onSearchClient() {
        const searchValue = this.view.search;
        const page = this.view.page;
        const size = this.view.size;
        if (searchValue.length < 3) {
            return;
        }
        if (page < 0) {
            return;
        }
        if (!size || size < 1) {
            return;
        }
        this.clientService.searchClient(searchValue, page, size).then(data => {
            const clients: ClientModel[] = data['data'];
            this.view.totalElements = data['totalElements'];
            this.view.clients = clients;
        }).catch((error) => {
            this.view.showError('Atención', 'Ocurrió un problema al intentar buscar los clientes');
        });
    }

}
