import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultConfigurationPresenter, DefaultConfigurationType } from '../models/defaultconfigurations.model';
import { environment } from 'src/environments/environment';
import { PromotionProvider } from './promotion.provider';
import { DataProvider } from './data.provider';
export const Settings = {
  defaultMinBilletonAmount: 20,
  defaultBilletonDiscountAmount: 1,
};
export const GeneralConfigurations = {
  specialPriceLimit: 1000,
  wholesalerPriceLimit: 2000,
  administratorPriceLimit: 3000,
  initialBoxPercentage: 0,
  invoicePercentage: 0,
  blink: true,
  creditCardPayment: true,
  deferred: true,
  creditCardIncrement: 4,
  defaultTax: 0,
  applyPromoDay: false,
  defaultAmountCreditCard: 0,
  minimumAmountDeferrer: 0
};

@Injectable({
  providedIn: 'root'
})
export class DefaultconfigurationsService {

  defaultMaxFinalConsumerAmount = 200;
  defaultMinFinalConsumerAmount = 40;
  defaultMaxCreditDebitFinalConsumerAmount = 30;
  advanceMinAmount = 100;
  defaultSynchronizedData: any;
  showSalesExpress: any;
  showAdminPriceDiscount: any;
  showCredentialsAdminPriceDiscount = true;
  defaultCreditDebitPercentageAmount = 4;
  defaultMinBilletonAmount = 20;
  defaultBilletonDiscountAmount = 1;
  defaultConfigurationPresenter: DefaultConfigurationPresenter[] = [];
  specialPriceLimit = 1000;
  wholesalerPriceLimit = 2000;
  administratorPriceLimit = 3000;
  initialBoxPercentage = 5;
  invoicePercentage = 20;
  enablePromotions = false;
  defaultAmountCreditCard = 0;

  constructor(private http: HttpClient, private promotionProvider?: PromotionProvider, private dataProvider?: DataProvider) { }


  public saveDefaultConfiguration(defaultConfigurationPresenter: DefaultConfigurationPresenter): Promise<DefaultConfigurationPresenter[]> {
    return new Promise((resolve, reject) => {
      this.http.post<DefaultConfigurationPresenter[]>(environment.apiUrl + '/saveDefaultConfiguration', defaultConfigurationPresenter)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }



  public findDefaultConfigurationsHttp(): Promise<DefaultConfigurationPresenter[]> {
    return new Promise((resolve, reject) => {
      this.http.get<DefaultConfigurationPresenter[]>(environment.apiUrl + '/findDefaultConfigurations')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }


  getDefaultConf() {

    return this.findDefaultConfigurationsHttp().then(data => {
      this.setDefaultConf(data);
    });
  }

  setDefaultConf(def: DefaultConfigurationPresenter[]) {

    this.defaultConfigurationPresenter = def;
    this.defaultConfigurationPresenter.forEach(defConf => {
      switch (defConf.defaultConfigurationType) {
        case DefaultConfigurationType.DEFAULT_MAX_FINAL_CONSUMER_AMOUNT:
          this.defaultMaxFinalConsumerAmount = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_MIN_FINAL_CONSUMER_AMOUNT:
          this.defaultMinFinalConsumerAmount = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_MAX_CREDIT_DEBIT_FINAL_CONSUMER_AMOUNT:
          this.defaultMaxCreditDebitFinalConsumerAmount = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_AMOUNT_MOVEMENT_ADVANCE:
          this.advanceMinAmount = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_SYNCHRONIZED_DATA:
          this.defaultSynchronizedData = Boolean(defConf.value === 'true' || defConf.value === 'TRUE');
          break;
        case DefaultConfigurationType.DEFAULT_SALES_EXPRESS:
          defConf.value = (defConf.value === 'true') ? true : false;
          this.showSalesExpress = defConf.value;
          break;
        case DefaultConfigurationType.DEFAULT_AMOUNT_CREDIT_DEBIT_CARD_SALES:
          this.defaultCreditDebitPercentageAmount = Number(defConf.value);
          GeneralConfigurations.creditCardIncrement = this.defaultCreditDebitPercentageAmount;
          break;
        case DefaultConfigurationType.DEFAULT_BILLETON_DISCOUNT_AMOUNT:
          this.defaultBilletonDiscountAmount = Number(defConf.value);
          Settings.defaultBilletonDiscountAmount = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_MIN_BILLETON_AMOUNT:
          this.defaultMinBilletonAmount = Number(defConf.value);
          Settings.defaultMinBilletonAmount = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_ADMIN_PRICE:
          defConf.value = (defConf.value === 'true') ? true : false;
          this.showAdminPriceDiscount = defConf.value;
          break;
        case DefaultConfigurationType.DEFAULT_CREDENTIALS_ADMIN_PRICE:
          defConf.value = (defConf.value === 'true') ? true : false;
          this.showCredentialsAdminPriceDiscount = defConf.value;
          break;
        case DefaultConfigurationType.DEFAULT_PROMOTIONS_ACTIVE:
          defConf.value = (defConf.value === 'true') ? true : false;
          this.enablePromotions = defConf.value;
          this.promotionProvider.enabled = defConf.value;
          break;
        case DefaultConfigurationType.MINIMUM_AMOUNT_DEFERRED:
          GeneralConfigurations.minimumAmountDeferrer = Number(defConf.value);
          break;
        case DefaultConfigurationType.DEFAULT_SHOW_DEVOLUTIONS:
          defConf.value = (defConf.value === 'true') ? true : false;
          this.dataProvider.showConfigDevolutions = defConf.value;
          break;
      }
    });
    this.defaultConfigurationPresenter = this.defaultConfigurationPresenter.filter(conf => {
      return conf.defaultConfigurationType !== DefaultConfigurationType.DEFAULT_MIN_BILLETON_AMOUNT;
    });
    console.log('this.dataProvider.showConfigDevolutions', this.dataProvider.showConfigDevolutions);
  }

  getGeneralConfigurations() {
    return this.http.get(environment.apiUrl + '/getGeneralConfigurations')
      .subscribe((def: DefaultConfigurationPresenter[]) => {
        def.forEach(defConf => {
          switch (defConf.generalConfigurationType) {
            case DefaultConfigurationType.SPECIAL_PRICE_LIMIT:
              GeneralConfigurations.specialPriceLimit = Number(defConf.value);
              this.specialPriceLimit = Number(defConf.value);
              break;
            case DefaultConfigurationType.WHOLE_SALER_PRICE_LIMIT:
              GeneralConfigurations.wholesalerPriceLimit = Number(defConf.value);
              this.wholesalerPriceLimit = Number(defConf.value);
              break;
            case DefaultConfigurationType.ADMINISTRATOR_PRICE_LIMIT:
              GeneralConfigurations.administratorPriceLimit = Number(defConf.value);
              this.administratorPriceLimit = Number(defConf.value);
              break;
            case DefaultConfigurationType.INITIAL_BOX_PERCENTAGE:
              GeneralConfigurations.initialBoxPercentage = Number(defConf.value);
              this.initialBoxPercentage = Number(defConf.value);
              break;
            case DefaultConfigurationType.INVOICE_PERCENTAGE:
              GeneralConfigurations.invoicePercentage = Number(defConf.value);
              this.invoicePercentage = Number(defConf.value);
              break;
            case DefaultConfigurationType.AMOUNT_CREDIT_CARD_DAY_PROMO:
              GeneralConfigurations.defaultAmountCreditCard = Number(defConf.value);
              this.defaultAmountCreditCard = Number(defConf.value);
              break;
            case DefaultConfigurationType.DEFAULT_EXPIRE_TIME_TO_CLAIMS:
              this.dataProvider.defaultTimeToClaim = Number(defConf.value);
              break;
          }
        });
      });
  }
}

