import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Country } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient) { }

  public searchCountry(): Promise<Country[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Country[]>(environment.apiUrl + '/country')
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
