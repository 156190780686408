import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MessageService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { PaymentMethod } from 'src/app/models/paymentmethod';
import { DayRegisterDetailToShowModel } from 'src/app/models/cash-register.model';
import { AbstractView } from 'src/app/core/abstract_view';
import { CloseDayReportView } from './close-day-report.view';
import { SystemReportModel, ReportCloseDayModel, AdvanceModel } from 'src/app/models/report.model';
import { ClosDayReportPresenter } from './presenter/close-day-report.presenter';
import { DataProvider } from 'src/app/services/data.provider';

@Component({
  selector: 'app-close-day-report',
  templateUrl: './close-day-report.component.html',
  styleUrls: ['./close-day-report.component.scss']
})
export class CloseDayReportComponent extends AbstractView implements OnInit, CloseDayReportView, AfterViewInit {

  systemReport: SystemReportModel = {
    BANK_TRANSFER: 0,
    CASH: 0,
    CHECK: 0,
    CREDIT: 0,
    CREDIT_CARD: 0,
    CREDIT_MEMO: 0,
    DEBIT: 0,
    WITHHOLD: 0,
    TOTAL_WITHOUT_DISPATCH: 0,
    SUBVENTION: 0
  };
  userReport: SystemReportModel = {
    BANK_TRANSFER: 0,
    CASH: 0,
    CHECK: 0,
    CREDIT: 0,
    CREDIT_CARD: 0,
    CREDIT_MEMO: 0,
    DEBIT: 0,
    WITHHOLD: 0,
    TOTAL_WITHOUT_DISPATCH: 0,
    SUBVENTION: 0
  };
  resume: SystemReportModel = {
    BANK_TRANSFER: 0,
    CASH: 0,
    CHECK: 0,
    CREDIT: 0,
    CREDIT_CARD: 0,
    CREDIT_MEMO: 0,
    DEBIT: 0,
    WITHHOLD: 0,
    TOTAL_WITHOUT_DISPATCH: 0,
    SUBVENTION: 0
  };
  dailyReport: ReportCloseDayModel;
  total = 0;
  hour = new Date();
  selectedReport: ReportCloseDayModel;
  barData: any;
  advances: AdvanceModel[] = [];
  userReportTotal: number;
  systemReportTotal: number;
  constructor(
    public closeDayReportPresenter: ClosDayReportPresenter,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: MessageService,
    public router: Router,
    public dataProvider: DataProvider,
  ) {
    super(service, router);
    const source = interval(1000);
    source.subscribe(() => this.hour = new Date());
    closeDayReportPresenter.view = this;
    this.selectedReport = config.data;


  }


  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.closeDayReportPresenter.getSystemReport();
    this.closeDayReportPresenter.getDailyReport();
    this.closeDayReportPresenter.getCashRegisterResume();
  }
}
